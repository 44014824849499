import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import Tile from '@igenius_dev/crystalkit/lib/components/Tile';
import { startWizard } from 'modules/payment-wizard/actions';
import { projectCreationActions } from 'modules/project/create/actions';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import { HubState } from 'types';
import { Wrapper } from 'views/elements';
import Layout from '../../Layout';
import {
    Border,
    FluidSubmitButton,
    InfoBox,
    InfoIcon,
    InfoText,
    LearnMore,
    LeftContent,
    RightContent,
    StyledForm,
    StyledTile,
    SubmitButton,
    TitleContainer,
    WelcomeTitle,
} from './elements';
import { timezones } from './fixtures';
import { SelectPlan } from './Plan/SelectPlan';
import { ProjectSettings } from './Settings/ProjectSettings';
import { SlightlyBiggerModal } from '../../TenantUsageLimit/elements';
import { TimeInterval } from 'core/entities/Price';
import { LanguageView, PlanForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';
import { projectCreationSelectors } from 'modules/project/create/selectors';

export interface ProjectCreationFormData {
    domain: string;
    name: string;
    timezone: string;
    languages: { list: { label: string; value: string }[]; favorite: string };
    favoriteLanguage: string;
    planId: string;
    cycle: string;
}

function getPriceId(plans: PlanForView[], data: { planId: string; cycle: string }) {
    return plans
        .find((plan) => plan.id === data.planId)!
        .prices.find((price) => price.timeInterval === data.cycle)!.id;
}

export type ProjectCreatorLabels = {
    title: string;
    subtitle: string;
    submit: string;
};

export type ProjectCreatorProps = {
    labels: ProjectCreatorLabels;
    inTrial: boolean;
    domainValidator: (domain: string) => Promise<boolean>;
    domainNormalizer: (domain: string) => string;
};

export const ProjectCreator: React.FC<ProjectCreatorProps> = (props) => {
    const { labels, inTrial, domainValidator: validator, domainNormalizer: normalizer } = props;

    const [planSelectionModalOpen, setPlanSelectionModalOpen] = useState<boolean>(false);
    const dispatch = useDispatch();
    const t = useTranslator();
    const plans = useSelector<HubState, PlanForView[]>(projectCreationInfoSelectors.selectPlans);
    const formMethods = useForm<ProjectCreationFormData>({
        mode: 'all',
        defaultValues: {
            planId: plans[0].id,
            cycle: 'month',
        },
    });
    const selectedPlan = useSelector<HubState, PlanForView | undefined>(
        projectCreationSelectors.selectActivePlan,
    );
    const languages = useSelector<HubState, LanguageView[]>(
        projectCreationInfoSelectors.selectLanguages,
    );

    const domain = useSelector<HubState, string>(projectCreationInfoSelectors.selectDomain);

    const { handleSubmit, formState, getValues } = formMethods;

    const currentPlan = plans.find((plan) => selectedPlan?.id === plan.id) ?? plans[0];
    const plan = selectedPlan || currentPlan;

    const openSelectionPlanModal = () => setPlanSelectionModalOpen(true);
    const closeSelectionPlanModal = () => {
        const { planId } = getValues();

        dispatch(projectCreationActions.update({ planId }));

        setPlanSelectionModalOpen(false);
    };
    const onSubmit = (data: ProjectCreationFormData) => {
        const { cycle, timezone, name, planId, domain, languages } = data;
        dispatch(
            projectCreationActions.update({
                planId,
                billingCycle: cycle as TimeInterval,
                priceId: getPriceId(plans, { cycle, planId }),
                domain,
                name,
                timezone,
                languages: languages?.list.map((lang) => lang.value),
                favorite_lang_id: languages.favorite,
            }),
        );
        dispatch(startWizard({}));
    };

    return (
        <Wrapper>
            <FormProvider {...formMethods}>
                <StyledForm onSubmit={handleSubmit(onSubmit)}>
                    <Layout
                        leftContent={
                            <LeftContent>
                                <TitleContainer>
                                    <WelcomeTitle>{labels.title}</WelcomeTitle>
                                    <SubmitButton
                                        label={labels.submit}
                                        loading={formState.isSubmitting}
                                        disabled={!formState.isValid}
                                    />
                                </TitleContainer>

                                <InfoBox>
                                    <Border />
                                    <InfoIcon />
                                    <InfoText
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                plan?.description?.notification &&
                                                t(plan.description.notification ?? '', {
                                                    payload: {
                                                        plan: plan.name,
                                                        PLAN: plan.name,
                                                    },
                                                }),
                                        }}
                                    />
                                    <LearnMore
                                        label={t(
                                            'hub-responsive-inline-notification-button-learn-more',
                                        )}
                                        onClick={openSelectionPlanModal}
                                    />
                                </InfoBox>

                                <StyledTile>
                                    <Tile.Header>
                                        <Tile.Title style={{ marginBottom: '16px' }}>
                                            {labels.subtitle}
                                        </Tile.Title>
                                    </Tile.Header>
                                    <Tile.Content>
                                        <ProjectSettings
                                            domain={domain}
                                            languages={{
                                                list: languages,
                                                favorite: '',
                                            }}
                                            timezones={timezones}
                                            domainValidator={validator}
                                            domainNormalizer={normalizer}
                                        />
                                    </Tile.Content>
                                </StyledTile>
                            </LeftContent>
                        }
                        rightContent={
                            <RightContent>
                                <SelectPlan
                                    selectedPlanId={currentPlan.id}
                                    showTrialDisclaimer={inTrial}
                                    plans={plans}
                                    actions={[
                                        <FluidSubmitButton
                                            label={labels.submit}
                                            loading={formState.isSubmitting}
                                            disabled={!formState.isValid}
                                        />,
                                    ]}
                                />
                                <SlightlyBiggerModal
                                    content={
                                        <div data-testid="select-plan-tablet">
                                            <SelectPlan
                                                showTrialDisclaimer
                                                plans={plans}
                                                actions={[
                                                    <FluidSubmitButton
                                                        label={labels.submit}
                                                        loading={formState.isSubmitting}
                                                        onClick={closeSelectionPlanModal}
                                                    />,
                                                ]}
                                            />
                                        </div>
                                    }
                                    open={planSelectionModalOpen}
                                    onClose={closeSelectionPlanModal}
                                    onOutsideClick={closeSelectionPlanModal}
                                />
                            </RightContent>
                        }
                    />
                </StyledForm>
            </FormProvider>
        </Wrapper>
    );
};
