import styled from 'styled-components';

import CircularLoader from '@igenius_dev/crystalkit/lib/components/CircularLoader';

export const CenteredLoader = styled(CircularLoader)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
