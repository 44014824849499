import { I18nDictionary, I18nModuleStatus } from './types';
import { DictionaryAction, fetchDictionary } from './actions';
import { getType } from 'typesafe-actions';
import { combineReducers } from 'redux';

const dictionary = (state = {}, action: DictionaryAction): I18nDictionary => {
    switch (action.type) {
        case getType(fetchDictionary.success): {
            return action.payload;
        }
        default: {
            return state;
        }
    }
};

const status = (
    state: I18nModuleStatus = 'LOADING',
    action: DictionaryAction,
): I18nModuleStatus => {
    switch (action.type) {
        case getType(fetchDictionary.request): {
            return 'LOADING';
        }
        case getType(fetchDictionary.success): {
            return 'READY';
        }
        case getType(fetchDictionary.failure): {
            return 'ERROR';
        }
        default: {
            return state;
        }
    }
};

export default combineReducers({
    dictionary,
    status,
});
