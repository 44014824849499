import { ChipSlider } from 'components/ChipSlider';
import { LargeText } from 'components/Typography/LargeText';
import React from 'react';
import { HeaderContainer, Subtitle } from './elements';

type Props = {
    title: string;
    subtitle: string;
    showShadow: boolean;
    defaultChip: 'person' | 'company';
    onChangeChip: (v: 'person' | 'company') => void;
};
export const Header = (props: Props) => (
    <HeaderContainer showShadow={props.showShadow}>
        <LargeText>{props.title}</LargeText>
        <Subtitle>{props.subtitle}</Subtitle>
        <ChipSlider
            chips={[
                { label: 'Company', value: 'company' }, // TODO: use hub-billing-details-dialogue-toggle-company
                { label: 'Person', value: 'person' }, // TODO: use hub-billing-details-dialogue-toggle-person
            ]}
            currentSelection={props.defaultChip}
            onChange={props.onChangeChip}
        />
    </HeaderContainer>
);
