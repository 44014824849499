import styled from 'styled-components';
import { BaseText } from 'components/Typography/BaseText';
import { LargeText } from 'components/Typography/LargeText';
import { SmallText } from 'components/Typography/SmallText';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { TotalPriceWithNextBilling } from 'components/Price/TotalPriceWithNextBilling';

export const Description = styled(BaseText)`
    margin-top: 16px;
`;

export const Title = styled(LargeText)``;

export const Rectangle = styled.div`
    padding: 0 24px;
    background-color: ${theme.colors.values.blue15};
    margin-top: 44px;
    border-radius: 8px;
`;

export const Label = styled(SmallText).attrs({
    color: theme.colors.names.purple700,
})`
    font-weight: ${theme.typography.weights.values.medium};
`;

export const Value = styled(SmallText).attrs({
    color: theme.colors.names.black300,
})``;

export const Row = styled.div`
    display: flex;
    padding: 16px 0;
    align-items: center;
    border-bottom: 1px solid ${theme.colors.values.black50};

    &:last-child {
        border-bottom: none;
    }

    ${Label} {
        display: flex;
        width: 40%;
    }

    ${Value} {
        display: flex;
        width: 60%;
    }
`;

export const SeatsPriceContainer = styled.div`
    margin-top: 24px;
    padding-bottom: 16px;
    border-bottom: 1px solid ${theme.colors.values.blue50};

    ${theme.typography.scales.values.Small as any};
`;

export const TotalRecap = styled(TotalPriceWithNextBilling)`
    margin-top: 16px;
`;

export const LearnMore = styled(SmallText).attrs({
    color: theme.colors.names.black200,
})``;

export const LearnMoreContainer = styled.div`
    margin-top: 16px;
    display: flex;
    justify-content: center;
`;
