import Text from '@igenius_dev/crystalkit/lib/components/Text';
import React from 'react';
import { CustomAvatar } from 'components/Sidebar/elements';

export const TaggedAvatar = ({
    label,
    color,
}: {
    label: string;
    color: { background: string; icon: string };
}) => {
    const initial = label.charAt(0).toUpperCase();

    return (
        <CustomAvatar bg={color.background}>
            <Text color="white">{initial}</Text>
        </CustomAvatar>
    );
};
