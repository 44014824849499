import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js/pure';
import { CardNumberElement, Elements, useElements, useStripe } from '@stripe/react-stripe-js';
import { CreditCardForm } from './CreditCardForm';
import HubModal from '../../../../../components/HubModal';
import { AddingPaymentMethod } from '../../../../../modules/payment-methods/types/types';
import { selectAppConfig } from '../../../../../modules/config/selectors';

interface Props {
    open: boolean;
    onClose: () => void;
    onSubmit: (p: AddingPaymentMethod) => void;
}

const PaymentMethodModal: React.FC<Props> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const onSubmit = async () => {
        props.onSubmit({
            card: elements!.getElement(CardNumberElement)!,
            stripe: stripe!,
        });
    };

    return (
        <HubModal
            onClose={props.onClose}
            dismissable={false}
            open={props.open}
            content={<CreditCardForm onSubmit={onSubmit} onCancel={props.onClose} />}
        />
    );
};

export const AddPaymentMethodModal: React.FC<Props> = ({ open, onSubmit, onClose }) => {
    const appConfig = useSelector(selectAppConfig);

    const stripePromise = useMemo(() => loadStripe(appConfig!.stripe.publishableKey), [appConfig]);

    return (
        <Elements stripe={stripePromise}>
            <PaymentMethodModal open={open} onClose={onClose} onSubmit={onSubmit} />
        </Elements>
    );
};
