import { LogPayload } from '@igenius_dev/igenius-js-logger/dist/Payload/LogPayload';
import { AxiosError } from 'axios';

type Formatter = (stringToFormat?: string) => LogPayload;

function createPayloadExtractor(formatter: Formatter = splitByNewLine) {
    return function extractPayloadFrom(error: Error) {
        const payload: LogPayload = { stack: formatter(error.stack) };
        const isAxios = !!(error as any).isAxiosError;

        if (isAxios) {
            const e = error as AxiosError;

            payload.request = {
                headers: e.config.headers,
                url: e.config.url,
            };
            payload.response = {
                headers: e.response?.headers,
                data: e.response?.data,
                status: e.response?.status,
                statusText: e.response?.statusText,
            };
        }

        return payload;
    };
}

const splitByNewLine: Formatter = (stack: string = '') =>
    stack?.split('\n').reduce(
        (prev, value, i) => ({
            ...prev,
            [i]: value.trim(),
        }),
        {},
    );

export { createPayloadExtractor };
