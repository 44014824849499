import { ActionType, getType } from 'typesafe-actions';
import { ProjectUpgradeState } from './types';
import * as projectUpgradeActions from './actions';

export const projectUpgradeReducer = (
    state: ProjectUpgradeState = { status: 'INITIAL' },
    action: ActionType<typeof projectUpgradeActions>,
): ProjectUpgradeState => {
    switch (action.type) {
        case getType(projectUpgradeActions.upgradeProject.request):
            return { ...state, status: 'UPGRADING' };
        case getType(projectUpgradeActions.upgradeProject.success):
            return { ...state, status: 'SUCCESS' };
        default:
            return state;
    }
};
