import { LanguageDto } from '../../project/types/dto';
import { Language } from '../../../core/entities/project/Language';

export function adaptToProjectLanguage(languages: LanguageDto[] = []): Language[] {
    return languages.map((lang) => ({
        name: lang.name,
        code: lang.iso_639_1_code,
        id: lang.id,
        favorite: lang.is_favorite,
    }));
}
