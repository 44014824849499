import { ProjectStatusDto } from '../project/types/dto';
import { PaymentMethod } from '../../core/entities/payment-method/PaymentMethod';

export type UserNotificationsModuleState = {
    userNotifications: UserNotificationsState;
};

export type UserNotificationsState =
    | Record<UserNotificationType.ExpiringCard, PaymentMethod>
    | Record<UserNotificationType.ExpiredCard, PaymentMethod>
    | {};

export enum UserNotificationType {
    ExpiringCard = 'expiringCard',
    ExpiredCard = 'expiredCard',
}

interface BaseUserNotification<T extends UserNotificationType, P> {
    type: T;
    payload: P;
}

export type ExpiringCardNotification = BaseUserNotification<
    UserNotificationType.ExpiringCard,
    PaymentMethod
>;
export type ExpiredCardNotification = BaseUserNotification<
    UserNotificationType.ExpiredCard,
    PaymentMethod
>;

export type UserNotifications = (ExpiringCardNotification | ExpiredCardNotification)[];

export type FetchUserNotificationsRequest = {
    defaultPaymentMethodID?: string;
    endOfCurrentPeriod?: number;
    projectStatus?: ProjectStatusDto;
};
