import { cancelWizard, endWizard, nextStepWizard, prevStepWizard, startWizard } from './actions';
import { call, takeLatest } from 'redux-saga/effects';
import { navigate } from '@reach/router';
import { paths, paymentWizardPaths } from '../../paths';

function handleNextStep(action: ReturnType<typeof nextStepWizard>) {
    if (action.payload.basePath) {
        return navigate(
            `${action.payload.basePath}/${paymentWizardPaths[action.payload.currentStep]}`,
        );
    }

    return navigate(`${paths.paymentWizard}/${paymentWizardPaths[action.payload.currentStep]}`);
}

function handlePrevStep(action: ReturnType<typeof prevStepWizard>) {
    if (action.payload.basePath) {
        return navigate(
            `${action.payload.basePath}/${paymentWizardPaths[action.payload.goToStep - 1]}`,
        );
    }

    return navigate(`${paths.paymentWizard}/${paymentWizardPaths[action.payload.goToStep - 1]}`);
}

function handleStart(action: ReturnType<typeof startWizard>) {
    if (action.payload?.basePath) {
        return navigate(action.payload.basePath);
    }

    return navigate(paths.paymentWizard);
}

function handleEnd() {
    // do nothing
}

function* handleCancel(action: ReturnType<typeof cancelWizard>) {
    yield call(action.payload.callback);
}

export function* paymentWizardSaga() {
    yield takeLatest(startWizard, handleStart);
    yield takeLatest(nextStepWizard, handleNextStep);
    yield takeLatest(prevStepWizard, handlePrevStep);
    yield takeLatest(endWizard, handleEnd);
    yield takeLatest(cancelWizard, handleCancel);
}
