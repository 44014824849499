import { addErrorToast, addSuccessToast } from 'modules/toasts/actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as projectDetailActions from '../project/detail/actions';
import * as actions from './actions';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';
import { FetchBillingDetails } from 'core/entities/billing-details/FetchBillingDetails';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';
import loggingActions from 'modules/logger/actions';

function handleFetchBillingInfo(useCase: FetchBillingDetails) {
    return function* (_: ActionType<typeof actions.fetchBillingInfo.request>) {
        try {
            const response: BillingInfo = yield call([useCase, 'get']);

            yield put(actions.fetchBillingInfo.success(response));
        } catch (e) {
            yield put(actions.fetchBillingInfo.failure(e));
        }
    };
}

function handleEditBillingDetails(editBillingDetailsUseCase: UpdateBillingDetails) {
    return function* (action: ReturnType<typeof actions.editBillingDetails.request>) {
        const formData = action.payload;

        try {
            const response: BillingInfo = yield call(
                [editBillingDetailsUseCase, 'update'],
                formData,
            );

            yield put(actions.editBillingDetails.success(response));
            yield put(projectDetailActions.closeBillingDetailsModal());
            yield put(
                addSuccessToast({
                    id: 'success-edit-billing-details',
                    isAutoDismissEnabled: true,
                    label: 'Billing details edited successfully!',
                    type: 'success',
                }),
            );
        } catch (error) {
            yield put(actions.editBillingDetails.failure(error));
            yield put(
                addErrorToast({
                    actions: [{ label: 'Close' }],
                    id: 'error-edit-billing-details',
                    type: 'error',
                    isAutoDismissEnabled: false,
                    label: 'Something wrong occurred while editing the billing details',
                }),
            );
        }
    };
}

export function createBillingInfoSaga({
    getBillingDetails,
    editBillingDetails,
}: {
    getBillingDetails: FetchBillingDetails;
    editBillingDetails: UpdateBillingDetails;
}) {
    return function* () {
        yield takeLatest(
            actions.fetchBillingInfo.request,
            handleFetchBillingInfo(getBillingDetails),
        );
        yield takeLatest(
            actions.editBillingDetails.request,
            handleEditBillingDetails(editBillingDetails),
        );
        yield takeLatest(actions.fetchBillingInfo.failure, function* ({ payload }) {
            yield put(
                loggingActions.error({
                    msg: 'Unable to retrieve billing info',
                    error: payload,
                }),
            );
        });
        yield takeLatest(actions.editBillingDetails.failure, function* ({ payload }) {
            yield put(
                loggingActions.error({
                    msg: 'Error editing billing details',
                    error: payload,
                }),
            );
        });
    };
}
