import styled, { css } from 'styled-components';

import Button from '@igenius_dev/crystalkit/lib/components/Button';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import theme from '@igenius_dev/crystalkit/lib/theme';

import { StatefulChip } from 'components/Fields/StatefulChip';
import { BaseMediumText } from 'components/Typography/BaseMediumText';

export const PlanText = styled(Text).attrs((props) => ({
    scale: props.scale ?? 'Base',
    color: 'black300',
}))`
    font-weight: ${theme.typography.weights.values.regular};
`;

export const Description = styled.div`
    padding: 24px 19px;
    border-radius: 8px;
    background-color: ${theme.colors.values.blue15};

    @media (max-width: 1279px) {
        background-color: unset;
        padding: 0;
    }
`;

export const PlanTitle = styled(BaseMediumText).attrs({
    color: 'purple700',
})`
    display: flex;
`;

export const Information = styled(PlanText)`
    margin-top: 8px;

    [data-lokalise] {
        color: ${theme.colors.values.purple700};
    }
`;

export const Title = styled(Text).attrs((props) => ({ scale: props.scale ?? 'Medium' }))`
    @media (max-width: 1279px) {
        font-size: 24px;
    }
`;

export const Features = styled.ul`
    list-style: none;
    margin-top: 24px;
`;

export const Feature = styled.li`
    display: flex;
    align-items: center;
`;

export const CheckIcon = styled(Icon).attrs({
    icon: 'check',
    size: Icon.size.base,
})`
    color: ${theme.colors.values.purple700};
    margin-right: 4px;
`;

export const PlanContainer = styled.div`
    overflow-y: scroll;

    @media (max-width: 1279px) {
        margin-top: 0;
    }
`;

export const OkGotIt = styled(Button).attrs({
    size: 'small',
    color: 'purple',
})`
    height: 36px;
    width: auto;
    margin-top: 0;

    @media (max-width: 1279px) {
        display: none;
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 40px;
    padding-bottom: 8px;
`;

export const Rectangle = styled.div<{ selected: boolean }>`
    display: block;
    padding: 24px 20px;
    width: 100%;
    border: 1px solid
        ${({ selected }) => (selected ? theme.colors.values.purple100 : theme.colors.values.blue50)};
    background-color: ${({ selected }) => (selected ? theme.colors.values.purple15 : 'white')};

    &:hover {
        cursor: pointer;
        background-color: ${theme.colors.values.purple15};
    }
`;

export const RectangleRowContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    ${Rectangle} {
        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

export const TrialText = styled(Text).attrs({
    scale: 'Micro',
    color: 'black200',
})`
    width: 100%;
    text-align: center;
    margin-top: 16px;
`;

export const StyledDescription = styled.div`
    padding: 24px 19px;
    border-radius: 8px;
    background-color: ${theme.colors.values.blue15};
`;

export const TriggerIcon = styled(Icon).attrs({
    size: 'base',
})`
    cursor: pointer;

    svg {
        color: ${theme.colors.values.black300};
    }
`;

export const TopText = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ViewPricingPageLink = styled(TextLink).attrs({
    scale: 'Base',
    underline: true,
    color: 'black300',
})`
    color: ${theme.colors.values.black300};
    border-bottom-color: ${theme.colors.values.black300};
`;

export const FAQText = styled(PlanText).attrs({ scale: 'SmallMedium' })`
    margin-top: 22px;
`;

export const FAQLink = styled(TextLink).attrs({
    color: 'black300',
})``;

export const SelectPlanDescription = styled(PlanText)`
    margin-top: 24px;
`;

export const PlanDetails = styled.div`
    margin-bottom: 16px;

    [data-lokalise] {
        color: ${theme.colors.values.purple700};
    }
`;

export const PlanPriceAndPeriod = styled.div`
    display: flex;
    align-items: center;
`;

export const BilledAnnually = styled(Text).attrs({
    scale: 'Small',
})`
    text-align: right;
`;

export const PlanPrice = styled(Text)<{ selected: boolean }>`
    position: absolute;
    display: flex;
    right: 0;
    top: -6px;
    flex-direction: column;

    ${(props) =>
        props.selected &&
        css`
            color: ${theme.colors.values.purple700};
        `}
`;

export const Amount = styled(Text).attrs({ scale: 'Title' })``;

export const Period = styled(Text)``;

export const BillingCycleSlideContainer = styled.div`
    margin-top: 16px;
    margin-bottom: 24px;
    border-radius: 16px;
    display: flex;
    width: fit-content;
    background-color: ${theme.colors.values.purple25};
`;

export const NonInteractiveStatefulChip = styled(StatefulChip).attrs({
    isInteractive: false,
})`
    & span {
        cursor: pointer;
    }
`;

export const UpgradeToEnterprise = styled(Text).attrs({
    scale: 'Small',
    color: 'black300',
})`
    margin-top: 24px;
`;
