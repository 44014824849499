import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';

import useTranslator from 'hooks/useTranslator';
import { SlightlyBiggerModal } from 'views/TenantUsageLimit/elements';
import {
    Description,
    Label,
    LearnMore,
    LearnMoreContainer,
    Rectangle,
    Row,
    SeatsPriceContainer,
    Title,
    TotalRecap,
    Value,
} from 'views/Project/Detail/Detail/Restart/elements';
import { ProjectDetailForView } from 'modules/project/detail/types';
import { CreditCard } from 'components/CreditCard';
import { NonInteractiveChip } from 'components/NonInteractiveChip';
import { SeatsWithPrice } from 'components/Price/SeatsWithPrice';
import { invoicePreviewSelectors } from 'modules/invoice/selectors';
import { invoicePreviewActions } from 'modules/invoice/actions';
import { ActionContainer, CancelButton, SaveButton } from 'views/Coupon/elements';
import { goToContentCenter } from 'utils/goToContentCenter';
import { formatDateTo } from 'utils/formatDateTo';
import { expirationTimestampFromTimeInterval } from 'utils/expirationTimestampFromTimeInterval';
import { projectRestartActions } from 'modules/project/restart/actions';
import projectRestartSelectors from 'modules/project/restart/selectors';

interface RestartProjectProps {
    project: ProjectDetailForView;
}

export const RestartProjectModal = ({ project }: RestartProjectProps) => {
    const t = useTranslator();
    const dispatch = useDispatch();
    const open = useSelector(projectRestartSelectors.isRestartProjectModalOpen);
    const invoice = useSelector(invoicePreviewSelectors.selectInvoice);
    const { seats, subscription, id: projectId } = project;
    const {
        defaultPaymentMethod: { brand, lastDigits },
        price,
        id: priceId,
    } = subscription;
    const {
        plan: { name },
        timeInterval,
    } = price;
    const billingDate = formatDateTo(expirationTimestampFromTimeInterval(Date.now(), timeInterval));

    useEffect(() => {
        open &&
            dispatch(
                invoicePreviewActions.fetch.request({
                    data: {
                        projectId,
                        seats,
                        priceId,
                    },
                }),
            );
    }, [dispatch, projectId, seats, priceId, open]);

    const closeModal = useCallback(() => {
        dispatch(projectRestartActions.closeRestartModal());
    }, [dispatch]);

    return (
        <SlightlyBiggerModal
            content={
                <>
                    <Title>{t('hub-dialogue-plan-reactivating-title')}</Title>
                    <Description>{t('hub-dialogue-plan-reactivating-body')}</Description>

                    <Rectangle>
                        <Row>
                            <Label>{t('hub-dialogue-plan-reactivating-label-plan')}</Label>
                            <Value>{name}</Value>
                        </Row>
                        <Row>
                            <Label>{t('hub-dialogue-plan-reactivating-label-seat-number')}</Label>
                            <Value>{seats}</Value>
                        </Row>
                        <Row>
                            <Label>{t('hub-dialogue-plan-reactivating-label-billing-type')}</Label>
                            <Value>
                                <NonInteractiveChip baseColor="white">
                                    {timeInterval === 'month'
                                        ? t(
                                              'hub-dialogue-plan-reactivating-billing-type-chip-monthly',
                                          )
                                        : t(
                                              'hub-dialogue-plan-reactivating-billing-type-chip-yearly',
                                          )}
                                </NonInteractiveChip>
                            </Value>
                        </Row>
                        <Row>
                            <Label>{t('hub-dialogue-plan-reactivating-label-card')}</Label>
                            <Value>
                                <CreditCard scale="small" brand={brand} lastDigits={lastDigits} />
                            </Value>
                        </Row>
                    </Rectangle>

                    <SeatsPriceContainer>
                        <SeatsWithPrice currentPrice={price} seats={seats} />
                    </SeatsPriceContainer>

                    <TotalRecap
                        invoice={invoice}
                        disclaimer={t('hub-dialogue-plan-reactivating-label-next-billing', {
                            payload: { Date: billingDate },
                        })}
                    />

                    <ActionContainer>
                        <CancelButton
                            onClick={closeModal}
                            label={t('hub-dialogue-plan-reactivating-button-no')}
                        />
                        <SaveButton
                            label={t('hub-dialogue-plan-reactivating-button-yes')}
                            onClick={() => {
                                dispatch(projectRestartActions.restart.request({ id: projectId }));
                            }}
                        />
                    </ActionContainer>

                    <LearnMoreContainer>
                        <LearnMore>
                            {t('hub-dialogue-plan-reactivating-text-link-go-to-help-center')}{' '}
                            <TextLink color="black200" onClick={goToContentCenter}>
                                {t(
                                    'hub-dialogue-plan-reactivating-text-link-go-to-help-center-link',
                                )}
                            </TextLink>
                        </LearnMore>
                    </LearnMoreContainer>
                </>
            }
            open={open}
            onClose={closeModal}
        />
    );
};
