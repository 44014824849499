import React from 'react';
// @ts-ignore
import Toast from '@igenius_dev/crystalkit/lib/components/Toast';
import { useDispatch } from 'react-redux';

type ToastActionProps = {
    label: string;
    action?: any;
    onDismiss: () => void;
};

const ToastAction = (props: ToastActionProps) => {
    const { label, action, onDismiss } = props;
    const dispatch = useDispatch();
    const handleOnClick = () => {
        action && dispatch(action);

        onDismiss();
    };

    return <Toast.Action label={label} onClick={handleOnClick} />;
};

export default ToastAction;
