import { ActionType, createAsyncAction } from 'typesafe-actions';
import { I18nDictionary } from './types';

export const fetchDictionary = createAsyncAction(
    '@i18n/FETCH_DICTIONARY_REQUEST',
    '@i18n/FETCH_DICTIONARY_SUCCESS',
    '@i18n/FETCH_DICTIONARY_FAILURE',
)<undefined, I18nDictionary, Error>();

export type DictionaryAction = ActionType<typeof fetchDictionary>;
