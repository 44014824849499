import { BaseText, StyledTextLink } from 'views/Project/Detail/Detail/SheetInvoices/elements';
import { download } from 'utils/download';
import React from 'react';

type DownloadLinkProps = { link: string };

export const DownloadLink = ({ link }: DownloadLinkProps) =>
    link ? (
        <StyledTextLink onClick={() => download(link)}>Pdf</StyledTextLink>
    ) : (
        <BaseText>-</BaseText>
    );
