import {
    AddIcon,
    AddPaymentMessage,
    AddPaymentMethod,
    NewPaymentMethod,
} from '../../../../PaymentWizard/Details/PaymentMethod/elements';
import React from 'react';

interface Props {
    onClick: () => void;
    brands?: React.ReactElement | React.ReactElement[];
    label: string;
}

export const AddPaymentButton = ({ onClick, label, brands }: Props) => {
    return (
        <AddPaymentMessage data-cy={'add-payment-method'}>
            <NewPaymentMethod onClick={onClick}>
                <AddIcon />
                <AddPaymentMethod>{label}</AddPaymentMethod>
            </NewPaymentMethod>
            {brands && brands}
        </AddPaymentMessage>
    );
};
