import { CategoryList, NotificationList } from '../types';
import { isNotificationVisible } from './isNotificationVisible';

export function getNotificationToDisplay(n: Record<string, NotificationList>, c: CategoryList) {
    const categories = Array.from(c.values());
    const now = new Date().getTime();
    for (let category of categories) {
        if (n[category] && n[category].length > 0) {
            for (let notification of n[category]) {
                if (isNotificationVisible(notification.id, retrieveFromStorage, now)) {
                    return n[category][0];
                }
            }
        }
    }
    return null;
}

function retrieveFromStorage(id: string) {
    return localStorage.getItem(id);
}
