import styled, { css } from 'styled-components';
import Scrollbars, { ScrollbarProps } from 'react-custom-scrollbars';
import theme from '@igenius_dev/crystalkit/lib/theme';

type ScrollableItemsProp = {
    hasScrollbar: boolean;
    withBottomBorder: boolean;
    withTopBorder: boolean;
} & ScrollbarProps;

export const ScrollableItems = styled(Scrollbars).attrs<ScrollableItemsProp>(
    ({ hasScrollbar }) => ({
        autoHide: true,
        autoHeight: !hasScrollbar,
        autoHeightMax: !hasScrollbar && '100%',
        'data-cy': 'sidebar-scroll',
    }),
)`
    ${({ withBottomBorder }: ScrollableItemsProp) =>
        withBottomBorder &&
        css`
            border-bottom: 1px solid ${theme.colors.values.blue25};
        `};

    ${({ withTopBorder }: ScrollableItemsProp) =>
        withTopBorder &&
        css`
            border-top: 1px solid ${theme.colors.values.blue25};
        `};
`;
