const projectManager = '/api/project-manager';
const userManagerURL = '/api/user-manager';
const paymentGatewaySlug = 'private/users/me';
const userManagerPrivate = '/api/user-manager/private/users/me';

const paths = {
    root: '/',
    createProject: '/create',
    project: '/project',
    paymentWizard: '/payment-wizard',
};

const paymentWizardPaths = ['seats', 'details', 'confirm'];

const segments: Record<string, string> = {
    projectId: ':projectId',
};

const getSegmentKey = (v: string) => {
    return Object.keys(segments).find((key) => segments[key] === v) || '';
};

const api = {
    validateDomain: `${projectManager}/private/domain`,
    submit: `${projectManager}/private/project`,
    projectCreationInfo: `${projectManager}/private/project/creation-info`,
    projectDetail: `${projectManager}/private/project`,
    tenantUsage: `${projectManager}/private/project`,
    invoicePreview: `${userManagerPrivate}/invoice-preview`,
    paymentMethods: `${userManagerPrivate}/payment-methods`,
    notifications: `${userManagerPrivate}/notifications`,
    invoices: `${userManagerPrivate}/invoices`,
};

const configUrl = `${process.env.PUBLIC_URL}/config.json`;

export {
    paths,
    projectManager,
    api,
    paymentWizardPaths,
    segments,
    getSegmentKey,
    userManagerURL,
    paymentGatewaySlug,
    configUrl,
};
