import { HubState } from '../../types';

const selectUser = (state: HubState) => {
    const user = state?.profile?.data?.user;

    return {
        firstName: user?.given_name ?? '',
        lastName: user?.family_name ?? '',
        id: user?.id,
        email: user?.email,
        company_name: user?.company_name,
        industry: user?.industry,
        job_title: user?.job_title,
        phone: user?.phone,
        newsletterEnabled: user?.newsletter_enabled,
    };
};

const profileSelectors = {
    selectUser,
};

export default profileSelectors;
