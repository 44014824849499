import React from 'react';

import { EmptyStateContainer, EmptyStateImg, EmptyStateText } from './elements';
import useTranslator from 'hooks/useTranslator';

export const EmptyState = () => {
    const t = useTranslator();

    return (
        <EmptyStateContainer>
            <EmptyStateImg />
            <EmptyStateText>{t('cons-user-management-empty-search-content-sorry')}</EmptyStateText>
        </EmptyStateContainer>
    );
};
