import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { BillingCycleSlideContainer, NonInteractiveStatefulChip, Title } from './elements';
import useTranslator from 'hooks/useTranslator';

interface Props {
    items: { label: string; value: string }[];
    textScale: string;
}

export const SelectBillingCycle: React.FC<Props> = (props) => {
    const { control } = useFormContext<{ cycle: string }>();
    const { textScale, items } = props;
    const t = useTranslator();

    return (
        <div data-cy="billing-cycles">
            <Title scale={textScale}>{t('hub-trial-project-details-title-choose-plan')}</Title>
            <BillingCycleSlideContainer>
                <Controller
                    name={'cycle'}
                    control={control}
                    rules={{ required: true }}
                    render={({ value, onChange }) => (
                        <>
                            {items.map((chip) => {
                                const selected = chip.value === value;

                                return (
                                    <div
                                        key={chip.value}
                                        data-name={`billing-cycle-${chip.value}`}
                                        data-cy={`billing-cycle${selected ? '-selected' : ''}`}
                                    >
                                        <NonInteractiveStatefulChip
                                            isSelected={selected}
                                            label={chip.label}
                                            onClick={(value) => value && onChange(chip.value)}
                                        />
                                    </div>
                                );
                            })}
                        </>
                    )}
                />
            </BillingCycleSlideContainer>
        </div>
    );
};
