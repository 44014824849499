import React, { useCallback, useState } from 'react';

import { DeleteButton, ProjectRemovalWrapper } from './elements';
import DoYouReallyWantToDeleteProject from 'views/Project/Detail/Detail/Deletion/DoYouReallyWantToDeleteProject';
import { useDispatch } from 'react-redux';
import { deleteProject } from 'modules/project/delete/actions';
import { ProjectId } from 'core/entities/project/Project';

interface DeleteProjectProps {
    id: ProjectId;
}

export const DeleteProject = ({ id }: DeleteProjectProps) => {
    const [open, setOpen] = useState(false);
    const openPrompt = useCallback(() => setOpen(true), [setOpen]);
    const closePrompt = useCallback(() => setOpen(false), [setOpen]);
    const dispatch = useDispatch();

    return (
        <ProjectRemovalWrapper>
            <DeleteButton label="Delete project" onClick={openPrompt} />

            <DoYouReallyWantToDeleteProject
                open={open}
                onSubmit={() => {
                    dispatch(deleteProject.request({ id, onSuccess: closePrompt }));
                }}
                onClose={closePrompt}
            />
        </ProjectRemovalWrapper>
    );
};
