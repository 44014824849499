import { Stripe, StripeCardNumberElement } from '@stripe/stripe-js';
import { PaymentMethod } from '../../../core/entities/payment-method/PaymentMethod';

export type PaymentMethodModuleState = {
    paymentMethods?: PaymentMethodsState;
};

export type PaymentMethodsState = {
    status: {
        retrieve?: PaymentMethodsStatus;
        add?: PaymentMethodsStatus;
    };
    methods?: PaymentMethod[];
};

export enum PaymentMethodsStatus {
    INITIAL = 'INITIAL',
    PENDING = 'PENDING',
    SUCCESSFUL = 'SUCCESSFUL',
    FAILURE = 'FAILURE',
}

export interface PaymentToken {
    clientSecret: string;
    id: string;
}

export type AddingPaymentMethod = {
    card: StripeCardNumberElement;
    stripe: Stripe;
    onSuccess?: () => void;
};
