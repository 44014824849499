import styled from 'styled-components';
import Text from '@igenius_dev/crystalkit/lib/components/Text';

export const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 444px;
    align-items: center;
    justify-items: center;
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 75%;
    align-items: center;
    margin: 0 auto;
`;

export const Title = styled(Text).attrs({
    scale: 'Large',
})`
    margin-top: 16px;
    text-align: center;
`;

export const Subtitle = styled(Text).attrs({
    scale: 'Base',
})`
    margin-top: 8px;
    text-align: center;
`;

export const Action = styled.div`
    margin-top: 40px;
`;

export const Illustration = styled.div``;
