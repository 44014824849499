import { ISagaModule } from 'redux-dynamic-modules-saga';
import { tenantUsageReducer } from './reducer';
import { tenantUsageSaga } from './saga';
import { goToConsole } from 'utils/goToConsole';
import { goToContentCenter } from 'utils/goToContentCenter';
import { ProjectId } from 'core/entities/project/Project';
import { TenantUsage, TenantUsageModuleState } from './types/types';

export const getTenantUsageModule = (
    fetchTenantUsage: (id: ProjectId) => Promise<TenantUsage>,
): ISagaModule<TenantUsageModuleState> => ({
    id: 'tenantUsage',
    reducerMap: {
        tenantUsage: tenantUsageReducer,
    },
    sagas: [
        tenantUsageSaga({
            api: fetchTenantUsage,
            openConsole: goToConsole,
            contactSupport: goToContentCenter,
        }),
    ],
});
