import styled from 'styled-components';
import { BaseText } from '../../components/Typography/BaseText';
import { NonInteractiveChip } from '../../components/NonInteractiveChip';
import { SeatsItem } from 'components/Price/SeatsWithPrice';
import { SmallText } from '../../components/Typography/SmallText';

import Button from '@igenius_dev/crystalkit/lib/components/Button';
import theme from '@igenius_dev/crystalkit/lib/theme';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import { TotalPriceContainer } from 'components/Price/elements';

export const ApplyButton = styled(Button).attrs({
    inverted: true,
})`
    height: 36px;
`;

export const CouponInputContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;

    & > :last-child {
        margin-left: 16px;
    }
`;

export const ProjectRecap = styled.div`
    margin-top: 24px;
`;

export const Background = styled.div`
    margin-top: 16px;
    padding: 22px 16px 24px;
    border-radius: 8px;
    background-color: ${theme.colors.values.blue25};
`;

export const ProjectName = styled(BaseText)``;

export const SeatsPriceContainer = styled.div`
    margin-top: 16px;

    ${SeatsItem}:first-child {
        margin-bottom: 16px;
    }
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

export const SaveButton = styled(Button)`
    margin-left: 4px;
`;

export const CancelButton = styled(Button).attrs({
    color: 'white',
})`
    margin-right: 4px;
`;

export const CouponApplied = styled(SmallText)`
    display: block;
`;

export const CouponAppliedContainer = styled.div.attrs({
    'data-cy': 'coupon-container',
})`
    margin-top: 24px;
`;

export const CouponChip = styled(NonInteractiveChip)`
    margin: 8px 0;
`;

export const CouponPriceText = styled(BaseText).attrs({
    color: 'purple700',
})``;

export const CouponPriceContainer = styled(TotalPriceContainer)``;

export const CenteredLoaderContainer = styled.div`
    position: relative;
    margin-top: 24px;
    height: 38px;
`;

export const CouponErrorText = styled(Text).attrs({
    scale: 'Micro',
})`
    color: ${theme.colors.values.red500};
    margin-top: 8px;
`;

export const ContactUsErrorLink = styled(TextLink).attrs({
    scale: 'Micro',
    underline: true,
    color: 'red500',
})`
    color: ${theme.colors.values.red500};
    border-bottom-color: ${theme.colors.values.red500};
`;
