import { FetchBillingDetails } from 'core/entities/billing-details/FetchBillingDetails';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';

export class HttpFetchBillingDetailsUseCase implements FetchBillingDetails {
    constructor(private readonly billingInfoFetcher: () => Promise<BillingInfo>) {}

    get(): Promise<BillingInfo> {
        return this.billingInfoFetcher();
    }
}
