import { Address, TaxId } from 'common/types';
import { TimeInterval } from 'core/entities/Price';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';

export type ProjectCreationState = {
    project?: ProjectCreationData;
    status: ProjectCreationStatus;
};

export type ProjectCreationModuleState = {
    projectCreation?: ProjectCreationState;
};

export enum ProjectCreationStatus {
    Completing = 'COMPLETING',
    Initial = 'INITIAL',
    Pending = 'PENDING',
    Succeeded = 'OK',
    Failed = 'KO',
}

export type ProjectCreationBaseData = {
    domain: string;
    name: string;
    timezone?: string;
    languages: string[];
};

export type ProjectCreationData = {
    planId: string;
    seats: number;
    priceId: string;
    paymentMethodId: string;
    invoice: {
        address: Address;
        taxId?: TaxId;
    };
    formType: FormType;
    coupon?: string;
    billingCycle: TimeInterval;
    favorite_lang_id: string;
} & ProjectCreationBaseData;
