import React from 'react';

import theme from '@igenius_dev/crystalkit/lib/theme';
import { StyledTitle } from '../Details/elements';
import { ChangeButton, Label, StyledTable, TitleContainer, Value } from './elements';
import { NonInteractiveChip } from '../../../components/NonInteractiveChip';
import { CreditCard } from '../../../components/CreditCard';
import { Address, TaxId } from 'common/types';
import useTranslator from '../../../hooks/useTranslator';
import { PaymentMethod } from '../../../core/entities/payment-method/PaymentMethod';

interface Props {
    seats: number;
    billingCycle: string;
    paymentMethod: PaymentMethod;
    address: Address;
    taxId?: TaxId;
    onChange: () => void;
    expiry: string;
}

export const PaymentDetailsSummary = (props: Props) => {
    const t = useTranslator();
    const { billingCycle, paymentMethod, address, taxId, onChange } = props;

    return (
        <div>
            <TitleContainer>
                <StyledTitle>
                    {t('hub-confirm-and-subscribe-area-title-payment-details')}
                </StyledTitle>
                <ChangeButton
                    label={t('hub-confirm-and-subscribe-area-title-payment-details-button-change')}
                    onClick={onChange}
                />
            </TitleContainer>
            {!!billingCycle ? (
                <StyledTable
                    list={[
                        {
                            name: 'billingType',
                            label: (
                                <Label>
                                    {t('hub-confirm-and-subscribe-area-label-billing-type')}
                                </Label>
                            ),
                            value: (
                                <NonInteractiveChip baseColor="white">
                                    {billingCycle === 'year'
                                        ? t('hub-confirm-and-subscribe-area-chip-yearly')
                                        : t('hub-confirm-and-subscribe-area-chip-monthly')}
                                </NonInteractiveChip>
                            ),
                            action: (
                                <Value>
                                    {t(`hub-confirm-and-subscribe-area-information-next-billing`, {
                                        payload: { DATE: props.expiry },
                                    })}
                                </Value>
                            ),
                        },
                        {
                            name: 'creditCard',
                            label: <Label>{t('hub-confirm-and-subscribe-area-label-card')}</Label>,
                            value: (
                                <CreditCard
                                    brand={paymentMethod.brand}
                                    lastDigits={paymentMethod.lastDigits}
                                    scale="small"
                                    cardNameColor={theme.colors.names.black300}
                                    cardNumberColor={theme.colors.names.black300}
                                />
                            ),
                            action: (
                                <Value>
                                    {t(`hub-confirm-and-subscribe-area-label-expiration-date`, {
                                        payload: {
                                            DATE: `${paymentMethod.expiration.month}/${paymentMethod.expiration.year}`,
                                        },
                                    })}
                                </Value>
                            ),
                        },
                        {
                            name: 'invoiceDetails',
                            label: (
                                <Label>
                                    {t('hub-confirm-and-subscribe-area-label-invoice-details')}
                                </Label>
                            ),
                            value: (
                                <Value>
                                    {address.name}
                                    <br />
                                    {address.address}
                                    <br />
                                    {address.city},
                                    <br />
                                    {address.state}
                                    <br />
                                    {address.zipCode}
                                    <br />
                                    {address.country?.label}
                                </Value>
                            ),
                            action: taxId?.vatNr ? (
                                <Value>
                                    {t('hub-confirm-and-subscribe-area-taxtype-information', {
                                        payload: { TAXTYPE: taxId.vatId?.label },
                                    })}
                                    <br />
                                    {t('hub-confirm-and-subscribe-area-vat-information', {
                                        payload: { VATNUMBER: taxId.vatNr },
                                    })}
                                </Value>
                            ) : undefined,
                        },
                    ]}
                />
            ) : null}
        </div>
    );
};
