import { ActionType, getType } from 'typesafe-actions';
import { invoicePreviewActions } from './actions';
import { InvoicePreviewState } from './types/types';

export function invoicePreviewReducer(
    state: InvoicePreviewState = { status: 'INITIAL' },
    action: ActionType<typeof invoicePreviewActions>,
): InvoicePreviewState {
    switch (action.type) {
        case getType(invoicePreviewActions.fetch.success): {
            return {
                ...state,
                status: 'SUCCESS',
                data: action.payload,
            };
        }
        case getType(invoicePreviewActions.fetch.request): {
            return {
                ...state,
                status: 'PENDING',
            };
        }
        case getType(invoicePreviewActions.fetch.failure): {
            return {
                ...state,
                status: 'FAILURE',
            };
        }
        default: {
            return state;
        }
    }
}
