import { call, put, takeLatest } from 'redux-saga/effects';
import { navigate } from '@reach/router';

import { projectCreationActions } from './actions';
import { addErrorToast, addSuccessToast } from 'modules/toasts/actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ProjectCreationInfoPaymentAddress, ProjectCreationInfoTaxId } from '../types/dto';
import { Address, TaxId } from 'common/types';
import { Project } from 'core/entities/project/Project';
import { endWizard } from '../../payment-wizard/actions';
import { removeUndefinedValuesFrom } from 'utils/removeUndefinedaValueFrom';
import { BillingDetailsFormData } from 'views/Project/Detail/Detail/FormEditBillingDetails/types';
import { fetchBillingInfo } from '../../billing-info/actions';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';
import loggingActions from 'modules/logger/actions';

const invoiceDetailsFrom = (invoice: { address: Address; taxId?: TaxId }) => {
    const { address, taxId } = invoice;

    const details: {
        payment_address: ProjectCreationInfoPaymentAddress;
        tax_id?: ProjectCreationInfoTaxId;
    } = {
        payment_address: {
            country: address.country?.value,
            state: address.state,
            city: address.city,
            line1: address.address,
            postal_code: address.zipCode,
        },
    };

    if (taxId) {
        details.tax_id = {
            type: taxId.vatId?.value,
            value: taxId.vatNr,
        };
    }

    return details;
};

function* handleSubmit(
    action: ReturnType<typeof projectCreationActions.create.request>,
    repository: ProjectRepository,
    updateBillingDetailsUseCase: UpdateBillingDetails,
) {
    const {
        domain,
        languages,
        name,
        paymentMethodId,
        priceId,
        seats,
        invoice,
        formType,
        coupon,
        favorite_lang_id,
    } = action.payload.projectData;
    const { onSuccess } = action.payload;

    try {
        const invoiceDetails = invoiceDetailsFrom(invoice);
        const userType = formType ? formType : invoiceDetails.tax_id ? 'company' : 'person';
        const response: Project = yield call([repository, 'create'], {
            name,
            domain,
            languages,
            payment_method_id: paymentMethodId,
            price_id: priceId,
            number_of_seats: seats,
            user_type: userType,
            coupon,
            favorite_lang_id,
            ...invoiceDetails,
        });

        if (invoice.address) {
            const { address, taxId = {} } = invoice;
            const formData: Partial<BillingDetailsFormData> = {
                city: address.city,
                country: address.country,
                fullName: address.name,
                line1: address.address,
                state: address.state,
                taxIDType: taxId.vatId,
                taxIDValue: taxId.vatNr,
                userType: userType,
                zipCode: address.zipCode,
            };

            yield call(
                [updateBillingDetailsUseCase, 'update'],
                removeUndefinedValuesFrom<BillingDetailsFormData>(formData),
            );
            yield put(fetchBillingInfo.request());
        }

        yield put(projectCreationActions.create.success());
        yield put(
            addSuccessToast({
                id: 'create-project-success',
                type: 'success',
                label: 'hub-trial-project-notification-toast-subscription-successful',
                isAutoDismissEnabled: true,
            }),
        );
        yield call(onSuccess, response.id);
    } catch (e) {
        yield put(
            projectCreationActions.create.failure(e.response?.data?.error?.message || e.message),
        );
    }
}

function* handleFailure({ payload }: ReturnType<typeof projectCreationActions.create.failure>) {
    yield put(
        addErrorToast({
            id: 'create-project-error',
            type: 'error',
            isAutoDismissEnabled: false,
            label: payload.message || 'gen-modal-content-system-down-try-now',
            actions: [{ label: 'Close' }],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to create project',
            error: payload,
        }),
    );
}

function* handleCancel(action: ReturnType<typeof projectCreationActions.cancel>) {
    yield put(endWizard());

    navigate(action.payload.redirectPath);
}

export function createProjectCreationSaga(
    repository: ProjectRepository,
    updateBillingDetailsUseCase: UpdateBillingDetails,
) {
    return function* projectCreationSaga() {
        yield takeLatest(projectCreationActions.create.request, (args) =>
            handleSubmit(args, repository, updateBillingDetailsUseCase),
        );
        yield takeLatest(projectCreationActions.create.failure, handleFailure);
        yield takeLatest(projectCreationActions.cancel, handleCancel);
    };
}
