import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { InvoicesState, InvoicesStatus } from './types';

const initialInvoices = {
    invoices: [],
    pagination: {
        size: 0,
        hasMore: false,
    },
};

const initialState: InvoicesState = {
    status: InvoicesStatus.Loading,
    invoices: initialInvoices,
};

export function invoicesReducer(state = initialState, action: ActionType<typeof actions>) {
    switch (action.type) {
        case getType(actions.retrieveInvoices.request): {
            return action.payload.lastElementId
                ? {
                      ...state,
                      status: InvoicesStatus.Loading,
                  }
                : {
                      status: InvoicesStatus.Loading,
                      invoices: initialInvoices,
                  };
        }
        case getType(actions.retrieveInvoices.success): {
            const { invoices = [] } = state.invoices;

            return {
                status: InvoicesStatus.Done,
                invoices: {
                    invoices: invoices.concat(action.payload.invoices),
                    pagination: action.payload.pagination,
                },
            };
        }
        case getType(actions.retrieveInvoices.failure): {
            return {
                ...state,
                status: InvoicesStatus.Error,
            };
        }
        default: {
            return state;
        }
    }
}
