import React, { useEffect, useState } from 'react';
import { RouteComponentProps, Router, useLocation } from '@reach/router';
import styled from 'styled-components';
import { PaymentWizardHeader } from './Header';
import { Wrapper } from '../elements';

// @ts-ignore
import Modal from '@igenius_dev/crystalkit/lib/components/Modal';

import { AreYouSureYouWantToLeave } from './AreYouSureYouWantToLeave';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { useDispatch } from 'react-redux';
import { cancelWizard } from '../../modules/payment-wizard/actions';
import { useModules } from '../../hooks/useModules';

const PaymentSteps = styled(Wrapper)`
    height: calc(100% - 80px);
`;

const FluidRouter = styled(Router)`
    width: 100%;
`;

type Props = {
    steps: Record<string, string>;
    views: React.ReactElement[];
    basePath: string;
    onCancel: () => void;
};

export const PaymentWizard: React.FC<RouteComponentProps & Props> = ({
    steps,
    views,
    basePath,
    onCancel,
}) => {
    const location = useLocation();
    const stepIndex = Object.keys(steps).findIndex((step) =>
        location.pathname.includes(`/${step}`),
    );
    const activeStep = stepIndex > -1 ? stepIndex + 1 : 1;
    const dispatch = useDispatch();
    const modules = useModules();
    const [wizardIsOpen, setWizardOpen] = useState<boolean>(true);
    const [exitModalIsOpen, setExitModalIsOpen] = useState<boolean>(false);

    const onClosingWizard = () => setExitModalIsOpen(true);
    const closeExitModal = () => setExitModalIsOpen(false);
    const onExitModalClick = () => {
        closeExitModal();

        dispatch(cancelWizard({ callback: onCancel }));

        setWizardOpen(false);
    };

    useEffect(() => onClosingWizard, [setWizardOpen]);

    return (
        <Modal
            type={Modal.type.screen}
            open={wizardIsOpen}
            render={() => (
                <DynamicModuleLoader modules={[modules.get('paymentMethods')]}>
                    <PaymentWizardHeader
                        onClose={onClosingWizard}
                        activeStep={activeStep}
                        steps={Object.keys(steps).map((key) => steps[key])}
                    />
                    <PaymentSteps>
                        <FluidRouter basepath={basePath}>{views}</FluidRouter>
                    </PaymentSteps>
                    <AreYouSureYouWantToLeave
                        open={exitModalIsOpen}
                        onConfirm={onExitModalClick}
                        onClose={closeExitModal}
                    />
                </DynamicModuleLoader>
            )}
            onClickOutside={onClosingWizard}
        />
    );
};
