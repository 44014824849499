import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { projectDetailActions } from '../detail/actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { projectRestartActions } from 'modules/project/restart/actions';

function* handleRestart(
    action: ActionType<typeof projectRestartActions.restart.request>,
    repository: ProjectRepository,
) {
    const { id } = action.payload;

    try {
        yield call([repository, 'restart'], id);
        yield put(projectRestartActions.restart.success());
        yield put(projectRestartActions.closeRestartModal());
        yield put(projectDetailActions.get.request({ id }));
    } catch (e) {
        yield put(projectRestartActions.restart.failure());
    }
}

export function createProjectRestartSaga(repository: ProjectRepository) {
    return function* () {
        yield takeLatest(projectRestartActions.restart.request, (args) =>
            handleRestart(args, repository),
        );
    };
}
