import React from 'react';

//@ts-ignore
import { TextField, TextFieldProps } from './TextField';

export interface NumberFieldProps extends Omit<TextFieldProps, 'type'> {}

export const NumberField: React.FC<TextFieldProps> = (props) => (
    <TextField {...props} type="number" />
);
