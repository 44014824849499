import { createAction, createAsyncAction } from 'typesafe-actions';
import { NotificationCategory, NotificationModel, RemoveNotificationPayload } from './types';

export const setNotification = createAsyncAction(
    '@notifications/SET_NOTIFICATION_REQUEST',
    '@notifications/SET_NOTIFICATION_SUCCESS',
    '@notifications/SET_NOTIFICATION_FAILURE',
)<NotificationModel, NotificationModel, string>();

export const removeNotification = createAsyncAction(
    '@notifications/REMOVE_NOTIFICATION_REQUEST',
    '@notifications/REMOVE_NOTIFICATION_SUCCESS',
    '@notifications/REMOVE_NOTIFICATION_FAILURE',
)<RemoveNotificationPayload, RemoveNotificationPayload, string>();

export const removeCategory = createAction(
    '@notifications/REMOVE_CATEGORY',
)<NotificationCategory>();
