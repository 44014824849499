import React from 'react';

import {
    TenantUsageLimitModal,
    TenantUsageLimitPlan,
} from '../TenantUsageLimit/TenantUsageLimitModal';
import { useSelector } from 'react-redux';
import tenantUsageSelectors from '../../modules/tenant-usage/selectors';
import { HubState } from '../../types';
import { TenantLimits, TenantLimitsPriceComparison } from '../../modules/tenant-usage/types/types';
import { ProjectDetailForView } from '../../modules/project/detail/types';
import useTranslator from '../../hooks/useTranslator';

interface Props {
    project: ProjectDetailForView;
}

export const SeatsLimitModal: React.FC<Props> = (props) => {
    const { project } = props;
    const t = useTranslator();
    const title = t('hub-select-seats-remove-seats-dialogue-title');
    const summary = t('hub-select-seats-remove-seats-dialogue-body');
    const open = useSelector<HubState, boolean>(tenantUsageSelectors.isModalOpen);
    const nextSeats = useSelector<HubState, number>(tenantUsageSelectors.selectNextSeats);
    const diff = useSelector<HubState, TenantLimits>(tenantUsageSelectors.selectDifferences);
    const usages = useSelector<HubState, TenantLimits>(tenantUsageSelectors.selectUsages);
    const prices = useSelector<HubState, TenantLimitsPriceComparison>(
        tenantUsageSelectors.selectPrices,
    );

    const currentPlan: TenantUsageLimitPlan = {
        title: t('hub-select-seats-remove-seats-dialogue-section-title-current-seats'),
        features: {
            users: {
                title: t('hub-select-seats-remove-seats-dialogue-current-seats-number', {
                    payload: {
                        NUMBER: usages.users,
                    },
                }),
                subtitle: t('hub-select-seats-remove-seats-dialogue-label-filled'),
                price: t('hub-select-seats-remove-seats-dialogue-label-current-price', {
                    payload: {
                        NUMBER: prices.users?.current,
                    },
                }),
            },
        },
    };
    const requestedPlan: TenantUsageLimitPlan = {
        title: t('hub-select-seats-remove-seats-dialogue-section-title-requested-seats'),
        features: {
            users: {
                title: t('hub-select-seats-remove-seats-dialogue-label-requested-seats', {
                    payload: {
                        NUMBER: nextSeats,
                    },
                }),
                subtitle: t('hub-select-seats-remove-seats-dialogue-label-seats', {
                    payload: {
                        NUMBER: diff?.users,
                    },
                }),
                diff: diff?.users,
                price: t('hub-select-seats-remove-seats-dialogue-label-requested-price', {
                    payload: {
                        NUMBER: prices.users?.requested,
                    },
                }),
            },
        },
    };

    return nextSeats > 0 ? (
        <TenantUsageLimitModal
            title={title}
            summary={summary}
            project={project}
            open={open}
            currentPlan={currentPlan}
            requestedPlan={requestedPlan}
        />
    ) : null;
};
