import useTranslator from 'hooks/useTranslator';
import { Price } from 'components/Price/Price';
import React from 'react';
import { InvoicePreviewForView } from 'modules/invoice/types/views';
import { PriceRecap, Subtitle, TotalPrice, TotalPriceContainer } from 'components/Price/elements';

interface TotalPriceWithNextBillingProps {
    invoice?: InvoicePreviewForView;
    className?: string;
    disclaimer?: string;
}

export const TotalPriceWithNextBilling: React.FC<TotalPriceWithNextBillingProps> = ({
    invoice,
    disclaimer,
    className,
}) => {
    const t = useTranslator();
    const couponCode = invoice?.coupon?.code;

    return (
        <TotalPriceContainer className={className}>
            <PriceRecap>
                <TotalPrice>{t('hub-project-detail-dialogue-label-total')}</TotalPrice>
                <Subtitle>{t('hub-project-detail-dialogue-label-VAT-included')}</Subtitle>
            </PriceRecap>

            {invoice && (
                <Price
                    hasCoupon={!!couponCode}
                    total={invoice.total}
                    due={invoice.due}
                    disclaimer={disclaimer}
                />
            )}
        </TotalPriceContainer>
    );
};
