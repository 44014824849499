import { HubState } from 'types';
import { BillingInfoForView, BillingInfoStatus } from './types/types';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';

const billingInfo = (state: HubState): BillingInfoForView | undefined => {
    return state.billingInfo?.info;
};

const isUserInTrial = (state: HubState): boolean => {
    return billingInfo(state)?.trial === true;
};

const isModuleReady = (state: HubState): boolean => {
    return state.billingInfo.status === 'READY';
};

const selectBillingInfoStatus = (state: HubState): BillingInfoStatus => state.billingInfo.status;

const selectCompleteAddress = (state: HubState) => state.billingInfo.info?.completeAddress;

const selectUserType = (state: HubState): FormType => state.billingInfo.info?.userType ?? 'company';

const billingInfoSelectors = {
    isUserInTrial,
    isModuleReady,
    billingInfo,
    selectBillingInfoStatus,
    selectCompleteAddress,
    selectUserType,
};

export default billingInfoSelectors;
