import { get } from 'lodash';

export const getToastById = (state, toastId) => {
    const byId = get(state, 'toasts.entities.toast.byId', {});

    return byId[toastId] || null;
};

export const getAllToasts = state => {
    const allIds = get(state, 'toasts.entities.toast.allIds', []);

    return allIds.map(toastId => getToastById(state, toastId));
};

export const getActionSideEffect = (state, toastId, sideEffectId) =>
    get(state, ['toasts', 'actionsSideEffects', 'byToastId', toastId, sideEffectId], {});
