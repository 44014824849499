import React from 'react';
import styled from 'styled-components';

import theme from '@igenius_dev/crystalkit/lib/theme';

import { CheckIcon } from './elements';
import { Coupon } from '../../../Coupon/Coupon';
import { useSelector } from 'react-redux';
import { invoicePreviewSelectors } from '../../../../modules/invoice/selectors';
import { HubState } from '../../../../types';
import { InvoicePreviewForView } from '../../../../modules/invoice/types/views';
import { BaseMediumText } from '../../../../components/Typography/BaseMediumText';
import { Accordion } from '../../../../components/Accordion';
import useTranslator from '../../../../hooks/useTranslator';

interface UseCouponProps {
    onReset: () => void;
    onApply: (code: string) => void;
    onChange: (code: string) => void;
}

const Title = styled(BaseMediumText)`
    display: flex;
    color: ${(props) => props.color ?? theme.colors.values.black500};
`;

export const UseCoupon = ({ onReset, onChange, onApply }: UseCouponProps) => {
    const t = useTranslator();
    const isStatusError = useSelector(invoicePreviewSelectors.isStatusError);
    const invoice = useSelector<HubState, InvoicePreviewForView | undefined>(
        invoicePreviewSelectors.selectInvoice,
    );

    const discount = t('hub-project-detail-dialogue-chip-coupon-discount-value', {
        payload: {
            DISCOUNT: invoice?.coupon?.discount,
        },
    });

    return (
        <div data-testid="coupon-box">
            <Accordion
                title={
                    invoice?.coupon?.code ? (
                        <Title color={'purple700'}>
                            <CheckIcon /> {t('hub-column-recap-toast-coupon-applied')}
                        </Title>
                    ) : (
                        <Title>{t('hub-column-recap-label-use-a-coupon')}</Title>
                    )
                }
                text={
                    <Coupon
                        color="white"
                        labels={{
                            disclaimer: t(
                                'hub-payment-details-coupon-notification-single-subscription',
                            ),
                        }}
                        coupon={{
                            code: invoice?.coupon?.code,
                            discount,
                        }}
                        onChange={onChange}
                        onReset={onReset}
                        onApply={onApply}
                        isStatusError={isStatusError}
                    />
                }
            />
        </div>
    );
};
