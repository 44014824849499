import { ProjectDetailStatus } from './types';
import { projectDetailActions } from './actions';
import { ActionType, getType } from 'typesafe-actions';

export const projectDetailReducer = (
    state = { status: ProjectDetailStatus.Initial },
    action: ActionType<typeof projectDetailActions>,
) => {
    switch (action.type) {
        case getType(projectDetailActions.get.success):
            return {
                ...state,
                status: ProjectDetailStatus.Succeeded,
                [action.payload.id]: action.payload.detail,
            };
        case getType(projectDetailActions.get.failure):
            return { ...state, status: ProjectDetailStatus.Failed };
        case getType(projectDetailActions.get.request):
            return { ...state, status: ProjectDetailStatus.Pending };
        case getType(projectDetailActions.openPaymentMethodModal):
            return { ...state, paymentMethodModal: 'OPEN' };
        case getType(projectDetailActions.closePaymentMethodModal):
            return { ...state, paymentMethodModal: 'CLOSED' };
        case getType(projectDetailActions.openBillingDetailsModal):
            return { ...state, billingDetailsModal: 'OPEN' };
        case getType(projectDetailActions.closeBillingDetailsModal):
            return { ...state, billingDetailsModal: 'CLOSED' };
        case getType(projectDetailActions.openInvoicesSheet):
            return { ...state, invoicesSheet: 'OPEN' };
        case getType(projectDetailActions.closeInvoicesSheet):
            return { ...state, invoicesSheet: 'CLOSED' };
        default:
            return state;
    }
};
