import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// @ts-ignore
import Sheet from '@igenius_dev/crystalkit/lib/components/Sheet';
import { CenteredLoader } from 'components/CenteredLoader';
import { Portal } from 'components/Portal';
import { retrieveInvoices } from 'modules/invoices/actions';
import { invoicesAreLoaded, selectInvoices } from 'modules/invoices/selectors';
import { closeInvoicesSheet } from 'modules/project/detail/actions';
import { isInvoicesSheetOpen } from 'modules/project/detail/selectors';
import { Content } from './Content';
import { Header } from './Header';
import { EmptyState } from './EmptyState';
import { ProjectDetailForView } from 'modules/project/detail/types';
import { HubState } from 'types';
import { InvoiceForView, InvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';

type Props = {
    projectDetail: ProjectDetailForView;
};

export const filterInvoice = (i: InvoiceForView, query: string) =>
    i.createdAt.includes(query.toLowerCase()) ||
    i.status.toLowerCase().includes(query.toLowerCase());

export const Component = (props: Props) => {
    const {
        projectDetail: { id: projectId, name },
    } = props;

    const [query, setQuery] = useState('');

    const dispatch = useDispatch();
    const isSheetOpen = useSelector(isInvoicesSheetOpen);
    const invoicesForView = useSelector<HubState, InvoicesForView | undefined>(selectInvoices);
    const invoices = invoicesForView?.invoices ?? [];
    const pagination = invoicesForView?.pagination;
    const invoicesLoaded = useSelector<HubState, boolean>(invoicesAreLoaded);

    const handleCloseSheet = () => dispatch(closeInvoicesSheet());
    const handleSearch = (q: string) => setQuery(q);
    const filteredInvoices = invoices.filter((i) => filterInvoice(i, query));
    const hasAnySearchResult = filteredInvoices.length > 0;

    useEffect(() => {
        isSheetOpen && dispatch(retrieveInvoices.request({ projectId }));
    }, [dispatch, projectId, isSheetOpen]);

    return (
        <Portal targetId="sheets">
            <Sheet isOpen={isSheetOpen}>
                {invoicesLoaded ? (
                    <>
                        <Header
                            projectName={name}
                            onCloseSheet={handleCloseSheet}
                            onSearch={handleSearch}
                        />

                        {hasAnySearchResult ? (
                            <Content
                                invoices={filteredInvoices}
                                onScrollFrame={() => {
                                    pagination?.hasMore &&
                                        dispatch(
                                            retrieveInvoices.request({
                                                projectId,
                                                lastElementId: pagination?.lastId,
                                            }),
                                        );
                                }}
                            />
                        ) : (
                            <EmptyState />
                        )}
                    </>
                ) : (
                    <CenteredLoader />
                )}
            </Sheet>
        </Portal>
    );
};

export const SheetInvoices = React.memo(Component);
