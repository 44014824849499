import { BillingInfoModuleState } from 'modules/billing-info/types/types';
import { ProjectListModuleState } from 'modules/project/list/types';
import { I18nModuleState } from 'modules/i18n/types';
import { NotificationsModuleState } from 'modules/notifications/types';
import { PaymentMethodModuleState } from 'modules/payment-methods/types/types';
import { PaymentWizardModuleState } from 'modules/payment-wizard/types';
import { ProfileModuleState } from 'modules/profile/types';
import { ProjectCreationModuleState } from 'modules/project/create/types';
import { ProjectDetailModuleState } from 'modules/project/detail/types';
import { ProjectCreationInfoModuleState } from 'modules/creation-info/types';
import { ConfigModuleState } from 'modules/config/types';
import { ProjectUpdateModuleState } from 'modules/project/update/types/types';
import { UserNotificationsModuleState } from 'modules/userNotifications/types';
import { TenantUsageModuleState } from 'modules/tenant-usage/types/types';
import { InvoicePreviewModuleState } from 'modules/invoice/types/types';
import { InvoicesModuleState } from 'modules/invoices/types';
import { UiModuleState } from 'modules/ui/types';
import { ProjectTerminationModuleState } from 'modules/project/terminate/types';
import { ProjectDeletionModuleState } from 'modules/project/delete/types';
import { ProjectRestartModuleState } from 'modules/project/restart/types';
import { LoggingModuleState } from 'modules/logger';
import { ProjectUpgradeModuleState } from 'modules/project/upgrade/types';

export type HubState = I18nModuleState &
    ProjectCreationModuleState &
    ProjectDetailModuleState &
    ProjectTerminationModuleState &
    ProjectDeletionModuleState &
    ProjectRestartModuleState &
    ProfileModuleState &
    NotificationsModuleState &
    ProjectCreationInfoModuleState &
    PaymentWizardModuleState &
    PaymentMethodModuleState &
    BillingInfoModuleState &
    ProjectListModuleState &
    ConfigModuleState &
    ProjectUpdateModuleState &
    InvoicePreviewModuleState &
    TenantUsageModuleState &
    UserNotificationsModuleState &
    InvoicesModuleState &
    UiModuleState &
    LoggingModuleState &
    ProjectUpgradeModuleState;

export enum NotificationCategories {
    ProjectError = 'PROJECT_ERROR',
    ProjectPendingBlocking = 'PROJECT_PENDING_BLOCKING',
    CreditCards = 'CREDIT_CARDS',
}
