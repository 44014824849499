import { ISagaModule } from 'redux-dynamic-modules-saga';
import { ModuleNotFoundError } from './ModuleNotFoundError';
import { HubStateKey, Modules } from './Modules';

export class HubModules implements Modules {
    private readonly modulesMap: Map<HubStateKey, ISagaModule<unknown>>;

    constructor(modules: ISagaModule<unknown>[] = []) {
        this.modulesMap = new Map(modules.map((module) => [module.id as HubStateKey, module]));
    }

    get<T>(id: HubStateKey): ISagaModule<T> {
        if (this.modulesMap.has(id)) {
            return this.modulesMap.get(id)! as ISagaModule<T>;
        }

        throw new ModuleNotFoundError(`Add ${id} module to the store if you want to use it.`);
    }
}
