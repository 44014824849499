import { HubState } from '../../types';

const DEFAULT_USAGE_VALUE = -1;
const defaultUsages = {
    users: DEFAULT_USAGE_VALUE,
    topics: DEFAULT_USAGE_VALUE,
    languages: DEFAULT_USAGE_VALUE,
    dataSources: DEFAULT_USAGE_VALUE,
};

const tenantUsageSelectors = {
    selectDifferences: (state: HubState) => state.tenantUsage?.ui.data?.diff ?? defaultUsages,
    selectPrices: (state: HubState) => state.tenantUsage?.ui.data?.prices ?? {},
    selectNextPlanId: (state: HubState) => state.tenantUsage?.ui.data?.nextPlanId,
    isModalOpen: (state: HubState) => state.tenantUsage?.ui.status === 'OPEN',
    selectNextSeats: (state: HubState) =>
        state.tenantUsage?.ui.data?.nextSeats ?? DEFAULT_USAGE_VALUE,
    selectUsages: (state: HubState) => state.tenantUsage?.data?.usages ?? defaultUsages,
};

export default tenantUsageSelectors;
