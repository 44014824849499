import React from 'react';

//@ts-ignore
import TextInput from '@igenius_dev/crystalkit/lib/components/Inputs/TextInput';
import { OutlinedFluidField } from './OutlinedFluidField';

export interface TextFieldProps {
    className?: string;
    label?: string;
    placeholder: string;
    onChange: (e: any) => void;
    onBlur?: () => void;
    value: string;
    name: string;
    instructionText?: string;
    isErrored?: boolean;
    type?: string;
}

export const TextField: React.FC<TextFieldProps> = ({
    className,
    label = '',
    name,
    placeholder,
    onChange,
    value,
    isErrored = false,
    instructionText = '',
    type = 'text',
    onBlur,
}) => (
    <OutlinedFluidField
        className={className}
        labelText={label}
        isErrored={isErrored}
        instructionText={instructionText}
    >
        <TextInput
            type={type}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name={name}
        />
    </OutlinedFluidField>
);
