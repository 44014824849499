import React from 'react';
import { StripeCardNumberElementOptions } from '@stripe/stripe-js';
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import Button from '@igenius_dev/crystalkit/lib/components/Button';
// @ts-ignore
import Field from '@igenius_dev/crystalkit/lib/components/Field';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { TextField } from 'components/Fields/TextField';
import { selectStatusForAddingPaymentMethod } from 'modules/payment-methods/selectors';
import useTranslator from 'hooks/useTranslator';

const Title = styled(Text).attrs({
    scale: 'Large',
})`
    margin-bottom: 8px;
`;

const Subtitle = styled(Text).attrs({
    scale: 'Small',
})`
    color: ${theme.colors.values.black300};
`;

const Actions = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;

    & > * {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

const StripeField = styled(Field).attrs({
    displayType: Field.DisplayTypes.OUTLINED,
})`
    width: 100%;
    margin-top: 16px;
`;

const StyledTextField = styled(TextField)`
    margin-top: 16px;
`;

const Content = styled.div`
    width: 100%;
    margin: 8px 0;
`;

type CreditCard = {
    cvc: string;
    cardNumber: string;
    expiryDate: string;
    cardHolder: string;
};

type Props = {
    onSubmit: (d: CreditCard) => void;
    onCancel: () => void;
};

const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
`;

export const CreditCardForm = ({ onSubmit, onCancel }: Props) => {
    const addingStatus = useSelector(selectStatusForAddingPaymentMethod);
    const t = useTranslator();
    const {
        handleSubmit,
        control,
        setError,
        errors,
        clearErrors,
        formState: { isValid },
    } = useForm<CreditCard>({
        mode: 'onChange',
    });
    const options: StripeCardNumberElementOptions = {
        style: {
            base: {
                fontFamily: theme.typography.systemFonts.replace(/;/, ''),
                color: theme.colors.values.black500,
                fontSize: '13px',
                lineHeight: '34px',
                '::placeholder': {
                    color: theme.colors.values.black100,
                    fontWeight: `${theme.typography.weights.values.regular}`,
                },
            },
            invalid: {
                color: theme.colors.values.black500,
            },
        },
        showIcon: true,
    };
    const submitDisabled = !isValid;
    const isSubmitting = addingStatus === 'PENDING';

    return (
        <form onSubmit={handleSubmit(onSubmit)} data-cy="add-payment-method-modal">
            {isSubmitting ? <Overlay /> : null}
            <Title>{t('payment-details-dialogue-title-add-card')}</Title>
            <Subtitle>{t('payment-details-dialogue-subtitle-add-card')}</Subtitle>
            <Content>
                <Controller
                    name={'cardNumber'}
                    defaultValue={''}
                    render={() => (
                        <StripeField
                            labelText={t('payment-details-dialogue-label-card-number')}
                            instructionText={errors.cardNumber?.message}
                            isErrored={errors.cardNumber}
                        >
                            <CardNumberElement
                                options={options}
                                onChange={({ error }) => {
                                    error
                                        ? setError('cardNumber', {
                                              message: error.message,
                                              type: 'validate',
                                          })
                                        : clearErrors('cardNumber');
                                }}
                            />
                        </StripeField>
                    )}
                    control={control}
                />

                <Controller
                    name={'cardHolder'}
                    defaultValue={''}
                    rules={{ required: 'Field is required' }}
                    render={({ value, onChange }) => (
                        <StyledTextField
                            onChange={onChange}
                            name={'cardHolder'}
                            value={value}
                            placeholder={t(
                                'payment-details-dialogue-label-card-holder-label-placeholder',
                            )}
                            label={t('payment-details-dialogue-label-card-holder-label')}
                            instructionText={errors.cardHolder?.message}
                            isErrored={!!errors.cardHolder}
                        />
                    )}
                    control={control}
                />

                <Controller
                    name={'expiryDate'}
                    control={control}
                    defaultValue={''}
                    render={() => (
                        <StripeField
                            labelText={t('payment-details-dialogue-label-expiration-date')}
                            instructionText={errors.expiryDate?.message}
                            isErrored={errors.expiryDate}
                        >
                            <CardExpiryElement
                                options={options}
                                onChange={({ error }) => {
                                    error
                                        ? setError('expiryDate', {
                                              message: error.message,
                                              type: 'validate',
                                          })
                                        : clearErrors('expiryDate');
                                }}
                            />
                        </StripeField>
                    )}
                />

                <Controller
                    name={'cvc'}
                    control={control}
                    defaultValue={''}
                    render={() => (
                        <StripeField
                            labelText="CVC"
                            instructionText={errors.cvc?.message}
                            isErrored={errors.cvc}
                        >
                            <CardCvcElement
                                options={options}
                                onChange={({ error }) => {
                                    error
                                        ? setError('cvc', {
                                              message: error.message,
                                              type: 'validate',
                                          })
                                        : clearErrors('cvc');
                                }}
                            />
                        </StripeField>
                    )}
                />
            </Content>
            <Actions>
                <Button
                    color="white"
                    label={t('payment-details-dialogue-button-cancel')}
                    onClick={onCancel}
                />
                <Button
                    disabled={submitDisabled}
                    loading={isSubmitting}
                    label={t('payment-details-dialogue-button-save')}
                    type="submit"
                />
            </Actions>
        </form>
    );
};
