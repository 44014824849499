import CkitToast from '@igenius_dev/crystalkit/lib/components/Toast';
import { Translator } from '@igenius_dev/igenius-i18n/lib';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ToastAction from '../ToastAction';

class Toast extends PureComponent {
    constructor(props) {
        super(props);

        this.dismiss = this.dismiss.bind(this);
    }

    dismiss() {
        const { id, onDismiss } = this.props;

        onDismiss(id);
    }

    render() {
        const { actions, enter, exit, id, in: animationIn, label, onExited, type } = this.props;

        const animationProps = {
            enter,
            exit,
            in: animationIn,
            onExited,
        };

        return (
            <Translator>
                {(t) => (
                    <div id={id}>
                        <CkitToast
                            {...animationProps}
                            type={type}
                            label={t(label)}
                            onDismiss={this.dismiss}
                            actions={actions.map(({ label: actionLabel, action }, index) => (
                                <ToastAction
                                    toastId={id}
                                    key={index}
                                    label={t(actionLabel)}
                                    onDismiss={this.dismiss}
                                    action={action}
                                />
                            ))}
                        />
                    </div>
                )}
            </Translator>
        );
    }
}

const animationProps = {
    enter: PropTypes.string,
    exit: PropTypes.string,
    in: PropTypes.bool,
    onExited: PropTypes.func,
};

Toast.propTypes = {
    ...animationProps,
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onDismiss: PropTypes.func,
    actions: PropTypes.arrayOf(
        PropTypes.shape({
            actionLabel: PropTypes.string,
            actionType: PropTypes.string,
        }),
    ),
    actionSideEffect: PropTypes.func,
};

Toast.defaultProps = {
    actions: [],
    onDismiss: () => {},
    actionSideEffect: () => {},
};

export default Toast;
