import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import styled from 'styled-components';

export const UnsubsribeBlock = styled.div`
    margin-top: 40px;
    margin-bottom: 40px;
`;

export const UnsubscribeButton = styled(TextLink).attrs({
    scale: 'Small',
    color: 'red700',
})``;

export const AvailableTillText = styled(Text).attrs({
    scale: 'Small',
    color: 'black200',
})`
    margin-left: 16px;
`;
