import React from 'react';
import styled from 'styled-components';

import Text from '@igenius_dev/crystalkit/lib/components/Text';
import { PriceForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';

const SeatsText = styled(Text).attrs({
    color: 'black500',
})``;

export const SeatsItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;

    & + & {
        margin-bottom: 0;
    }
`;

interface SeatsWithPriceProps {
    currentPrice: PriceForView;
    seats: number;
}

export const SeatsWithPrice = (props: SeatsWithPriceProps) => {
    const t = useTranslator();
    const { currentPrice, seats } = props;

    return (
        <>
            <SeatsItem>
                <SeatsText>{t('hub-column-recap-label-coupon-accordion-price-per-seat')}</SeatsText>
                <SeatsText>{currentPrice.amount.withDigits}</SeatsText>
            </SeatsItem>
            <SeatsItem>
                <SeatsText>{t('hub-column-recap-label-coupon-accordion-number-seats')}</SeatsText>
                <SeatsText>{seats}</SeatsText>
            </SeatsItem>
        </>
    );
};
