import { add } from 'date-fns';
import { put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { NotificationRepository } from '../../core/repository/notifications/NotificationRepository';

const dismissDurationInHours = 12;

export function createNotificationsSaga(notificationRepository: NotificationRepository) {
    return function* notificationsSaga() {
        yield takeLatest(
            actions.setNotification.request,
            function* (action: ReturnType<typeof actions.setNotification.request>) {
                yield put(actions.setNotification.success(action.payload));
            },
        );
        yield takeLatest(
            actions.removeNotification.request,
            function* (action: ReturnType<typeof actions.removeNotification.request>) {
                const { id } = action.payload;
                const visibleAtTime = add(new Date(), { hours: dismissDurationInHours }).getTime();

                notificationRepository.save<number>(id, visibleAtTime);

                yield put(actions.removeNotification.success(action.payload));
            },
        );
    };
}
