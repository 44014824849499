import React from 'react';

import { CheckIcon, FAQLink, FAQText, Feature, Features, PlanText, PlanTitle } from './elements';
import { contactSupport } from 'utils/support';
import { Accordion } from '../../../../components/Accordion';
import { openFAQinNewTab } from '../../../../utils/goToFaq';
import useTranslator from '../../../../hooks/useTranslator';

interface Props {
    title: string;
    trial?: boolean;
    text: string;
    features?: string[];
    hasTrigger?: boolean;
}

export const PlanDescription = ({ title, text, features = [] }: Props) => {
    const t = useTranslator();

    return (
        <Accordion
            subtitle={text}
            title={<PlanTitle>{title}</PlanTitle>}
            text={
                <Features>
                    {features.map((feature) => (
                        <Feature key={feature}>
                            <CheckIcon /> <PlanText scale="SmallMedium">{t(feature)}</PlanText>
                        </Feature>
                    ))}
                    <Feature key="faq">
                        <FAQText>
                            {t('hub-column-recap-plan-features-faqs-contact-accordion-read')}{' '}
                            <FAQLink onClick={openFAQinNewTab}>FAQ</FAQLink>{' '}
                            {t('hub-column-recap-plan-features-faqs-contact-accordion-or')}{' '}
                            <FAQLink onClick={contactSupport}>
                                {t(
                                    'hub-column-recap-plan-features-faqs-contact-accordion-text-link-contact-us',
                                )}
                            </FAQLink>{' '}
                            {t(
                                'hub-column-recap-plan-features-faqs-contact-accordion-for-more-info',
                            )}
                        </FAQText>
                    </Feature>
                </Features>
            }
        />
    );
};
