import { HubState } from '../../types';
import { PaymentMethodsStatus } from './types/types';
import { PaymentMethod } from '../../core/entities/payment-method/PaymentMethod';

export const selectPaymentMethods: (state: HubState) => Array<PaymentMethod> = (state: HubState) =>
    state.paymentMethods?.methods ?? [];

export const selectActivePaymentMethod = (state: HubState) => {
    const paymentMethodId = state.projectCreation?.project?.paymentMethodId;

    return state.paymentMethods?.methods?.find(
        (method: PaymentMethod) => method.id === paymentMethodId,
    );
};

export const selectCards: (state: HubState) => PaymentMethod[] | undefined = (state: HubState) =>
    state.paymentMethods?.methods?.filter((method: PaymentMethod) => method.type === 'card');

export const selectStatusForAddingPaymentMethod = (state: HubState) =>
    state.paymentMethods?.status.add ?? PaymentMethodsStatus.INITIAL;
