import { call, put, takeLatest } from 'redux-saga/effects';

import { addErrorToast, addSuccessToast } from 'modules/toasts/actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import projectListActions from 'modules/project/list/actions';
import { deleteProject } from 'modules/project/delete/actions';
import loggingActions from 'modules/logger/actions';

export function createProjectDeletionSaga(repository: ProjectRepository) {
    return function* () {
        yield takeLatest(deleteProject.request, (args) => handleDeleteProject(args, repository));
        yield takeLatest(deleteProject.failure, handleDeleteProjectError);
        yield takeLatest(deleteProject.success, handleDeleteProjectSuccess);
    };
}

function* handleDeleteProject(
    action: ReturnType<typeof deleteProject.request>,
    repository: ProjectRepository,
) {
    try {
        const { id: projectId, onSuccess = () => {} } = action.payload;

        yield call([repository, 'delete'], projectId);
        yield call(onSuccess);
        yield put(deleteProject.success());
    } catch (e) {
        yield put(deleteProject.failure(e.message));
    }
}

function* handleDeleteProjectError({ payload }: ReturnType<typeof deleteProject.failure>) {
    yield put(
        addErrorToast({
            id: 'delete-project-error',
            type: 'error',
            label: 'hub-unsubscribe-toast-error',
            isAutoDismissEnabled: false,
            actions: [
                {
                    label: 'Close',
                },
            ],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to delete project',
            error: payload,
        }),
    );
}

function* handleDeleteProjectSuccess() {
    yield put(projectListActions.projectListFetch.request());
    yield put(
        addSuccessToast({
            type: 'success',
            id: 'delete-project-success',
            label: `You've successfully deleted the project`,
            isAutoDismissEnabled: true,
        }),
    );
}
