import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
// @ts-ignore
import TextInput from '@igenius_dev/crystalkit/lib/components/Inputs/TextInput';
import React, { useState } from 'react';
import { contactSupport } from 'utils/support';
import { LinkAction } from '../../Actions';
import { DetailTable } from '../../DetailTable';
import { Widget } from '../../Widget';
import useTranslator from '../../../../../../hooks/useTranslator';
import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';
// @ts-ignore
import Field from '@igenius_dev/crystalkit/lib/components/Field';
import RadioGroup from '@igenius_dev/crystalkit/lib/components/RadioGroup';
import Radio from '@igenius_dev/crystalkit/lib/components/Radio';
import { RadioContainer, Label, BlueProjectVersionContainer, StyledToolTip } from './elements';
import { useFeatures } from 'components/FeatureFlags/hook';
import { ProjectStatusDto } from 'modules/project/types/dto';

interface Props {
    name: string;
    domain: string;
    timezone?: string;
    projectVersion: string;
    status: ProjectStatusDto;
    onUpgradeProject: (projectId: string) => void;
}
export const About: React.FC<Props> = ({
    name,
    domain,
    timezone,
    projectVersion,
    status,
    onUpgradeProject,
}) => {
    const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
    const [shouldShowVersionTooltip, setShouldShowVersionTooltip] = useState(false);
    const [versionType, setVersionType] = useState<'manual' | 'latest'>('latest');
    const [version, setVersion] = useState('');
    // user can only upgrade once per session
    const [hasUpgradeBeenRequested, setHasUpgradeBeenRequested] = useState(false);
    const t = useTranslator();
    const FEATURE_TIMEZONE = false;
    const { upgradeProject } = useFeatures();

    const onTooltipAck = () => setShouldShowVersionTooltip(false);

    const handleDialogHasClosed = () => {
        setVersionType('latest');
        setVersion('');
    };

    const isProjectUpgradeDisabled =
        hasUpgradeBeenRequested || !upgradeProject.enabled || status === 'PENDING_BLOCKING';

    const items = [
        {
            name: 'rowProjectName',
            label: t('hub-trial-project-label-project-name'),
            value: name,
            action: <LinkAction label={t('hub-trial-project-button-change')} disabled />,
        },
        {
            name: 'rowProjectDomain',
            label: t('hub-trial-project-label-project-domain'),
            value: (
                <TextLink
                    color="black300"
                    scale="Small"
                    onClick={() => {
                        window.open(`https://${domain}`, '_blank', 'noreferrer');
                    }}
                >
                    {domain}
                </TextLink>
            ),
            action: (
                <LinkAction
                    label={t('hub-trial-project-button-contact-us-to-change')}
                    onClick={contactSupport}
                />
            ),
        },
        {
            name: 'rowProjectVersion',
            label: t('hub_project_detail_about_label_version'),
            value: projectVersion,
            action: (
                <StyledToolTip
                    title={t('hub_project_detail_upgrade_popover_title')}
                    label={t('hub_project_detail_upgrade_popover_body')}
                    color="green700"
                    show={shouldShowVersionTooltip}
                    position={StyledToolTip.position.main.rightCenter}
                    isDismissable
                    onDismiss={onTooltipAck}
                    actions={[
                        {
                            label: t('hub_project_detail_upgrade_popover_cta'),
                            color: 'white',
                            onClick: onTooltipAck,
                        },
                    ]}
                >
                    <LinkAction
                        disabled={isProjectUpgradeDisabled}
                        label={t('hub_project_detail_about_textlink_upgrade_version')}
                        onClick={() => setIsUpgradeDialogOpen(true)}
                    />
                </StyledToolTip>
            ),
        },
    ];
    if (FEATURE_TIMEZONE) {
        items.push({
            name: 'rowLocation',
            label: t('hub-trial-project-label-location'),
            value: t(timezone ? '' : '-'),
            action: (
                <LinkAction
                    label={t('hub-trial-project-button-contact-us-to-change-timezone')}
                    onClick={contactSupport}
                />
            ),
        });
    }
    return (
        <>
            <Dialog
                title={t('hub_project_detail_upgrade_version_dialog_title')}
                type={Dialog.type.small}
                open={isUpgradeDialogOpen}
                onCancel={() => setIsUpgradeDialogOpen(false)}
                dismissable
                onClickOutside={() => setIsUpgradeDialogOpen(false)}
                onExited={handleDialogHasClosed}
                actions={[
                    {
                        secondary: true,
                        label: t('hub_project_detail_upgrade_version_dialog_cancel_button'),
                        onClick: () => {
                            setIsUpgradeDialogOpen(false);
                        },
                    },
                    {
                        positive: true,
                        label: t('hub_project_detail_upgrade_version_dialog_upgrade_button'),
                        onClick: () => {
                            setIsUpgradeDialogOpen(false);
                            setShouldShowVersionTooltip(true);
                            setHasUpgradeBeenRequested(true);
                            onUpgradeProject(versionType === 'manual' ? version : '');
                        },
                    },
                ]}
                renderContent={() => (
                    <>
                        <RadioGroup
                            name="versionType"
                            value={versionType}
                            onValueChange={(t) => setVersionType(t)}
                        >
                            <RadioContainer>
                                <div onClick={() => setVersionType('latest')}>
                                    <Label>
                                        {t(
                                            'hub_project_detail_upgrade_version_dialog_latest_version',
                                        )}
                                    </Label>
                                    <Radio value="latest" />
                                </div>
                                <div onClick={() => setVersionType('manual')}>
                                    <Label>
                                        {t(
                                            'hub_project_detail_upgrade_version_dialog_manual_input',
                                        )}
                                    </Label>
                                    <Radio value="manual" />
                                </div>
                            </RadioContainer>
                        </RadioGroup>

                        <BlueProjectVersionContainer visible={versionType === 'manual'}>
                            <Field
                                outlined
                                labelText={t(
                                    'hub_project_detail_upgrade_version_dialog_manual_input_input_title',
                                )}
                            >
                                <TextInput
                                    placeholder={t(
                                        'hub_project_detail_upgrade_version_dialog_manual_input_input_placeholder',
                                    )}
                                    value={version}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                        setVersion(e.target.value)
                                    }
                                />
                            </Field>
                        </BlueProjectVersionContainer>
                    </>
                )}
            />
            <Widget title={t('hub-trial-project-detail-title-about-project')}>
                <DetailTable list={items} />
            </Widget>
        </>
    );
};
