import { createAction } from 'typesafe-actions';

export const nextStepWizard = createAction('@payment-wizard/PAYMENT_WIZARD_NEXT_STEP')<{
    currentStep: number;
    basePath?: string;
}>();

export const prevStepWizard = createAction('@payment-wizard/PAYMENT_WIZARD_PREV_STEP')<{
    goToStep: number;
    basePath?: string;
}>();

export const startWizard = createAction('@payment-wizard/PAYMENT_WIZARD_START')<
    | undefined
    | {
          basePath?: string;
      }
>();

export const endWizard = createAction('@payment-wizard/PAYMENT_WIZARD_END')();

export const cancelWizard = createAction('@payment-wizard/PAYMENT_WIZARD_CANCEL')<{
    callback: () => void;
}>();
