import { CouponDto, ProjectSubscriptionDto } from '../../types/dto';
import { Subscription } from '../../../../core/entities/project/Subscription';
import { CreditCardDTO, InvoiceDto } from '../../../../common/dtos';
import { Coupon } from '../../../../core/entities/Coupon';
import { PaymentMethod } from '../../../../core/entities/payment-method/PaymentMethod';
import { Invoice } from 'core/entities/invoice/Invoice';

export function adaptToSubscription(
    adaptToInvoice: (invoice: InvoiceDto) => Invoice,
    adaptToPaymentMethod: (method: CreditCardDTO) => PaymentMethod,
    adaptToCoupon: (coupon?: CouponDto) => Coupon | undefined,
) {
    return function (res: ProjectSubscriptionDto): Subscription {
        const { default_payment_method, price, latest_invoice, coupon } = res;
        const { plan } = price;

        return {
            id: res.id,
            quantity: res.quantity,
            endOfCurrentPeriod: res.current_period_end * 1000,
            endOfTrial: res.trial_end ? res.trial_end * 1000 : undefined,
            latestInvoice: adaptToInvoice(latest_invoice),
            price: {
                currency: price.currency,
                id: price.id,
                timeInterval: price.recurring_interval === 'month' ? 'month' : 'year',
                amount: price.unit_amount,
                plan: {
                    description: plan.description,
                    id: plan.id,
                    name: plan.name,
                    metadata: {
                        dataSources: plan.metadata.data_sources,
                        languages: plan.metadata.languages,
                        users: plan.metadata.customer_support,
                        topics: plan.metadata.topics,
                    },
                },
            },
            defaultPaymentMethod: adaptToPaymentMethod(default_payment_method),
            coupon: adaptToCoupon(coupon),
        };
    };
}
