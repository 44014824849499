import axios from 'axios';
import { I18nDictionary } from './types';

export const i18nUri = '/console-dictionaries/web';

export const fetchDictionary = async (): Promise<I18nDictionary> => {
    const response = await axios.get<I18nDictionary>(`${i18nUri}/en.json`);

    return response.data;
};
