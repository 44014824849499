import React, { SyntheticEvent } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ChipItem, ChipsField } from 'components/Fields/ChipsField';
import { SelectField } from 'components/Fields/SelectField';
import { TextField } from 'components/Fields/TextField';
import { TextFieldWithLabel } from 'components/Fields/TextFieldWithLabel';
import { ProjectCreationFormData } from '../ProjectCreator';
import useTranslator from 'hooks/useTranslator';
import { DOMAIN_REGEX } from 'modules/project/create/api/domain/domainNormalizer';

const FluidContainer = styled.div`
    width: 100%;

    & + & {
        margin-top: 32px;
    }
`;

interface Props {
    timezones: { label: string; value: string }[];
    languages: { list: { label: string; value: string }[]; favorite: string };
    domainValidator: (domain: string) => Promise<boolean>;
    domainNormalizer: (domain: string) => string;
    domain: string;
}

const FEATURE_TIMEZONE = false;

export const ProjectSettings: React.FC<Props> = (props) => {
    const { timezones, domainNormalizer, domainValidator, languages, domain } = props;
    const t = useTranslator();
    const {
        control,
        errors,
        setValue,
        trigger,
        getValues,
        formState: { dirtyFields },
    } = useFormContext<ProjectCreationFormData>();
    const crystalDomain = `.${domain}`;
    const defaultLanguage = languages.list.length > 0 ? languages.list[0] : '';
    const defaultFavorite = languages.list.length > 0 ? languages.list[0].value : '';

    const handleFavoriteSelect = (item: ChipItem) => {
        const previousLanguages = getValues()['languages'];
        // If user has set an unselected language as favorite, select it
        const newList = previousLanguages.list.some((l) => l.value === item.value)
            ? previousLanguages.list
            : [...previousLanguages.list, item];
        setValue(
            'languages',
            {
                list: newList,
                favorite: item.value,
            },
            { shouldValidate: true },
        );
    };

    const handleFavoriteDeselect = () => {
        const prevLanguages = getValues()['languages'];
        setValue('languages', { ...prevLanguages, favorite: '' }, { shouldValidate: true });
    };

    const handleSelect = (item: ChipItem) => {
        const previousLanguages = getValues()['languages'];
        setValue(
            'languages',
            {
                ...previousLanguages,
                list: [...previousLanguages.list, item],
            },
            {
                shouldValidate: true,
            },
        );
    };

    const handleDeselect = (item: ChipItem) => {
        const previousLanguages = getValues()['languages'];
        const newList = previousLanguages.list.filter((l) => l.value !== item.value);
        const newFavorite =
            previousLanguages.favorite === item.value ? '' : previousLanguages.favorite;
        setValue('languages', { list: newList, favorite: newFavorite }, { shouldValidate: true });
    };

    return (
        <>
            <FluidContainer>
                <Controller
                    defaultValue=""
                    control={control}
                    name="name"
                    rules={{
                        required: t('hub-trial-project-error-project-name-mandatory'),
                        minLength: {
                            value: 2,
                            message: t('hub-trial-project-name-error-message-too-short'),
                        },
                        maxLength: {
                            value: 15,
                            message: t('hub-trial-project-name-error-message-too-long'),
                        },
                    }}
                    render={({ onChange, value, onBlur }) => (
                        <TextField
                            onBlur={() => {
                                onBlur();

                                const { name } = getValues();

                                setValue('name', name.trim());

                                return trigger('domain');
                            }}
                            label={t('hub-trial-project-label-project-name')}
                            placeholder={t('hub-trial-project-placeholder-project-name')}
                            onChange={(event: SyntheticEvent<HTMLInputElement>) => {
                                onChange(event);

                                if (!dirtyFields.domain) {
                                    const value = event.currentTarget.value;
                                    const normalized = domainNormalizer(value);

                                    setValue('domain', normalized);

                                    return trigger('domain');
                                }
                            }}
                            value={value}
                            name="name"
                            isErrored={!!errors.name?.message}
                            instructionText={
                                errors.name?.message ||
                                t('hub-trial-project-name-subtitle-name-features')
                            }
                        />
                    )}
                />
            </FluidContainer>
            <FluidContainer>
                <Controller
                    name="domain"
                    defaultValue=""
                    control={control}
                    rules={{
                        required: true,
                        minLength: {
                            value: 5,
                            message: t('hub-trial-project-domain-error-message-too-short'),
                        },
                        maxLength: {
                            value: 15,
                            message: t('hub-trial-project-domain-error-message-too-long'),
                        },
                        pattern: {
                            value: DOMAIN_REGEX,
                            message: t(
                                'hub-trial-project-domain-error-message-no-special-character',
                            ),
                        },
                        validate: async (value) => {
                            const response = await domainValidator(value);

                            return response || t('hub-trial-project-error-domain-used');
                        },
                    }}
                    render={({ onChange, value, onBlur }) => (
                        <TextFieldWithLabel
                            onBlur={onBlur}
                            label={t('hub-trial-project-label-project-domain')}
                            placeholder={t('hub-trial-project-placeholder-project-domain')}
                            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                                const value = e.currentTarget.value;
                                const normalized = domainNormalizer(value);

                                onChange(normalized);
                            }}
                            value={value}
                            name="domain"
                            isErrored={!!errors.domain}
                            instructionText={
                                errors.domain?.message ||
                                t('hub-trial-project-domain-subtitle-domain-features')
                            }
                            trailingLabel={crystalDomain}
                        />
                    )}
                />
            </FluidContainer>
            {FEATURE_TIMEZONE && (
                <FluidContainer>
                    <Controller
                        name="timezone"
                        defaultValue=""
                        control={control}
                        rules={{
                            required: true,
                        }}
                        render={({ onChange, value }) => {
                            return (
                                <SelectField
                                    label={t('hub-trial-project-label-location')}
                                    name="timezone"
                                    value={value}
                                    onChange={onChange}
                                    items={timezones}
                                    placeholder={t('hub-trial-project-placeholder-select-location')}
                                    instructionText={t('hub-trial-project-description-location')}
                                />
                            );
                        }}
                    />
                </FluidContainer>
            )}

            {defaultLanguage && (
                <FluidContainer>
                    <Controller
                        name="languages"
                        control={control}
                        defaultValue={{ list: [defaultLanguage], favorite: defaultFavorite }}
                        rules={{
                            validate: (value) => value.list.length > 0 && value.favorite !== '',
                        }}
                        render={({ value }) => (
                            <ChipsField
                                value={value}
                                label={t('hub-trial-project-label-language')}
                                chips={languages.list}
                                instructionText={t('hub-trial-project-description-language')}
                                onFavouriteSelect={handleFavoriteSelect}
                                onFavouriteDeselect={handleFavoriteDeselect}
                                onSelect={handleSelect}
                                onDeselect={handleDeselect}
                            />
                        )}
                    />
                </FluidContainer>
            )}
        </>
    );
};
