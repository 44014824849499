import React from 'react';

import { FieldAction, FieldLabel, FieldValue, Row, StyledTable } from './elements';
import { Entry } from '../../types';

type Props = {
    list: Array<Entry>;
    className?: string;
};

export const Table: React.FC<Props> = ({ list, className }) => (
    <StyledTable data-cy="widget" className={className}>
        {list.map((element, i) => (
            <Row key={i} data-cy={element.name}>
                <FieldLabel data-cy="label">{element.label}</FieldLabel>
                <FieldValue data-cy="value">{element.value}</FieldValue>
                <FieldAction data-cy="action">{element.action}</FieldAction>
            </Row>
        ))}
    </StyledTable>
);
