import React from 'react';

//@ts-ignore
import Select from '@igenius_dev/crystalkit/lib/components/Select';
import styled from 'styled-components';
import { TitleField } from './TitleField';
import { Instructions } from '../Instructions';
import { LabelValuePair, Translator } from 'common/types';

export type SelectItem = { label: string; value: string };

interface Props {
    label?: string;
    placeholder: string;
    onChange: (item: SelectItem) => void;
    value?: SelectItem;
    name: string;
    items: SelectItem[];
    instructionText?: string;
    defaultValue?: SelectItem;
    alwaysEnableSearch?: boolean;
    className?: string;
    filterFn?: (query: string, t: Translator) => (item: LabelValuePair) => boolean;
}

const Title = styled(TitleField)`
    margin-bottom: 8px;
`;

export const SelectField: React.FC<Props> = ({
    label = '',
    name,
    placeholder,
    onChange,
    value,
    instructionText = '',
    items,
    defaultValue,
    alwaysEnableSearch = false,
    className = '',
    filterFn,
}) => (
    <>
        {label && <Title>{label}</Title>}
        <Select
            isFluid
            placeholder={placeholder}
            onChange={onChange}
            value={value}
            name={name}
            items={items}
            filterFn={filterFn}
            defaultValue={defaultValue}
            alwaysEnableSearch={alwaysEnableSearch}
            className={className}
        />
        {instructionText && <Instructions>{instructionText}</Instructions>}
    </>
);
