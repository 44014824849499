import { TimeInterval } from '../core/entities/Price';

export const expirationTimestampFromTimeInterval = (
    now: number,
    interval?: TimeInterval,
): number => {
    if (!interval) {
        return now;
    }

    const dateObj = new Date(now);
    const month = dateObj.getMonth();
    const year = dateObj.getFullYear();

    if (interval === 'year') {
        return dateObj.setFullYear(year + 1);
    }

    return dateObj.setMonth(month + 1);
};
