import { ISagaModule } from 'redux-dynamic-modules-saga';
import { PaymentMethodModuleState } from './types/types';
import { createPaymentMethodsSaga } from './saga';
import { reducer } from './reducer';
import { PaymentMethodsRepository } from '../../core/repository/payment-methods/PaymentMethodsRepository';

export const getPaymentMethodsModule = (
    paymentMethodsRepository: PaymentMethodsRepository,
): ISagaModule<PaymentMethodModuleState> => ({
    id: 'paymentMethods',
    sagas: [createPaymentMethodsSaga(paymentMethodsRepository)],
    reducerMap: {
        paymentMethods: reducer as any,
    },
});
