import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentMethodsActions } from './actions';
import { SetupIntent, StripeError } from '@stripe/stripe-js';
import { addErrorToast, addSuccessToast } from 'modules/toasts/actions';
import { PaymentMethodsRepository } from 'core/repository/payment-methods/PaymentMethodsRepository';
import { PaymentMethod } from 'core/entities/payment-method/PaymentMethod';
import { PaymentToken } from 'modules/payment-methods/types/types';
import loggingActions from 'modules/logger/actions';

function* retrievePaymentMethods(
    action: ReturnType<typeof paymentMethodsActions.retrieve.request>,
    paymentMethodsRepository: PaymentMethodsRepository,
) {
    try {
        const response: PaymentMethod[] = yield call([paymentMethodsRepository, 'cards']);

        yield put(paymentMethodsActions.retrieve.success(response));
    } catch (e) {
        yield put(paymentMethodsActions.retrieve.failure(e.message));
    }
}

function* addPaymentMethods(
    action: ReturnType<typeof paymentMethodsActions.add.request>,
    paymentMethodsRepository: PaymentMethodsRepository,
) {
    const { stripe, card, onSuccess = () => null } = action.payload;

    try {
        const response: PaymentToken = yield call([paymentMethodsRepository, 'token']);

        const {
            error,
            setupIntent,
        }: { error?: StripeError; setupIntent?: SetupIntent } = yield call(
            [stripe, 'confirmCardSetup'],
            response.clientSecret,
            { payment_method: { card } },
        );

        if (error) {
            yield put(paymentMethodsActions.add.failure(new Error(error.message)));
            yield call([paymentMethodsRepository, 'deleteToken'], response.clientSecret);
        } else {
            yield put(paymentMethodsActions.add.success({ intent: setupIntent! }));
            yield put(paymentMethodsActions.retrieve.request({ type: 'card' }));
            yield put(
                addSuccessToast({
                    id: 'add-payment-method-success',
                    label: 'Payment method added!',
                    isAutoDismissEnabled: true,
                    type: 'success',
                }),
            );
            yield call(onSuccess);
        }
    } catch (e) {
        yield put(paymentMethodsActions.add.failure(e));
    }
}

function* handleAddPaymentMethodsFailure({
    payload,
}: ReturnType<typeof paymentMethodsActions.add.failure>) {
    yield put(
        addErrorToast({
            id: 'add-payment-method-error',
            type: 'error',
            label: 'Something went wrong adding the payment method',
            isAutoDismissEnabled: false,
            actions: [{ label: 'Close' }],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to add payment method',
            error: payload,
        }),
    );
}

export function createPaymentMethodsSaga(paymentMethodsRepository: PaymentMethodsRepository) {
    return function* paymentMethodsSaga() {
        yield takeLatest(paymentMethodsActions.retrieve.request, (args) =>
            retrievePaymentMethods(args, paymentMethodsRepository),
        );
        yield takeLatest(paymentMethodsActions.add.request, (args) =>
            addPaymentMethods(args, paymentMethodsRepository),
        );
        yield takeLatest(paymentMethodsActions.add.failure, handleAddPaymentMethodsFailure);
    };
}
