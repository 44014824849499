import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import * as toastsActions from '../../actions';
import * as toastsSelectors from '../../selectors';

class ToastsConsumer extends PureComponent {
    render() {
        const { children, toasts, addToast, removeToast, actionSideEffect } = this.props;
        return children({ toasts, addToast, removeToast, actionSideEffect });
    }
}

const mapStateToProps = state => ({
    toasts: toastsSelectors.getAllToasts(state),
});

const mapDispatchToProps = dispatch => ({
    addToast: payload => {
        dispatch(toastsActions.addSuccessToast(payload));
    },
    removeToast: toastId => {
        dispatch(toastsActions.removeToast(toastId));
    },
});

const ConnectedToastsConsumer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(ToastsConsumer);

function ToastsProvider({ children }) {
    return (
        <ConnectedToastsConsumer>{children}</ConnectedToastsConsumer>
    );
}

ToastsConsumer.propTypes = {
    children: PropTypes.func.isRequired,
    toasts: PropTypes.array,
    addToast: PropTypes.func,
    removeToast: PropTypes.func,
};

ToastsConsumer.defaultProps = {
    toasts: [],
    addToast: () => {},
    removeToast: () => {},
};

export default ToastsProvider;
