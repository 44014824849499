import styled from 'styled-components';

import Button from '@igenius_dev/crystalkit/lib/components/Button';

export const DeleteButton = styled(Button).attrs({
    color: 'red',
    size: 'base',
})``;

export const ProjectRemovalWrapper = styled.div``;
