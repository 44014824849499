import { ISagaModule } from 'redux-dynamic-modules-saga';
import { createProjectUpdateSaga } from './saga';
import { ProjectUpdateModuleState } from './types/types';
import { projectUpdateReducer } from './reducer';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ChangePlanVerifier } from 'core/entities/project/ChangePlanVerifier';
import { ChangeSeatsVerifier } from 'core/entities/project/ChangeSeatsVerifier';
import { InvoicePreviewRequest } from 'modules/invoice/types/dto';
import { InvoicePreview } from 'modules/invoice/types/types';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';

export const getProjectUpdateModule = (
    repository: ProjectRepository,
    changePlan: ChangePlanVerifier,
    changeSeats: ChangeSeatsVerifier,
    updateBillingDetailsUseCase: UpdateBillingDetails,
    invoicePreviewRetriever: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>,
): ISagaModule<ProjectUpdateModuleState> => ({
    id: 'projectUpdate',
    sagas: [
        createProjectUpdateSaga({
            repository: repository,
            changePlanUseCase: changePlan,
            changeSeatsUseCase: changeSeats,
            updateBillingDetailsUseCase,
            invoicePreviewRetriever,
        }),
    ],
    reducerMap: {
        projectUpdate: projectUpdateReducer,
    },
});
