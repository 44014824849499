import { createAsyncAction } from 'typesafe-actions';
import { InvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';

export const retrieveInvoices = createAsyncAction(
    '@invoices/FETCH_INVOICES_REQUEST',
    '@invoices/FETCH_INVOICES_SUCCESS',
    '@invoices/FETCH_INVOICES_FAILURE',
)<
    {
        projectId: string;
        lastElementId?: string;
    },
    InvoicesForView,
    Error
>();
