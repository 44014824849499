import { HttpClient } from 'services/HttpClient';
import { InvoicePreviewRequest, InvoicePreviewResponse } from '../types/dto';
import { InvoicePreview } from '../types/types';
import { api } from 'paths';
import { adaptToCoupon } from 'common/adapters/adaptToCoupon';

export const createInvoicePreviewRetriever = (client: HttpClient) => {
    return async (dto: InvoicePreviewRequest): Promise<InvoicePreview> => {
        const qs = Object.entries(dto)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        try {
            const invoicePreviewResponse = await client.get<InvoicePreviewResponse>(
                `${api.invoicePreview}?${qs}`,
            );

            return adaptToInvoicePreview(invoicePreviewResponse);
        } catch (e) {
            throw new Error(e.message);
        }
    };
};

function adaptToInvoicePreview(response: InvoicePreviewResponse): InvoicePreview {
    return {
        currency: response.currency,
        total: response.subtotal,
        due: response.total,
        coupon: adaptToCoupon(response.coupon),
    };
}
