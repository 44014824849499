import styled, { css } from 'styled-components';

import theme from '@igenius_dev/crystalkit/lib/theme';

import { MediumText } from 'components/Typography/MediumText';
import { SmallText } from 'components/Typography/SmallText';

export const PriceRecap = styled.div`
    display: flex;
    flex-direction: column;
`;

export const TotalPriceContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    position: relative;

    ${PriceRecap}:last-child {
        text-align: right;
    }
`;

export const TotalPrice = styled(MediumText)<{ hasCoupon: boolean }>`
    ${(props) =>
        props.hasCoupon &&
        css`
            text-decoration: line-through;
        `}
`;

export const DiscountedPrice = styled(MediumText)``;

export const Subtitle = styled(SmallText)`
    color: ${theme.colors.values.black200};
    white-space: pre-wrap;
`;
