import React from 'react';
import { useSelector } from 'react-redux';

// @ts-ignore
import { Provider } from '@igenius_dev/igenius-i18n/lib';

import { selectDictionary, selectStatus } from '../selectors';

const ConnectedI18nProvider = ({ children }: { children: React.ReactNode }) => {
    const dictionary = useSelector(selectDictionary);
    const status = useSelector(selectStatus);

    return <Provider dictionary={dictionary}>{status === 'READY' ? children : null}</Provider>;
};

const DictionaryProvider = ({ children }: { children: React.ReactNode }) => (
    <ConnectedI18nProvider>{children}</ConnectedI18nProvider>
);

export default DictionaryProvider;
