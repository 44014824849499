import styled from 'styled-components';

import theme from '@igenius_dev/crystalkit/lib/theme';

import { StyledTitle } from '../Details/elements';
import { BaseText as BText } from 'components/Typography/BaseText';
import { SmallText as SText } from 'components/Typography/SmallText';
import { CouponRowWithPriceAndCode } from '../../Coupon/CouponRowWithPriceAndCode';

export const Information = styled.div`
    margin-bottom: 16px;

    & + & {
        margin-bottom: 0;
    }
`;

export const BaseText = styled(BText).attrs({
    color: 'black500',
})`
    display: block;
    font-weight: ${theme.typography.weights.values.medium};
`;

export const SmallText = styled(SText).attrs({
    color: 'black300',
})`
    margin-top: 8px;
    display: block;
`;

export const DisclaimerArea = styled.div`
    padding: 24px 19px;
    background-color: ${theme.colors.values.blue15};
    margin-top: 16px;
`;

export const SeatsInformationContainer = styled.div`
    margin-top: 40px;
`;

export const Separator = styled.div`
    width: 100%;
    margin: 16px 0 24px 0;
    border-bottom: 1px solid ${theme.colors.values.blue50};
`;

export const DisclaimerTitle = styled(BaseText).attrs({
    color: 'black500',
})`
    display: block;
    font-weight: ${theme.typography.weights.values.medium};
`;

export const DisclaimerText = styled(SmallText).attrs({
    color: 'black300',
})`
    margin-top: 8px;
    display: block;
`;

export const Subtitle = styled(StyledTitle)`
    margin-bottom: 24px;
`;

export const ProjectRecap = styled.div`
    & > * {
        margin-top: 24px;
    }
`;

export const LoaderContainer = styled.div`
    position: relative;
    min-height: 38px;
`;

export const CouponRecap = styled(CouponRowWithPriceAndCode)`
    margin-top: 16px;
`;
