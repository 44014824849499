// @ts-ignore
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import * as projectUpgradeActions from './actions';
import * as toastsActions from 'modules/toasts/actions';

const handleUpgrade = (repository: ProjectRepository) =>
    function* (action: ActionType<typeof projectUpgradeActions.upgradeProject.request>) {
        const { projectId, projectVersion } = action.payload;
        try {
            yield call([repository, 'upgrade'], projectId, { project_version: projectVersion });
            yield put(projectUpgradeActions.upgradeProject.success());
        } catch (e) {
            console.error(e);
            yield put(
                toastsActions.addErrorToast({
                    id: 'upgrade-project-error',
                    isAutoDismissEnabled: false,
                    label: 'Something went wrong',
                    type: 'error',
                    actions: [{ label: 'Close' }],
                }),
            );
            yield put(projectUpgradeActions.upgradeProject.failure(e));
        }
    };

export function createProjectUpgradeSaga({ repository }: { repository: ProjectRepository }) {
    return function* projectUpdateSaga() {
        yield takeLatest(projectUpgradeActions.upgradeProject.request, handleUpgrade(repository));
    };
}
