import styled from 'styled-components';
import Button from '@igenius_dev/crystalkit/lib/components/Button';

const AddButton = styled(Button).attrs({
    icon: 'add',
})``;

export const RoundedButton = styled(AddButton).attrs({
    rounded: true,
    inverted: true,
})`
    min-width: initial;
    min-height: initial;
    width: 32px;
    height: 32px;
    margin: auto;
`;

export const ExpandedButton = styled(AddButton).attrs({
    inverted: true,
})``;

export const ItemList = styled.div`
    margin-top: 24px;
`;
