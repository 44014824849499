import { ActionType, getType } from 'typesafe-actions';
import { PaymentWizardState, PaymentWizardStatus } from './types';
import * as paymentWizardActions from './actions';

export const paymentWizardReducer = (
    state: PaymentWizardState = {
        status: PaymentWizardStatus.INITIAL,
    },
    action: ActionType<typeof paymentWizardActions>,
) => {
    switch (action.type) {
        case getType(paymentWizardActions.startWizard):
            return {
                ...state,
                status: PaymentWizardStatus.PENDING,
            };
        case getType(paymentWizardActions.endWizard):
            return {
                ...state,
                status: PaymentWizardStatus.READY,
            };
        case getType(paymentWizardActions.cancelWizard):
            return {
                ...state,
                status: PaymentWizardStatus.CANCELED,
            };
        default:
            return state;
    }
};
