export function isNotificationVisible(
    id: string,
    retriever: (id: string) => string | null,
    now: number,
) {
    const showAt = retriever(id);

    if (showAt) {
        return now >= Number(showAt);
    }

    return true;
}
