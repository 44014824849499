import { Dispatch } from 'redux';
import { useDispatch } from 'react-redux';
import { useTransition } from 'react-spring';

import { Translator } from 'common/types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import * as notificationActions from '../actions';
import { NotificationAction, NotificationModel, NotificationType } from '../types';
import {
    ActionButton,
    AnimatedBanner,
    DismissIcon,
    ErrorNotification,
    InfoIcon,
    InfoNotification,
    Label,
    WarningNotification,
} from './elements';
import useTranslator from 'hooks/useTranslator';

function getInlineNotificationComponent(type: NotificationType) {
    switch (type) {
        case 'error':
            return ErrorNotification;
        case 'warning':
            return WarningNotification;
        case 'info':
        default:
            return InfoNotification;
    }
}

function getActionsComponent(
    t: Translator,
    dispatch: Dispatch<any>,
    actions: NotificationAction[] = [],
): () => JSX.Element {
    const getClickHandler = (
        action: NotificationAction,
    ): React.MouseEventHandler<HTMLButtonElement> => {
        if (action.onClick) {
            return action.onClick;
        }

        if (action.onClickDispatch) {
            return () => dispatch(action.onClickDispatch);
        }

        return () => {};
    };

    return () => (
        <>
            {actions.map((action, idx) => (
                <ActionButton
                    key={action.label + idx}
                    label={t(action.label)}
                    onClick={getClickHandler(action)}
                />
            ))}
        </>
    );
}

const emptyNotification: NotificationModel = {
    id: '',
    category: '',
    message: '',
    type: 'error',
};

type Props = {
    notification: NotificationModel | null;
    className?: string;
    updateNotificationHeight: (h: number) => void;
};

function NotificationBanner({
    updateNotificationHeight,
    notification: nextNotification,
    className,
}: Props) {
    const [notification, setNotification] = useState<NotificationModel>(emptyNotification);
    const t = useTranslator();
    const dispatch = useDispatch();
    const notificationRef = useRef<HTMLDivElement>(null);

    const transitions = useTransition(!!nextNotification, null, {
        from: { transform: 'scale(0.9)', left: '0%' },
        enter: { transform: 'scale(1)' },
        leave: { transform: 'scale:(0.9)', left: '100%' },
    });

    const Component = getInlineNotificationComponent(notification.type);
    const ActionButtons = getActionsComponent(t, dispatch, notification.actions);

    const dismissNotification = () => {
        dispatch(
            notificationActions.removeNotification.request({
                category: notification.category,
                id: notification.id,
            }),
        );
    };

    useLayoutEffect(() => {
        if (nextNotification && nextNotification.id !== notification.id) {
            setNotification(nextNotification);
            window.setTimeout(() => {
                const notificationHeight =
                    notificationRef.current?.getBoundingClientRect().height || 0;
                updateNotificationHeight(Math.ceil((notificationHeight * 100) / 90));
            }, 500);
        }
    }, [notificationRef, updateNotificationHeight, nextNotification, notification]);

    return (
        <>
            {transitions.map(
                ({ item, key, props }) =>
                    item && (
                        <AnimatedBanner
                            ref={notificationRef}
                            key={key}
                            visible={!!notification.id}
                            style={props}
                        >
                            <Component
                                data-cy={`inline-notification-${notification.type}`}
                                className={className}
                            >
                                <InfoIcon />
                                <Label>
                                    {t(notification.message, {
                                        payload: notification.messagePayload,
                                    })}
                                </Label>
                                <div style={{ flex: 1 }}>
                                    <ActionButtons />
                                </div>
                                {notification.type !== 'error' && notification.dismissable && (
                                    <DismissIcon onClick={dismissNotification} />
                                )}
                            </Component>
                        </AnimatedBanner>
                    ),
            )}
        </>
    );
}

export default NotificationBanner;
