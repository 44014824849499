import { TenantUsage } from './types/types';
import tenantUsageActions from './actions';
import { call, put, takeLatest } from 'redux-saga/effects';
import { ProjectId } from 'core/entities/project/Project';
import loggingActions from 'modules/logger/actions';

type TenantUsageSagaParams = {
    api: (id: ProjectId) => Promise<TenantUsage>;
    contactSupport: () => void;
    openConsole: (domain: string) => void;
};

export function tenantUsageSaga({ api, contactSupport, openConsole }: TenantUsageSagaParams) {
    return function* () {
        yield takeLatest(
            tenantUsageActions.fetch.request,
            function* (action: ReturnType<typeof tenantUsageActions.fetch.request>) {
                try {
                    const response: TenantUsage = yield call(api, action.payload.projectId);

                    yield put(tenantUsageActions.fetch.success(response));
                } catch (e) {
                    yield put(tenantUsageActions.fetch.failure(e));
                }
            },
        );
        yield takeLatest(tenantUsageActions.fetch.failure, function* ({ payload }) {
            yield put(
                loggingActions.error({
                    msg: 'Unable to retrieve tenant usage',
                    error: payload,
                }),
            );
        });

        yield takeLatest(
            tenantUsageActions.openConsole,
            (action: ReturnType<typeof tenantUsageActions.openConsole>) =>
                openConsole(action.payload.domain),
        );

        yield takeLatest(tenantUsageActions.openContentCenter, contactSupport);
    };
}
