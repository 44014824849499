import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useSpring } from 'react-spring';

import { selectAllNotificationsByCategory } from '../selectors';
import { CategoryList } from '../types';
import { AnimatedAccordion } from './elements';
import NotificationBanner from './NotificationBanner';
import { getNotificationToDisplay } from './getNotificationToDisplay';

type Props = {
    categories: CategoryList;
    className?: string;
};

function Notification(props: Props) {
    const [notificationHeight, setNotificationHeight] = useState(0);
    const notifications = useSelector(selectAllNotificationsByCategory);
    const notificationToDisplay = getNotificationToDisplay(notifications, props.categories);
    const animatedProps = useSpring({ height: notificationToDisplay ? notificationHeight : 0 });

    return (
        <AnimatedAccordion style={animatedProps}>
            <NotificationBanner
                updateNotificationHeight={setNotificationHeight}
                className={props.className}
                notification={notificationToDisplay}
            />
        </AnimatedAccordion>
    );
}

export default Notification;
