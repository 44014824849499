import { RouteComponentProps, useMatch } from '@reach/router';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { projectCreationInfoActions } from 'modules/creation-info/actions';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import { paymentMethodsActions } from 'modules/payment-methods/actions';
import { AddingPaymentMethod } from 'modules/payment-methods/types/types';
import { nextStepWizard, prevStepWizard } from 'modules/payment-wizard/actions';
import { projectDetailSelectors } from 'modules/project/detail/selectors';
import { getSegmentKey, paths, paymentWizardPaths, segments } from 'paths';
import { ConfirmAndPayStep } from './PaymentWizard/Confirm/ConfirmAndPayStep';
import { PaymentDetails, PaymentDetailsStep } from './PaymentWizard/Details/PaymentDetailsStep';
import { PaymentWizard } from './PaymentWizard/PaymentWizard';
import { Seats, SeatSelectionStep, Summary } from './PaymentWizard/SelectSeats/SeatSelectionStep';
import projectUpdateActions from '../modules/project/update/actions';
import billingInfoSelectors from '../modules/billing-info/selectors';
import { FormType } from './PaymentWizard/Details/InvoiceDetails/InvoiceDetails';
import { HubState } from 'types';
import { projectDetailActions } from 'modules/project/detail/actions';
import { CenteredLoader } from 'components/CenteredLoader';
import { Address, TaxId } from 'common/types';
import { projectUpdateSelectors } from 'modules/project/update/selectors';
import profileSelectors from '../modules/profile/selectors';
import { Disclaimer } from './PaymentWizard/Summary/Disclaimer';
import { SeatsLimitModal } from './Limitations/SeatsLimitModal';
import { DisclaimerText, DisclaimerTitle, Information } from './PaymentWizard/Summary/elements';
import { UseCoupon } from './Project/Create/Plan/UseCoupon';
import { invoicePreviewActions } from 'modules/invoice/actions';
import { InvoicePreviewForView } from 'modules/invoice/types/views';
import { invoicePreviewSelectors } from 'modules/invoice/selectors';
import { PlanForView, PriceForView } from 'modules/creation-info/types';
import useTranslator from '../hooks/useTranslator';
import { BillingInfoForView } from 'modules/billing-info/types/types';
import { ProjectDetailForView } from 'modules/project/detail/types';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { useModules } from 'hooks/useModules';

export const PaymentWizardForEdit: React.FC<RouteComponentProps> = () => {
    const t = useTranslator();
    const modules = useModules();
    const match = useMatch(`${paths.project}/${segments.projectId}${paths.paymentWizard}/*`);
    const projectId = match?.[getSegmentKey(segments.projectId)] || '';
    const projectPath = `${paths.project}/${projectId}`;
    const basePath = `${projectPath}${paths.paymentWizard}`;
    const projectDetail = useSelector<HubState, ProjectDetailForView | undefined>(
        projectDetailSelectors.selectProjectById(projectId),
    );
    const isInitial = useSelector(projectUpdateSelectors.isWizardInitial);
    const isChangingPlan = useSelector(projectUpdateSelectors.isChangingPlan);
    const isChangingSeats = useSelector(projectUpdateSelectors.isChangingSeats);
    const isDowngradingSeats = useSelector(projectUpdateSelectors.isDowngradingSeats(projectId));
    const isDowngradingPlan = useSelector(projectUpdateSelectors.isDowngradingPlan(projectId));
    const invoicePreview = useSelector<HubState, InvoicePreviewForView | undefined>(
        invoicePreviewSelectors.selectInvoice,
    );
    const dispatch = useDispatch();

    const currentSubscription = projectDetail?.subscription;
    const currentPrice = currentSubscription?.price;
    const currentPlanId = currentPrice?.plan.id;
    const currentPlan = useSelector(
        projectCreationInfoSelectors.selectPlanById(currentPrice?.plan.id),
    );
    const currentSeats = projectDetail?.seats;
    const currentBillingInfo = useSelector<HubState, BillingInfoForView | undefined>(
        billingInfoSelectors.billingInfo,
    );

    const userInfo = useSelector(profileSelectors.selectUser);
    const currentFormType = currentBillingInfo?.userType ?? 'company';
    const currentInvoice = currentBillingInfo
        ? {
              address: {
                  country: currentBillingInfo.address?.country ?? ('' as any),
                  state: currentBillingInfo.address?.state,
                  city: currentBillingInfo.address?.city,
                  address: currentBillingInfo.address?.line1 ?? '',
                  zipCode: currentBillingInfo.address?.postalCode,
                  companyName: userInfo.company_name,
                  name: currentBillingInfo.name,
              },
              taxId: {
                  vatNr: currentBillingInfo.tax?.value,
                  vatId: currentBillingInfo.tax?.label ?? ('' as any),
              },
          }
        : { address: { address: '' } };

    const availableSubscriptions = useSelector<HubState, PriceForView[]>(
        projectCreationInfoSelectors.selectSubscriptionsByPlanId(currentPlanId),
    );

    const selectedPlan = useSelector<HubState, PlanForView | undefined>(
        projectUpdateSelectors.selectPlan,
    );
    const selectedPrice = useSelector(projectUpdateSelectors.selectPrice);
    const selectedBillingCycle = selectedPrice?.timeInterval;
    const selectedFormType = useSelector<HubState, FormType | undefined>(
        projectUpdateSelectors.selectFormType,
    );
    const selectedInvoice = useSelector(projectUpdateSelectors.selectInvoice);
    const selectedSeats = useSelector(projectUpdateSelectors.selectSeats);
    const selectedCoupon = useSelector<HubState, string | undefined>(
        projectUpdateSelectors.selectCoupon,
    );

    const billingCycle = selectedBillingCycle ?? currentPrice?.timeInterval;
    const formType = selectedFormType ?? currentFormType;
    const invoice = selectedInvoice ?? currentInvoice;
    const seats = (selectedSeats ?? currentSeats)!;
    const price = selectedPrice ?? currentPrice;
    const priceId = price?.id;
    const planId = currentPlanId;
    const paymentMethodId = currentSubscription?.defaultPaymentMethod.id;
    const languages = projectDetail?.languages.map((lang) => lang.id) ?? [];
    const domain = projectDetail?.domain;
    const name = projectDetail?.name;
    const plan = selectedPlan ?? currentPlan;

    const shouldSkipOneStep = isChangingSeats || isChangingPlan;

    const onSeatsUpdate = (seats: number) => {
        dispatch(
            projectUpdateActions.wizard.edit({
                seats,
                id: projectId,
                coupon: selectedCoupon,
            }),
        );
        dispatch(
            invoicePreviewActions.fetch.request({
                data: {
                    seats,
                    projectId,
                    coupon: selectedCoupon,
                },
            }),
        );
    };

    const onSeatsSubmit = ({ seats }: Seats) => {
        const payload = {
            seats,
            id: projectId,
        };

        if (isChangingSeats) {
            dispatch(
                projectUpdateActions.changeSeats.submit.request({
                    data: payload,
                    onSuccess: () => {
                        dispatch(
                            nextStepWizard({
                                currentStep: 2,
                                basePath,
                            }),
                        );
                    },
                }),
            );
        } else {
            dispatch(projectUpdateActions.wizard.edit(payload));

            dispatch(
                nextStepWizard({
                    currentStep: isChangingPlan ? 2 : 1,
                    basePath,
                }),
            );
        }
    };

    const detailsSubmit = (data: PaymentDetails) => {
        const { card: paymentMethodId, formType, vatNr } = data;

        const invoice: { address: Address; taxId?: TaxId } = {
            address: {
                country: data.country,
                state: data.state,
                city: data.city,
                address: data.address,
                zipCode: data.zipCode,
                name: data.name,
            },
        };

        if (vatNr) {
            invoice.taxId = {
                vatId: data.vatId,
                vatNr: data.vatNr,
            };
        }

        dispatch(
            projectUpdateActions.wizard.edit({
                paymentMethodId: paymentMethodId,
                invoice,
                formType,
                id: projectId,
            }),
        );
        dispatch(nextStepWizard({ currentStep: 2, basePath }));
    };

    const onPaymentTypeUpdate = (priceId: string) => {
        dispatch(
            projectUpdateActions.wizard.edit({
                priceId,
                id: projectId,
            }),
        );
    };

    const onPaymentMethodSubmit = ({ stripe, card, onSuccess }: AddingPaymentMethod) => {
        dispatch(
            paymentMethodsActions.add.request({
                stripe,
                card,
                onSuccess,
            }),
        );
    };

    const steps = {
        [paymentWizardPaths[0]]: t('hub-payment-wizard-stepper-label-select-seats'),
        [paymentWizardPaths[1]]: t('hub-payment-wizard-stepper-label-payment-details'),
        [paymentWizardPaths[2]]: t('hub-payment-wizard-stepper-label-confirm-and-subscribe'),
    };

    const onSeatsChange = useCallback(() => dispatch(prevStepWizard({ goToStep: 1, basePath })), [
        dispatch,
        basePath,
    ]);
    const onPaymentMethodsChange = useCallback(
        () => dispatch(prevStepWizard({ goToStep: 2, basePath })),
        [dispatch, basePath],
    );

    useEffect(() => {
        dispatch(projectDetailActions.get.request({ id: projectId }));
        dispatch(projectCreationInfoActions.request());
        dispatch(paymentMethodsActions.retrieve.request({ type: 'card' }));
    }, [dispatch, projectId]);

    useEffect(() => {
        isInitial && dispatch(projectUpdateActions.wizard.end({ redirectPath: projectPath }));
    }, [dispatch, isInitial, projectPath]);

    if (
        plan &&
        priceId &&
        planId &&
        domain &&
        name &&
        billingCycle &&
        availableSubscriptions.length > 0 &&
        projectDetail
    ) {
        const disclaimer = isChangingPlan ? (
            <Information>
                <DisclaimerTitle>
                    {t(
                        isDowngradingPlan
                            ? 'hub-column-recap-disclaimer-title-downgrading-plan'
                            : 'hub-column-recap-disclaimer-title-upgrading-plan',
                    )}
                </DisclaimerTitle>
                <DisclaimerText>
                    {t(
                        isDowngradingPlan
                            ? 'hub-column-recap-disclaimer-body-downgrading-plan'
                            : 'hub-column-recap-disclaimer-body-upgrading-plan',
                    )}
                </DisclaimerText>
            </Information>
        ) : isChangingSeats ? (
            <Information>
                <DisclaimerTitle>
                    {t(
                        isDowngradingSeats
                            ? 'hub-column-recap-disclaimer-title-downgrading-seats'
                            : 'hub-column-recap-disclaimer-title-upgrading-seats',
                    )}
                </DisclaimerTitle>
                <DisclaimerText>
                    {t(
                        isDowngradingSeats
                            ? 'hub-column-recap-disclaimer-body-downgrading-seats'
                            : 'hub-column-recap-disclaimer-body-upgrading-seats',
                    )}
                </DisclaimerText>
            </Information>
        ) : (
            <Disclaimer />
        );

        const summary: Summary = {
            plan,
            subscription: price,
            seats,
            disclaimer,
            coupon: (
                <UseCoupon
                    onChange={(value: string) => {
                        if (invoicePreview?.coupon?.code) {
                            if (value !== invoicePreview.coupon.code) {
                                dispatch(
                                    projectUpdateActions.wizard.edit({
                                        id: projectId,
                                        coupon: undefined,
                                    }),
                                );
                            }
                        }
                    }}
                    onApply={(coupon: string) => {
                        dispatch(
                            invoicePreviewActions.fetch.request({
                                data: {
                                    coupon,
                                    projectId,
                                    seats,
                                },
                                onSuccess: () => {
                                    dispatch(
                                        projectUpdateActions.wizard.edit({
                                            id: projectId,
                                            coupon,
                                        }),
                                    );
                                },
                            }),
                        );
                    }}
                    onReset={() => {
                        dispatch(
                            invoicePreviewActions.fetch.request({
                                data: {
                                    projectId,
                                    seats,
                                },
                                onSuccess: () => {
                                    dispatch(
                                        projectUpdateActions.wizard.edit({
                                            id: projectId,
                                            coupon: undefined,
                                        }),
                                    );
                                },
                            }),
                        );
                    }}
                />
            ),
        };

        const aggregatedProjectDetail = {
            id: projectId,
            priceId,
            planId,
            paymentMethodId: paymentMethodId!,
            seats,
            languages,
            domain,
            name,
            formType,
            invoice,
            coupon: invoicePreview?.coupon?.code,
            billingCycle,
            favorite_lang_id: projectDetail.languages.find((l) => l.favorite)?.id || 'en',
        };

        return (
            <DynamicModuleLoader
                modules={[modules.get('projectDetail'), modules.get('projectUpdate')]}
            >
                <div data-cy="payment-wizard-edit">
                    <PaymentWizard
                        onCancel={() =>
                            dispatch(projectUpdateActions.wizard.end({ redirectPath: projectPath }))
                        }
                        basePath={basePath}
                        steps={steps}
                        views={[
                            <SeatSelectionStep
                                default
                                key={paymentWizardPaths[0]}
                                path={paymentWizardPaths[0]}
                                seats={aggregatedProjectDetail.seats}
                                projectId={projectId}
                                onUpdate={onSeatsUpdate}
                                onSubmit={onSeatsSubmit}
                                summary={summary}
                                labels={{
                                    notification: t(
                                        shouldSkipOneStep
                                            ? 'hub-select-seats-area-plan-name-inline-notification-remove-seats'
                                            : 'hub-select-seats-area-inline-notification-at-least-five',
                                    ),
                                }}
                            />,
                            <PaymentDetailsStep
                                key={paymentWizardPaths[1]}
                                path={paymentWizardPaths[1]}
                                onSubmit={detailsSubmit}
                                onPaymentTypeUpdate={onPaymentTypeUpdate}
                                onPaymentMethodSubmit={onPaymentMethodSubmit}
                                currentPaymentMethodId={
                                    currentSubscription?.defaultPaymentMethod.id
                                }
                                invoice={{
                                    ...invoice,
                                    type: formType,
                                }}
                                summary={summary}
                            />,
                            <ConfirmAndPayStep
                                path={paymentWizardPaths[2]}
                                onSeatsChange={onSeatsChange}
                                onPaymentMethodsChange={onPaymentMethodsChange}
                                key={paymentWizardPaths[2]}
                                projectData={aggregatedProjectDetail}
                                billingCycle={billingCycle}
                                summary={{
                                    ...summary,
                                    onSubmit: () => {
                                        dispatch(
                                            projectUpdateActions.request.request({
                                                projectData: aggregatedProjectDetail,
                                                onSuccess: () =>
                                                    dispatch(
                                                        projectUpdateActions.wizard.end({
                                                            redirectPath: `${paths.project}/${aggregatedProjectDetail.id}`,
                                                        }),
                                                    ),
                                            }),
                                        );
                                    },
                                }}
                            />,
                        ]}
                    />

                    <SeatsLimitModal project={projectDetail} />
                </div>
            </DynamicModuleLoader>
        );
    } else return <CenteredLoader size="super" />;
};
