import { createAction, createAsyncAction } from 'typesafe-actions';
import { TenantLimits, TenantLimitsPriceComparison } from './types/types';
import { ProjectId } from 'core/entities/project/Project';

interface TenantUsage {}

const tenantUsageActions = {
    showModal: createAction('@tenant-usage/TENANT_USAGE_SHOW')<{
        differences: TenantLimits;
        nextPlanId?: string;
        nextSeats?: number;
        prices?: TenantLimitsPriceComparison;
    }>(),
    hideModal: createAction('@tenant-usage/TENANT_USAGE_HIDE')(),
    openContentCenter: createAction('@tenant-usage/TENANT_USAGE_CONTENT_CENTER')(),
    openConsole: createAction('@tenant-usage/TENANT_USAGE_CONSOLE')<{ domain: string }>(),
    fetch: createAsyncAction(
        '@tenant-usage/TENANT_USAGE_REQUEST',
        '@tenant-usage/TENANT_USAGE_SUCCESS',
        '@tenant-usage/TENANT_USAGE_ERROR',
    )<{ projectId: ProjectId }, TenantUsage, Error>(),
};

export default tenantUsageActions;
