import styled from 'styled-components';
// @ts-ignore
import Sheet from '@igenius_dev/crystalkit/lib/components/Sheet';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import SearchField from '@igenius_dev/crystalkit/lib/components/SearchField';
import BackButton from '@igenius_dev/crystalkit/lib/components/BackButton';
// @ts-ignore
import Img from '@igenius_dev/crystalkit/lib/components/Img';
import invoices_empty_state from 'assets/emptyState.png';

export const StyledSheetHeader = styled(Sheet.Header)`
    flex-wrap: wrap;
    padding: 30px 0 8px 0;
`;

export const StyledSheetContent = styled(Sheet.Content).attrs({
    'data-cy': 'invoice-sheet-content',
})`
    & > div > div {
        height: 100%;
    }
`;

export const Left = styled.div`
    position: relative;
    display: flex;
    flex-basis: 50%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;

export const Right = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 50%;
    * + * {
        margin-left: 16px;
    }
`;

export const StyledBackButton = styled(BackButton)`
    position: absolute;
    top: 5px;
    left: 0;
    transform: translate(-100%);
`;

export const Cell = styled.div<{ big?: boolean }>`
    display: flex;
    align-items: center;
    flex: ${({ big }) => (big ? '1 0 40%' : '1 0 25%')};
`;

export const ColumnTitle = styled(Text).attrs({
    color: 'black200',
    scale: 'SmallMedium',
})`
    flex: 1 0 0;
`;

export const StyledSearchField = styled(SearchField)`
    width: 342px;
`;

export const Title = styled(Text).attrs({
    scale: 'Title',
    color: 'black500',
})``;

export const BaseText = styled(Text).attrs({ color: 'black500' })``;

export const StyledTextLink = styled(TextLink).attrs({ color: 'purple700' })``;

export const Subtitle = styled(Text).attrs({
    scale: 'SmallMedium',
    color: 'purple700',
})`
    padding: 8px 0 16px 0;
`;

export const TitlesContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 28px;
    width: 100%;
`;

export const EmptyStateContainer = styled.div.attrs({
    'data-cy': 'invoices-empty-state',
})`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
`;

export const EmptyStateImg = styled(Img).attrs({
    src: invoices_empty_state,
})`
    width: 232px;
    height: 150px;
`;

export const EmptyStateText = styled(BaseText)`
    margin-top: 24px;
`;

export const InvoiceContent = styled.div.attrs({
    'data-cy': 'invoices-sheet-content-row',
})`
    display: flex;
`;
