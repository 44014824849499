import { changePlan } from './changePlan';
import { wizard } from './wizard';
import { changeSeats } from './changeSeats';
import { couponActions } from 'modules/project/update/actions/coupon';
import { requestProjectUpdate } from 'modules/project/update/actions/update';

const projectUpdateActions = {
    request: requestProjectUpdate,
    wizard: wizard,
    changePlan: changePlan,
    changeSeats: changeSeats,
    coupon: couponActions,
};

export default projectUpdateActions;
