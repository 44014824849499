import React from 'react';
import { Entry } from '../types';
import styled from 'styled-components';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import { Table } from './Table/Table';

type Props = {
    list: Array<Entry>;
    className?: string;
};

const Label = styled(Text).attrs({
    scale: 'Base',
    color: 'black500',
})``;

export const Value = styled(Text).attrs({
    scale: 'Small',
    color: 'black300',
})`
    display: flex;
    align-items: center;
    width: 100%;
`;

export const DetailTable: React.FC<Props> = ({ list }) => {
    return (
        <Table
            list={list.map((entry) => {
                return {
                    name: entry.name,
                    label: <Label>{entry.label}</Label>,
                    value: <Value>{entry.value}</Value>,
                    action: entry.action,
                };
            })}
        />
    );
};
