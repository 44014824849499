import React, { useRef, useState } from 'react';
import Scrollbars, { positionValues } from 'react-custom-scrollbars';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from '@igenius_dev/crystalkit/lib/components/Button';
import billingInfoSelectors from 'modules/billing-info/selectors';
import {
    ButtonsContainer,
    Container,
    FlexScrollbars,
    ScrollableDialogContent,
    ScrollableModalContainer,
    StyledForm,
} from './elements';
import { FormContent } from './FormContent';
import { Header } from './Header';
import {
    BillingDetailsFormData,
    BillingItemType,
    CompanyBillingData,
    PersonBillingData,
} from './types';
import useTranslator from 'hooks/useTranslator';
import { HubState } from 'types';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';

type Props = {
    addrItems: BillingItemType[];
    taxItems: BillingItemType[];
    onCancel: () => void;
    onSubmit: (data: BillingDetailsFormData) => void;
};

export const FormEditBillingDetails = (props: Props) => {
    const { control, formState, handleSubmit } = useForm<BillingDetailsFormData>();
    const type = useSelector<HubState, FormType>(billingInfoSelectors.selectUserType);
    const billingInfoStatus = useSelector(billingInfoSelectors.selectBillingInfoStatus);
    const [showFormShadow, setShowFormShadow] = useState(false);
    const [userType, setUserType] = useState<'person' | 'company'>(type);
    const isLoading = billingInfoStatus === 'PENDING';
    const t = useTranslator();
    const scrollRef = useRef<Scrollbars | null>();

    const handleScroll = (v: positionValues) => {
        setShowFormShadow(v.top > 0);
    };

    const onSubmit = (formData: BillingDetailsFormData) => {
        return props.onSubmit(
            userType === 'company'
                ? {
                      userType,
                      ...(formData as CompanyBillingData),
                  }
                : {
                      userType,
                      ...(formData as PersonBillingData),
                  },
        );
    };

    return (
        <ScrollableModalContainer>
            <ScrollableDialogContent>
                <Header
                    showShadow={showFormShadow}
                    title={t('hub-billing-details-dialogue-title-billing-details')}
                    subtitle={t('hub-billing-details-dialogue-subtititle')}
                    defaultChip={userType}
                    onChangeChip={setUserType}
                />

                <Container>
                    <FlexScrollbars
                        ref={(f) => (scrollRef.current = f)}
                        onScrollFrame={handleScroll}
                    >
                        <StyledForm>
                            <FormContent
                                control={control}
                                items={
                                    userType === 'person'
                                        ? props.addrItems
                                        : [...props.addrItems, ...props.taxItems]
                                }
                                errors={formState.errors}
                            />
                        </StyledForm>
                    </FlexScrollbars>
                </Container>
                <ButtonsContainer data-testid="edit-billing-details-action-buttons">
                    <Button
                        label={t('hub-billing-details-dialogue-button-cancel')}
                        color={'white'}
                        onClick={props.onCancel}
                    />
                    <Button
                        label={t('hub-billing-details-dialogue-button-save')}
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        disabled={!formState.isDirty || isLoading}
                    />
                </ButtonsContainer>
            </ScrollableDialogContent>
        </ScrollableModalContainer>
    );
};
