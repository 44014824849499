import { ISagaModule } from 'redux-dynamic-modules-saga';

import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { projectDeletionReducer as reducer } from 'modules/project/delete/reducer';
import { ProjectDeletionModuleState } from 'modules/project/delete/types';
import { createProjectDeletionSaga } from 'modules/project/delete/saga';

export const getProjectDeletionModule = (
    repository: ProjectRepository,
): ISagaModule<ProjectDeletionModuleState> => ({
    id: 'projectDeletion',
    reducerMap: { projectDeletion: reducer as any },
    sagas: [createProjectDeletionSaga(repository)],
});
