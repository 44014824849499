export type Project = {
    name: string;
    avatarUrl?: string;
    domain: string;
    numberOfSeats: number;
    owner: string;
    status: ProjectStatus;
    languages: string[];
    id: ProjectId;
};

export enum ProjectStatus {
    PENDING_BLOCKING = 'PENDING_BLOCKING',
    PENDING_ACTIVATION = 'PENDING_ACTIVATION',
    PENDING_DELETION = 'PENDING_DELETION',
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
    DELETED = 'DELETED',
    ERROR = 'ERROR',
    BLOCKING_ERROR = 'BLOCKING_ERROR',
    PENDING_RESUME = 'PENDING_RESUME',
}

export type ProjectId = string;
