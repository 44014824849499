import { Widget } from '../Widget';
import React from 'react';
import { formatDistanceToNowStrict } from 'date-fns';

import { DetailTable, Value } from '../DetailTable';
import styled from 'styled-components';
import { LinkAction } from '../Actions';
import { useDispatch } from 'react-redux';
import projectUpdateActions from '../../../../../modules/project/update/actions';
import { NonInteractiveChip } from '../../../../../components/NonInteractiveChip';
import useTranslator from '../../../../../hooks/useTranslator';
import { Language } from '../../../../../core/entities/project/Language';

const ValueContainer = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
`;
const StyledValue = styled(Value)`
    width: auto;
`;

const ActiveTrialChip = styled(NonInteractiveChip)`
    margin-right: 8px;
`;

interface Props {
    name: string;
    languages: Language[];
    seats: number;
    expirationTimestamp?: number;
    projectId: string;
    editable: boolean;
}

export const Plan = ({
    name,
    languages,
    seats,
    expirationTimestamp,
    projectId,
    editable,
}: Props) => {
    const t = useTranslator();
    const dispatch = useDispatch();

    return (
        <>
            <Widget title={t('hub-trial-project-detail-title-project-plan')}>
                <DetailTable
                    list={[
                        {
                            name: 'rowPlan',
                            label: t('hub-trial-project-detail-label-plan'),
                            value: (
                                <PlanValue name={name} expirationTimestamp={expirationTimestamp} />
                            ),
                            action: (
                                <div data-cy="change-plan">
                                    <LinkAction
                                        disabled={!editable}
                                        onClick={() =>
                                            dispatch(
                                                projectUpdateActions.changePlan.start({
                                                    id: projectId,
                                                }),
                                            )
                                        }
                                        label={t('hub-trial-project-button-change-plan')}
                                    />
                                </div>
                            ),
                        },
                        {
                            name: 'rowSeats',
                            label: t('hub-trial-project-detail-label-seats'),
                            value: t('hub-select-seats-area-label-seats-number', {
                                payload: { NUMBER: seats },
                            }),
                            action: (
                                <div data-testid="change-seats">
                                    <LinkAction
                                        disabled={!editable}
                                        onClick={() =>
                                            dispatch(
                                                projectUpdateActions.changeSeats.start({
                                                    id: projectId,
                                                }),
                                            )
                                        }
                                        label={t('hub-trial-project-button-change-seats')}
                                    />
                                </div>
                            ),
                        },
                        {
                            name: 'rowLanguage',
                            label: t('hub-trial-project-detail-label-languages'),
                            value: (
                                <>
                                    {languages.map((lang: Language) => (
                                        <NonInteractiveChip key={lang.id} isFavorite={lang.favorite} leadingIcon="star_deselect">
                                            {t(`hub-trial-project-language-option-${lang.name}`)}
                                        </NonInteractiveChip>
                                    ))}
                                </>
                            ),
                        },
                    ]}
                />
            </Widget>
        </>
    );
};

type PlanValueProps = Pick<Props, 'expirationTimestamp' | 'name'>;

const PlanValue = ({ expirationTimestamp, name }: PlanValueProps) => {
    const t = useTranslator();
    const now = new Date().getTime();
    const isActive = expirationTimestamp ? expirationTimestamp - now > 0 : false;

    return (
        <ValueContainer>
            {isActive && (
                <ActiveTrialChip>
                    {t('hub-trial-project-details-version-chip-trial')}
                </ActiveTrialChip>
            )}
            <StyledValue>{name}</StyledValue>
            {isActive && (
                <Value>
                    &nbsp;{`| ${formatDistanceToNowStrict(new Date(expirationTimestamp!))} left`}
                </Value>
            )}
        </ValueContainer>
    );
};
