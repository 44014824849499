import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Text from '@igenius_dev/crystalkit/lib/components/Text';

import { PlanDescription } from '../../Project/Create/Plan/PlanDescription';
import { TotalPrice } from '../Details/TotalPrice/TotalPrice';
import {
    CouponRecap,
    DisclaimerArea,
    LoaderContainer,
    ProjectRecap,
    SeatsInformationContainer,
    Separator,
    Subtitle,
} from './elements';
import billingInfoSelectors from 'modules/billing-info/selectors';
import { SeatsWithPrice } from 'components/Price/SeatsWithPrice';
import { HubState } from 'types';
import { InvoicePreviewForView } from 'modules/invoice/types/views';
import { invoicePreviewSelectors } from 'modules/invoice/selectors';
import { CenteredLoader } from 'components/CenteredLoader';
import { PlanForView, PriceForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';
import { DuePrice, Price, SubscriptionType } from '../Details/TotalPrice/elements';
import { Price as CouponPrice } from 'components/Price/Price';

export interface SummaryProps {
    actionButton: React.ReactElement;
    seats: number;
    subscription?: PriceForView;
    plan?: PlanForView;
    disclaimer?: React.ReactElement[] | React.ReactElement;
    coupon?: React.ReactElement;
}

const PriceWithCoupon = styled(CouponPrice)`
    display: inline-flex;
`;

export const OrderSummary: React.FC<SummaryProps> = (props) => {
    const { plan, actionButton, seats, subscription, coupon } = props;
    const t = useTranslator();
    const trialVersion = useSelector(billingInfoSelectors.isUserInTrial);
    const invoice = useSelector<HubState, InvoicePreviewForView | undefined>(
        invoicePreviewSelectors.selectInvoice,
    );
    const invoicePreviewIsLoading = useSelector<HubState, boolean>(
        invoicePreviewSelectors.isLoading,
    );

    return plan && subscription ? (
        <div>
            <Subtitle>{t('hub-column-recap-title-order-summary')}</Subtitle>

            <ProjectRecap>
                <PlanDescription
                    title={plan.name}
                    trial={trialVersion}
                    text={t(plan.description?.standard)}
                    features={plan.features.features}
                    hasTrigger={!!plan.description?.standard}
                />
                {coupon}
            </ProjectRecap>

            <SeatsInformationContainer>
                <SeatsWithPrice seats={seats} currentPrice={subscription} />
            </SeatsInformationContainer>

            {invoice?.coupon && (
                <CouponRecap code={invoice.coupon.code} discount={invoice.coupon.discount} />
            )}

            <Separator />

            {invoicePreviewIsLoading ? (
                <LoaderContainer>
                    <CenteredLoader />
                </LoaderContainer>
            ) : (
                invoice && (
                    <TotalPrice>
                        <Price withStrikethrough={trialVersion}>
                            {invoice.coupon ? (
                                <PriceWithCoupon
                                    total={invoice.total}
                                    due={<Text scale="Title">{invoice.due}</Text>}
                                    hasCoupon={!!invoice.coupon}
                                />
                            ) : (
                                invoice.total
                            )}
                            <SubscriptionType>/{t(subscription.timeInterval)}</SubscriptionType>
                        </Price>
                        {trialVersion && (
                            <DuePrice>{trialVersion ? invoice.zero : invoice.due}</DuePrice>
                        )}
                    </TotalPrice>
                )
            )}

            <div data-cy="order-summary-submit-button">{actionButton}</div>

            {props.disclaimer && (
                <DisclaimerArea data-testid="order-summary-disclaimer">
                    {props.disclaimer}
                </DisclaimerArea>
            )}
        </div>
    ) : null;
};
