import React from 'react';
import styled from 'styled-components';

// @ts-ignore
import Modal from '@igenius_dev/crystalkit/lib/components/Modal';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';

export type HubModalProps = {
    content: React.ReactElement;
    open: boolean;
    onOutsideClick?: () => void;
    dismissable?: boolean;
    onClose: () => void;
    className?: string;
};

const HubModalContent = styled.div`
    width: 430px;
    padding: 40px;
    position: relative;
`;

const CloseModal = styled(Icon).attrs({
    icon: 'remove',
})`
    position: absolute;
    top: 25px;
    right: 25px;
    cursor: pointer;
    color: ${Icon.color.black500};
    z-index: 9999;
`;

const HubModal = ({
    content,
    className,
    onOutsideClick,
    open,
    dismissable = true,
    onClose,
}: HubModalProps) => {
    return (
        <Modal
            className={className}
            isDismissable={dismissable}
            type={Modal.type.small}
            open={open}
            render={() => (
                <HubModalContent>
                    <CloseModal onClick={onClose} />
                    {content}
                </HubModalContent>
            )}
            onClickOutside={onOutsideClick}
        />
    );
};

export { HubModal as default, HubModalContent };
