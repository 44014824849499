import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from '@reach/router';

import authModule from '@igenius_dev/crystal.dynamic-modules/lib/modules/auth';
import Avatar from '@igenius_dev/crystalkit/lib/components/Avatar';
import Sidebar from '@igenius_dev/crystalkit/lib/components/Sidebar';
import { SidebarStatus } from '@igenius_dev/crystalkit/lib/components/Sidebar/types';

// @ts-ignore
import { MobileDetectContext } from '@igenius_dev/crystalkit/lib/utils/MobileDetectProvider';
import { CreateProjectButton } from 'components/Sidebar/Create/CreateProjectButton';
import billingInfoSelectors from 'modules/billing-info/selectors';
import projectListSelectors from 'modules/project/list/selectors';
import { paths } from 'paths';
import { goToContentCenter } from 'utils/goToContentCenter';
import useTranslator from 'hooks/useTranslator';
import profileSelectors from 'modules/profile/selectors';
import { ItemList } from './Create/elements';
import { Content, User } from './elements';
import { HubState } from 'types';
import { Project } from 'core/entities/project/Project';
import { Footer } from 'components/Sidebar/Footer/Footer';
import { Items } from 'components/Sidebar/Items/Items';
import { SmallText } from 'components/Typography/SmallText';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { StyledFooterIcon } from 'components/Sidebar/Footer/elements';

export type SidebarItem = {
    icon?: React.ReactElement;
    label: string | React.ReactElement;
    subtitle?: string;
    id?: string;
    value?: string;
    onClick?: () => void;
    selected?: boolean;
};

export type SidebarContent = {
    status: SidebarStatus;
    items: SidebarItem[];
};

export const EnrichedSidebar = () => {
    const t = useTranslator();
    const user = useSelector(profileSelectors.selectUser);
    const projects = useSelector<HubState, Project[]>(projectListSelectors.selectProjects);
    const isUserInTrial = useSelector<HubState, boolean>(billingInfoSelectors.isUserInTrial);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = useContext<boolean>(MobileDetectContext);

    const footerItems: SidebarItem[] = [
        {
            icon: <StyledFooterIcon size={24} icon="chat" />,
            label: <SmallText color={theme.colors.names.black300}>Help center</SmallText>,
            onClick: goToContentCenter,
        },
        {
            icon: <StyledFooterIcon size={24} icon="logout" />,
            label: <SmallText color={theme.colors.names.black300}>Logout</SmallText>,
            onClick: () => dispatch(authModule.actions.logout.request()),
        },
        {
            icon: <Avatar uniqueString={user.id} disabled />,
            label: <User>{`${user.firstName} ${user.lastName}`}</User>,
            onClick: () => {
                window.open(
                    `${window.location.origin}/login-manager-webapp/account/profile`,
                    '_blank',
                    'noreferrer',
                );
            },
        },
    ];

    const contentItems: SidebarItem[] =
        isUserInTrial && projects.length === 0
            ? [
                  {
                      label: 'Trial project',
                      id: '0',
                      onClick: () => navigate(paths.createProject),
                      selected: location.pathname.includes(paths.createProject),
                  },
              ]
            : [
                  ...projects.map((project) => ({
                      label: project.name,
                      id: project.id,
                      onClick: () => navigate(`${paths.project}/${project.id}`),
                      selected: location.pathname.includes(project.id),
                      subtitle: t('hub-select-seats-area-label-seats-number', {
                          payload: { NUMBER: String(project.numberOfSeats) },
                      }),
                  })),
              ];

    return (
        <Sidebar initialStatus={isMobile ? 'collapsed' : 'expanded'} position="left">
            <Sidebar.Consumer>
                {({ status: s }) => {
                    const status = s as SidebarStatus;

                    return (
                        <Content data-testid="sidebar">
                            <Items items={contentItems} status={status} />
                            {!isUserInTrial && (
                                <ItemList>
                                    <CreateProjectButton status={status} />
                                </ItemList>
                            )}
                            <Footer status={status} items={footerItems} />
                        </Content>
                    );
                }}
            </Sidebar.Consumer>
        </Sidebar>
    );
};
