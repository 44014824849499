import { BillingDetailsFormData } from 'views/Project/Detail/Detail/FormEditBillingDetails/types';
import { HttpClient } from 'services/HttpClient';
import { BillingInfoDto, EditBillingDetailsRequest } from '../types/dto';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';

export const billingInfoURL = '/api/user-manager/private/users/me/billing-info';

export function retrieveBillingInformation(client: HttpClient) {
    return async () => {
        try {
            const response = await client.get<BillingInfoDto>(billingInfoURL);

            return adaptToDomain(response);
        } catch (e) {
            throw new Error(e.message);
        }
    };
}

function adaptToDomain(dto: BillingInfoDto): BillingInfo {
    let billingInfo: BillingInfo = {
        name: dto.name,
        trial: dto.trial,
    };

    if (!!dto.tax_id) {
        billingInfo = {
            ...billingInfo,
            tax: {
                country: dto.tax_id.country,
                type: dto.tax_id.type,
                value: dto.tax_id.value,
                verification: {
                    status: dto.tax_id.verification.status,
                    address: dto.tax_id.verification.verified_address,
                    name: dto.tax_id.verification.verified_name,
                },
            },
        };
    }

    if (!!dto.address) {
        billingInfo = {
            ...billingInfo,
            address: {
                city: dto.address.city,
                country: dto.address.country,
                line1: dto.address.line1,
                line2: dto.address.line2,
                postalCode: dto.address.postal_code,
                state: dto.address.state,
            },
        };
    }

    return billingInfo;
}

export function updateBillingDetails(client: HttpClient) {
    return async (data: BillingDetailsFormData): Promise<BillingInfo> => {
        try {
            const adaptedData = adaptToBillingDetailsDto(data);
            const res = await client.patch<EditBillingDetailsRequest, BillingInfoDto>(
                billingInfoURL,
                adaptedData,
            );

            return adaptToDomain(res);
        } catch (e) {
            throw new Error(e.message);
        }
    };
}

const adaptToBillingDetailsDto = (data: BillingDetailsFormData): EditBillingDetailsRequest => {
    const res = {
        user_type: data.userType,
        full_name: data.fullName,
        address: {
            city: data.city,
            country: data.country.value,
            postal_code: data.zipCode,
            line1: data.line1,
            line2: '',
            state: data.state,
        },
    };

    if (data.userType === 'company') {
        return {
            ...res,
            tax_id: {
                type: data.taxIDType.value,
                value: data.taxIDValue,
            },
        };
    }

    return res;
};
