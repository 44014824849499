import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

// @ts-ignore
import { WindowSizerContext } from '@igenius_dev/crystalkit/lib/components/WindowSizer/template';
// @ts-ignore

import { MobileDetectContext } from '@igenius_dev/crystalkit/lib/utils/MobileDetectProvider';
import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';
import someGreatIdea from 'assets/8.png';
import projectListSelectors from 'modules/project/list/selectors';
import {
    BlockingDialogContent,
    FullscreenBlockingModal,
    MobileBlockWrapper,
    TabletCrystalLogo,
} from './elements';
import useTranslator from '../hooks/useTranslator';

type WindowSize = 'mobile' | 'tablet' | 'desktopSmall' | 'desktopMedium' | 'desktopBig';

const MobileBlock = () => {
    const t = useTranslator();
    const isMobile: boolean = useContext(MobileDetectContext);
    const { size }: { size: WindowSize } = useContext(WindowSizerContext);
    const projects = useSelector(projectListSelectors.selectProjects);

    const hasProjects = projects?.length >= 1 || false;

    const isOpen = isMobile && ['mobile', 'tablet'].includes(size);

    return (
        <MobileBlockWrapper isOpen={isOpen}>
            <TabletCrystalLogo />
            <FullscreenBlockingModal
                open={isOpen}
                renderContent={() => (
                    <BlockingDialogContent>
                        <Dialog.IllustrationContent
                            title={
                                hasProjects
                                    ? t('hub-courtesy-generic-mobile-title')
                                    : t('hub-courtesy-firstaccess-mobile-title')
                            }
                            subtitle={
                                hasProjects
                                    ? t('hub-courtesy-generic-mobile-subtitle')
                                    : t('hub-courtesy-firstaccess-mobile-subtitle')
                            }
                            imageSrc={someGreatIdea}
                            imageSize="super"
                        />
                    </BlockingDialogContent>
                )}
            />
        </MobileBlockWrapper>
    );
};

export default MobileBlock;
