import { createAsyncAction } from 'typesafe-actions';
import { BillingDetailsFormData } from 'views/Project/Detail/Detail/FormEditBillingDetails/types';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';

export const fetchBillingInfo = createAsyncAction(
    '@billing-info/FETCH_BILLING_INFO_REQUEST',
    '@billing-info/FETCH_BILLING_INFO_SUCCESS',
    '@billing-info/FETCH_BILLING_INFO_FAILURE',
)<undefined, BillingInfo, Error>();

export const editBillingDetails = createAsyncAction(
    '@project-update/EDIT_BILLING_DETAILS_REQUEST',
    '@project-update/EDIT_BILLING_DETAILS_SUCCESS',
    '@project-update/EDIT_BILLING_DETAILS_FAILURE',
)<BillingDetailsFormData, BillingInfo, Error>();
