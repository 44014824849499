import { isArray, isObject, omit } from 'lodash';

const isEntity = (input) => isObject(input) && input.allIds && input.byId;

export const createEntity = ({ array, idKey = 'id' }) => {
    if (!isArray(array)) {
        return null;
    }
    return array.reduce(
        ({ allIds, byId }, item, index) => ({
            allIds: [...allIds, item[idKey] || index],
            byId: {
                ...byId,
                [item[idKey] || index]: item,
            },
        }),
        { allIds: [], byId: {} },
    );
};

export const addToEntity = ({ entity, idKey = 'id', item = {} }) => {
    if (!isEntity(entity) || !item[idKey]) {
        return null;
    }
    return {
        allIds: [...entity.allIds, item[idKey]],
        byId: { ...entity.byId, [item[idKey]]: item },
    };
};

export const removeFromEntity = ({ entity, idVal }) => {
    if (!isEntity(entity) || !idVal) {
        return null;
    }

    return {
        allIds: entity.allIds.filter((id) => idVal !== id),
        byId: omit(entity.byId, idVal),
    };
};
