import React from 'react';
import { Label, Left, PriceContainer, Right, VatIncluded } from './elements';

export const TotalPrice: React.FC = ({ children }) => {
    return (
        <PriceContainer>
            <Left>
                <Label scale="Medium">Total</Label>
                <VatIncluded>(VAT Included)</VatIncluded>
            </Left>
            <Right>{children}</Right>
        </PriceContainer>
    );
};
