import { InvoiceDto } from '../dtos';
// @ts-ignore
import NumberFormatter from '@igenius_dev/igenius-number';
import { Invoice } from 'core/entities/invoice/Invoice';

const FIXED_CURRENCY = 'usd'; // FIXME when backend decide to send it

export const adaptToInvoice = (invoice: InvoiceDto): Invoice => {
    const id = invoice.id;

    const adapted: Invoice = {
        createdAt: invoice.created * 1000,
        paid: invoice.paid,
        pdfLink: invoice.invoice_pdf,
        status: invoice.status,
        amountDue: priceFormattedFor(invoice.amount_due),
        subtotal: priceFormattedFor(invoice.subtotal),
        total: priceFormattedFor(invoice.total),
    };

    if (id) {
        adapted.id = id;
    }

    return adapted;
};

const priceFormattedFor = (n: number = 0): string => {
    if (n === 0) {
        return NumberFormatter.formatCurrency({
            n,
            options: {
                currency: FIXED_CURRENCY,
                minimumFractionDigits: 0,
            },
        });
    }

    return NumberFormatter.formatCurrency({
        n: n / 100,
        options: {
            currency: FIXED_CURRENCY,
            minimumFractionDigits: 2,
        },
    });
};
