import { ISagaModule } from 'redux-dynamic-modules-saga';
import { BillingInfoForView, BillingInfoModuleState } from './types/types';
import { createBillingInfoReducer } from './reducer';
import { createBillingInfoSaga } from './saga';
import { fetchBillingInfo } from './actions';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';
import { FetchBillingDetails } from 'core/entities/billing-details/FetchBillingDetails';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';

interface BillingInfoModuleParams {
    updateUseCase: UpdateBillingDetails;
    getUseCase: FetchBillingDetails;
    viewAdapter: (b: BillingInfo) => BillingInfoForView;
}

export const getBillingInfoModule = ({
    updateUseCase,
    getUseCase,
    viewAdapter,
}: BillingInfoModuleParams): ISagaModule<BillingInfoModuleState> => {
    return {
        id: 'billingInfo',
        sagas: [
            createBillingInfoSaga({
                getBillingDetails: getUseCase,
                editBillingDetails: updateUseCase,
            }),
        ],
        reducerMap: {
            billingInfo: createBillingInfoReducer({
                toBillingInfoForView: viewAdapter,
            }),
        },
        initialActions: [fetchBillingInfo.request()],
    };
};
