import { ISagaModule } from 'redux-dynamic-modules-saga';
import { createProjectCreationInfoSaga } from './saga';
import { CreationInfoForView, ProjectCreationInfoModuleState } from './types';
import { projectCreationInfoReducer } from './reducer';
import { CreationInfoUseCase } from '../../core/entities/creation-info/CreationInfoUseCase';
import { CreationInfo } from '../../core/entities/creation-info/CreationInfo';

export const getProjectCreationInfoModule = (
    useCase: CreationInfoUseCase,
    viewAdapter: (info: CreationInfo) => CreationInfoForView,
): ISagaModule<ProjectCreationInfoModuleState> => {
    return {
        sagas: [createProjectCreationInfoSaga(useCase, viewAdapter)],
        id: 'creationInfo',
        reducerMap: {
            creationInfo: projectCreationInfoReducer,
        },
    };
};
