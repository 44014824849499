import { createAsyncAction } from 'typesafe-actions';
import { SetupIntent } from '@stripe/stripe-js';

import { AddingPaymentMethod } from 'modules/payment-methods/types/types';
import { PaymentMethod } from 'core/entities/payment-method/PaymentMethod';

const retrievePaymentMethods = createAsyncAction(
    '@payment-methods/PAYMENT_METHODS_GET_REQUEST',
    '@payment-methods/PAYMENT_METHODS_GET_SUCCESS',
    '@payment-methods/PAYMENT_METHODS_GET_FAILURE',
)<{ type: 'card' | 'iban' }, PaymentMethod[], Error>();

const addPaymentMethods = createAsyncAction(
    '@payment-methods/PAYMENT_METHODS_ADD_REQUEST',
    '@payment-methods/PAYMENT_METHODS_ADD_SUCCESS',
    '@payment-methods/PAYMENT_METHODS_ADD_FAILURE',
)<AddingPaymentMethod, { intent: SetupIntent }, Error>();

export const paymentMethodsActions = {
    retrieve: retrievePaymentMethods,
    add: addPaymentMethods,
};
