import { TenantLimits } from '../../../modules/tenant-usage/types/types';
import { ProjectId } from '../../entities/project/Project';
import { ChangeSeatsVerifier } from '../../entities/project/ChangeSeatsVerifier';

export function createChangeSeatsVerifierUseCase(
    tenantUsages: (id: ProjectId) => Promise<TenantLimits>,
): ChangeSeatsVerifier {
    return async (id: ProjectId, seats: number): Promise<Partial<TenantLimits>> => {
        try {
            const { users = 0 } = await tenantUsages(id);

            if (seats > users) {
                return { users: 0 };
            } else {
                return {
                    users: users - seats,
                };
            }
        } catch (e) {
            throw new Error('no-features');
        }
    };
}
