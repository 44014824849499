import { ISagaModule } from 'redux-dynamic-modules-saga';
import { projectCreationReducer } from './reducers';
import { createProjectCreationSaga } from './saga';
import { ProjectCreationModuleState } from './types';
import { ProjectRepository } from '../../../core/repository/project/ProjectRepository';
import { UpdateBillingDetails } from '../../../core/entities/billing-details/UpdateBillingDetails';

export const getProjectCreationModule = (
    repository: ProjectRepository,
    updateBillingDetailsUseCase: UpdateBillingDetails,
): ISagaModule<ProjectCreationModuleState> => ({
    id: 'projectCreation',
    reducerMap: {
        projectCreation: projectCreationReducer,
    } as any,
    sagas: [createProjectCreationSaga(repository, updateBillingDetailsUseCase)],
});
