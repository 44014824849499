import styled from 'styled-components';

import Text from '@igenius_dev/crystalkit/lib/components/Text';

export const StyledTitle = styled(Text).attrs({
    scale: 'Medium',
    color: 'black500',
})`
    display: block;
`;

export const StyledSubtitle = styled(Text).attrs({
    scale: 'Small',
    color: 'black300',
})``;
