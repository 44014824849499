import { ActionType, getType } from 'typesafe-actions';
import { deleteProject } from 'modules/project/delete/actions';
import { ProjectDeletionState } from 'modules/project/delete/types';

export const projectDeletionReducer = (
    state: ProjectDeletionState = { status: 'PENDING' },
    action: ActionType<typeof deleteProject>,
): ProjectDeletionState => {
    switch (action.type) {
        case getType(deleteProject.request): {
            return { status: 'PENDING' };
        }
        case getType(deleteProject.failure): {
            return { status: 'ERROR' };
        }
        case getType(deleteProject.success): {
            return { status: 'SUCCESS' };
        }
        default: {
            return state;
        }
    }
};
