import React from 'react';
import {
    ExpirationDate,
    PurpleRadioInput,
    RadioLabel,
    RectangleRowContainer,
    StyledRectangle,
} from '../../../../PaymentWizard/Details/PaymentMethod/elements';
import RadioGroup from '@igenius_dev/crystalkit/lib/components/RadioGroup';
import TrashWithTooltip from '../../../../../components/TrashWithTooltip';

import { CreditCard } from '../../../../../components/CreditCard';
import useTranslator from '../../../../../hooks/useTranslator';
import { PaymentMethod } from '../../../../../core/entities/payment-method/PaymentMethod';

interface Props {
    value: string;
    methods: PaymentMethod[];
    onChange: (value: string) => void;
    showExpiration?: boolean;
}

export const PaymentMethodsList = ({ methods, onChange, value, showExpiration }: Props) => {
    const t = useTranslator();
    const isSingleElement = methods.length === 1;

    return (
        <RectangleRowContainer>
            <RadioGroup value={value} onValueChange={onChange} name="paymentMethod">
                {methods.map((method: PaymentMethod) => (
                    <StyledRectangle
                        selected={value === method.id}
                        key={method.id}
                        data-cy="payment-method"
                        onClick={() => onChange(method.id)}
                    >
                        <RadioLabel>
                            {!isSingleElement && (
                                <PurpleRadioInput
                                    value={method.id}
                                    data-cy={'payment-method-input-radio'}
                                />
                            )}
                            <CreditCard brand={method.brand} lastDigits={method.lastDigits} />
                        </RadioLabel>
                        <RadioLabel>
                            {showExpiration && (
                                <ExpirationDate>
                                    {t(`hub-confirm-and-subscribe-area-label-expiration-date`, {
                                        payload: {
                                            DATE: `${method.expiration.month}/${method.expiration.year}`,
                                        },
                                    })}
                                </ExpirationDate>
                            )}
                            {!isSingleElement ? <TrashWithTooltip /> : null}
                        </RadioLabel>
                    </StyledRectangle>
                ))}
            </RadioGroup>
        </RectangleRowContainer>
    );
};
