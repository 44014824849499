import AnimationOrchestrator from '@igenius_dev/crystalkit/lib/components/Toast/AnimationOrchestrator';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Toast from '../Toast';
import { Wrapper } from './elements';

class ToastsContainer extends Component {
    render() {
        const { toasts, removeToast, actionSideEffect } = this.props;

        return (
            <Wrapper>
                <AnimationOrchestrator>
                    {toasts.map(({ id, label, type, actions }) => (
                        <Toast
                            key={id}
                            id={id}
                            label={label}
                            type={type}
                            onDismiss={removeToast}
                            actions={actions}
                            actionSideEffect={actionSideEffect}
                        />
                    ))}
                </AnimationOrchestrator>
            </Wrapper>
        );
    }
}

ToastsContainer.propTypes = {
    toasts: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            actions: PropTypes.array,
            actionsPayloads: PropTypes.object,
        }),
    ),
    addToast: PropTypes.func,
    removeToast: PropTypes.func,
    actionSideEffect: PropTypes.func,
};

ToastsContainer.defaultProps = {
    toasts: [],
    addToast: () => {},
    removeToast: () => {},
    actionSideEffect: () => {},
};

export default ToastsContainer;
