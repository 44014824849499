import { applyMiddleware } from 'redux';
import { createStore } from 'redux-dynamic-modules';
import { getSagaExtension } from 'redux-dynamic-modules-saga';
import createSagaMiddleware from 'redux-saga';

import { Logger } from '@igenius_dev/igenius-js-logger/dist/Logger/Logger';
import { AuthModuleState } from '@igenius_dev/crystal.dynamic-modules/lib/modules/auth/types';
import { TrackingModuleState } from '@igenius_dev/crystal.dynamic-modules/lib/modules/tracking/types';

import { ConfigModuleState } from 'modules/config/types';
import { HubState } from 'types';
import { ProfileModuleState } from 'modules/profile/types';
import { BillingInfoModuleState } from 'modules/billing-info/types/types';
import { ProjectListModuleState } from 'modules/project/list/types';
import { Modules } from 'config/modules/Modules';
import { UiModuleState } from 'modules/ui/types';
import { I18nModuleState } from 'modules/i18n/types';
import { LoggingModuleState } from 'modules/logger';

type CreateStoreParams = {
    modules: Modules;
    logger: Logger;
};

const createReduxStore = (params: CreateStoreParams) => {
    const { modules, logger } = params;

    const sagaMiddleware = createSagaMiddleware({
        onError(error: Error, { sagaStack }) {
            logger.error('Unexpected error', {
                message: error.message,
                name: error.name,
                stack: sagaStack,
            });
        },
    });

    const moduleStoreSettings = {
        enhancers: [applyMiddleware(sagaMiddleware)],
        extensions: [getSagaExtension()],
    };

    return createStore<HubState>(
        moduleStoreSettings,
        modules.get<I18nModuleState>('i18n'),
        modules.get<ProfileModuleState>('profile'),
        modules.get('toasts'),
        modules.get<BillingInfoModuleState>('billingInfo'),
        modules.get<AuthModuleState>('auth-module'),
        modules.get<TrackingModuleState>('tracking'),
        modules.get<ProjectListModuleState>('projectList'),
        modules.get<ConfigModuleState>('config'),
        modules.get<UiModuleState>('ui'),
        modules.get<LoggingModuleState>('logging'),
    );
};

export default createReduxStore;
