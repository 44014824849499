import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProjectId } from 'core/entities/project/Project';
import { ProjectDetailForView } from './types';

export const retrieveProjectDetail = createAsyncAction(
    '@project-details/PROJECT_RETRIEVAL_REQUEST',
    '@project-details/PROJECT_RETRIEVAL_SUCCESS',
    '@project-details/PROJECT_RETRIEVAL_FAILURE',
)<{ id: ProjectId }, { id: ProjectId; detail: ProjectDetailForView }, Error>();

export const openPaymentMethodModal = createAction(
    '@project-details/OPEN_PAYMENT_METHOD_MODAL',
)<undefined>();
export const closePaymentMethodModal = createAction(
    '@project-details/CLOSE_PAYMENT_METHOD_MODAL',
)<undefined>();

export const openInvoicesSheet = createAction('@project-details/OPEN_INVOICES_SHEET')<undefined>();
export const closeInvoicesSheet = createAction(
    '@project-details/CLOSE_INVOICES_SHEET',
)<undefined>();

// TODO: make generic modal action, which hanldes the currently displayed modal
export const openBillingDetailsModal = createAction(
    '@project-details/OPEN_BILLING_DETAILS_MODAL',
)<undefined>();
export const closeBillingDetailsModal = createAction(
    '@project-details/CLOSE_BILLING_DETAILS_MODAL',
)<undefined>();

export const projectDetailActions = {
    get: retrieveProjectDetail,
    openPaymentMethodModal,
    closePaymentMethodModal,
    openInvoicesSheet,
    closeInvoicesSheet,
    openBillingDetailsModal,
    closeBillingDetailsModal,
};
