import styled from 'styled-components';
import HubModal, { HubModalContent } from '../../components/HubModal';

import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { BaseText } from '../../components/Typography/BaseText';

export const Title = styled(Text).attrs({
    scale: 'Large',
})`
    display: block;
`;

export const Summary = styled(BaseText)`
    display: block;
    margin-top: 8px;
`;

export const PlanDifferencesContainer = styled.div`
    margin: 24px -40px;
    padding: 24px 40px;
    background-color: ${theme.colors.values.blue15};
    display: flex;
    flex-direction: column;
`;

export const ForwardIcon = styled(Icon).attrs({
    size: Icon.size.base,
    icon: 'forward',
})`
    margin: 0 16px;
    height: 71px;
    display: flex;
    align-items: center;
    color: ${theme.colors.values.purple700};
`;
export const TitleSpacer = styled(ForwardIcon)`
    visibility: hidden;
    height: 0;
`;

const PlanTitle = styled(Text).attrs({
    scale: 'Base',
})`
    width: 100%;
`;

export const CurrentPlanTitle = styled(PlanTitle).attrs({
    color: theme.colors.values.black200,
})``;

export const NextPlanTitle = styled(PlanTitle).attrs({
    color: theme.colors.values.purple700,
})``;

export const SlightlyBiggerModal = styled(HubModal)`
    ${HubModalContent} {
        width: 533px;
    }
`;

export const FeatureContainer = styled.div`
    margin-top: 16px;
    display: flex;
    flex-basis: 100%;
`;

export const FeatureBox = styled.div<{ withPrice?: boolean }>`
    padding: 16px 14px 16px 24px;
    border-radius: 8px;
    background-color: ${theme.colors.values.white};
    height: ${(props) => (props.withPrice ? ' 92px' : '71px')};
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const FeatureTitle = styled(Text).attrs((props) => ({
    scale: 'Base',
    color: props.color ?? theme.colors.values.black300,
}))``;

export const FeatureSubtitle = styled(Text).attrs((props) => ({
    scale: 'Small',
    color: props.color ?? theme.colors.values.black200,
}))``;

export const FeaturePrice = styled(Text).attrs((props) => ({
    scale: 'Micro',
    color: props.color ?? theme.colors.values.black200,
}))`
    margin-top: 8px;
`;

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 24px;
`;

export const LearnMore = styled(Text).attrs({
    color: theme.colors.values.black200,
    scale: 'Small',
})`
    margin-top: 16px;
    text-align: center;
    width: 100%;
`;

export const ContentCenter = styled(TextLink).attrs({
    scale: 'Small',
    underline: true,
    color: 'black200',
})`
    color: ${theme.colors.values.black200};
`;
