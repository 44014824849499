import { InvoicesRepository } from 'core/repository/invoice/InvoicesRepository';
import { HttpClient } from 'services/HttpClient';
import { InvoiceDto, PaginatedInvoicesDto } from 'common/dtos';
import { Invoice } from 'core/entities/invoice/Invoice';
import { Invoices } from 'core/entities/invoice/Invoices';

export class InvoicesFromHttp implements InvoicesRepository {
    constructor(
        private readonly client: HttpClient,
        private readonly baseUrl: string,
        private readonly adaptInvoice: (invoice: InvoiceDto) => Invoice,
    ) {}

    async getAllOf(projectId: string, lastInvoiceId?: string): Promise<Invoices> {
        let url = this.baseUrl + `?project_id=${projectId}`;

        if (lastInvoiceId) {
            url += '&starting_after=' + lastInvoiceId;
        }

        const response = await this.client.get<PaginatedInvoicesDto>(url);

        return {
            invoices: response.data.map(this.adaptInvoice),
            pagination: {
                hasMore: response.has_more,
                lastId: response.last_id,
                size: response.size,
            },
        };
    }
}
