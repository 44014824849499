import React, { useEffect, useRef } from 'react';

import { StyledItem } from 'components/Sidebar/elements';
import { BaseText, Cell, InvoiceContent, StyledSheetContent } from './elements';
import { InvoiceForView } from 'modules/invoices/adapter/toInvoicesForView';
import { DownloadLink } from 'views/Project/Detail/Detail/SheetInvoices/DownloadLink';
import { StatusChip } from 'views/Project/Detail/Detail/SheetInvoices/StatusChip';
import useTranslator from 'hooks/useTranslator';

type Props = {
    invoices: InvoiceForView[];
    onScrollFrame: () => void;
};

export const Content = (props: Props) => {
    const t = useTranslator();
    const { invoices: data, onScrollFrame } = props;
    const childOfScrollableDiv = useRef<HTMLDivElement>();

    useEffect(() => {
        const scrollableElement = childOfScrollableDiv.current?.parentElement;
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    onScrollFrame();
                }
            },
            {
                threshold: 0.75,
            },
        );

        scrollableElement && observer.observe(scrollableElement);

        return () => observer.unobserve(scrollableElement!);
    }, [childOfScrollableDiv, onScrollFrame]);

    return (
        <StyledSheetContent>
            <div ref={(node: HTMLDivElement) => (childOfScrollableDiv.current = node)}>
                {data.map((i) => (
                    <StyledItem
                        key={i.pdfLink}
                        center={
                            <InvoiceContent>
                                <Cell big>
                                    <BaseText>
                                        {t('hub-project-details-payment-history-value-date', {
                                            payload: { date: i.createdAt },
                                        })}
                                    </BaseText>
                                </Cell>
                                <Cell>
                                    <BaseText>
                                        {t('hub-project-details-payment-history-value-amount', {
                                            payload: { amount: i.amountDue },
                                        })}
                                    </BaseText>
                                </Cell>
                                <Cell>
                                    <BaseText>
                                        <StatusChip color={i.color} status={t(i.status)} />
                                    </BaseText>
                                </Cell>
                                <Cell>
                                    <DownloadLink link={i.pdfLink} />
                                </Cell>
                            </InvoiceContent>
                        }
                        value={i.pdfLink}
                    />
                ))}
            </div>
        </StyledSheetContent>
    );
};
