import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import { BillingInfoForView, BillingInfoState } from './types/types';
import { BillingInfo } from 'core/entities/billing-details/BillingInfo';

interface BillingInfoReducerParams {
    toBillingInfoForView: (b: BillingInfo) => BillingInfoForView;
}

export const createBillingInfoReducer = ({ toBillingInfoForView }: BillingInfoReducerParams) => {
    return (
        state: BillingInfoState = { status: 'INITIAL' },
        action: ActionType<typeof actions>,
    ): BillingInfoState => {
        switch (action.type) {
            case getType(actions.fetchBillingInfo.success):
            case getType(actions.editBillingDetails.success):
                return {
                    ...state,
                    status: 'READY',
                    info: toBillingInfoForView(action.payload),
                };
            case getType(actions.fetchBillingInfo.failure):
            case getType(actions.editBillingDetails.failure):
                return { ...state, status: 'ERROR' };
            case getType(actions.fetchBillingInfo.request):
            case getType(actions.editBillingDetails.request):
                return { ...state, status: 'PENDING' };
            default:
                return state;
        }
    };
};
