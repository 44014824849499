import styled from 'styled-components';
import { InfoNotification } from '../../../modules/notifications/components/elements';

export const StyledInfoNotification = styled(InfoNotification)`
    margin-top: 16px;
`;

export const Selection = styled.div`
    width: 140px;
    margin-top: 24px;
`;
