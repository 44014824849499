import { ISagaModule } from 'redux-dynamic-modules-saga';
import { ProjectRestartModuleState } from 'modules/project/restart/types';
import { projectRestartReducer } from 'modules/project/restart/reducer';
import { createProjectRestartSaga } from 'modules/project/restart/saga';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';

export const getProjectRestart = (
    repository: ProjectRepository,
): ISagaModule<ProjectRestartModuleState> => {
    return {
        id: 'projectRestart',
        reducerMap: {
            projectRestart: projectRestartReducer,
        },
        sagas: [createProjectRestartSaga(repository)],
    };
};
