import { globalHistory } from '@reach/router';
import { Config } from 'modules/config/types';
import {
    authModule as cdmAuthModule,
    profileModule as cdmProfileModule,
    trackingModule as cdmTrackingModule,
} from '@igenius_dev/crystal.dynamic-modules/lib';
import { getConfigModule } from 'modules/config/module';
import { getPaymentWizardModule } from 'modules/payment-wizard';
import { HubModules } from './HubModules';
import { getProjectCreationModule } from 'modules/project/create';
import { HttpProjectRepository } from 'modules/project/HttpProjectRepository';
import { api } from 'paths';
import { HttpClient } from 'services/HttpClient';
import { getProjectDetailModule } from 'modules/project/detail';
import getProjectListModule from '../../modules/project/list';
import { adaptToProjectDetail } from 'modules/project/detail/adapter/adaptToProjectDetail';
import { adaptToProjectLanguage } from 'modules/creation-info/adapter/adaptToProjectLanguage';
import { getProjectUpdateModule } from 'modules/project/update';
import { getProjectCreationInfoModule } from 'modules/creation-info';
import { createProjectCreationInfoRetriever } from 'modules/creation-info/api/createProjectCreationInfoRetriever';
import {
    ChangePlanVerifierUseCase,
    RetrievePlanFeatures,
    RetrieveTenantLimits,
} from 'core/use-cases/project/ChangePlanVerifierUseCase';
import { TenantLimits, TenantUsage } from 'modules/tenant-usage/types/types';
import { ProjectId } from 'core/entities/project/Project';
import { adaptToProjectDetailForView } from 'modules/project/detail/adapter/adaptToProjectDetailForView';
import { adaptToCreationInfoForView } from 'modules/creation-info/adapter/adaptToCreationInfoForView';
import { adaptToCouponView } from 'modules/invoice/adapters/toInvoicePreviewForView';
import { HttpCreationInfoUseCase } from 'core/use-cases/creation-info/HttpCreationInfoUseCase';
import { CreationInfoGetter } from 'core/entities/creation-info/CreationInfoGetter';
import { fetchTenantUsage } from 'modules/tenant-usage/api/fetchTenantUsage';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { differencesForPlans } from 'modules/tenant-usage/differencesForPlans';
import { adaptToPricesForView } from 'modules/creation-info/adapter/adaptToPriceForView';
import { getUserNotificationsModule } from 'modules/userNotifications';
import { createNotificationsFetcher } from 'modules/userNotifications/api/api';
import { HttpFetchInvoicesUseCase } from 'core/use-cases/invoice/HttpFetchInvoicesUseCase';
import { getInvoicesModule } from 'modules/invoices';
import { Modules } from './Modules';
import { getToastsModule } from 'modules/toasts/module';
import { getBillingInfoModule } from 'modules/billing-info';
import { version } from '../../../package.json';
import AuthClient from '@igenius_dev/igenius-auth-client/lib';
import { AxiosInstance } from 'axios';
import { getInvoicePreviewModule } from 'modules/invoice';
import { createInvoicePreviewRetriever } from 'modules/invoice/api/createInvoicePreviewRetriever';
import { createChangeSeatsVerifierUseCase } from 'core/use-cases/project/changeSeatsVerifier';
import { InvoicePreviewRequest } from 'modules/invoice/types/dto';
import { InvoicePreview } from 'modules/invoice/types/types';
import { getTenantUsageModule } from 'modules/tenant-usage';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';
import { EditBillingDetailsUseCase } from 'core/use-cases/billing-details/EditBillingDetailsUseCase';
import { retrieveBillingInformation, updateBillingDetails } from 'modules/billing-info/api';
import { HttpFetchBillingDetailsUseCase } from 'core/use-cases/billing-details/HttpFetchBillingDetailsUseCase';
import { adaptToInvoice } from 'common/adapters/adaptToInvoice';
import { adaptToSubscription } from 'modules/project/detail/adapter/adaptToSubscription';
import { adaptToPaymentMethod } from 'common/adapters/adaptToPaymentMethod';
import { adaptToCoupon } from 'common/adapters/adaptToCoupon';
import { PaymentMethodsRepository } from 'core/repository/payment-methods/PaymentMethodsRepository';
import { PaymentMethodsWebRepository } from 'modules/payment-methods/api/PaymentMethodsWebRepository';
import { getPaymentMethodsModule } from 'modules/payment-methods';
import { StorageNotificationRepository } from 'modules/notifications/api/StorageNotificationRepository';
import { getNotificationsModule } from 'modules/notifications';
import { createBillingInfoForViewAdapter } from 'modules/billing-info/adapter/toBillingInfoForView';
import { FixtureVatTypes } from 'common/utils/FixtureVatTypes';
import { vatIdTypes } from 'common/fixtures/vatIdTypes';
import { FixtureCountries } from 'common/utils/FixtureCountries';
import { countries } from 'common/fixtures/countries';
import { getUiModule } from 'modules/ui/getUiModule';
import { toInvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';
import { InvoicesFromHttp } from 'modules/invoices/api/InvoicesFromHttp';
import { getProjectTerminationModule } from 'modules/project/terminate';
import { getProjectDeletionModule } from 'modules/project/delete';
import { getProjectRestart } from 'modules/project/restart';
import { getModule as getI18nModule } from 'modules/i18n';
import { getLoggingModule } from 'modules/logger';
import { Logger } from '@igenius_dev/igenius-js-logger/dist/Logger/Logger';
import { getProjectUpgradeModule } from 'modules/project/upgrade';

interface CreateModulesParams {
    config: Config;
    client: HttpClient;
    axiosInstance: AxiosInstance;
    serviceName: string;
    logger: Logger;
}

export const createModules = (params: CreateModulesParams): Modules => {
    const { config, client, axiosInstance, serviceName, logger } = params;

    const authClient = new AuthClient(
        config.modules.Auth.client.clientId,
        `${window.location.origin}/iam`,
        `${window.location.origin}/callback`,
    );
    const notificationRepository = new StorageNotificationRepository(localStorage);
    const projectRepository = new HttpProjectRepository(
        client,
        api.submit,
        adaptToProjectDetail(
            adaptToProjectLanguage,
            adaptToSubscription(adaptToInvoice, adaptToPaymentMethod, adaptToCoupon),
        ),
    );
    const paymentMethodsRepository: PaymentMethodsRepository = new PaymentMethodsWebRepository(
        client,
        adaptToPaymentMethod,
        api.paymentMethods,
    );
    const fetchProjectCreationInfo = createProjectCreationInfoRetriever({
        client,
        url: api.projectCreationInfo,
    });
    const fetchUserNotifications = createNotificationsFetcher(client, api.notifications);

    const i18nModule = getI18nModule();
    const paymentWizardModule = getPaymentWizardModule();
    const configModule = getConfigModule(config);
    const projectDetailModule = getProjectDetailModule(
        projectRepository,
        adaptToProjectDetailForView(adaptToPricesForView, adaptToCouponView),
    );
    const projectTerminationModule = getProjectTerminationModule(projectRepository);
    const projectDeletionModule = getProjectDeletionModule(projectRepository);
    const projectListModule = getProjectListModule(projectRepository);
    const tenantUsageFetcher = fetchTenantUsage(client);
    const invoicePreviewRetriever = createInvoicePreviewRetriever(client);
    const billingDetailsUpdater = updateBillingDetails(client);
    const billingInfoFetcher = retrieveBillingInformation(client);
    const updateBillingDetailsUseCase = new EditBillingDetailsUseCase(billingDetailsUpdater);
    const fetchBillingDetailsUseCase = new HttpFetchBillingDetailsUseCase(billingInfoFetcher);
    const projectCreationModule = getProjectCreationModule(
        projectRepository,
        updateBillingDetailsUseCase,
    );
    const projectUpdateModule = createProjectUpdateModule(
        fetchProjectCreationInfo,
        tenantUsageFetcher,
        projectRepository,
        invoicePreviewRetriever,
        updateBillingDetailsUseCase,
    );
    const projectRestartModule = getProjectRestart(projectRepository);
    const userNotificationsModule = getUserNotificationsModule(fetchUserNotifications);
    const projectCreationInfoModule = createCreationInfoModule(fetchProjectCreationInfo);
    const invoicesModule = createInvoicesModule(client);

    const authModule = cdmAuthModule.getModule(axiosInstance, authClient);
    const profileModule = cdmProfileModule.getModule(authClient);
    const toastsModule = getToastsModule();
    const countriesRepo = new FixtureCountries(countries);
    const taxesRepo = new FixtureVatTypes(vatIdTypes);
    const toBillingInfoForView = createBillingInfoForViewAdapter(taxesRepo, countriesRepo);
    const billingInfoModule = getBillingInfoModule({
        updateUseCase: updateBillingDetailsUseCase,
        getUseCase: fetchBillingDetailsUseCase,
        viewAdapter: toBillingInfoForView,
    });
    const trackingModule = cdmTrackingModule.getModule({
        globalHistory,
        serviceName,
        version,
    });
    const invoicePreviewModule = getInvoicePreviewModule(invoicePreviewRetriever);
    const tenantUsageModule = getTenantUsageModule(tenantUsageFetcher);
    const paymentMethodsModule = getPaymentMethodsModule(paymentMethodsRepository);
    const notificationsModule = getNotificationsModule(notificationRepository);
    const uiModule = getUiModule(countriesRepo, taxesRepo);
    const loggingModule = getLoggingModule(logger);
    const projectUpgradeModule = getProjectUpgradeModule(projectRepository);

    return new HubModules([
        i18nModule,
        authModule,
        profileModule,
        toastsModule,
        billingInfoModule,
        trackingModule,
        paymentWizardModule,
        configModule,
        projectCreationModule,
        projectDetailModule,
        projectDeletionModule,
        projectListModule,
        projectUpdateModule,
        projectCreationInfoModule,
        projectTerminationModule,
        projectRestartModule,
        userNotificationsModule,
        invoicesModule,
        invoicePreviewModule,
        tenantUsageModule,
        paymentMethodsModule,
        notificationsModule,
        uiModule,
        loggingModule,
        projectUpgradeModule,
    ]);
};

const createInvoicesModule = (client: HttpClient) => {
    const useCase = new HttpFetchInvoicesUseCase(
        new InvoicesFromHttp(client, api.invoices, adaptToInvoice),
    );

    return getInvoicesModule(useCase, toInvoicesForView);
};

const createProjectUpdateModule = (
    fetchProjectCreationInfo: CreationInfoGetter,
    fetchTenantUsage: (id: ProjectId) => Promise<TenantUsage>,
    repository: ProjectRepository,
    invoicePreviewRetriever: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>,
    updateBillingDetailsUseCase: UpdateBillingDetails,
) => {
    const tenantUsagesFor: RetrieveTenantLimits = async (id: ProjectId) => {
        const response = await fetchTenantUsage(id);

        return response.usages;
    };
    const featuresOf: RetrievePlanFeatures = async (
        planId: string,
    ): Promise<Partial<TenantLimits>> => {
        const response = await fetchProjectCreationInfo();
        const features = response?.plans.find((p) => p.id === planId)?.features;

        if (features?.features) {
            return {
                dataSources: features.dataSources!,
                topics: features.topics!,
                users: features.users!,
                languages: features.languages!,
            };
        }

        return {};
    };

    return getProjectUpdateModule(
        repository,
        new ChangePlanVerifierUseCase(tenantUsagesFor, featuresOf, differencesForPlans),
        createChangeSeatsVerifierUseCase(tenantUsagesFor),
        updateBillingDetailsUseCase,
        invoicePreviewRetriever,
    );
};

const createCreationInfoModule = (fetchProjectCreationInfo: CreationInfoGetter) => {
    return getProjectCreationInfoModule(
        new HttpCreationInfoUseCase(fetchProjectCreationInfo),
        adaptToCreationInfoForView(adaptToPricesForView),
    );
};
