import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import DoYouReallyWantToUnsubscribe from './DoYouReallyWantToUnsubscribe';
import { AvailableTillText, UnsubscribeButton, UnsubsribeBlock } from './elements';
import { formatDateTo } from 'utils/formatDateTo';
import useTranslator from 'hooks/useTranslator';
import { terminateProject } from 'modules/project/terminate/actions';

type UnsubscribeProps = {
    projectId: string;
    editable: boolean;
    currentPeriodEnd: number;
};

const Unsubscribe = ({ projectId, editable, currentPeriodEnd }: UnsubscribeProps) => {
    const t = useTranslator();
    const dispatch = useDispatch();
    const [isUnsubscribeModalVisible, setUnsubscribeModalVisibility] = useState<boolean>(false);

    const onUnsubscribe = useCallback(() => {
        dispatch(terminateProject.request({ id: projectId }));
        setUnsubscribeModalVisibility(false);
    }, [dispatch, projectId]);

    return (
        <UnsubsribeBlock>
            <UnsubscribeButton
                disabled={!editable}
                onClick={() => {
                    setUnsubscribeModalVisibility(true);
                }}
            >
                {t('hub-trial-project-detail-button-unsubscribe-project')}
            </UnsubscribeButton>
            <AvailableTillText>
                {t('hub-trial-project-detail-info-unsubscribe-project', {
                    payload: { DATE: formatDateTo(currentPeriodEnd) },
                })}
            </AvailableTillText>
            <DoYouReallyWantToUnsubscribe
                open={isUnsubscribeModalVisible}
                onClose={() => {
                    setUnsubscribeModalVisibility(false);
                }}
                onSubmit={onUnsubscribe}
            />
        </UnsubsribeBlock>
    );
};

export default Unsubscribe;
