import React, { useEffect } from 'react';
import { Redirect, RouteComponentProps, Router as ReachRouter } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { CenteredLoader } from 'components/CenteredLoader';
import billingInfoSelectors from 'modules/billing-info/selectors';
import projectListSelectors from 'modules/project/list/selectors';
import { paths, segments } from 'paths';
import { PaymentWizardForCreation } from './PaymentWizardForCreation';
import { PaymentWizardForEdit } from './PaymentWizardForEdit';
import { ProjectCreator, ProjectCreatorProps } from './Project/Create/ProjectCreator';
import { ProjectDetail } from './Project/Detail/ProjectDetail';
import useTranslator from '../hooks/useTranslator';
import { HubState } from 'types';
import { Project } from 'core/entities/project/Project';
import { NoProjectsFound } from 'views/Project/Empty/NoProjectsFound';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import { projectCreationInfoActions } from 'modules/creation-info/actions';

export type RouterProps = {
    domainValidator: (domain: string) => Promise<boolean>;
    domainNormalizer: (domain: string) => string;
};

const Router: React.FC<RouterProps> = (props) => {
    const isUserInTrial = useSelector(billingInfoSelectors.isUserInTrial);
    const isAuthModuleReady = useSelector((state: any) => state.auth.status === 'READY');

    return isAuthModuleReady ? (
        <ReachRouter>
            <ProjectDetail path={`${paths.project}/${segments.projectId}`} />
            <PaymentWizardForCreation path={`${paths.paymentWizard}/*`} />
            <CreateProject
                path={paths.createProject}
                inTrial={isUserInTrial}
                domainNormalizer={props.domainNormalizer}
                domainValidator={props.domainValidator}
            />

            {isUserInTrial ? (
                <Redirect from="*" to={paths.createProject} noThrow />
            ) : (
                <>
                    <PaymentWizardForEdit
                        path={`${paths.project}/${segments.projectId}${paths.paymentWizard}/*`}
                    />
                    <Projects path={paths.project} />
                    <Redirect from="*" to={paths.project} noThrow />
                </>
            )}
        </ReachRouter>
    ) : (
        <CenteredLoader />
    );
};

const Projects: React.FC<RouteComponentProps> = ({ navigate }) => {
    const projects = useSelector<HubState, Project[]>(projectListSelectors.selectProjects);

    return projects.length > 0 ? (
        <Redirect from="*" to={`${paths.project}/${projects[0].id}`} noThrow />
    ) : (
        <NoProjectsFound onClick={() => navigate!(paths.createProject)} />
    );
};

const CreateProject: React.FC<RouteComponentProps & Omit<ProjectCreatorProps, 'labels'>> = (
    props,
) => {
    const t = useTranslator();
    const dispatch = useDispatch();
    const { inTrial, domainNormalizer, domainValidator } = props;
    const labels = inTrial
        ? {
              title: t('hub-trial-project-title'),
              submit: t('hub-trial-project-primary-button-continue'),
              subtitle: t('hub-trial-project-subtitle'),
          }
        : {
              title: t('hub-new-project-title'),
              submit: t('hub-trial-project-primary-button-continue'),
              subtitle: t('hub-new-project-subtitle'),
          };
    const projectCreationInfoAreReady = useSelector<HubState, boolean>(
        projectCreationInfoSelectors.isReady,
    );

    useEffect(() => {
        dispatch(projectCreationInfoActions.request());
    }, [dispatch]);

    return projectCreationInfoAreReady ? (
        <ProjectCreator
            labels={labels}
            inTrial={inTrial}
            domainNormalizer={domainNormalizer}
            domainValidator={domainValidator}
        />
    ) : (
        <CenteredLoader />
    );
};

export default Router;
