import { ActionType, getType } from 'typesafe-actions';
import { ProjectUpdateState } from './types/types';
import projectUpdateActions from './actions';

export const projectUpdateReducer = (
    state: ProjectUpdateState = { status: 'INITIAL' },
    action: ActionType<typeof projectUpdateActions>,
): ProjectUpdateState => {
    switch (action.type) {
        case getType(projectUpdateActions.request.success): {
            return {
                ...state,
                data: undefined,
                status: 'SUCCESS',
            };
        }
        case getType(projectUpdateActions.request.request): {
            return {
                ...state,
                status: 'PENDING',
            };
        }
        case getType(projectUpdateActions.request.failure): {
            return {
                ...state,
                status: 'ERROR',
            };
        }
        case getType(projectUpdateActions.wizard.edit): {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload,
                },
                ui: {
                    ...state.ui,
                    wizard: {
                        status: 'PENDING',
                    },
                },
            };
        }
        case getType(projectUpdateActions.wizard.start):
            return {
                ...state,
                ui: {
                    ...state.ui,
                    wizard: {
                        status: 'PENDING',
                    },
                },
            };
        case getType(projectUpdateActions.wizard.end):
            return {
                ...state,
                data: undefined,
                ui: {
                    ...state.ui,
                    wizard: {
                        status: 'SUCCESS',
                    },
                },
            };
        case getType(projectUpdateActions.changePlan.start): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    changePlan: {
                        status: 'OPEN',
                    },
                },
            };
        }
        case getType(projectUpdateActions.changePlan.submit.request): {
            return {
                ...state,
                data: {
                    ...state.data,
                    id: action.payload.id,
                    priceId: action.payload.priceId,
                    planId: action.payload.planId,
                },
                ui: {
                    ...state.ui,
                    changePlan: {
                        status: 'PENDING',
                    },
                },
            };
        }
        case getType(projectUpdateActions.changePlan.submit.success): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    changePlan: {
                        status: 'SUCCESS',
                    },
                },
            };
        }
        case getType(projectUpdateActions.changePlan.submit.failure): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    changePlan: {
                        status: 'ERROR',
                    },
                },
            };
        }
        case getType(projectUpdateActions.changePlan.cancel): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    changePlan: {
                        status: 'INITIAL',
                    },
                },
            };
        }
        case getType(projectUpdateActions.coupon.showModal): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    coupon: {
                        status: 'OPEN',
                    },
                },
            };
        }
        case getType(projectUpdateActions.coupon.hideModal): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    coupon: {
                        status: 'CLOSED',
                    },
                },
            };
        }
        case getType(projectUpdateActions.changeSeats.start): {
            return {
                ...state,
                ui: {
                    ...state.ui,
                    changeSeats: {
                        status: 'PENDING',
                    },
                },
            };
        }
        default:
            return state;
    }
};
