import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './actions';
import { FetchInvoicesUseCase } from 'core/entities/invoice/FetchInvoicesUseCase';
import { InvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';
import { Invoices } from 'core/entities/invoice/Invoices';
import { addErrorToast } from 'modules/toasts/actions';
import loggingActions from 'modules/logger/actions';

function* handleRetrieveInvoices(
    action: ReturnType<typeof actions.retrieveInvoices.request>,
    useCase: FetchInvoicesUseCase,
    adaptToView: (invoices: Invoices) => InvoicesForView,
) {
    try {
        const { projectId, lastElementId } = action.payload;
        const res: Invoices = yield call([useCase, 'fetch'], projectId, lastElementId);
        const adaptedInvoices = adaptToView(res);

        yield put(actions.retrieveInvoices.success(adaptedInvoices));
    } catch (error) {
        yield put(actions.retrieveInvoices.failure(error));
    }
}

export function createInvoicesSaga(
    useCase: FetchInvoicesUseCase,
    adaptToView: (invoices: Invoices) => InvoicesForView,
) {
    return function* invoicesSaga() {
        yield takeLatest(actions.retrieveInvoices.request, (args) =>
            handleRetrieveInvoices(args, useCase, adaptToView),
        );
        yield takeLatest(actions.retrieveInvoices.failure, function* ({ payload }) {
            yield put(
                addErrorToast({
                    id: 'retrieve-invoices-error',
                    type: 'error',
                    label: 'hub-project-details-payment-history-error-toast-unable-to-fetch-info',
                    isAutoDismissEnabled: false,
                    actions: [
                        {
                            label: 'Close',
                        },
                    ],
                }),
            );

            yield put(
                loggingActions.error({
                    msg: 'Unable to retrieve invoices',
                    error: payload,
                }),
            );
        });
    };
}
