import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import profileSelectors from 'modules/profile/selectors';

const INTERCOM_APP_ID = 'mlx2ebvc';
const INTERCOM_SCRIPT_ID = 'intercom-script';

function Intercom() {
    const user = useSelector(profileSelectors.selectUser);
    const userId = user.id;
    const intercomScript = document.getElementById(INTERCOM_SCRIPT_ID);

    if (!intercomScript) {
        const scriptElement = document.createElement('script');

        scriptElement.type = 'text/javascript';
        scriptElement.id = INTERCOM_SCRIPT_ID;
        scriptElement.async = true;
        scriptElement.defer = true;
        scriptElement.innerHTML = `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;

        document.body.append(scriptElement);
    }

    useEffect(() => {
        if (userId) {
            // @ts-ignore
            window.Intercom('boot', {
                app_id: INTERCOM_APP_ID,
                ...user,
            });
        }
    }, [user, userId]);

    return null;
}

export default Intercom;
