import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { invoicePreviewActions } from './actions';
import { InvoicePreviewRequest } from './types/dto';
import { InvoicePreview } from './types/types';
import { addErrorToast } from '../toasts/actions';
import { InvoicePreviewForView } from './types/views';

import { toInvoicePreviewForView } from './adapters/toInvoicePreviewForView';
import { removeUndefinedValuesFrom } from 'utils/removeUndefinedaValueFrom';
import loggingActions from 'modules/logger/actions';

interface InvoicePreviewSagaParams {
    api: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>;
}

export function invoicePreviewSaga({ api }: InvoicePreviewSagaParams) {
    return function* () {
        yield takeLatest(
            invoicePreviewActions.fetch.request,
            function* (action: ReturnType<typeof invoicePreviewActions.fetch.request>) {
                try {
                    const { coupon, seats, projectId, priceId } = action.payload.data;
                    const { onSuccess = () => {} } = action.payload;

                    let dto: InvoicePreviewRequest = removeUndefinedValuesFrom<InvoicePreviewRequest>(
                        {
                            project_id: projectId,
                            coupon,
                            number_of_seats: seats,
                            price_id: priceId,
                        },
                    );

                    const invoicePreview: InvoicePreview = yield call(api, dto);
                    const invoicePreviewForView: InvoicePreviewForView = toInvoicePreviewForView(
                        invoicePreview,
                    ); // TODO: injectable

                    yield put(invoicePreviewActions.fetch.success(invoicePreviewForView));
                    yield call(onSuccess);
                } catch (e) {
                    yield put(invoicePreviewActions.fetch.failure(e));
                }
            },
        );

        yield takeEvery(
            invoicePreviewActions.fetch.failure,
            function* ({ payload }: ReturnType<typeof invoicePreviewActions.fetch.failure>) {
                yield addErrorToast({
                    actions: [],
                    isAutoDismissEnabled: false,
                    type: 'error',
                    id: 'error-add-coupon',
                    label: 'Error applying coupon',
                });

                yield put(
                    loggingActions.error({
                        msg: 'Unable to fetch invoice preview',
                        error: payload,
                    }),
                );
            },
        );
    };
}
