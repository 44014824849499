export const vatIdTypes = [
    {
        label: 'United Arab Emirates TRN',
        value: 'ae_trn',
        country: 'AE',
        format: '123456789012345',
    },
    {
        label: 'Australian Business Number (AU ABN)',
        value: 'au_abn',
        country: 'AU',
        format: '12345678912',
    },
    {
        label: 'Brazilian CNPJ number',
        value: 'br_cnpj',
        country: 'BR',
        format: '01.234.456/5432-10',
    },
    { label: 'Brazilian CPF number', value: 'br_cpf', country: 'BR', format: '123.456.789-87' },
    { label: 'Canadian BN', value: 'ca_bn', country: 'CA', format: '123456789' },
    { label: 'Canadian QST number', value: 'ca_qst', country: 'CA', format: '1234567890TQ1234' },
    {
        label: 'Switzerland VAT number',
        value: 'ch_vat',
        country: 'CH',
        format: 'CHE-123.456.789 MWST',
    },
    { label: 'Chilean TIN', value: 'cl_tin', country: 'CL', format: '12.345.678-K' },
    { label: 'Spanish CIF number', value: 'es_cif', country: 'ES', format: 'A12345678' },
    { label: 'Austria VAT number', value: 'eu_vat', country: 'AT', format: 'ATU12345678' },
    { label: 'Belgium VAT number', value: 'eu_vat', country: 'BE', format: 'BE0123456789' },
    { label: 'Bulgaria VAT number', value: 'eu_vat', country: 'BG', format: 'BG0123456789' },
    { label: 'Cyprus VAT number', value: 'eu_vat', country: 'CY', format: 'CY12345678Z' },
    { label: 'Czech Republic VAT number', value: 'eu_vat', country: 'CZ', format: 'CZ1234567890' },
    { label: 'Germany VAT number', value: 'eu_vat', country: 'DE', format: 'DE123456789' },
    { label: 'Denmark VAT number', value: 'eu_vat', country: 'DK', format: 'DK12345678' },
    { label: 'Estonia VAT number', value: 'eu_vat', country: 'EE', format: 'EE123456789' },
    { label: 'Spain VAT number', value: 'eu_vat', country: 'ES', format: 'ESA1234567Z' },
    { label: 'Finland VAT number', value: 'eu_vat', country: 'FI', format: 'FI12345678' },
    { label: 'France VAT number', value: 'eu_vat', country: 'FR', format: 'FRAB123456789' },
    { label: 'United Kingdom VAT number', value: 'eu_vat', country: 'GB', format: 'GB123456789' },
    { label: 'Greece VAT number', value: 'eu_vat', country: 'EL', format: 'EL123456789' },
    { label: 'Croatia VAT number', value: 'eu_vat', country: 'HR', format: 'HR12345678912' },
    { label: 'Hungary VAT number', value: 'eu_vat', country: 'HU', format: 'HU12345678912' },
    { label: 'Ireland VAT number', value: 'eu_vat', country: 'IE', format: 'IE1234567AB' },
    { label: 'Italy VAT number', value: 'eu_vat', country: 'IT', format: 'IT12345678912' },
    { label: 'Lithuania VAT number', value: 'eu_vat', country: 'LT', format: 'LT123456789123' },
    { label: 'Luxembourg VAT number', value: 'eu_vat', country: 'LU', format: 'LU12345678' },
    { label: 'Latvia VAT number', value: 'eu_vat', country: 'LV', format: 'LV12345678912' },
    { label: 'Malta VAT number', value: 'eu_vat', country: 'MT', format: 'MT12345678' },
    { label: 'Netherlands VAT number', value: 'eu_vat', country: 'NL', format: 'NL123456789B12' },
    { label: 'Poland VAT number', value: 'eu_vat', country: 'PL', format: 'PL1234567890' },
    { label: 'Portugal VAT number', value: 'eu_vat', country: 'PT', format: 'PT123456789' },
    { label: 'Romania VAT number', value: 'eu_vat', country: 'RO', format: 'RO1234567891' },
    { label: 'Sweden VAT number', value: 'eu_vat', country: 'SE', format: 'SE123456789123' },
    { label: 'Slovenia VAT number', value: 'eu_vat', country: 'SI', format: 'SI12345678' },
    { label: 'Slovakia VAT number', value: 'eu_vat', country: 'SK', format: 'SK1234567891' },
    { label: 'Hong Kong BR number', value: 'hk_br', country: 'HK', format: '12345678' },
    {
        label: 'Indonesian NPWP number',
        value: 'id_npwp',
        country: 'ID',
        format: '12.345.678.9-012.345',
    },
    { label: 'Indian GST number', value: 'in_gst', country: 'IN', format: '12ABCDE3456FGZH' },
    {
        label: 'Japanese Corporate Number (Hōjin Bangō)',
        value: 'jp_cn',
        country: 'JP',
        format: '1234567891234',
    },
    {
        label:
            'Japanese Registered Foreign Businesses’ Registration Number (Tōroku Kokugai Jigyōsha no Tōroku Bangō)',
        value: 'jp_rn',
        country: 'JP',
        format: '12345',
    },
    { label: 'Korean BRN', value: 'kr_brn', country: 'KR', format: '123-45-67890' },
    {
        label: 'Liechtensteinian UID number',
        value: 'li_uid',
        country: 'LI',
        format: 'CHE123456789',
    },
    { label: 'Mexican RFC number', value: 'mx_rfc', country: 'MX', format: 'ABC010203AB9' },
    { label: 'Malaysian FRP number', value: 'my_frp', country: 'MY', format: '12345678' },
    { label: 'Malaysian ITN', value: 'my_itn', country: 'MY', format: 'C 1234567890' },
    { label: 'Malaysian SST number', value: 'my_sst', country: 'MY', format: 'A12-3456-78912345' },
    { label: 'Norwegian VAT number', value: 'no_vat', country: 'NO', format: '123456789MVA' },
    { label: 'New Zealand GST number', value: 'nz_gst', country: 'NZ', format: '123456789' },
    { label: 'Russian INN', value: 'ru_inn', country: 'RU', format: '1234567891' },
    { label: 'Russian KPP', value: 'ru_kpp', country: 'RU', format: '123456789' },
    { label: 'Saudi Arabia VAT', value: 'sa_vat', country: 'SA', format: '123456789012345' },
    { label: 'Singaporean GST', value: 'sg_gst', country: 'SG', format: 'M12345678X' },
    { label: 'Singaporean UEN', value: 'sg_uen', country: 'SG', format: '123456789F' },
    { label: 'Thai VAT', value: 'th_vat', country: 'TH', format: '1234567891234' },
    { label: 'Taiwanese VAT', value: 'tw_vat', country: 'TW', format: '12345678' },
    { label: 'United States EIN', value: 'us_ein', country: 'US', format: '12-3456789' },
    { label: 'South African VAT number', value: 'za_vat', country: 'ZA', format: '4123456789' },
];
