import React from 'react';
import styled from 'styled-components';
import { Left, Right } from '../elements';

const LeftInner = styled.div`
    margin-top: 40px;
    width: 100%;
    max-width: 960px;
`;

const RightInner = styled.div`
    display: flex;
    margin-top: 96px;
    max-width: 444px;
    width: 100%;
`;

export interface GenericStepProps<T> {
    left: React.ReactElement;
    right: React.ReactElement;
    onSubmit?: (data: T) => void;
}

const StepContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

export const GenericStep: React.FC<GenericStepProps<unknown>> = ({ left, right }) => (
    <StepContainer>
        <Left>
            <LeftInner>{left}</LeftInner>
        </Left>
        <Right>
            <RightInner>{right}</RightInner>
        </Right>
    </StepContainer>
);
