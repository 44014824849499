import React from 'react';
import { useSelector } from 'react-redux';

import {
    TenantUsageLimitModal,
    TenantUsageLimitPlan,
} from '../TenantUsageLimit/TenantUsageLimitModal';
import tenantUsageSelectors from 'modules/tenant-usage/selectors';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import { HubState } from 'types';
import { TenantLimits } from 'modules/tenant-usage/types/types';
import useTranslator from 'hooks/useTranslator';
import { ProjectDetailForView } from 'modules/project/detail/types';

interface Props {
    project: ProjectDetailForView;
}

export const PlanLimitModal: React.FC<Props> = (props) => {
    const { project } = props;

    const t = useTranslator();
    const tenantUsageLimitOpen = useSelector(tenantUsageSelectors.isModalOpen);
    const nextPlanId = useSelector(tenantUsageSelectors.selectNextPlanId);
    const nextPlan = useSelector(projectCreationInfoSelectors.selectPlanById(nextPlanId));
    const diff = useSelector<HubState, TenantLimits>(tenantUsageSelectors.selectDifferences);
    const currentUsages = useSelector<HubState, TenantLimits>(tenantUsageSelectors.selectUsages);

    const currentPlan: TenantUsageLimitPlan = {
        title: t('hub-limitation-dialogue-label-current-plan', {
            payload: { PLAN: project.subscription.price.plan.name },
        }),
        features: {
            languages: {
                title: t('hub-limitation-dialogue-card-label-current-languages', {
                    payload: { NUMBER: currentUsages.languages },
                }),
            },
            topics: {
                title: t('hub-limitation-dialogue-card-label-current-topic', {
                    payload: { NUMBER: currentUsages.topics },
                }),
                subtitle: t('hub-limitation-dialogue-card-label-topic-status-published'),
            },
            dataSources: {
                title: t('hub-limitation-dialogue-card-label-current-data-source', {
                    payload: { NUMBER: currentUsages.dataSources },
                }),
                subtitle: t('hub-limitation-dialogue-card-label-data-source-status-connected'),
            },
        },
    };
    const requestedPlan: TenantUsageLimitPlan = {
        title: t('hub-limitation-dialogue-hub-limitation-dialogue-label-requested-plan', {
            payload: { PLAN: nextPlan?.name },
        }),
        features: {
            languages: {
                title: t('hub-limitation-dialogue-card-label-requested-languages', {
                    payload: { NUMBER: nextPlan?.features.languages },
                }),
                subtitle: t('hub-limitation-dialogue-card-label-requested-languages-to-remove', {
                    payload: { NUMBER: diff?.languages },
                }),
                diff: diff?.languages,
            },
            topics: {
                title: t('hub-limitation-dialogue-card-label-requested-topics', {
                    payload: { NUMBER: nextPlan?.features.topics },
                }),
                subtitle: t('hub-limitation-dialogue-card-label-requested-topics-to-unpublish', {
                    payload: { NUMBER: diff?.topics },
                }),
                diff: diff?.topics,
            },
            dataSources: {
                title: t('hub-limitation-dialogue-card-label-requested-data-source', {
                    payload: { NUMBER: nextPlan?.features.dataSources },
                }),
                subtitle: t(
                    'hub-limitation-dialogue-card-label-requested-data-source-to-disconnect',
                    { payload: { NUMBER: diff?.dataSources } },
                ),
                diff: diff?.dataSources,
            },
        },
    };

    return nextPlanId ? (
        <TenantUsageLimitModal
            title={t('hub-limitation-dialogue-title')}
            summary={t('hub-limitation-dialogue-message')}
            project={project}
            open={tenantUsageLimitOpen}
            currentPlan={currentPlan}
            requestedPlan={requestedPlan}
        />
    ) : null;
};
