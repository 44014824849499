import reducer from './reducer';
import saga from './saga';
import { ISagaModule } from 'redux-dynamic-modules-saga';

export const getToastsModule = (): ISagaModule<any> => ({
    id: 'toasts',
    reducerMap: {
        toasts: reducer,
    } as any,
    sagas: [saga],
});
