import { ActionType, getType } from 'typesafe-actions';
import projectListActions from './actions';
import { ProjectListState } from './types';

export const projectListReducer = (
    state: ProjectListState = { status: 'INITIAL' },
    action: ActionType<typeof projectListActions>,
): ProjectListState => {
    switch (action.type) {
        case getType(projectListActions.projectListInit.success):
            return {
                ...state,
                status: 'READY',
            };
        case getType(projectListActions.projectListInit.failure):
            return { ...state, status: 'ERROR' };
        case getType(projectListActions.projectListInit.request):
            return { ...state, status: 'PENDING' };

        case getType(projectListActions.projectListFetch.success):
            return {
                ...state,
                projects: action.payload,
            };
        default:
            return state;
    }
};
