import styled, { css } from 'styled-components';
// @ts-ignore
import Item from '@igenius_dev/crystalkit/lib/components/Item';
// @ts-ignore
import ItemList from '@igenius_dev/crystalkit/lib/components/ItemList';
import Text from '@igenius_dev/crystalkit/lib/components/Text';

import theme from '@igenius_dev/crystalkit/lib/theme';

export const User = styled(Text).attrs({
    color: 'black500',
})`
    white-space: nowrap;
    font-weight: ${theme.typography.weights.values.medium};
`;

export const ItemListContent = styled(ItemList)`
    &,
    li {
        width: 100%;
        overflow: hidden;
    }
    li {
        padding: 8px 16px;
    }
`;

export const StyledItem = styled(Item)<{ selected: boolean }>`
    width: 100%;
    min-height: 60px;

    > div {
        overflow: hidden;
    }

    ${(props) =>
        props.selected
            ? css`
                  background-color: ${theme.colors.values.blue15};
                  border-left: 4px solid ${theme.colors.values.purple700};
              `
            : css``}
`;

export const ItemCenter = styled.div`
    display: flex;
    flex-direction: column;
`;

export const CustomAvatar = styled.div<{ bg: string }>`
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${({ bg }) => bg};
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    align-items: center;
    height: 100%;
`;
