import { api } from '../../../paths';
import { TenantUsageDto } from '../types/dto';
import { TenantUsage, TenantUsageRetriever } from '../types/types';

export const fetchTenantUsage: TenantUsageRetriever = (client) => async (id) => {
    const url = [api.tenantUsage, id, 'tenant-usage'].join('/');
    const response = await client.get<TenantUsageDto>(url);

    return adaptToDomain(response);
};

const adaptToDomain = (data: TenantUsageDto): TenantUsage => {
    const { limits, usages } = data;
    const { datasources: limitsDataSources, ...restOfLimits } = limits;
    const { datasources: usagesDataSources, ...restOfUsages } = usages;

    return {
        limits: {
            dataSources: limitsDataSources,
            ...restOfLimits,
        },
        usages: {
            dataSources: usagesDataSources,
            ...restOfUsages,
        },
    };
};
