// @ts-ignore
import NumberFormatter from '@igenius_dev/igenius-number';

import { Price, TimeInterval } from '../../../core/entities/Price';
import { PriceForView } from '../types';

export type PriceForViewAdapter = (prices: Price[], fractionDigits?: number) => PriceForView[];

export const adaptToPricesForView = (prices: Price[]): PriceForView[] => {
    return prices.map((price) => {
        return {
            ...price,
            amount: {
                withDigits: formatPriceWithDigits(price, 2),
                withoutDigits: formatPriceWithDigits(price, 0),
            },
        };
    });
};

const formatPriceWithDigits = (price: Price, digits: number): string =>
    NumberFormatter.formatCurrency({
        n: planPriceBasedOn(price.amount, price.timeInterval),
        options: {
            currency: price.currency,
            maximumFractionDigits: digits,
            minimumFractionDigits: digits,
        },
    });

const planPriceBasedOn = (amount: number, timeInterval: TimeInterval): number => {
    if (!amount) {
        return 0;
    }

    if (timeInterval === 'year') {
        return amount / 100 / 12;
    }

    return amount / 100;
};
