import { isEmpty, omit } from 'lodash';
import { getType } from "typesafe-actions";
import { addToEntity, removeFromEntity } from 'utils/normalize';
import * as toastsActions from '../actions';
import { ToastsActions } from '../actions';

const getToastFromPayload = (payload: any) => omit(payload, 'actionsSideEffects');

/**
 *
 * @type {ToastsState}
 */
export const initialState = {
    entities: {
        toast: {
            allIds: [],
            byId: {},
        },
    },
};

const onAddToast = (state: any, payload: any = {}) => {
    const { id: toastId } = payload;
    if (isEmpty(payload) || !toastId) {
        return state;
    }

    return {
        ...state,
        entities: {
            toast: addToEntity({
                entity: state.entities.toast,
                item: getToastFromPayload(payload),
            }),
        },
    };
};

const onRemoveToast = (state: any, toastId: any) => ({
    ...state,
    entities: {
        toast: removeFromEntity({
            entity: state.entities.toast,
            idVal: toastId,
        }),
    },
});

export default function reducer(state = initialState, action: ToastsActions) {
    switch (action.type) {
        case getType(toastsActions.addErrorToast):
        case getType(toastsActions.addSuccessToast):
        case getType(toastsActions.addWarningToast):
        case getType(toastsActions.addInfoToast):
            return onAddToast(state, action.payload);
        case getType(toastsActions.removeToast):
            return onRemoveToast(state, action.payload);
        default:
            return state;
    }
}
