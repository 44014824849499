export const formatDateTo = (ts: number): string => {
    const d = new Date(ts);

    if (Number.isNaN(d.getTime())) {
        return '';
    }

    const dd = d.getDate();
    const mm = d.getMonth() + 1;
    const yyyy = d.getFullYear();

    return dd + '/' + mm + '/' + yyyy;
};
