import { CreationInfoUseCase } from '../../entities/creation-info/CreationInfoUseCase';
import { CreationInfoGetter } from '../../entities/creation-info/CreationInfoGetter';
import { CreationInfo } from '../../entities/creation-info/CreationInfo';

export class HttpCreationInfoUseCase implements CreationInfoUseCase {
    constructor(private readonly projectCreationInfo: CreationInfoGetter) {}

    async fetch(): Promise<CreationInfo> {
        return this.projectCreationInfo();
    }
}
