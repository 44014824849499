export const removeUndefinedValuesFrom = <T>(o: Record<string, any>) =>
    Object.keys(o).reduce((prev: Record<string, any>, nextKey: string) => {
        const value = o[nextKey];
        const isNumber = typeof value === 'number';
        const valueIsUndefined = !isNumber
            ? typeof value === 'undefined' || Object.keys(value).length === 0
            : false;

        return valueIsUndefined
            ? prev
            : {
                  ...prev,
                  [nextKey]: value,
              };
    }, {}) as T;
