import React, { useCallback, useState } from 'react';
import { PropertyChangeDialog } from '../../../PropertyChangeDialog';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import styled from 'styled-components';

import theme from '@igenius_dev/crystalkit/lib/theme';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import { PaymentMethodsList } from './PaymentMethodsList';
import { AddPaymentButton } from './AddPaymentButton';
import { AddPaymentMethodModal } from '../../../../PaymentWizard/Details/PaymentMethod/Modal/AddPaymentMethodModal';
import { paymentMethodsActions } from '../../../../../modules/payment-methods/actions';
import { useDispatch } from 'react-redux';
import useTranslator from '../../../../../hooks/useTranslator';
import { PaymentMethod } from '../../../../../core/entities/payment-method/PaymentMethod';

const Title = styled(Text).attrs({
    scale: 'Large',
})`
    display: block;
`;

const Subtitle = styled(Text).attrs({
    scale: 'Small',
})`
    margin-top: 8px;
    color: ${theme.colors.values.black300};
`;

const Content = styled.div`
    width: 100%;
    margin: 40px 0;
`;

interface Props {
    defaultPaymentMethodId: string;
    paymentMethods: PaymentMethod[];
    onSave: (data: any) => void;
    onCancel: () => void;
    onClose: () => void;
    open: boolean;
}

export type ChangePaymentMethodFormData = { paymentMethod: string };

export const ChangePaymentMethod = ({
    paymentMethods,
    onCancel,
    onSave,
    open,
    onClose,
    defaultPaymentMethodId,
}: Props) => {
    const [addPaymentMethodModalIsOpen, setPaymentMethodModalIsOpen] = useState<boolean>(false);
    const t = useTranslator();
    const formProps = useForm<ChangePaymentMethodFormData>({
        mode: 'onChange',
    });
    const dispatch = useDispatch();
    const closePaymentMethodModal = useCallback(() => setPaymentMethodModalIsOpen(false), [
        setPaymentMethodModalIsOpen,
    ]);
    const openPaymentMethodModal = useCallback(() => setPaymentMethodModalIsOpen(true), [
        setPaymentMethodModalIsOpen,
    ]);

    return (
        <FormProvider {...formProps}>
            <PropertyChangeDialog
                actionText={{
                    ok: t('hub-dialogue-change-credit-button-save'),
                    cancel: t('hub-dialogue-change-credit-button-cancel'),
                }}
                open={open}
                content={
                    <>
                        <Title>{t('hub-dialogue-change-credit-card-title')}</Title>
                        <Subtitle>{t('hub-dialogue-change-credit-card-subtitle')}</Subtitle>
                        <Content>
                            <Controller
                                rules={{ required: true }}
                                defaultValue={defaultPaymentMethodId}
                                control={formProps.control}
                                name={'paymentMethod'}
                                render={({ value, onChange }) => (
                                    <PaymentMethodsList
                                        value={value}
                                        methods={paymentMethods}
                                        onChange={onChange}
                                    />
                                )}
                            />
                            <AddPaymentButton
                                onClick={openPaymentMethodModal}
                                label={t('hub-dialogue-change-credit-textlink-add-new-card')}
                            />
                        </Content>

                        <AddPaymentMethodModal
                            open={addPaymentMethodModalIsOpen}
                            onClose={closePaymentMethodModal}
                            onSubmit={({ stripe, card, onSuccess }) => {
                                dispatch(
                                    paymentMethodsActions.add.request({
                                        stripe,
                                        card,
                                        onSuccess: onSuccess
                                            ? () => {
                                                  onSuccess();
                                                  closePaymentMethodModal();
                                              }
                                            : closePaymentMethodModal,
                                    }),
                                );
                            }}
                        />
                    </>
                }
                onSave={onSave}
                onCancel={onCancel}
                onClose={onClose}
            />
        </FormProvider>
    );
};
