import { ActionType, getType } from 'typesafe-actions';
import { paymentMethodsActions } from './actions';
import { PaymentMethodsStatus } from './types/types';
import { combineReducers } from 'redux';
import { SetupIntent } from '@stripe/stripe-js';
import { PaymentMethod } from '../../core/entities/payment-method/PaymentMethod';

export const paymentMethodsRetrieveReducer = (
    retrieveStatus: PaymentMethodsStatus = PaymentMethodsStatus.INITIAL,
    action: ActionType<typeof paymentMethodsActions.retrieve>,
) => {
    switch (action.type) {
        case getType(paymentMethodsActions.retrieve.success):
            return PaymentMethodsStatus.SUCCESSFUL;
        case getType(paymentMethodsActions.retrieve.failure):
            return PaymentMethodsStatus.FAILURE;
        case getType(paymentMethodsActions.retrieve.request):
            return PaymentMethodsStatus.PENDING;
        default:
            return retrieveStatus;
    }
};

export const methodsReducer = (
    methods: PaymentMethod[] = [],
    action: ActionType<typeof paymentMethodsActions.retrieve>,
) => {
    switch (action.type) {
        case getType(paymentMethodsActions.retrieve.success): {
            return action.payload;
        }
        default: {
            return methods;
        }
    }
};

export const paymentMethodsAddReducer = (
    addStatus: PaymentMethodsStatus = PaymentMethodsStatus.INITIAL,
    action: ActionType<typeof paymentMethodsActions.add>,
) => {
    switch (action.type) {
        case getType(paymentMethodsActions.add.request): {
            return PaymentMethodsStatus.PENDING;
        }
        case getType(paymentMethodsActions.add.success): {
            return PaymentMethodsStatus.SUCCESSFUL;
        }
        case getType(paymentMethodsActions.add.failure): {
            return PaymentMethodsStatus.FAILURE;
        }

        default:
            return addStatus;
    }
};

function intentReducer(
    intent: SetupIntent | null = null,
    action: ActionType<typeof paymentMethodsActions.add>,
) {
    switch (action.type) {
        case getType(paymentMethodsActions.add.success):
            return action.payload.intent;
        case getType(paymentMethodsActions.add.failure):
            return null;
        default:
            return intent;
    }
}

export const statusReducer = combineReducers({
    retrieve: paymentMethodsRetrieveReducer,
    add: paymentMethodsAddReducer,
});

export const reducer = combineReducers({
    methods: methodsReducer,
    status: statusReducer,
    intent: intentReducer,
});
