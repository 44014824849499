import React, { useState } from 'react';
import {
    Information,
    StyledDescription,
    TopText,
    TriggerIcon,
} from '../views/Project/Create/Plan/elements';

type AccordionProps = {
    title: React.ReactElement | string;
    subtitle?: React.ReactElement | string;
    text: React.ReactElement | string;
};

export const Accordion = (props: AccordionProps) => {
    const { title, text, subtitle } = props;

    const [icon, setIcon] = useState<'expand_more' | 'expand_less'>('expand_more');

    return (
        <StyledDescription>
            <TopText>
                {title}

                <TriggerIcon
                    data-testid="accordion-toggle"
                    icon={icon}
                    onClick={() => setIcon(icon === 'expand_more' ? 'expand_less' : 'expand_more')}
                />
            </TopText>
            {subtitle && <Information dangerouslySetInnerHTML={{ __html: subtitle }} />}
            {icon === 'expand_less' && text}
        </StyledDescription>
    );
};
