import { HubState } from 'types';
import { ProjectCreationStatus } from './types';
import { DEFAULT_SEATS_NUMBER } from 'utils/defaultConfig';
import { TimeInterval } from 'core/entities/Price';
import { Address, TaxId } from 'common/types';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';
import { PlanForView } from 'modules/creation-info/types';

const selectProjectCreationData = (state: HubState) => state.projectCreation?.project;

const selectInvoiceDetailsAddress = (state: HubState): Address | undefined =>
    state.projectCreation?.project?.invoice?.address;

const selectInvoiceDetailsTaxId = (state: HubState): TaxId | undefined =>
    state.projectCreation?.project?.invoice?.taxId;

const selectInvoiceType = (state: HubState): FormType | undefined =>
    state.projectCreation?.project?.formType;

const selectNumberOfSeats = (state: HubState): number =>
    selectProjectCreationData(state)?.seats ?? DEFAULT_SEATS_NUMBER;

const selectBillingCycle = (state: HubState): TimeInterval =>
    state.projectCreation?.project?.billingCycle ?? 'month';

const selectActivePlanId = (state: HubState) => state.projectCreation?.project?.planId;

const selectActivePlan = (state: HubState): PlanForView | undefined => {
    const planId = selectActivePlanId(state);

    return state.creationInfo?.info?.plans.find((plan: PlanForView) => plan.id === planId);
};

export const selectProjectCreationStatus = (state: HubState) => {
    return state.projectCreation?.status ?? ProjectCreationStatus.Initial;
};

const selectActiveSubscriptionId = (state: HubState) => state.projectCreation?.project?.priceId;

const isSubmittingCreation = (state: HubState) =>
    selectProjectCreationStatus(state) === ProjectCreationStatus.Pending;

const selectCoupon = (state: HubState) => state.projectCreation?.project?.coupon;

export const projectCreationSelectors = {
    selectNumberOfSeats,
    selectProjectCreationData,
    selectActiveSubscriptionId,
    selectBillingCycle,
    selectActivePlanId,
    selectActivePlan,
    selectInvoiceDetailsAddress,
    selectInvoiceDetailsTaxId,
    selectInvoiceType,
    isSubmittingCreation,
    selectCoupon,
};
