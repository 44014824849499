import { CountryFX, LabelValuePair } from 'common/types';
import { Countries } from 'common/utils/Countries';

export class FixtureCountries implements Countries {
    private readonly map: Map<string, string>;

    constructor(fx: CountryFX[]) {
        this.map = new Map(fx.map((country) => [country['alpha-2'], country.label]));
    }

    getBy(id: string): LabelValuePair {
        const label = this.map.get(id);

        if (label) {
            return { label, value: id };
        }

        throw new Error('Unable to find country for ' + id);
    }

    list(): LabelValuePair[] {
        return Array.from(this.map.entries()).map(([value, label]) => ({ label, value }));
    }
}
