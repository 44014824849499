export const timezones = [
    { value: 'US East', label: 'US East' },
    { value: 'US West', label: 'US West' },
    { value: 'Africa', label: 'Africa' },
    { value: 'Asia Pacific', label: 'Asia Pacific' },
    { value: 'Canada', label: 'Canada' },
    { value: 'China', label: 'China' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Middle East', label: 'Middle East' },
    { value: 'South America', label: 'South America' },
];
