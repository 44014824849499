import { ISagaModule } from 'redux-dynamic-modules-saga';

import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { createProjectTerminationSaga } from 'modules/project/terminate/saga';
import { ProjectTerminationModuleState } from 'modules/project/terminate/types';
import { projectTerminationReducer as reducer } from 'modules/project/terminate/reducer';

export const getProjectTerminationModule = (
    repository: ProjectRepository,
): ISagaModule<ProjectTerminationModuleState> => ({
    id: 'projectTermination',
    reducerMap: { projectTermination: reducer as any },
    sagas: [createProjectTerminationSaga(repository)],
});
