import { HttpClient } from '../../../services/HttpClient';
import { ProjectId } from '../../../core/entities/project/Project';

export type ErrorMessage = string;

export interface TenantLimits {
    users: number;
    dataSources: number;
    topics: number;
    languages: number;
}

export type TenantUsage = {
    limits: TenantLimits;
    usages: TenantLimits;
};

export enum TenantUsageStatus {
    PENDING = 'PENDING',
    INITIAL = 'INITIAL',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export enum TenantUsageModalStatus {
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
}

export type TenantLimitsPriceComparison = {
    [K in keyof TenantLimits]?: {
        current: string;
        requested: string;
    };
};

export interface TenantUsageState {
    status: TenantUsageStatus;
    data?: TenantUsage;
    ui: {
        status: TenantUsageModalStatus;
        data?: {
            diff: TenantLimits;
            nextPlanId?: string;
            nextSeats?: number;
            prices?: TenantLimitsPriceComparison;
        };
    };
}

export type TenantUsageModuleState = {
    tenantUsage?: TenantUsageState;
};

export type TenantUsageRetriever = (client: HttpClient) => (id: ProjectId) => Promise<TenantUsage>;
