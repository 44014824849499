import { createNotificationsSaga } from './saga';
import { notificationsReducer } from './reducer';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { NotificationsModuleState } from './types';
import { NotificationRepository } from '../../core/repository/notifications/NotificationRepository';

export const getNotificationsModule = (
    repository: NotificationRepository,
): ISagaModule<NotificationsModuleState> => ({
    id: 'notifications',
    reducerMap: { notifications: notificationsReducer } as any,
    sagas: [createNotificationsSaga(repository)],
});
