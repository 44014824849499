import { format } from 'date-fns';
import { Invoice } from 'core/entities/invoice/Invoice';
import { Invoices } from 'core/entities/invoice/Invoices';

export interface InvoiceForView extends Omit<Invoice, 'createdAt' | 'paid'> {
    createdAt: string;
    color: 'purple' | 'red';
}

export interface InvoicesForView extends Omit<Invoices, 'invoices'> {
    invoices: InvoiceForView[];
}

export const toInvoicesForView = (invoices: Invoices): InvoicesForView => {
    const { pagination, invoices: data } = invoices;

    return {
        pagination: pagination,
        invoices: data.map((i) => ({
            ...i,
            createdAt: format(i.createdAt, 'dd/MM/yyyy'),
            status: i.paid
                ? 'hub-project-details-payment-history-chip-status-paid'
                : 'hub-project-details-payment-history-chip-status-pending',
            color: i.paid ? 'purple' : 'red',
        })),
    };
};
