import { ISagaModule } from 'redux-dynamic-modules-saga';
import { PaymentWizardModuleState } from './types';
import { paymentWizardSaga } from './saga';
import { paymentWizardReducer } from './reducers';

export const getPaymentWizardModule = (): ISagaModule<PaymentWizardModuleState> => ({
    id: 'paymentWizard',
    reducerMap: { paymentWizard: paymentWizardReducer },
    sagas: [paymentWizardSaga],
});
