import { ISagaModule } from 'redux-dynamic-modules-saga';
import { createUserNotificationSaga } from './saga';
import { UserNotifications, UserNotificationsModuleState } from './types';

export const getUserNotificationsModule = (
    fetchNotifications: () => Promise<UserNotifications>,
): ISagaModule<UserNotificationsModuleState> => ({
    id: 'userNotifications',
    sagas: [createUserNotificationSaga(fetchNotifications)],
});
