import { Logger } from '@igenius_dev/igenius-js-logger/dist/Logger/Logger';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { loggingSaga } from 'modules/logger/saga';
import { createPayloadExtractor } from 'modules/logger/payloadExtractor';

export interface LoggingModuleState {
    logging: {};
}

export const getLoggingModule = (logger: Logger): ISagaModule<LoggingModuleState> => {
    return {
        id: 'logging',
        sagas: [
            {
                saga: loggingSaga,
                argument: {
                    logger,
                    errorPayloadExtractor: createPayloadExtractor(),
                },
            },
        ],
    };
};
