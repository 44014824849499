import {
    BillingDetailsUpdater,
    UpdateBillingDetails,
} from '../../entities/billing-details/UpdateBillingDetails';
import { BillingDetailsFormData } from '../../../views/Project/Detail/Detail/FormEditBillingDetails/types';
import { BillingInfo } from '../../entities/billing-details/BillingInfo';

export class EditBillingDetailsUseCase implements UpdateBillingDetails {
    constructor(private readonly updater: BillingDetailsUpdater) {}

    async update(data: BillingDetailsFormData): Promise<BillingInfo> {
        try {
            return await this.updater(data);
        } catch (error) {
            throw new Error('failed to update billing details');
        }
    }
}
