import { IModule } from 'redux-dynamic-modules';
import { configReducer } from './reducer';
import { setConfig } from './actions';
import { Config, ConfigModuleState } from './types';

export const getConfigModule = (config: Config): IModule<ConfigModuleState> => ({
    id: 'config',
    reducerMap: { config: configReducer },
    initialActions: [setConfig(config)],
});
