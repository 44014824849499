import { Plan } from 'core/entities/Price';
import { ProjectDetail } from '../../../core/entities/project/ProjectDetail';
import { Subscription } from '../../../core/entities/project/Subscription';
import { PriceForView } from '../../creation-info/types';
import { CouponForView } from '../../invoice/types/views';

export type ProjectDetailState = {
    status: ProjectDetailStatus;
    paymentMethodModal: DialogStatus;
    billingDetailsModal: DialogStatus;
    invoicesSheet: DialogStatus;
};

type DialogStatus = 'OPEN' | 'CLOSED';

export type ProjectDetailModuleState = {
    projectDetail: Record<string, ProjectDetailForView> & ProjectDetailState;
};

export enum ProjectDetailStatus {
    Initial = 'INITIAL',
    Pending = 'PENDING',
    Succeeded = 'OK',
    Failed = 'KO',
}

export type SubscriptionForView = Omit<Subscription, 'price' | 'coupon'> & {
    price: Plan & PriceForView;
    coupon?: CouponForView;
};

export interface ProjectDetailForView extends Omit<ProjectDetail, 'subscription'> {
    subscription: SubscriptionForView;
}
