import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import RadioGroup from '@igenius_dev/crystalkit/lib/components/RadioGroup';
import theme from '@igenius_dev/crystalkit/lib/theme';

import { contactSupport } from 'utils/support';
import TrashWithTooltip from '../../../../components/TrashWithTooltip';
import { selectStatusForAddingPaymentMethod } from '../../../../modules/payment-methods/selectors';
import {
    AddingPaymentMethod,
    PaymentMethodsStatus,
} from '../../../../modules/payment-methods/types/types';
import { HubState } from '../../../../types';
import { AddPaymentButton } from '../../../Project/Detail/Detail/Payment/AddPaymentButton';
import { StyledTitle } from '../elements';
import {
    AddCreditCard,
    AmericanExpress,
    CardBrandImage,
    ContactUsLink,
    CreditCardBrands,
    ExpirationDate,
    FirstPaymentMessage,
    MasterCard,
    PurpleRadioInput,
    RadioLabel,
    RectangleRowContainer,
    StyledRectangle,
    SubtitleWithMargin,
    Visa,
} from './elements';
import { AddPaymentMethodModal } from './Modal/AddPaymentMethodModal';
import { CreditCard } from '../../../../components/CreditCard';
import useTranslator from '../../../../hooks/useTranslator';
import { PaymentMethod } from '../../../../core/entities/payment-method/PaymentMethod';

interface Props {
    currentPaymentMethodId?: string;
    paymentMethods?: PaymentMethod[];
    onSubmit: (p: AddingPaymentMethod) => void;
}

export const PaymentMethods = ({
    paymentMethods = [],
    onSubmit,
    currentPaymentMethodId,
}: Props) => {
    const [addPaymentMethodModalIsOpen, setPaymentMethodModalOpen] = useState<boolean>(false);
    const t = useTranslator();
    const { control, setValue, trigger } = useFormContext();
    const addingStatus = useSelector<HubState, PaymentMethodsStatus>(
        selectStatusForAddingPaymentMethod,
    );

    const hasPaymentMethod = paymentMethods.length > 0;
    const isSingleElement = hasPaymentMethod && paymentMethods.length === 1;
    const paymentMethodDefaultValue =
        currentPaymentMethodId ?? (hasPaymentMethod ? paymentMethods[0].id : null);
    const canClick = (status: PaymentMethodsStatus) => status !== PaymentMethodsStatus.PENDING;
    const closePaymentModal = useCallback(() => {
        canClick(addingStatus) && setPaymentMethodModalOpen(false);
    }, [setPaymentMethodModalOpen, addingStatus]);
    const openPaymentModal = useCallback(() => setPaymentMethodModalOpen(true), [
        setPaymentMethodModalOpen,
    ]);

    const creditCardBrands = (
        <CreditCardBrands>
            <CardBrandImage>
                <Visa />
            </CardBrandImage>
            <CardBrandImage>
                <MasterCard />
            </CardBrandImage>
            <CardBrandImage>
                <AmericanExpress />
            </CardBrandImage>
        </CreditCardBrands>
    );

    useEffect(() => {
        if (paymentMethods?.length && !currentPaymentMethodId) {
            setValue('card', paymentMethods[0].id);
            trigger('card');
        }
    }, [paymentMethods, currentPaymentMethodId, setValue, trigger]);

    return (
        <div>
            <StyledTitle>{t('hub-payment-details-payment-method-section-title')}</StyledTitle>
            <SubtitleWithMargin>
                {t('hub-payment-details-payment-method-section-subtititle')}{' '}
                <ContactUsLink onClick={contactSupport}>
                    {t('hub-payment-details-payment-method-section-textlink-contact-us')}
                </ContactUsLink>
            </SubtitleWithMargin>
            <Controller
                control={control}
                name={'card'}
                defaultValue={paymentMethodDefaultValue}
                rules={{ required: true }}
                render={({ value, onChange }) => (
                    <RectangleRowContainer>
                        <RadioGroup value={value} onValueChange={onChange}>
                            {paymentMethods?.map((card) => (
                                <StyledRectangle
                                    selected={value === card.id}
                                    key={card.id}
                                    data-cy="payment-method"
                                    onClick={() => onChange(card.id)}
                                >
                                    <RadioLabel>
                                        {!isSingleElement && (
                                            <PurpleRadioInput
                                                value={card.id}
                                                data-cy={'payment-method-input-radio'}
                                            />
                                        )}
                                        <CreditCard
                                            brand={card.brand}
                                            lastDigits={card.lastDigits}
                                            cardNumberColor={theme.colors.values.black300}
                                        />
                                    </RadioLabel>
                                    <RadioLabel>
                                        <ExpirationDate>
                                            {t(
                                                `hub-confirm-and-subscribe-area-label-expiration-date`,
                                                {
                                                    payload: {
                                                        DATE: `${card.expiration.month}/${card.expiration.year}`,
                                                    },
                                                },
                                            )}
                                        </ExpirationDate>
                                        {!isSingleElement ? <TrashWithTooltip /> : null}
                                    </RadioLabel>
                                </StyledRectangle>
                            ))}
                        </RadioGroup>
                    </RectangleRowContainer>
                )}
            />

            {hasPaymentMethod ? (
                <AddPaymentButton
                    brands={creditCardBrands}
                    onClick={openPaymentModal}
                    label={t('hub-dialogue-change-credit-textlink-add-new-card')}
                />
            ) : (
                <FirstPaymentMessage data-cy={'add-payment-method'}>
                    <AddCreditCard
                        label={t('hub-dialogue-change-credit-textlink-add-new-card')}
                        onClick={openPaymentModal}
                    />
                    {creditCardBrands}
                </FirstPaymentMessage>
            )}

            <AddPaymentMethodModal
                open={addPaymentMethodModalIsOpen}
                onClose={closePaymentModal}
                onSubmit={({ stripe, card }) => {
                    onSubmit({
                        stripe,
                        card,
                        onSuccess: closePaymentModal,
                    });
                }}
            />
        </div>
    );
};
