import React from 'react';

// @ts-ignore
import Img from '@igenius_dev/crystalkit/lib/components/Img';
import Button from '@igenius_dev/crystalkit/lib/components/Button';

import emptyState from 'assets/emptyState.png';
import useTranslator from 'hooks/useTranslator';
import { EmptyState } from 'views/Project/Detail/State/EmptyState';
import Layout from 'views/Layout';
import styled from 'styled-components';

interface Props {
    onClick: () => void;
}

const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`;

const NotFoundImage = styled(Img).attrs({
    src: emptyState,
})`
    margin-bottom: 24px;
`;

export const NoProjectsFound: React.FC<Props> = ({ onClick }) => {
    const t = useTranslator();

    return (
        <Layout>
            <CenteredContainer>
                <EmptyState
                    image={<NotFoundImage alt="content-not-found" height="250px" />}
                    title={t('hub-project-detail-empty-state-title-no-projects-found')}
                    subtitle={t('hub-project-detail-empty-state-body-no-projects-found')}
                    action={
                        <Button
                            onClick={onClick}
                            label={t('hub-project-detail-empty-state-button-create-a-new-project')}
                        />
                    }
                />
            </CenteredContainer>
        </Layout>
    );
};
