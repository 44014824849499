import React, { MutableRefObject, useRef } from 'react';
//@ts-ignore
import Text from '@igenius_dev/crystalkit/lib/components/Text';
//@ts-ignore
import TextInput from '@igenius_dev/crystalkit/lib/components/Inputs/TextInput';
//@ts-ignore
import Field from '@igenius_dev/crystalkit/lib/components/Field';
//@ts-ignore
import theme from '@igenius_dev/crystalkit/lib/theme';

import styled from 'styled-components';
import { TextFieldProps } from './TextField';

interface Props extends TextFieldProps {
    trailingLabel: string;
}

const textWidth = 130;

const Container = styled.div`
    position: relative;
`;

const StyledText = styled(Text).attrs({
    scale: 'Small',
})`
    position: absolute;
    top: 24px;
    right: 0;
    font-weight: ${theme.typography.weights.values.medium};
    line-height: 34px;
    padding: 0 24px;
    border-left: 1px solid ${theme.colors.values.black50};
    color: ${theme.colors.values.black300};
    cursor: default;
    width: ${textWidth}px;
    transition: border-color 300ms;
    text-align: center;
`;

const StyledTextInput = styled(TextInput)`
    width: calc(100% - ${textWidth}px);
`;

export const TextFieldWithLabel: React.FC<Props> = (props) => {
    const {
        className,
        label,
        isErrored,
        instructionText,
        placeholder,
        name,
        value,
        onChange,
        onBlur,
        trailingLabel,
    } = props;
    const labelRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;

    return (
        <Container>
            <Field
                displayType={Field.DisplayTypes.OUTLINED}
                className={className}
                labelText={label}
                isErrored={isErrored}
                instructionText={instructionText}
            >
                <StyledTextInput
                    type="text"
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    name={name}
                    onFocus={() => {
                        labelRef.current.style.borderLeftColor = theme.colors.values.black500;
                    }}
                    onBlur={() => {
                        labelRef.current.style.borderLeftColor = '';
                        onBlur && onBlur();
                    }}
                />
            </Field>
            <StyledText ref={labelRef}>{trailingLabel}</StyledText>
        </Container>
    );
};
