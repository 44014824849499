import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { Store } from 'redux';

// @ts-ignore
import Flexboxgrid from '@igenius_dev/crystalkit/lib/components/Flexboxgrid';
// @ts-ignore
import WindowSizer from '@igenius_dev/crystalkit/lib/components/WindowSizer';
// @ts-ignore
import MobileDetectProvider from '@igenius_dev/crystalkit/lib/utils/MobileDetectProvider';
import { DictionaryProvider } from 'modules/i18n';
import Intercom from './components/Intercom';
import GlobalStyle from './views/GlobalStyle';
import { HubState } from 'types';
import { HubModulesProvider } from 'config/ui/HubModulesContext';
import { Features } from 'modules/config/types';
import { FeatureFlags } from 'components/FeatureFlags';
import Router, { RouterProps } from './views/Router';
import { Tos } from 'views/Tos';
import MobileBlock from './views/MobileBlock';
import ToastsProvider from './modules/toasts/providers/ToastsProvider';
import ToastsContainer from 'modules/toasts/components/ToastsContainer';
import { Modules } from 'config/modules/Modules';

interface AppProps extends RouterProps {
    store: Store<HubState>;
    modules: Modules;
    features: Features;
}

const App = ({ store, modules, features, domainNormalizer, domainValidator }: AppProps) => (
    <ReduxProvider store={store}>
        <HubModulesProvider modules={modules}>
            <GlobalStyle />
            <Flexboxgrid />
            <DictionaryProvider>
                <WindowSizer>
                    <MobileDetectProvider>
                        <FeatureFlags features={features}>
                            <DynamicModuleLoader
                                modules={[
                                    modules.get('projectCreation'),
                                    modules.get('creationInfo'),
                                    modules.get('paymentWizard'),
                                    modules.get('invoice'),
                                ]}
                            >
                                <Router
                                    domainNormalizer={domainNormalizer}
                                    domainValidator={domainValidator}
                                />
                            </DynamicModuleLoader>
                            <ToastsProvider>
                                {({ toasts, addToast, removeToast, actionSideEffect }: any) => (
                                    <ToastsContainer
                                        toasts={toasts}
                                        addToast={addToast}
                                        removeToast={removeToast}
                                        actionSideEffect={actionSideEffect}
                                    />
                                )}
                            </ToastsProvider>
                            <Tos />
                            <MobileBlock />
                        </FeatureFlags>
                    </MobileDetectProvider>
                </WindowSizer>
                <Intercom />
            </DictionaryProvider>
        </HubModulesProvider>
    </ReduxProvider>
);

export default App;
