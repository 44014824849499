import React, { useEffect, useState } from 'react';

import {
    ApplyButton,
    ContactUsErrorLink,
    CouponApplied,
    CouponAppliedContainer,
    CouponChip,
    CouponErrorText,
    CouponInputContainer,
} from './elements';
import { SmallText } from '../../components/Typography/SmallText';
import { TextField } from '../../components/Fields/TextField';
import { COLOR } from '@igenius_dev/crystalkit/lib/components/Chip/types';
import { contactSupport } from '../../utils/support';
import useTranslator from '../../hooks/useTranslator';

interface CouponProps {
    onReset: () => void;
    onChange?: (value: string) => void;
    onApply: (code: string) => void;
    coupon: {
        code?: string;
        discount?: string;
    };
    isStatusError: boolean;
    labels: {
        applied?: string;
        disclaimer?: string;
        nextBilling?: string;
    };
    color?: COLOR;
}

export const Coupon = (props: CouponProps) => {
    const t = useTranslator();
    const {
        onReset,
        coupon: cpn,
        onApply,
        isStatusError,
        labels,
        color,
        onChange = () => {},
    } = props;
    const { code = '', discount = '' } = cpn;
    const [couponIsValid, setCouponValid] = useState<boolean>(false);
    const [coupon, setCoupon] = useState<string>(code ?? '');
    const [hasError, setError] = useState<boolean>(false);
    const shouldApplyButtonBeDisabled = hasError || coupon === '';

    useEffect(() => {
        isStatusError && setError(true);
    }, [isStatusError, setError]);

    useEffect(() => {
        code && setCouponValid(true);
    }, [code, setCouponValid]);

    return couponIsValid ? (
        <CouponAppliedContainer>
            {labels.applied && <CouponApplied>{labels.applied}</CouponApplied>}
            <CouponChip
                baseColor={color}
                isDeletable
                onDelete={() => {
                    setCoupon('');
                    setCouponValid(false);

                    onReset();
                }}
            >{`${code} ${discount}`}</CouponChip>
            {labels.nextBilling && <SmallText color={'black300'}>{labels.nextBilling}</SmallText>}
            {labels.disclaimer && <SmallText color={'black300'}>{labels.disclaimer}</SmallText>}
        </CouponAppliedContainer>
    ) : (
        <>
            <CouponInputContainer>
                <TextField
                    placeholder={t('hub-project-detail-dialogue-placeholder-coupon-code')}
                    onChange={(event: React.SyntheticEvent<HTMLInputElement>) => {
                        const value = event.currentTarget.value;

                        setError(false);
                        setCoupon(value);
                        setCouponValid(false);

                        onChange(value);
                    }}
                    value={coupon}
                    name="coupon"
                    isErrored={hasError}
                />
                <ApplyButton
                    disabled={shouldApplyButtonBeDisabled}
                    label={t('hub-project-detail-dialogue-button-apply-coupon')}
                    onClick={() => onApply(coupon)}
                />
            </CouponInputContainer>
            {hasError && (
                <CouponErrorText>
                    {t('hub-project-detail-dialogue-error-message-coupon-not-valid')}{' '}
                    <ContactUsErrorLink onClick={contactSupport}>
                        {t('hub-project-detail-dialogue-error-message-contact-us')}
                    </ContactUsErrorLink>
                </CouponErrorText>
            )}
        </>
    );
};
