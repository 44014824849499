import RadioGroup from '@igenius_dev/crystalkit/lib/components/RadioGroup';

import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Controller, useFormContext } from 'react-hook-form';

import {
    ContactUsLink,
    PurpleRadioInput,
    RadioLabel,
} from 'views/PaymentWizard/Details/PaymentMethod/elements';
import {
    ActionContainer,
    Amount,
    BilledAnnually,
    Period,
    PlanContainer,
    PlanDetails,
    PlanPrice,
    PlanPriceAndPeriod,
    PlanTitle,
    Rectangle,
    RectangleRowContainer,
    SelectPlanDescription,
    Title,
    TrialText,
    UpgradeToEnterprise,
    ViewPricingPageLink,
} from './elements';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import { contactSupport } from 'utils/support';
import { SelectBillingCycle } from './SelectBillingCycle';
import { openPricing } from 'utils/openPricing';
import { PlanForView, PriceForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';
import { TimeInterval } from 'core/entities/Price';

export type SelectPlanFormData = { cycle: string; planId: string };

export type SelectPlanProps = {
    plans: PlanForView[];
    textScale?: 'Base' | 'Medium';
    actions: React.ReactElement[];
    showTrialDisclaimer: boolean;
    selectedCycle?: TimeInterval;
    selectedPlanId?: string;
    onChange?: (p: Partial<SelectPlanFormData>) => void;
};

export const SelectPlan = ({
    plans,
    textScale = 'Medium',
    actions = [],
    showTrialDisclaimer,
    selectedPlanId,
    selectedCycle = 'month',
}: SelectPlanProps) => {
    const t = useTranslator();
    const { control, reset, getValues } = useFormContext<{ cycle: string; planId: string }>();
    const planId = getValues('planId');
    const defaultPlanId = selectedPlanId || plans[0].id;
    const subscriptionPrices = useSelector(
        projectCreationInfoSelectors.selectSubscriptionsByPlanId(planId || defaultPlanId),
    );

    const options = subscriptionPrices.map((price: PriceForView) => ({
        value: price.timeInterval,
        label: t(
            price.timeInterval === 'year'
                ? 'hub-plan-selection-dialogue-chip-billing-cycle-annual'
                : 'hub-plan-selection-dialogue-chip-billing-cycle-monthly',
            {
                payload: {
                    NUMBER: '16',
                },
            },
        ),
    }));

    useEffect(() => {
        return () => reset();
    }, [reset]);

    return (
        <PlanContainer>
            <SelectBillingCycle items={options} textScale={textScale} />

            <>
                <Title scale={textScale}>{t('hub-trial-project-details-title-select-plan')}</Title>
                <RectangleRowContainer>
                    <Controller
                        name="planId"
                        control={control}
                        render={({ value, onChange }) => (
                            <RadioGroup value={value} onValueChange={onChange} name="planId">
                                {plans?.map((plan: PlanForView) => {
                                    const selected = value === plan.id;

                                    return (
                                        <Rectangle
                                            selected={selected}
                                            key={plan.id}
                                            data-cy="plan-selection"
                                            onClick={() => onChange(plan.id)}
                                            data-name={plan.name}
                                        >
                                            <RadioLabel>
                                                <PurpleRadioInput
                                                    value={plan.id}
                                                    data-cy={`plan${selected ? '-selected' : ''}`}
                                                />
                                                <PlanTitle>{plan.name}</PlanTitle>
                                                {plan.prices
                                                    .filter(
                                                        (price) =>
                                                            price.timeInterval === selectedCycle,
                                                    )
                                                    .map((price) => (
                                                        <PlanPrice
                                                            selected={selected}
                                                            key={price.id}
                                                        >
                                                            <PlanPriceAndPeriod>
                                                                <Amount>
                                                                    {price.amount.withoutDigits}
                                                                </Amount>{' '}
                                                                <Period>
                                                                    {t(
                                                                        'hub-plan-selection-dialogue-label-price-period-monthly',
                                                                    )}
                                                                </Period>
                                                            </PlanPriceAndPeriod>
                                                            {price.timeInterval === 'year' && (
                                                                <BilledAnnually>
                                                                    {t(
                                                                        'hub-plan-selection-dialogue-label-price-billed-annually',
                                                                    )}
                                                                </BilledAnnually>
                                                            )}
                                                        </PlanPrice>
                                                    ))}
                                            </RadioLabel>
                                            {plan.description?.standard && (
                                                <SelectPlanDescription>
                                                    <PlanDetails
                                                        dangerouslySetInnerHTML={{
                                                            __html: t(plan.description.standard),
                                                        }}
                                                    />
                                                    {t(
                                                        'hub-trial-project-details-plan-view-label-more-info',
                                                    )}{' '}
                                                    <ViewPricingPageLink onClick={openPricing}>
                                                        {t(
                                                            'hub-trial-project-details-plan-view-text-link',
                                                        )}
                                                    </ViewPricingPageLink>
                                                </SelectPlanDescription>
                                            )}
                                        </Rectangle>
                                    );
                                })}
                            </RadioGroup>
                        )}
                    />
                </RectangleRowContainer>
            </>

            <UpgradeToEnterprise>
                {t('hub-plan-selection-dialogue-label-plan-info-enterprise')}{' '}
                <ContactUsLink onClick={contactSupport}>
                    {t('hub-plan-selection-dialogue-label-plan-info-enterprise-contact-us')}
                </ContactUsLink>
            </UpgradeToEnterprise>
            <ActionContainer data-cy="plan-container-submit-button">{actions}</ActionContainer>
            {showTrialDisclaimer && (
                <TrialText>{t('hub-trial-project-label-free-trial')}</TrialText>
            )}
        </PlanContainer>
    );
};
