import styled, { css } from 'styled-components';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
// @ts-ignore
import Tooltip from '@igenius_dev/crystalkit/lib/components/Tooltip';
//@ts-ignore
import theme from '@igenius_dev/crystalkit/lib/theme';

export const RadioContainer = styled.div`
    & > div {
        display: flex;
        justify-content: space-between;
        padding: 24px 0;
        cursor: pointer;
        border-bottom: 1px solid ${theme.colors.values.blue50};
    }
`;

export const Label = styled(Text).attrs({ color: theme.colors.values.black300 })``;

export const BlueProjectVersionContainer = styled.div<{ visible: boolean }>`
    background-color: ${theme.colors.values.blue25};
    border-radius: 8px;
    transition: all 0.2s linear;
    padding: 0px;
    max-height: 0;
    padding: 0 24px;
    overflow: hidden;

    ${({ visible }) =>
        visible &&
        css`
            padding: 24px;
            max-height: 200px;
        `}
`;

export const StyledToolTip = styled(Tooltip)`
    cursor: default;
`;
