import { HttpClient } from './HttpClient';
import { AxiosInstance, AxiosResponse } from 'axios';
import AuthClient from '@igenius_dev/igenius-auth-client/lib';

export class AxiosHttpClient implements HttpClient {
    constructor(private readonly client: AxiosInstance) {
        this.client.interceptors.request.use(
            (config) => ({
                ...config,
                headers: {
                    Authorization: `Bearer ${AuthClient.getToken()}`,
                },
            }),
            (error) => Promise.reject(error),
        );
    }

    get<T>(url: string): Promise<T> {
        return this.client.get(url).then(this.mapToData);
    }

    delete(url: string): Promise<boolean> {
        return this.client.delete(url);
    }

    patch<DATA, T>(url: string, data: DATA): Promise<T> {
        return this.client.patch(url, data).then(this.mapToData);
    }

    post<DataType, ReturnType>(url: string, data: DataType): Promise<ReturnType> {
        return this.client.post<ReturnType>(url, data).then(this.mapToData);
    }

    put<T, R>(url: string, data: T): Promise<R> {
        return this.client.put(url, data).then(this.mapToData);
    }

    private mapToData<T>(response: AxiosResponse<T>): T {
        return response.data;
    }
}
