import { call, takeEvery } from 'redux-saga/effects';
import loggingActions from './actions';
import { Logger } from '@igenius_dev/igenius-js-logger/dist/Logger/Logger';
import { LogPayload } from '@igenius_dev/igenius-js-logger/dist/Payload/LogPayload';

type LoggingSagaParams = {
    logger: Logger;
    errorPayloadExtractor: (e: Error) => LogPayload;
};

export function* loggingSaga({ logger, errorPayloadExtractor }: LoggingSagaParams) {
    yield takeEvery(
        loggingActions.error,
        function* (action: ReturnType<typeof loggingActions.error>) {
            const {
                payload: { msg, error },
            } = action;
            const payload: LogPayload = errorPayloadExtractor(error);

            yield call([logger, 'error'], msg, payload);
        },
    );
}
