import { TenantLimits } from './types/types';

export const differencesForPlans = (
    current: TenantLimits,
    next: TenantLimits,
): Partial<TenantLimits> => {
    return Object.keys(current).reduce((prev, currentKey) => {
        const currentUsage: number = current[currentKey as keyof TenantLimits];
        const nextPlanLimit: number = next[currentKey as keyof TenantLimits];
        const difference = currentUsage - nextPlanLimit;
        const currentPlanHasMoreUsagesThanNewPlan = difference > 0;

        if (currentPlanHasMoreUsagesThanNewPlan) {
            return {
                ...prev,
                [currentKey]: difference,
            };
        }

        return prev;
    }, {});
};
