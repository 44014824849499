import { CreationInfo } from '../../../core/entities/creation-info/CreationInfo';
import { Language } from '../../../core/entities/project/Language';
import { PriceForViewAdapter } from './adaptToPriceForView';

export const adaptToCreationInfoForView = (priceForViewAdapter: PriceForViewAdapter) => (
    info: CreationInfo,
) => {
    const { languages, plans } = info;

    return {
        ...info,
        languages: languages.map((lang: Language) => ({
            value: lang.id,
            label: `hub-trial-project-language-option-${lang.name.toLowerCase()}`,
        })),
        plans: plans.map((plan) => {
            return {
                ...plan,
                prices: priceForViewAdapter(plan.prices),
            };
        }),
    };
};
