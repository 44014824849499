import { InvoicePreview } from '../types/types';

// @ts-ignore
import NumberFormatter from '@igenius_dev/igenius-number';
import { CouponForView, InvoicePreviewForView } from '../types/views';
import { Coupon } from '../../../core/entities/Coupon';

export function toInvoicePreviewForView(invoicePreview: InvoicePreview): InvoicePreviewForView {
    const { due = 0, total = 0, currency, coupon } = invoicePreview;

    const couponForView = adaptToCouponView({
        coupon,
        currency,
    });

    let previewForView: Partial<InvoicePreviewForView> = {
        due: NumberFormatter.formatCurrency({
            n: due / 100,
            options: {
                currency,
                minimumFractionDigits: minimumFractionDigitsFor(due),
            },
        }),
        total: NumberFormatter.formatCurrency({
            n: total / 100,
            options: {
                currency,
                minimumFractionDigits: minimumFractionDigitsFor(total),
            },
        }),
        zero: NumberFormatter.formatCurrency({
            n: 0,
            options: {
                currency,
                maximumFractionDigits: 0,
            },
        }),
    };

    if (couponForView) {
        previewForView = {
            ...previewForView,
            coupon: couponForView,
        };
    }

    return previewForView as InvoicePreviewForView;
}

export type CouponViewParams = {
    coupon?: Coupon;
    currency: string;
};

export function adaptToCouponView({
    coupon,
    currency,
}: CouponViewParams): CouponForView | undefined {
    if (!coupon) {
        return undefined;
    }

    return {
        code: coupon.id,
        discount: `-${
            typeof coupon.off.amount !== 'undefined'
                ? NumberFormatter.formatCurrency({
                      n: coupon.off.amount / 100,
                      options: {
                          minimumFractionDigits: 0,
                          currency: coupon.currency ?? currency,
                      },
                  })
                : NumberFormatter.formatPercentage({
                      n: coupon.off.percent,
                  })
        }`,
    };
}

function minimumFractionDigitsFor(amount: number) {
    return amount === 0 ? 0 : 2;
}
