import { TenantLimits } from '../../../modules/tenant-usage/types/types';
import { PlanFeatures } from '../../entities/plan/PlanFeatures';
import { ProjectId } from '../../entities/project/Project';
import { ChangePlanVerifier } from '../../entities/project/ChangePlanVerifier';

export type RetrieveTenantLimits = (id: ProjectId) => Promise<TenantLimits>;
export type RetrievePlanFeatures = (id: string) => Promise<PlanFeatures>;

export class ChangePlanVerifierUseCase implements ChangePlanVerifier {
    constructor(
        private readonly tenantUsages: RetrieveTenantLimits,
        private readonly nextPlan: RetrievePlanFeatures,
        private readonly differencesForPlans: (
            a: TenantLimits,
            b: TenantLimits,
        ) => Partial<TenantLimits>,
    ) {}

    async verify(currentProjectId: string, nextPlanId: string): Promise<Partial<TenantLimits>> {
        const limits = await this.tenantUsages(currentProjectId);
        const features = await this.nextPlan(nextPlanId);

        if (Object.keys(features).length === 0) {
            throw new Error('no-features');
        } else {
            return this.differencesForPlans(limits, {
                languages: features.languages!,
                dataSources: features.dataSources!,
                users: features.users!,
                topics: features.topics!,
            });
        }
    }
}
