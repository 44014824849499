import { ISagaModule } from 'redux-dynamic-modules-saga';
import { InvoicePreview, InvoicePreviewModuleState } from './types/types';
import { invoicePreviewReducer } from './reducer';
import { invoicePreviewSaga } from './saga';
import { InvoicePreviewRequest } from './types/dto';

export const getInvoicePreviewModule = (
    retriever: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>,
): ISagaModule<InvoicePreviewModuleState> => ({
    id: 'invoice',
    reducerMap: {
        invoice: invoicePreviewReducer as any,
    },
    sagas: [
        invoicePreviewSaga({
            api: retriever,
        }),
    ],
});
