import { createAsyncAction } from 'typesafe-actions';
import { Project } from '../../../core/entities/project/Project';

const projectListInit = createAsyncAction(
    '@project_list/PROJECT_LIST_INIT_REQUEST',
    '@project_list/PROJECT_LIST_INIT_SUCCESS',
    '@project_list/PROJECT_LIST_INIT_FAILURE',
)<undefined, undefined, Error>();

const projectListFetch = createAsyncAction(
    '@project_list/PROJECT_LIST_FETCH_REQUEST',
    '@project_list/PROJECT_LIST_FETCH_SUCCESS',
    '@project_list/PROJECT_LIST_FETCH_FAILURE',
)<undefined, Project[], Error>();

const projectListActions = {
    projectListFetch,
    projectListInit,
};

export default projectListActions;
