import React from 'react';
import { useNavigate } from '@reach/router';

import { SidebarStatus } from '@igenius_dev/crystalkit/lib/components/Sidebar/types';

import { ExpandedButton, RoundedButton } from './elements';
import { paths } from 'paths';
import useTranslator from 'hooks/useTranslator';

type CreateProjectButtonProps = {
    status: SidebarStatus;
};

export const CreateProjectButton = ({ status }: CreateProjectButtonProps) => {
    const t = useTranslator();
    const navigate = useNavigate();
    const onClick = () => navigate(paths.createProject);

    return status === 'collapsed' ? (
        <RoundedButton onClick={onClick} />
    ) : (
        <ExpandedButton label={t('hub-new-project-title')} onClick={onClick} />
    );
};
