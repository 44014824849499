import React, { useEffect, useState } from 'react';

import RadioGroup from '@igenius_dev/crystalkit/lib/components/RadioGroup';
import Text from '@igenius_dev/crystalkit/lib/components/Text';

import { StyledTitle } from '../elements';
import { FormContainer, InlineRadio, RadioWithLabel, RowRadioGroup, Separator } from './elements';
import { useFormContext } from 'react-hook-form';
import { AddressForm } from './AddressForm';
import { Address, TaxId } from 'common/types';
import { TaxIdForm } from './TaxIdForm';
import useTranslator from '../../../../hooks/useTranslator';

export type FormType = 'person' | 'company';

interface Props {
    address?: Address;
    taxId?: TaxId;
    type: FormType;
}

export const InvoiceDetails = ({ address, taxId, type }: Props) => {
    const [currentFormType, setCurrentFormType] = useState<FormType>(type);
    const t = useTranslator();
    const { setValue } = useFormContext<{ formType: FormType }>();

    useEffect(() => {
        setValue('formType', currentFormType);
    }, [setValue, currentFormType]);

    return (
        <div>
            <StyledTitle>{t('hub-payment-details-invoice-details-section-title')}</StyledTitle>
            <RowRadioGroup data-cy="invoice-details-type">
                <RadioGroup
                    value={currentFormType}
                    onValueChange={(value: FormType) => {
                        setCurrentFormType(value);
                        setValue('formType', value);
                    }}
                >
                    <RadioWithLabel>
                        <InlineRadio value={'company'} />
                        <Text>
                            {t('hub-payment-details-invoice-details-section-company-label')}
                        </Text>
                    </RadioWithLabel>
                    <RadioWithLabel>
                        <InlineRadio value={'person'} />
                        <Text>{t('hub-payment-details-invoice-details-section-person-label')}</Text>
                    </RadioWithLabel>
                </RadioGroup>
            </RowRadioGroup>

            <FormContainer>
                <AddressForm details={address} formType={currentFormType} />

                {currentFormType === 'company' && (
                    <>
                        <Separator />
                        <TaxIdForm details={taxId} />
                    </>
                )}
            </FormContainer>
        </div>
    );
};
