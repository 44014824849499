import { HttpClient } from 'services/HttpClient';

type DomainToValidate = string;
type DomainValidator = (data: DomainToValidate) => Promise<boolean>;

export function domainValidator(client: HttpClient, url: string): DomainValidator {
    return async (domain: DomainToValidate) => {
        const endpoint = [url, domain].join('/');

        try {
            await client.get(endpoint);
        } catch (e) {
            return false;
        }

        return true;
    };
}
