import React from 'react';
import { BaseText, Information, SmallText } from './elements';
import useTranslator from '../../../hooks/useTranslator';

export const Disclaimer = () => {
    const t = useTranslator();

    return (
        <>
            <Information>
                <BaseText>{t('hub-column-recap-disclaimer-title-pay-now')}</BaseText>
                <SmallText>{t('hub-column-recap-disclaimer-body-pay-now')}</SmallText>
            </Information>
            <Information>
                <BaseText>{t('hub-column-recap-disclaimer-title-pay-after-trial')}</BaseText>
                <SmallText>{t('hub-column-recap-disclaimer-body-pay-after-trial')}</SmallText>
            </Information>
        </>
    );
};
