// @ts-ignore
import NumberFormatter from '@igenius_dev/igenius-number';

import projectUpdateActions from './actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { retrieveProjectDetail } from '../detail/actions';
import { ProjectUpdateDto } from './types/dto';
import { endWizard, startWizard } from '../../payment-wizard/actions';
import { ProjectCreationInfoPaymentAddress, ProjectCreationInfoTaxId } from '../types/dto';
import { navigate } from '@reach/router';
import { paths } from 'paths';
import { addErrorToast, addSuccessToast } from '../../toasts/actions';
import tenantUsageActions from '../../tenant-usage/actions';
import { TenantLimits } from '../../tenant-usage/types/types';
import { removeUndefinedValuesFrom } from 'utils/removeUndefinedaValueFrom';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ChangePlanVerifier } from 'core/entities/project/ChangePlanVerifier';
import { ChangeSeatsVerifier } from 'core/entities/project/ChangeSeatsVerifier';
import { InvoicePreviewRequest } from '../../invoice/types/dto';
import { InvoicePreview } from '../../invoice/types/types';
import { UpdateBillingDetails } from 'core/entities/billing-details/UpdateBillingDetails';
import { BillingDetailsFormData } from 'views/Project/Detail/Detail/FormEditBillingDetails/types';
import { fetchBillingInfo } from '../../billing-info/actions';
import loggingActions from 'modules/logger/actions';

function* handleRequest(
    action: ActionType<typeof projectUpdateActions.request.request>,
    repository: ProjectRepository,
    updateBillingDetailsUseCase: UpdateBillingDetails,
) {
    const { projectData, onSuccess = () => {} } = action.payload;
    const {
        id,
        paymentMethodId,
        priceId,
        name,
        domain,
        seats,
        languages,
        invoice,
        coupon,
        formType,
    } = projectData;

    const dto: Partial<ProjectUpdateDto> = {
        price_id: priceId,
        name: name,
        domain: domain,
        number_of_seats: seats,
        languages,
        payment_method_id: paymentMethodId,
        payment_address: invoice?.address
            ? removeUndefinedValuesFrom<ProjectCreationInfoPaymentAddress>({
                  line1: invoice?.address.address,
                  city: invoice?.address?.city,
                  country: invoice?.address?.country?.value,
                  postal_code: invoice?.address?.zipCode,
                  state: invoice?.address?.state,
              })
            : undefined,
        tax_id: invoice?.taxId
            ? removeUndefinedValuesFrom<ProjectCreationInfoTaxId>({
                  type: invoice?.taxId?.vatId?.value,
                  value: invoice?.taxId?.vatNr,
              })
            : undefined,
        coupon,
        user_type: formType,
    };

    const dtoWithNoUndefined = removeUndefinedValuesFrom<ProjectUpdateDto>(dto);

    try {
        yield call([repository, 'update'], id, dtoWithNoUndefined);

        if (!!invoice) {
            const { address, taxId = {} } = invoice;

            const args = {
                city: address.city,
                country: address.country,
                fullName: address.name,
                line1: address.address,
                state: address.state,
                taxIDType: taxId.vatId,
                taxIDValue: taxId.vatNr,
                userType: formType,
                zipCode: address.zipCode,
            };

            yield call(
                [updateBillingDetailsUseCase, 'update'],
                removeUndefinedValuesFrom<BillingDetailsFormData>(args),
            );

            yield put(fetchBillingInfo.request());
        }

        yield put(projectUpdateActions.request.success());
        yield put(retrieveProjectDetail.request({ id }));
        yield call(onSuccess);
    } catch (e) {
        yield put(projectUpdateActions.request.failure(e));
    }
}

function* startEdit(action: ActionType<typeof projectUpdateActions.wizard.start>) {
    yield put(
        startWizard({
            basePath: action.payload.basePath,
        }),
    );
}

function* endEdit(action: ActionType<typeof projectUpdateActions.wizard.end>) {
    yield call(endWizard);

    navigate(action.payload.redirectPath);
}

function* handleRequestFailure({
    payload,
}: ActionType<typeof projectUpdateActions.request.failure>) {
    const { message, type, ...rest } = payload;

    yield put(
        addErrorToast({
            id: 'update-project-error',
            type: 'error',
            label: type === 'users' ? message : 'hub-project-detail-error-toast-change-not-saved',
            isAutoDismissEnabled: false,
            actions: [
                {
                    label: 'Close',
                },
            ],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to update project',
            error: { ...rest, message },
        }),
    );
}

function* handleRequestSuccess() {
    yield put(
        addSuccessToast({
            id: 'update-project-success',
            type: 'success',
            label: 'hub-project-detail-success-toast-change-saved',
            isAutoDismissEnabled: true,
        }),
    );
}

function* handleChangePlan(
    action: ActionType<typeof projectUpdateActions.changePlan.submit.request>,
    useCase: ChangePlanVerifier,
) {
    const { id: projectId, planId } = action.payload;

    try {
        const differences: TenantLimits = yield call([useCase, 'verify'], projectId, planId);

        yield put(projectUpdateActions.changePlan.submit.success());

        if (Object.keys(differences).length > 0) {
            yield put(projectUpdateActions.changePlan.cancel());
            yield put(tenantUsageActions.showModal({ differences, nextPlanId: planId }));
        } else {
            yield put(
                projectUpdateActions.wizard.start({
                    basePath: `${paths.project}/${projectId}${paths.paymentWizard}`,
                }),
            );
        }
    } catch (e) {
        yield put(projectUpdateActions.changePlan.submit.failure());
        yield put(
            addErrorToast({
                actions: [
                    {
                        label: 'Close',
                    },
                ],
                id: 'error-change-plan',
                type: 'error',
                isAutoDismissEnabled: false,
                label: 'Something wrong occurred while retrieving requested plan',
            }),
        );
        yield put(projectUpdateActions.changePlan.cancel());
    }
}

function* startChangeSeats(action: ActionType<typeof projectUpdateActions.changeSeats.start>) {
    yield put(
        projectUpdateActions.wizard.start({
            basePath: `${paths.project}/${action.payload.id}${paths.paymentWizard}`,
        }),
    );
}

function* handleChangeSeats(
    action: ActionType<typeof projectUpdateActions.changeSeats.submit.request>,
    useCase: ChangeSeatsVerifier,
    fetchInvoicePreview: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>,
): any {
    const {
        data: { id, seats },
        onSuccess = () => {},
    } = action.payload;

    try {
        const [{ users }, invoiceWithCurrentSeats, invoiceWithNextSeats] = yield all([
            call(useCase, id, seats),
            call(fetchInvoicePreview, {
                project_id: id,
            }),
            call(fetchInvoicePreview, {
                project_id: id,
                number_of_seats: seats,
            }),
        ]);

        yield put(projectUpdateActions.changeSeats.submit.success());

        if (users > 0) {
            yield put(projectUpdateActions.changeSeats.cancel());
            yield put(
                tenantUsageActions.showModal({
                    nextSeats: seats,
                    differences: {
                        dataSources: 0,
                        languages: 0,
                        topics: 0,
                        users,
                    },
                    prices: {
                        users: {
                            current: NumberFormatter.formatCurrency({
                                n: invoiceWithCurrentSeats.due / 100,
                                options: {
                                    currency: invoiceWithCurrentSeats.currency,
                                    minimumFractionDigits: 2,
                                },
                            }),
                            requested: NumberFormatter.formatCurrency({
                                n: invoiceWithNextSeats.due / 100,
                                options: {
                                    currency: invoiceWithNextSeats.currency,
                                    minimumFractionDigits: 2,
                                },
                            }),
                        },
                    },
                }),
            );
        } else {
            yield call(onSuccess);
        }
    } catch (e) {
        yield put(projectUpdateActions.changeSeats.submit.failure(e.message));
    }
}

export function createProjectUpdateSaga({
    repository,
    changePlanUseCase,
    changeSeatsUseCase,
    updateBillingDetailsUseCase,
    invoicePreviewRetriever,
}: {
    repository: ProjectRepository;
    changePlanUseCase: ChangePlanVerifier;
    changeSeatsUseCase: ChangeSeatsVerifier;
    updateBillingDetailsUseCase: UpdateBillingDetails;
    invoicePreviewRetriever: (dto: InvoicePreviewRequest) => Promise<InvoicePreview>;
}) {
    return function* projectUpdateSaga() {
        yield takeLatest(projectUpdateActions.request.request, (args) =>
            handleRequest(args, repository, updateBillingDetailsUseCase),
        );
        yield takeLatest(projectUpdateActions.request.failure, handleRequestFailure);
        yield takeLatest(projectUpdateActions.request.success, handleRequestSuccess);
        yield takeLatest(projectUpdateActions.wizard.start, startEdit);
        yield takeLatest(projectUpdateActions.wizard.end, endEdit);
        yield takeLatest(projectUpdateActions.changePlan.submit.request, (args) =>
            handleChangePlan(args, changePlanUseCase),
        );
        yield takeLatest(projectUpdateActions.changeSeats.start, startChangeSeats);
        yield takeLatest(projectUpdateActions.changeSeats.submit.request, (_) =>
            handleChangeSeats(_, changeSeatsUseCase, invoicePreviewRetriever),
        );
    };
}
