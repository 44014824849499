import styled from 'styled-components';

import Text from '@igenius_dev/crystalkit/lib/components/Text';
import Button from '@igenius_dev/crystalkit/lib/components/Button';

import { Widget } from '../Project/Detail/Detail/Widget';

export const PageTitle = styled(Text).attrs({
    scale: 'Large',
    color: 'black500',
})`
    display: block;
`;

export const PaymentWizardWidget = styled(Widget)`
    margin: 24px 0;
`;

export const SubmitButton = styled(Button).attrs({
    type: 'submit',
    size: 'small',
    fluid: true,
})`
    height: 56px;
    margin-top: 40px;
`;
