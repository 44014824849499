import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import theme from '@igenius_dev/crystalkit/lib/theme';
import React, { MouseEventHandler } from 'react';
import styled, { css } from 'styled-components';

const StyledText = styled(Text).attrs<{ onClick: React.MouseEvent<any> }>({
    scale: 'Small',
})`
    ${({ onClick }) =>
        onClick
            ? css`
                  cursor: pointer;
                  color: ${theme.colors.values.purple700};
              `
            : css`
                  cursor: default;
                  color: ${theme.colors.values.black100};
              `}
`;
const StyledTextLink = styled(TextLink).attrs({
    scale: 'Small',
    color: 'purple700',
})``;

type BaseActionProps = {
    label: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
};

export const BaseAction = (props: BaseActionProps) => (
    <StyledText onClick={props.onClick}>{props.label}</StyledText>
);

export const LinkAction = (props: BaseActionProps) => (
    <StyledTextLink onClick={props.onClick} disabled={!props.onClick || props.disabled}>
        {props.label}
    </StyledTextLink>
);
