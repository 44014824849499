import styled, { css } from 'styled-components';
import { SelectField } from 'components/Fields/SelectField';
import { TextField } from 'components/Fields/TextField';
import { SmallText } from 'components/Typography/SmallText';
import { Scrollbars } from 'react-custom-scrollbars';
import theme from '@igenius_dev/crystalkit/lib/theme';

export const StyledTextField = styled(TextField)`
    margin-bottom: 16px;
`;
export const StyledSelectField = styled(SelectField)`
    margin-bottom: 16px;
`;

export const HeaderContainer = styled.div<{ showShadow: boolean }>`
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    margin: -40px -40px 0 -40px;
    padding: 40px 40px 18px 40px;
    ${({ showShadow }) =>
        showShadow &&
        css`
            box-shadow: rgb(224, 224, 224) 0 2px 4px 0;
        `}
`;

export const Subtitle = styled(SmallText).attrs({ color: 'black300' })``;

export const ScrollableContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
`;

export const ScrollableDialogContent = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
`;

export const ScrollableModalContainer = styled.div`
    max-height: calc(80vh - 80px);
    display: flex;
`;

export const FlexScrollbars = styled(Scrollbars).attrs({
    hideTracksWhenNotNeeded: true,
    autoHide: true,
    autoHeight: true,
    autoHeightMin: '100%',
    autoHeightMax: '100%',
})``;

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 12px;

    & > * {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export const StyledForm = styled.form`
    padding: 0 40px;
`;

export const Container = styled.div`
    display: flex;
    border-bottom: 1px solid ${theme.colors.values.black50};
    margin: 0 -40px;
    overflow-y: auto;
`;
