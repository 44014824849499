import { RouteComponentProps } from '@reach/router';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { NumberField } from 'components/Fields/NumberField';
import { PageTitle, PaymentWizardWidget, SubmitButton } from '../elements';
import { GenericStep } from '../GenericStep';
import { OrderSummary, SummaryProps } from '../Summary/OrderSummary';
import { InfoIcon } from '../../Project/Create/elements';
import { Selection, StyledInfoNotification } from './elements';
import { SmallText } from 'components/Typography/SmallText';
import useTranslator from 'hooks/useTranslator';
import { DEFAULT_SEATS_NUMBER } from 'utils/defaultConfig';
import { useDispatch } from 'react-redux';
import { invoicePreviewActions } from 'modules/invoice/actions';

export type Summary = Omit<SummaryProps, 'actionButton'>;

export interface Seats {
    seats: number;
}

interface SeatSelectionStepProps extends RouteComponentProps {
    projectId?: string;
    seats: number;
    onSubmit: (data: Seats) => void;
    onUpdate: (seats: number) => void;
    summary: Summary;
    labels: {
        notification: string;
    };
}

export const SeatSelectionStep: React.FC<SeatSelectionStepProps> = (props) => {
    const { seats, summary, onSubmit, labels, onUpdate, projectId } = props;
    const { subscription } = summary;
    const priceId = subscription?.id;
    const dispatch = useDispatch();
    const t = useTranslator();
    const {
        control,
        errors,
        handleSubmit,
        formState: { isValid },
        getValues,
    } = useForm<Seats>({
        mode: 'onChange',
    });
    const userHasEmptyInput = String(getValues('seats')) === '';
    const isSubmitDisabled = !isValid || userHasEmptyInput;

    useEffect(() => {
        !!seats &&
            dispatch(invoicePreviewActions.fetch.request({ data: { priceId, seats, projectId } }));
    }, [dispatch, seats, projectId, priceId]);

    return (
        <GenericStep
            left={
                <>
                    <PageTitle>{t('hub-select-seats-step-title-select-seats')}</PageTitle>
                    <PaymentWizardWidget
                        title={t('hub-select-seats-area-title-choose-seats-number')}
                    >
                        <StyledInfoNotification>
                            <InfoIcon />
                            <SmallText>{labels.notification}</SmallText>
                        </StyledInfoNotification>

                        <Selection>
                            <Controller
                                name="seats"
                                rules={{ min: { value: 5, message: '' } }}
                                defaultValue={seats}
                                control={control}
                                render={({ value, onChange }) => (
                                    <NumberField
                                        placeholder=""
                                        name="seats"
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                            if (event.target.value === '') {
                                                onChange(event);
                                            } else {
                                                const value = Number(event.target.value);

                                                if (value >= DEFAULT_SEATS_NUMBER) {
                                                    onUpdate(value);
                                                }

                                                onChange(value);
                                            }
                                        }}
                                        value={value}
                                        isErrored={!!errors.seats}
                                    />
                                )}
                            />
                        </Selection>
                    </PaymentWizardWidget>
                </>
            }
            right={
                <OrderSummary
                    disclaimer={summary.disclaimer}
                    seats={summary.seats}
                    plan={summary.plan}
                    subscription={summary.subscription}
                    coupon={summary.coupon}
                    actionButton={
                        <SubmitButton
                            disabled={isSubmitDisabled}
                            label={t('hub-column-recap-continue')}
                            onClick={handleSubmit(onSubmit)}
                        />
                    }
                />
            }
        />
    );
};
