import React from 'react';

import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';
import { DialogButtonProps } from '@igenius_dev/crystalkit/lib/components/Dialog/types';
import { ImageSizeType } from '@igenius_dev/crystalkit/lib/components/Dialog/IllustrationContent/types';

type HubDialogProps = {
    open: boolean;
    onClose: () => void;
    actions: DialogButtonProps[];
    illustration?: {
        title: string;
        subtitle?: string;
        imageSrc?: string;
        imageSize?: ImageSizeType;
    };
};

export const HubIllustrationDialog: React.FC<HubDialogProps> = (props) => {
    const { open = false, onClose = () => null, actions = [], illustration } = props;

    return (
        <Dialog
            type={Dialog.type.small}
            open={open}
            onCancel={onClose}
            dismissable
            clickOutside
            actions={actions}
            renderContent={() => (
                <Dialog.IllustrationContent
                    title={illustration?.title}
                    subtitle={illustration?.subtitle}
                    imageSrc={illustration?.imageSrc}
                    imageSize={illustration?.imageSize}
                />
            )}
        />
    );
};
