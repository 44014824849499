import { ProjectDetailForView } from '../types';
import { CouponViewParams } from '../../../invoice/adapters/toInvoicePreviewForView';
import { ProjectDetail } from '../../../../core/entities/project/ProjectDetail';
import { CouponForView } from '../../../invoice/types/views';
import { PriceForViewAdapter } from '../../../creation-info/adapter/adaptToPriceForView';

export const adaptToProjectDetailForView = (
    adaptPrices: PriceForViewAdapter,
    adaptToCoupon: (params: CouponViewParams) => CouponForView | undefined,
) => (projectDetails: ProjectDetail): ProjectDetailForView => {
    return {
        ...projectDetails,
        subscription: {
            ...projectDetails.subscription,
            price: {
                ...projectDetails.subscription.price,
                ...adaptPrices([projectDetails.subscription.price], 2)[0],
            },
            coupon: adaptToCoupon({
                coupon: projectDetails.subscription.coupon,
                currency: projectDetails.subscription.price.currency,
            }),
        },
    };
};
