import { CreditCardDTO, InvoiceDto } from 'common/dtos';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';
import { BillingInfoAddressDto } from '../../billing-info/types/dto';

export type LanguageDto = {
    id: string;
    name: string;
    iso_639_1_code: string;
    is_favorite: boolean;
};

export type PlanMetadata = {
    inline_notification?: string;
    topics?: number;
    data_sources?: number;
    languages?: number;
    customer_support?: number;
    features?: string;
};

export type PriceDto = {
    id: string;
    currency: string;
    recurring_interval: string;
    unit_amount: number;
};

export type PlanDto = {
    product_id?: string;
    number_of_languages?: number;
    prices: PriceDto[];
} & BasePlanDto;

export type ProjectCreationInfoResponseDto = {
    languages: LanguageDto[];
    regions: any[];
    plans: PlanDto[];
    base_domain: string;
};

type BasePlanDto = {
    id: string;
    name: string;
    description: string;
    metadata: PlanMetadata;
    unit_label: string;
};

export type ProjectCreationInfoPaymentAddress = {
    line1: string;
    city?: string;
    country?: string;
    postal_code?: string;
    state?: string;
};

export type ProjectCreationInfoTaxId = {
    type?: string;
    value?: string;
};

export type ProjectCreationDto = {
    price_id?: string;
    name: string;
    domain: string;
    number_of_seats?: number;
    languages: string[];
    payment_method_id: string;
    payment_address: ProjectCreationInfoPaymentAddress;
    tax_id?: ProjectCreationInfoTaxId;
    user_type: FormType;
    coupon?: string;
    favorite_lang_id: string;
};

export type ProjectUpgradeDto = {
    project_version?: string;
};

export enum ProjectStatusDto {
    // Project has yet to be activated
    PendingActivation = 'PENDING_ACTIVATION',

    // Project is active
    Active = 'ACTIVE',

    // Project is in a pending grace period.
    // This happens on the terminate project request
    PendingBlocking = 'PENDING_BLOCKING',

    // Project is in grace period
    Blocked = 'BLOCKED',

    // Project failed to pause. ATM manual action is required
    BlockingError = 'BLOCKING_ERROR',

    // Project was in grace period and a request to re-enable it is made
    PendingResume = 'PENDING_RESUME',

    // Project is currently being deleted.
    // This happens on the end of the grace period
    PendingDeletion = 'PENDING_DELETION',

    // Project has been successfully deleted
    Deleted = 'DELETED',

    // Project is in an error state
    Error = 'ERROR',
}

export type ProjectDetailDto = {
    id: string;
    name: string;
    status: ProjectStatusDto;
    avatar_url?: string;
    domain: string;
    owner: string;
    number_of_seats: number;
    languages: LanguageDto[];
    complete_domain: string;
    subscription: ProjectSubscriptionDto;
    payment_address: BillingInfoAddressDto;
    project_version: string;
};

export type ProjectDto = {
    name: string;
    avatar_url?: string;
    domain: string;
    number_of_seats: number;
    owner: string;
    status: ProjectStatusDto;
    languages: string[];
    id: string;
};

export type NotificationDto = {
    type: string;
    payload: {
        id: string;
        type: string;
        brand: string;
        last4: string;
        exp_month: number;
        exp_year: number;
        checks: {
            address_line1_check: string | null;
            address_postal_code_check: string | null;
            cvc_check: string | null;
        };
    };
};

export interface CouponDto {
    id: string;
    duration: string;
    amount_off?: number;
    currency?: string;
    percent_off?: number;
    duration_in_months?: number;
}

export type ProjectSubscriptionDto = {
    id: string;
    quantity: number;
    current_period_end: number;
    trial_end?: number;
    price: PriceWithPlanDto;
    default_payment_method: CreditCardDTO;
    latest_invoice: InvoiceDto;
    coupon?: CouponDto;
};

type PriceWithPlanDto = PriceDto & { plan: BasePlanDto };
