import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormElement, FormRow, StyledSelectField, StyledTextField } from './elements';
import { Address } from 'common/types';

import { FormType } from './InvoiceDetails';
import useTranslator from '../../../../hooks/useTranslator';
import { useSelector } from 'react-redux';
import uiSelectors from 'modules/ui/selectors';

interface Props {
    details?: Address;
    formType: FormType;
}

const getDefaultName = (type: FormType, details?: Address) =>
    type === 'person' ? details?.name : details?.companyName;

export const AddressForm = ({ details, formType }: Props) => {
    const t = useTranslator();
    const items = useSelector(uiSelectors.selectAllCountries);
    const { control, setValue, formState } = useFormContext<Address>();

    const defaultName = getDefaultName(formType, details);
    const defaultValues: Address = {
        country: details?.country ?? ('' as any),
        state: details?.state ?? '',
        city: details?.city ?? '',
        address: details?.address ?? '',
        zipCode: details?.zipCode ?? '',
        name: defaultName,
    };
    const hasNameChanged = formState.dirtyFields.name;

    useEffect(() => {
        if (!hasNameChanged) {
            setValue('name', defaultName);
        }
    }, [setValue, defaultName, hasNameChanged]);

    return (
        <>
            <FormRow>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name={'country'}
                        defaultValue={defaultValues.country}
                        control={control}
                        render={({ onChange, value }) => (
                            <div data-cy="address-form-select-country">
                                <StyledSelectField
                                    alwaysEnableSearch
                                    defaultValue={defaultValues.country}
                                    label={t(
                                        'hub-payment-details-invoice-details-section-country-label',
                                    )}
                                    placeholder={t(
                                        'hub-payment-details-invoice-details-section-country-label-placeholder',
                                    )}
                                    onChange={onChange}
                                    value={value}
                                    name={'country'}
                                    items={items}
                                />
                            </div>
                        )}
                    />
                </FormElement>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name={'name'}
                        control={control}
                        defaultValue={defaultValues.name}
                        render={({ value, onChange }) => (
                            <StyledTextField
                                label={t('hub-payment-details-invoice-details-section-name-label')}
                                placeholder={t(
                                    `hub-payment-details-invoice-details-section-name-label-${formType}-placeholder`,
                                )}
                                onChange={onChange}
                                value={value}
                                name={'name'}
                            />
                        )}
                    />
                </FormElement>
            </FormRow>
            <FormRow>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name="address"
                        control={control}
                        defaultValue={defaultValues.address}
                        render={({ onChange, value }) => (
                            <StyledTextField
                                label={t(
                                    'hub-payment-details-invoice-details-section-address-label',
                                )}
                                placeholder={t(
                                    'hub-payment-details-invoice-details-section-address-label-placeholder',
                                )}
                                onChange={onChange}
                                value={value}
                                name={'address'}
                            />
                        )}
                    />
                </FormElement>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name={'zipCode'}
                        control={control}
                        defaultValue={defaultValues.zipCode}
                        render={({ onChange, value }) => (
                            <StyledTextField
                                label={t('hub-payment-details-invoice-details-section-zip-label')}
                                placeholder={t(
                                    'hub-payment-details-invoice-details-section-zip-label-placeholder',
                                )}
                                onChange={onChange}
                                value={value}
                                name={'zipCode'}
                            />
                        )}
                    />
                </FormElement>
            </FormRow>
            <FormRow>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name={'city'}
                        control={control}
                        defaultValue={defaultValues.city}
                        render={({ value, onChange }) => (
                            <StyledTextField
                                label={t('hub-payment-details-invoice-details-section-zip-city')}
                                placeholder={t(
                                    'hub-payment-details-invoice-details-section-zip-city-placeholder',
                                )}
                                onChange={onChange}
                                value={value}
                                name={'city'}
                            />
                        )}
                    />
                </FormElement>
                <FormElement>
                    <Controller
                        rules={{ required: true }}
                        name={'state'}
                        defaultValue={defaultValues.state}
                        control={control}
                        render={({ onChange, value }) => (
                            <StyledTextField
                                label={t('hub-payment-details-invoice-details-section-state')}
                                value={value}
                                onChange={onChange}
                                name={'state'}
                                placeholder={t(
                                    'hub-payment-details-invoice-details-section-state-placeholder',
                                )}
                            />
                        )}
                    />
                </FormElement>
            </FormRow>
        </>
    );
};
