import { Action, ActionType, createAction, PayloadAction } from 'typesafe-actions';

const toastActionTypes = {
    ADD_INFO: '@toasts/ADD_INFO',
    ADD_SUCCESS: '@toasts/ADD_SUCCESS',
    ADD_WARNING: '@toasts/ADD_WARNING',
    ADD_ERROR: '@toasts/ADD_ERROR',
    REMOVE: '@toasts/REMOVE',
} as const;

interface ToastAction {
    label: string;
    action?: Action;
}

interface BasicToast {
    id: string;
    label: string;
}

// Error
interface AddToastErrorPayload extends BasicToast {
    type: 'error';
    isAutoDismissEnabled: false;
    actions: ToastAction[];
}

export const addErrorToast: (
    payload: AddToastErrorPayload,
) => PayloadAction<typeof toastActionTypes.ADD_ERROR, AddToastErrorPayload> = createAction(
    toastActionTypes.ADD_ERROR,
)<AddToastErrorPayload>();

// Info
interface AddToastInfoPayload extends BasicToast {
    type: 'info';
    isAutoDismissEnabled: true;
}
export const addInfoToast = createAction(toastActionTypes.ADD_INFO)<AddToastInfoPayload>();

// Success
interface AddToastSuccessPayload extends BasicToast {
    type: 'success';
    isAutoDismissEnabled: true;
}
export const addSuccessToast = createAction(toastActionTypes.ADD_SUCCESS)<AddToastSuccessPayload>();

// Warning
interface AddToastWarningPayload extends BasicToast {
    type: 'warning';
    isAutoDismissEnabled: true;
}
export const addWarningToast = createAction(toastActionTypes.ADD_WARNING)<AddToastWarningPayload>();

export const removeToast = createAction(toastActionTypes.REMOVE)<string>();

export type ToastsActions =
    | ActionType<typeof addErrorToast>
    | ActionType<typeof addInfoToast>
    | ActionType<typeof addSuccessToast>
    | ActionType<typeof addWarningToast>
    | ActionType<typeof removeToast>;
