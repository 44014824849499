import { PaymentMethodsRepository } from '../../../core/repository/payment-methods/PaymentMethodsRepository';
import { HttpClient } from '../../../services/HttpClient';
import { PaymentToken } from '../types/types';
import { CreditCardDTO } from 'common/dtos';
import { PaymentTokenDto } from '../types/dtos';
import { PaymentMethod } from '../../../core/entities/payment-method/PaymentMethod';

type PaymentMethodAdapter = (input: CreditCardDTO) => PaymentMethod;

export class PaymentMethodsWebRepository implements PaymentMethodsRepository {
    constructor(
        private readonly client: HttpClient,
        private readonly paymentMethodAdapter: PaymentMethodAdapter,
        private readonly baseUrl: string = `/api/user-manager/private/users/me/payment-methods`,
    ) {}

    async cards(): Promise<PaymentMethod[]> {
        try {
            const response = await this.client.get<CreditCardDTO[]>(`${this.baseUrl}/card`);

            return response.map((m) => this.paymentMethodAdapter(m));
        } catch (e) {
            throw new Error(e.message);
        }
    }

    async token(): Promise<PaymentToken> {
        try {
            const response = await this.client.get<PaymentTokenDto>(`${this.baseUrl}/add/token`);

            return {
                clientSecret: response.client_secret,
                id: response.id,
            };
        } catch (e) {
            throw new Error(e.message);
        }
    }

    async deleteToken(token: string): Promise<boolean> {
        try {
            return this.client.delete(`${this.baseUrl}/add/token/${token}`);
        } catch (e) {
            throw new Error(e.message);
        }
    }
}
