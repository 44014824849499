import { createAction, createAsyncAction } from 'typesafe-actions';
import { ErrorMessage } from '../types/types';
import { ProjectId } from 'core/entities/project/Project';

export const changeSeats = {
    start: createAction('@project-details/PROJECT_UPDATE_CHANGE_SEATS_START')<{ id: ProjectId }>(),
    submit: createAsyncAction(
        '@project-details/PROJECT_UPDATE_CHANGE_SEATS_REQUEST',
        '@project-details/PROJECT_UPDATE_CHANGE_SEATS_SUCCESS',
        '@project-details/PROJECT_UPDATE_CHANGE_SEATS_FAILURE',
    )<{ data: { id: ProjectId; seats: number }; onSuccess: () => void }, undefined, ErrorMessage>(),
    cancel: createAction('@project-details/PROJECT_UPDATE_CHANGE_SEATS_CANCEL')(),
};
