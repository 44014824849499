import { CouponPriceContainer, CouponPriceText } from './elements';
import React from 'react';

interface CouponRowWithPriceAndCodeProps {
    code: string;
    discount: string;
    className?: string;
}

export const CouponRowWithPriceAndCode = ({
    code,
    discount,
    className,
}: CouponRowWithPriceAndCodeProps) => (
    <CouponPriceContainer data-cy="coupon-price" className={className}>
        <CouponPriceText>{code}</CouponPriceText>
        <CouponPriceText>{discount}</CouponPriceText>
    </CouponPriceContainer>
);
