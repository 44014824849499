import { LanguageDto, ProjectDetailDto, ProjectSubscriptionDto } from '../../types/dto';
import { Language } from '../../../../core/entities/project/Language';
import { ProjectDetail } from '../../../../core/entities/project/ProjectDetail';
import { Subscription } from '../../../../core/entities/project/Subscription';

export const adaptToProjectDetail = (
    adaptToProjectLanguage: (dto: LanguageDto[]) => Language[],
    adaptToSubscription: (res: ProjectSubscriptionDto) => Subscription,
) => (dto: ProjectDetailDto): ProjectDetail => {
    return {
        id: dto.id,
        domain: dto.domain,
        name: dto.name,
        seats: dto.number_of_seats,
        status: dto.status,
        languages: adaptToProjectLanguage(dto.languages),
        subscription: adaptToSubscription(dto.subscription),
        projectVersion: dto.project_version,
        completeDomain: dto.complete_domain,
        paymentAddress: {
            city: dto.payment_address.city,
            country: dto.payment_address.country,
            line1: dto.payment_address.line1,
            line2: dto.payment_address.line2,
            postalCode: dto.payment_address.postal_code,
            state: dto.payment_address.state,
        },
    };
};
