import { ModalTos } from '@igenius_dev/crystal.dynamic-modules/lib/modules/privacy';
import { useSelector } from 'react-redux';
import { profileModule } from '@igenius_dev/crystal.dynamic-modules/lib';
import React from 'react';
import { ProfileModuleState } from '@igenius_dev/crystal.dynamic-modules/lib/modules/profile/types';

export const Tos: React.FC = () => {
    const identity = useSelector<ProfileModuleState, string | undefined>(
        profileModule.selectors.getIdentityId,
    );
    const role = useSelector(profileModule.selectors.getUserRole);

    return <ModalTos userRole={role!} identity_id={identity!} />;
};
