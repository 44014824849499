import React from 'react';
import styled from 'styled-components';
import { EnrichedSidebar } from 'components/Sidebar/EnrichedSidebar';
import { Left, MainContainer, Right, Wrapper } from './elements';
import { ZIndexes } from 'utils/zIndexes';

type LayoutProps = {
    leftContent?: React.ReactElement;
    rightContent?: React.ReactElement;
};

const ResponsiveRight = styled(Right).attrs({
    'data-cy': 'layout-right',
})`
    @media (max-width: 1279px) {
        display: none;
    }
`;

const ResponsiveLeft = styled(Left).attrs({
    'data-cy': 'layout-left',
})`
    @media (max-width: 1279px) {
        width: 100%;
        justify-content: center;
    }
`;

const Layout: React.FC<LayoutProps> = ({ leftContent, rightContent, children }) => {
    return (
        <Wrapper>
            <EnrichedSidebar />

            <div id="sheets" style={{ zIndex: ZIndexes.Sheets }} />

            <MainContainer>
                {children ? (
                    children
                ) : (
                    <>
                        <ResponsiveLeft>{leftContent}</ResponsiveLeft>
                        <ResponsiveRight>{rightContent}</ResponsiveRight>
                    </>
                )}
            </MainContainer>
        </Wrapper>
    );
};

export default Layout;
