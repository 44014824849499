import styled from 'styled-components';

import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
import theme from '@igenius_dev/crystalkit/lib/theme';

import { ItemListContent, StyledItem } from 'components/Sidebar/elements';

export const ItemListFooter = styled(ItemListContent)`
    margin-top: auto;
    padding-top: 36px;
    padding-bottom: 24px;
`;

export const FooterItem = styled(StyledItem)`
    min-height: 48px;
`;

export const StyledFooterIcon = styled(Icon).attrs({
    size: 24,
})`
    svg {
        color: ${theme.colors.values.black300};
    }
`;

const IconContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ExpandedIcon = styled(IconContainer)`
    width: 32px;
`;

export const CollapsedIcon = styled(IconContainer)`
    width: 40px;
`;
