import styled, { css } from 'styled-components';
import React, { Fragment } from 'react';

import theme from '@igenius_dev/crystalkit/lib/theme';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';

const headerHeight = 80;

const Header = styled.div`
    height: ${headerHeight}px;
    width: 100%;
    border-bottom: 1px solid ${theme.colors.values.blue25};
`;

const SelectedText = styled(Text).attrs({
    scale: 'Small',
})`
    font-weight: ${theme.typography.weights.values.medium};
`;

const StyledBadge = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
`;

const Step = styled.div<{ active: boolean; past?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    height: 24px;
    background-color: ${({ active, past }) =>
        active
            ? theme.colors.values.purple700
            : !past
            ? theme.colors.values.white
            : theme.colors.values.purple25};
    padding-left: 8px;
    padding-right: 16px;
    margin: 0 8px;

    ${SelectedText} {
        font-weight: ${({ past }) =>
            past
                ? theme.typography.weights.values.medium
                : theme.typography.weights.values.regular};

        color: ${({ active }) =>
            active ? theme.colors.values.white : theme.colors.values.purple700};
    }

    ${StyledBadge} {
        ${({ active, past }) =>
            active
                ? css`
                      background-color: ${theme.colors.values.white};
                      color: ${theme.colors.values.purple700};
                  `
                : past
                ? css`
                      color: ${theme.colors.values.white};
                      background-color: ${theme.colors.values.purple700};
                  `
                : css`
                      color: ${theme.colors.values.white};
                      background-color: ${theme.colors.values.purple100};
                  `}
    }
`;

const Steps = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

const StepNumber = styled(Text).attrs({
    scale: 'Micro',
})`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SmallIcon = styled(Icon).attrs({ size: 'small' })``;

const NextStepIndicator = styled(SmallIcon).attrs({ icon: 'right' })`
    svg {
        color: ${theme.colors.values.purple500};
    }
`;

const CheckIcon = styled(SmallIcon).attrs({ icon: 'check' })`
    color: ${theme.colors.values.purple100};
    display: flex;
`;

const PreviousStepIndicator = styled.div`
    ::before {
        margin: 0 -8px;
        content: '';
        display: block;
        width: 56px;
        border-bottom: 1px solid ${theme.colors.values.purple100};
    }
`;

const CloseIcon = styled(Icon).attrs({ icon: 'remove' })`
    position: absolute;
    right: 60px;
    top: ${headerHeight / 2}px;
    transform: translate(-50%, -50%);
    cursor: pointer;
`;

interface Props {
    onClose: () => void;
    activeStep: number;
    steps: string[];
}

export const PaymentWizardHeader: React.FC<Props> = ({ onClose, steps, activeStep = 1 }) => {
    return (
        <Header>
            <Steps>
                {steps.map((step, i) => {
                    const currentStep = i + 1;
                    const atLastElement = currentStep >= steps.length;
                    const activeStepAlreadySeen = activeStep > currentStep;
                    const activeStepIsCurrentStep = activeStep === currentStep;

                    return (
                        <Fragment key={i}>
                            <Step
                                active={activeStepIsCurrentStep}
                                past={activeStepAlreadySeen}
                                key={currentStep}
                            >
                                <StyledBadge>
                                    <StepNumber>
                                        {activeStepAlreadySeen ? <CheckIcon /> : currentStep}
                                    </StepNumber>
                                </StyledBadge>
                                <SelectedText>{step}</SelectedText>
                            </Step>
                            {atLastElement ? null : activeStepAlreadySeen ? (
                                <PreviousStepIndicator />
                            ) : (
                                <NextStepIndicator />
                            )}
                        </Fragment>
                    );
                })}
            </Steps>
            <div data-testid="payment-wizard-close-icon">
                <CloseIcon onClick={onClose} />
            </div>
        </Header>
    );
};
