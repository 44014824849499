import { ProjectTerminationState } from 'modules/project/terminate/types';
import { ActionType, getType } from 'typesafe-actions';
import { terminateProject } from 'modules/project/terminate/actions';

export const projectTerminationReducer = (
    state: ProjectTerminationState = { status: 'PENDING' },
    action: ActionType<typeof terminateProject>,
): ProjectTerminationState => {
    switch (action.type) {
        case getType(terminateProject.request): {
            return { status: 'PENDING' };
        }
        case getType(terminateProject.failure): {
            return { status: 'ERROR' };
        }
        case getType(terminateProject.success): {
            return { status: 'SUCCESS' };
        }
        default: {
            return state;
        }
    }
};
