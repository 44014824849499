import {
    TenantUsage,
    TenantUsageModalStatus,
    TenantUsageState,
    TenantUsageStatus,
} from './types/types';
import { ActionType, getType } from 'typesafe-actions';
import tenantUsageActions from './actions';

export const tenantUsageReducer = (
    state: TenantUsageState = {
        status: TenantUsageStatus.INITIAL,
        ui: {
            status: TenantUsageModalStatus.CLOSE,
        },
    },
    action: ActionType<typeof tenantUsageActions>,
): TenantUsageState => {
    switch (action.type) {
        case getType(tenantUsageActions.fetch.request): {
            return {
                ...state,
                status: TenantUsageStatus.PENDING,
            };
        }
        case getType(tenantUsageActions.fetch.success): {
            return {
                ...state,
                status: TenantUsageStatus.SUCCESS,
                data: action.payload as TenantUsage,
            };
        }
        case getType(tenantUsageActions.fetch.failure): {
            return {
                ...state,
                status: TenantUsageStatus.ERROR,
            };
        }
        case getType(tenantUsageActions.showModal): {
            return {
                ...state,
                ui: {
                    status: TenantUsageModalStatus.OPEN,
                    data: {
                        diff: action.payload.differences,
                        nextPlanId: action.payload.nextPlanId,
                        nextSeats: action.payload.nextSeats,
                        prices: action.payload.prices,
                    },
                },
            };
        }
        case getType(tenantUsageActions.hideModal): {
            return {
                ...state,
                ui: {
                    status: TenantUsageModalStatus.CLOSE,
                },
            };
        }
        default:
            return state;
    }
};
