import { getType } from 'typesafe-actions';
import { setConfig } from './actions';
import { ConfigState } from './types';
import { AnyAction } from 'redux';

export const configReducer = (state: ConfigState = null, action: AnyAction) => {
    switch (action.type) {
        case getType(setConfig):
            return { ...action.payload };
        default:
            return state;
    }
};
