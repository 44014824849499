import styled from 'styled-components';
import { SelectField } from '../../../../components/Fields/SelectField';
import { TextField } from '../../../../components/Fields/TextField';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { PurpleRadioInput } from '../PaymentMethod/elements';

export const RowRadioGroup = styled.div`
    display: flex;
    margin-top: 16px;

    & > :first-child {
        margin-right: 32px;
    }
`;

export const RadioWithLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

export const InlineRadio = styled(PurpleRadioInput)`
    margin-right: 8px;
`;

export const FormContainer = styled.div`
    margin-top: 24px;
`;

export const FormRow = styled.div`
    display: flex;
    justify-content: space-between;

    & > :first-child {
        margin-right: 16px;
    }
`;

export const FormElement = styled.div`
    width: 100%;
    margin-top: 16px;
`;

export const StyledTextField = styled(TextField)`
    & span {
        margin-bottom: 10px;
    }

    & input {
        height: 36px;
    }
`;

export const StyledSelectField = styled(SelectField)`
    & > div {
        height: 61px;
    }
`;

export const Separator = styled.div`
    width: 100%;
    margin-top: 24px;
    border-bottom: 1px solid ${theme.colors.values.blue25};
`;
