import { createAsyncAction } from 'typesafe-actions';
import { InvoicePreviewForView, InvoicePreviewRequestForView } from './types/views';

export const invoicePreviewActions = {
    fetch: createAsyncAction(
        '@invoice-preview/INVOICE_PREVIEW_REQUEST',
        '@invoice-preview/INVOICE_PREVIEW_SUCCESS',
        '@invoice-preview/INVOICE_PREVIEW_FAILURE',
    )<
        { data: InvoicePreviewRequestForView; onSuccess?: () => void },
        InvoicePreviewForView,
        Error
    >(),
};
