import styled, { css } from 'styled-components';

// @ts-ignore
import CrystalLogo from '@igenius_dev/crystalkit/lib/components/CrystalLogo';
import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';
import theme from '@igenius_dev/crystalkit/lib/theme';

const leftWrapperWidth = 60;
const rightWrapperWidth = 100 - leftWrapperWidth;

export const Wrapper = styled.div`
    display: flex;
    width: 100vw;

    > main {
        align-items: center;
    }
`;

export const MainContainer = styled.main`
    display: flex;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;

    @media (max-width: 1023px) {
        width: calc(100% - 72px);
        margin-left: 72px;
    }
`;

export const Left = styled.div`
    height: 100%;
    display: flex;
    background-color: ${theme.colors.values.blue15};
    padding: 0 60px;
    width: ${leftWrapperWidth}%;
    overflow-y: auto;
    justify-content: flex-end;
`;

export const Right = styled.div`
    width: ${rightWrapperWidth}%;
    height: 100%;
    display: flex;
    padding: 0 60px;
    overflow-y: auto;
    justify-content: flex-start;
`;

export const FullscreenBlockingModal = styled(Dialog).attrs({
    type: 'screen',
    dismissable: false,
    clickOutside: false,
})`
    background-color: ${theme.colors.values.blue15};
`;

export const BlockingDialogContent = styled.div`
    width: 100%;
    max-width: 380px;

    padding: 0 16px;
`;

export const TabletCrystalLogo = styled(CrystalLogo)`
    position: absolute;
    top: 24px;
    left: 32px;

    z-index: 10000;

    display: none;
    visibility: hidden;
`;

export const MobileBlockWrapper = styled.div<{ isOpen: boolean }>`
    ${({ isOpen }) =>
        isOpen &&
        css`
            @media only screen and (min-width: 768px) {
                ${TabletCrystalLogo} {
                    display: block;
                    visibility: visible;
                }
            }
        `}
`;
