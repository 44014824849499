import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';

import { PaymentWizard } from './PaymentWizard/PaymentWizard';
import { Seats, SeatSelectionStep, Summary } from './PaymentWizard/SelectSeats/SeatSelectionStep';
import { paths, paymentWizardPaths } from 'paths';
import { projectCreationSelectors } from 'modules/project/create/selectors';
import { projectCreationActions } from 'modules/project/create/actions';
import { endWizard, nextStepWizard, prevStepWizard } from 'modules/payment-wizard/actions';
import { PaymentDetails, PaymentDetailsStep } from './PaymentWizard/Details/PaymentDetailsStep';
import { paymentMethodsActions } from 'modules/payment-methods/actions';
import { HubState } from 'types';
import { AddingPaymentMethod } from 'modules/payment-methods/types/types';
import { selectActivePaymentMethod } from 'modules/payment-methods/selectors';
import { ConfirmAndPayStep } from './PaymentWizard/Confirm/ConfirmAndPayStep';

import { ProjectCreationData } from 'modules/project/create/types';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import billingInfoSelectors from '../modules/billing-info/selectors';
import { fetchBillingInfo } from 'modules/billing-info/actions';
import projectListActions from 'modules/project/list/actions';
import profileSelectors from '../modules/profile/selectors';
import { Address, TaxId } from 'common/types';
import { Disclaimer } from './PaymentWizard/Summary/Disclaimer';
import { invoicePreviewActions } from 'modules/invoice/actions';
import { UseCoupon } from './Project/Create/Plan/UseCoupon';
import { InvoicePreviewForView } from 'modules/invoice/types/views';
import { invoicePreviewSelectors } from 'modules/invoice/selectors';
import { PriceForView } from 'modules/creation-info/types';
import useTranslator from '../hooks/useTranslator';
import { PaymentMethod } from 'core/entities/payment-method/PaymentMethod';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';
import { BillingInfoForView } from 'modules/billing-info/types/types';
import { TimeInterval } from 'core/entities/Price';

export const PaymentWizardForCreation: React.FC<RouteComponentProps> = () => {
    const t = useTranslator();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const invoice = useSelector<HubState, InvoicePreviewForView | undefined>(
        invoicePreviewSelectors.selectInvoice,
    );
    const coupon = useSelector(projectCreationSelectors.selectCoupon);
    const isTrial = useSelector<HubState, boolean>(billingInfoSelectors.isUserInTrial);
    const plan = useSelector(projectCreationSelectors.selectActivePlan);
    const subscriptions = useSelector<HubState, PriceForView[]>(
        projectCreationInfoSelectors.selectSubscriptionsByPlanId(plan?.id),
    );
    const activeSubscriptionId = useSelector(projectCreationSelectors.selectActiveSubscriptionId);
    const subscription =
        subscriptions?.find(
            (subscription: PriceForView) => subscription.id === activeSubscriptionId,
        ) ?? subscriptions?.find((p: PriceForView) => p.timeInterval === 'month');
    const seats = useSelector<HubState, number>(projectCreationSelectors.selectNumberOfSeats);
    const priceId = subscription?.id;
    const currentPaymentMethodId = useSelector<HubState, PaymentMethod | undefined>(
        selectActivePaymentMethod,
    )?.id;
    const mergeAddressInformation = (
        userInfo: { company_name?: string },
        addressFromInvoice?: Address,
        billingInfo?: BillingInfoForView,
    ): Address => {
        let addressToReturn: Partial<Address> = addressFromInvoice
            ? addressFromInvoice
            : billingInfo
            ? {
                  country: billingInfo.address?.country ?? ('' as any),
                  state: billingInfo.address?.state,
                  city: billingInfo.address?.city,
                  address: billingInfo.address?.line1 ?? '',
                  zipCode: billingInfo.address?.postalCode,
                  name: billingInfo.name,
              }
            : {};

        if (!addressToReturn.companyName) {
            addressToReturn = {
                ...addressToReturn,
                companyName: userInfo.company_name,
            };
        }

        return addressToReturn as Address;
    };

    const billingInfo = useSelector<HubState, BillingInfoForView | undefined>(
        billingInfoSelectors.billingInfo,
    );
    const userInfo = useSelector(profileSelectors.selectUser);
    const addressFromInvoice = useSelector(projectCreationSelectors.selectInvoiceDetailsAddress);
    const address = mergeAddressInformation(userInfo, addressFromInvoice, billingInfo);
    const selectedFormType = useSelector<HubState, FormType | undefined>(
        projectCreationSelectors.selectInvoiceType,
    );
    const selectedTaxId = useSelector<HubState, TaxId | undefined>(
        projectCreationSelectors.selectInvoiceDetailsTaxId,
    );
    const currentTaxId =
        billingInfo?.tax &&
        ({
            vatNr: billingInfo.tax?.value,
            vatId: billingInfo.tax?.label ?? '',
        } as TaxId);

    const taxId = selectedTaxId ?? currentTaxId;
    const projectData = useSelector<HubState, ProjectCreationData | undefined>(
        projectCreationSelectors.selectProjectCreationData,
    );
    const billingCycle = useSelector<HubState, TimeInterval>(
        projectCreationSelectors.selectBillingCycle,
    );
    const isUserInTrial = useSelector(billingInfoSelectors.isUserInTrial);
    const currentUserType = useSelector<HubState, FormType>(billingInfoSelectors.selectUserType);

    const formType = isUserInTrial ? 'company' : selectedFormType ?? currentUserType;

    const onSeatsUpdate = (seats: number) => {
        dispatch(
            projectCreationActions.update({
                seats,
            }),
        );
        dispatch(
            invoicePreviewActions.fetch.request({
                data: {
                    seats,
                    priceId: subscription?.id,
                    coupon,
                },
            }),
        );
    };
    const onSeatsSubmit = (data: Seats) => {
        dispatch(projectCreationActions.update(data));
        dispatch(
            nextStepWizard({
                currentStep: 1,
            }),
        );
    };

    const onPaymentDetailsSubmit = (data: PaymentDetails) => {
        const { card: paymentMethodId, formType, vatId, vatNr } = data;

        const invoice: { address: Address; taxId?: TaxId } = {
            address: {
                country: data.country,
                state: data.state,
                city: data.city,
                address: data.address,
                zipCode: data.zipCode,
                name: data.name,
                companyName: data.companyName,
            },
        };

        if (vatNr) {
            invoice.taxId = {
                vatId: vatId,
                vatNr: vatNr,
            };
        }

        dispatch(
            projectCreationActions.update({
                paymentMethodId: paymentMethodId,
                invoice,
                formType,
            }),
        );
        dispatch(nextStepWizard({ currentStep: 2 }));
    };
    const onPaymentTypeUpdate = (priceId: string) => {
        dispatch(
            projectCreationActions.update({
                priceId,
            }),
        );
    };
    const onPaymentMethodSubmit = ({ stripe, card, onSuccess }: AddingPaymentMethod) => {
        dispatch(
            paymentMethodsActions.add.request({
                stripe,
                card,
                onSuccess,
            }),
        );
    };

    const onSeatsChange = useCallback(() => dispatch(prevStepWizard({ goToStep: 1 })), [dispatch]);
    const onPaymentMethodsChange = useCallback(() => dispatch(prevStepWizard({ goToStep: 2 })), [
        dispatch,
    ]);

    const steps = {
        [paymentWizardPaths[0]]: t('hub-payment-wizard-stepper-label-select-seats'),
        [paymentWizardPaths[1]]: t('hub-payment-wizard-stepper-label-payment-details'),
        [paymentWizardPaths[2]]: t('hub-payment-wizard-stepper-label-confirm-and-subscribe'),
    };

    const summary: Summary = {
        plan,
        seats,
        subscription,
        disclaimer: isTrial ? <Disclaimer /> : undefined,
        coupon: (
            <UseCoupon
                onChange={(value: string) => {
                    if (invoice?.coupon?.code) {
                        if (value !== invoice.coupon.code) {
                            dispatch(
                                projectCreationActions.update({
                                    coupon: undefined,
                                    seats,
                                    priceId,
                                }),
                            );
                        }
                    }
                }}
                onApply={(coupon: string) => {
                    dispatch(
                        invoicePreviewActions.fetch.request({
                            data: {
                                coupon,
                                priceId,
                                seats,
                            },
                            onSuccess: () => {
                                dispatch(
                                    projectCreationActions.update({
                                        coupon,
                                    }),
                                );
                            },
                        }),
                    );
                }}
                onReset={() => {
                    dispatch(
                        invoicePreviewActions.fetch.request({
                            data: {
                                priceId,
                                seats,
                            },
                            onSuccess: () => {
                                dispatch(
                                    projectCreationActions.update({
                                        coupon: undefined,
                                        priceId,
                                    }),
                                );
                            },
                        }),
                    );
                }}
            />
        ),
    };

    useEffect(() => {
        dispatch(paymentMethodsActions.retrieve.request({ type: 'card' }));
    }, [dispatch]);

    return (
        <PaymentWizard
            onCancel={() =>
                dispatch(projectCreationActions.cancel({ redirectPath: paths.createProject }))
            }
            basePath={paths.paymentWizard}
            steps={steps}
            views={[
                <SeatSelectionStep
                    default
                    key={paymentWizardPaths[0]}
                    path={paymentWizardPaths[0]}
                    seats={seats}
                    onUpdate={onSeatsUpdate}
                    onSubmit={onSeatsSubmit}
                    summary={summary}
                    labels={{
                        notification: t('hub-select-seats-area-inline-notification-at-least-five'),
                    }}
                />,
                <PaymentDetailsStep
                    key={paymentWizardPaths[1]}
                    path={paymentWizardPaths[1]}
                    onSubmit={onPaymentDetailsSubmit}
                    onPaymentTypeUpdate={onPaymentTypeUpdate}
                    onPaymentMethodSubmit={onPaymentMethodSubmit}
                    currentPaymentMethodId={currentPaymentMethodId}
                    invoice={{
                        address,
                        taxId: taxId,
                        type: formType,
                    }}
                    summary={summary}
                />,
                <ConfirmAndPayStep
                    path={paymentWizardPaths[2]}
                    key={paymentWizardPaths[2]}
                    projectData={projectData}
                    billingCycle={billingCycle}
                    onSeatsChange={onSeatsChange}
                    onPaymentMethodsChange={onPaymentMethodsChange}
                    summary={{
                        ...summary,
                        onSubmit: () => {
                            dispatch(
                                projectCreationActions.create.request({
                                    projectData: projectData!,
                                    onSuccess: (pId: string) => {
                                        navigate(`${paths.project}/${pId}`).then((_) =>
                                            dispatch(endWizard()),
                                        );
                                        dispatch(fetchBillingInfo.request());
                                        dispatch(projectListActions.projectListFetch.request());
                                    },
                                }),
                            );
                        },
                    }}
                />,
            ]}
        />
    );
};
