import { call, put, takeLatest } from 'redux-saga/effects';

import { addErrorToast } from 'modules/toasts/actions';
import * as actions from './actions';
import * as api from './api';
import { I18nDictionary } from './types';
import loggingActions from 'modules/logger/actions';

function* fetchDictionary() {
    try {
        const dictionary: I18nDictionary = yield call(api.fetchDictionary);
        yield put(actions.fetchDictionary.success(dictionary));
    } catch (e) {
        yield put(actions.fetchDictionary.failure(e));
    }
}

export default function* i18nSaga() {
    yield takeLatest(actions.fetchDictionary.request, fetchDictionary);
    yield takeLatest(
        actions.fetchDictionary.failure,
        function* ({ payload }: ReturnType<typeof actions.fetchDictionary.failure>) {
            yield put(
                addErrorToast({
                    id: 'i18n-init-error',
                    type: 'error',
                    label: 'An error while loading the data',
                    isAutoDismissEnabled: false,
                    actions: [
                        {
                            label: 'Close',
                        },
                    ],
                }),
            );

            yield put(
                loggingActions.error({
                    msg: 'Unable to fetch dictionaries',
                    error: payload,
                }),
            );
        },
    );
}
