import React from 'react';
import { HubModules } from '../modules/HubModules';
import { Modules } from '../modules/Modules';

export const HubModulesContext = React.createContext<Modules>(new HubModules());

interface Props {
    modules: Modules;
}

export const HubModulesProvider: React.FC<Props> = ({ children, modules }) => {
    return <HubModulesContext.Provider value={modules}>{children}</HubModulesContext.Provider>;
};
