import { HubState } from '../../../types';
import { ProjectId } from '../../../core/entities/project/Project';
import { ProjectDetailForView } from './types';

const selectProjectById = (id: ProjectId) => (state: HubState): ProjectDetailForView | undefined =>
    state?.projectDetail ? state.projectDetail[id] : undefined;

export const selectProjectDetailStatus = (state: HubState) => state?.projectDetail?.status;

export const isPaymentMethodModalOpen = (state: HubState) =>
    state?.projectDetail?.paymentMethodModal === 'OPEN';

export const isBillingDetailsModalOpen = (state: HubState) =>
    state?.projectDetail?.billingDetailsModal === 'OPEN';

export const isInvoicesSheetOpen = (state: HubState) =>
    state?.projectDetail?.invoicesSheet === 'OPEN';

export const projectDetailSelectors = {
    selectProjectById,
    selectProjectDetailStatus,
    selectModalOpen: isPaymentMethodModalOpen,
    isInvoicesSheetOpen,
};
