import { HttpClient } from 'services/HttpClient';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ProjectCreationDto, ProjectDetailDto, ProjectDto, ProjectUpgradeDto } from './types/dto';
import { Project, ProjectId, ProjectStatus } from 'core/entities/project/Project';
import { ProjectDetail } from 'core/entities/project/ProjectDetail';
import { ProjectUpdateDto } from './update/types/dto';
import { projectManager } from 'paths';

export class HttpProjectRepository implements ProjectRepository {
    constructor(
        private readonly client: HttpClient,
        private readonly baseUrl: string,
        private readonly adaptToProjectDetail: (dto: ProjectDetailDto) => ProjectDetail,
    ) {}

    async create(dto: ProjectCreationDto): Promise<Project> {
        try {
            const response = await this.client.post<ProjectCreationDto, ProjectDto>(
                this.baseUrl,
                dto,
            );

            return this.adaptToProject(response);
        } catch (e) {
            throw new Error('Unable to create project');
        }
    }

    async getBy(id: ProjectId): Promise<ProjectDetail> {
        const url = [this.baseUrl, id].join('/');

        try {
            const response = await this.client.get<ProjectDetailDto>(url);

            return this.adaptToProjectDetail(response);
        } catch (e) {
            throw new Error('Unable to get project with id ' + id);
        }
    }

    async terminate(id: ProjectId): Promise<void> {
        const url = [this.baseUrl, id, 'terminate'].join('/');

        try {
            await this.client.put(url);
        } catch (e) {
            throw new Error('Unable to terminate project with id ' + id);
        }
    }

    async list(): Promise<Project[]> {
        try {
            const projects = await this.client.get<ProjectDto[]>(this.baseUrl);

            return this.adaptToProjects(projects);
        } catch (e) {
            throw new Error('Unable to retrieve projects');
        }
    }

    async update(id: ProjectId, dto: Partial<ProjectUpdateDto>): Promise<void> {
        const url = [this.baseUrl, '/', id].join('');

        try {
            await this.client.patch<Partial<ProjectUpdateDto>, void>(url, dto);
        } catch (e) {
            throw new Error('Unable to update project with id ' + id);
        }
    }

    async delete(id: ProjectId): Promise<void> {
        const url = [this.baseUrl, id].join('/');

        try {
            await this.client.delete(url);
        } catch (e) {
            throw new Error(`Unable to delete project with id ${id}`);
        }
    }

    async restart(id: ProjectId): Promise<void> {
        const url = [this.baseUrl, id, 'restart'].join('/');

        try {
            await this.client.put(url);
        } catch (e) {
            throw new Error(`Unable to restart project with id ${id}`);
        }
    }

    async upgrade(projectId: string, dto: ProjectUpgradeDto): Promise<void> {
        const url = `${projectManager}/private/upgrade/project/${projectId}`;

        try {
            await this.client.put<ProjectUpgradeDto, ProjectDetailDto>(url, dto);
        } catch (e) {
            throw new Error('Unable to upgrade project with id ' + projectId);
        }
    }

    private adaptToProjects(dto: ProjectDto[] = []): Project[] {
        return dto.map(this.adaptToProject);
    }

    private adaptToProject(project: ProjectDto): Project {
        return {
            id: project.id,
            domain: project.domain,
            avatarUrl: project.avatar_url,
            numberOfSeats: project.number_of_seats,
            status: ProjectStatus[project.status],
            languages: project.languages,
            name: project.name,
            owner: project.owner,
        };
    }
}
