import React, { useEffect, useRef, useState } from 'react';

import { getColor } from '@igenius_dev/crystalkit/lib/components/Avatar/utils/getColor';

import { ItemCenter, ItemListContent, StyledItem } from 'components/Sidebar/elements';
import { SidebarContent, SidebarItem } from 'components/Sidebar/EnrichedSidebar';
import { ScrollableItems } from 'components/Sidebar/Items/elements';
import Scrollbars from 'react-custom-scrollbars';
import { TaggedAvatar } from 'components/Sidebar/TaggedAvatar';
import { SmallText } from 'components/Typography/SmallText';
import { BaseText } from 'components/Typography/BaseText';

interface ItemsProp extends SidebarContent {}

export const Items = ({ status, items }: ItemsProp) => {
    const [scrollbarIsVisible, setIsScrollbarVisible] = useState(false);
    const [scrollTop, setScrollTop] = useState(0);
    const scrollableRef = useRef<Scrollbars | null>();

    useEffect(() => {
        const clientHeight = scrollableRef.current?.getClientHeight();
        const scrollHeight = scrollableRef.current?.getScrollHeight();

        setIsScrollbarVisible(clientHeight !== scrollHeight);
    }, [setIsScrollbarVisible]);

    const hasBottomBorder = scrollbarIsVisible && scrollTop < 1;
    const hasTopBorder = scrollTop > 0;

    return status === 'collapsed' ? (
        <ItemListContent
            items={items}
            renderItem={({ id, label, onClick, selected }: SidebarItem) => (
                <StyledItem
                    selected={selected}
                    onClick={onClick}
                    center={<TaggedAvatar label={label as string} color={getColor(id)} />}
                    value={label}
                />
            )}
        />
    ) : (
        <ScrollableItems
            ref={(n) => (scrollableRef.current = n)}
            withBottomBorder={hasBottomBorder}
            withTopBorder={hasTopBorder}
            hasScrollbar={scrollbarIsVisible}
            onScrollFrame={({ top }) => setScrollTop(top)}
        >
            <ItemListContent
                items={items}
                renderItem={({ id, label, subtitle, onClick, selected = false }: SidebarItem) => (
                    <StyledItem
                        selected={selected}
                        onClick={onClick}
                        left={<TaggedAvatar label={label as string} color={getColor(id)} />}
                        center={
                            <ItemCenter>
                                <BaseText ellipsis>{label}</BaseText>
                                <SmallText color="black200">{subtitle}</SmallText>
                            </ItemCenter>
                        }
                        value={label}
                    />
                )}
            />
        </ScrollableItems>
    );
};
