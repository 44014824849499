import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProjectId } from '../../../../core/entities/project/Project';

export const changePlan = {
    start: createAction('@project-details/PROJECT_UPDATE_CHANGE_PLAN_START')<{
        id: ProjectId;
    }>(),
    submit: createAsyncAction(
        '@project-details/PROJECT_UPDATE_CHANGE_PLAN_REQUEST',
        '@project-details/PROJECT_UPDATE_CHANGE_PLAN_SUCCESS',
        '@project-details/PROJECT_UPDATE_CHANGE_PLAN_FAILURE',
    )<
        {
            id: ProjectId;
            planId: string;
            priceId: string;
        },
        undefined,
        undefined
    >(),
    cancel: createAction('@project-details/PROJECT_UPDATE_CHANGE_PLAN_CANCEL')(),
};
