import React from 'react';

import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';

import somethingWentWrong from '../../assets/29.png';
import { HubIllustrationDialog } from '../../components/HubIllustrationDialog';
import useTranslator from '../../hooks/useTranslator';

type AreYouSureYouWantToLeaveProps = {
    open: boolean;
    onClose: () => void;
    onConfirm: () => void;
};

export const AreYouSureYouWantToLeave: React.FC<AreYouSureYouWantToLeaveProps> = ({
    open = false,
    onClose,
    onConfirm,
}) => {
    const t = useTranslator();

    return (
        <HubIllustrationDialog
            open={open}
            onClose={onClose}
            actions={[
                {
                    label: t('hub-payment-wizard-exit-dialogue-button-cancel'),
                    onClick: onClose,
                },
                {
                    label: t('hub-payment-wizard-exit-dialogue-button-leave'),
                    negative: true,
                    onClick: onConfirm,
                },
            ]}
            illustration={{
                title: t('hub-payment-wizard-exit-dialogue-title'),
                subtitle: t('hub-payment-wizard-exit-dialogue-body'),
                imageSrc: somethingWentWrong,
                imageSize: Dialog.IllustrationContent.imageSize.small,
            }}
        />
    );
};
