import { CreditCardDTO } from 'common/dtos';

export enum UserNotificationTypeDTO {
    ExpiredCard = 'expired_card',
    ExpiringCard = 'expiring_card',
}

interface BaseUserNotificationDTO<K extends UserNotificationTypeDTO, T> {
    type: K;
    payload: T;
}

export type ExpiringCardDTO = BaseUserNotificationDTO<
    UserNotificationTypeDTO.ExpiringCard,
    CreditCardDTO
>;
export type ExpiredCardDTO = BaseUserNotificationDTO<
    UserNotificationTypeDTO.ExpiredCard,
    CreditCardDTO
>;

export type UserNotificationDto = (ExpiringCardDTO | ExpiredCardDTO)[];
