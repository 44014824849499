import { ActionType, getType } from 'typesafe-actions';
import { projectCreationInfoActions } from './actions';
import { ProjectCreationInfoState, ProjectCreationInfoStatus } from './types';

export const projectCreationInfoReducer = (
    state: ProjectCreationInfoState = { status: ProjectCreationInfoStatus.INITIAL },
    action: ActionType<typeof projectCreationInfoActions>,
) => {
    switch (action.type) {
        case getType(projectCreationInfoActions.success): {
            return {
                ...state,
                status: ProjectCreationInfoStatus.READY,
                info: action.payload,
            };
        }
        case getType(projectCreationInfoActions.failure): {
            return {
                ...state,
                status: ProjectCreationInfoStatus.ERROR,
            };
        }
        case getType(projectCreationInfoActions.request): {
            return {
                ...state,
                status: ProjectCreationInfoStatus.PENDING,
            };
        }
        default:
            return state;
    }
};
