import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { useSelector } from 'react-redux';

import { OrderSummary, SummaryProps } from '../Summary/OrderSummary';
import { PageTitle, PaymentWizardWidget, SubmitButton } from '../elements';
import { GenericStep } from '../GenericStep';
import { Seats } from './Seats';
import { PaymentDetailsSummary } from './PaymentDetailsSummary';
import { HubState } from '../../../types';
import { Content } from './elements';
import { projectCreationSelectors } from '../../../modules/project/create/selectors';
import { ProjectCreationData } from '../../../modules/project/create/types';
import { selectPaymentMethods } from '../../../modules/payment-methods/selectors';
import { expirationTimestampFromTimeInterval } from '../../../utils/expirationTimestampFromTimeInterval';
import { formatDateTo } from '../../../utils/formatDateTo';
import { TimeInterval } from '../../../core/entities/Price';
import { projectUpdateSelectors } from '../../../modules/project/update/selectors';
import useTranslator from '../../../hooks/useTranslator';
import { PaymentMethod } from '../../../core/entities/payment-method/PaymentMethod';

interface Props {
    billingCycle: TimeInterval;
    projectData?: ProjectCreationData;
    summary: Omit<SummaryProps, 'actionButton'> & { onSubmit: () => void };
    onSeatsChange: () => void;
    onPaymentMethodsChange: () => void;
}

export const ConfirmAndPayStep: React.FC<RouteComponentProps & Props> = ({
    projectData,
    summary,
    billingCycle,
    onPaymentMethodsChange,
    onSeatsChange,
}) => {
    const t = useTranslator();
    const cards = useSelector<HubState, PaymentMethod[]>(selectPaymentMethods);
    const expiryTs = expirationTimestampFromTimeInterval(Date.now(), billingCycle);
    const isSubmittingChange = useSelector<HubState, boolean>(
        projectUpdateSelectors.isRequestingChange,
    );
    const isSubmittingCreation = useSelector<HubState, boolean>(
        projectCreationSelectors.isSubmittingCreation,
    );
    const { onSubmit, plan, seats, subscription, disclaimer, coupon } = summary;
    const isSubmitting = isSubmittingChange || isSubmittingCreation;

    return projectData ? (
        <GenericStep
            left={
                <>
                    <PageTitle>
                        {t('hub-confirm-and-subscribe-section-title-confirm-and-subscribe')}
                    </PageTitle>
                    <PaymentWizardWidget>
                        <Content>
                            <Seats numberOfSeats={projectData.seats} onChange={onSeatsChange} />
                            <PaymentDetailsSummary
                                address={projectData.invoice.address}
                                taxId={projectData.invoice.taxId}
                                paymentMethod={
                                    cards.find((p) => p.id === projectData.paymentMethodId)!
                                }
                                seats={projectData.seats}
                                billingCycle={billingCycle}
                                onChange={onPaymentMethodsChange}
                                expiry={formatDateTo(expiryTs)}
                            />
                        </Content>
                    </PaymentWizardWidget>
                </>
            }
            right={
                <OrderSummary
                    seats={seats}
                    subscription={subscription}
                    plan={plan}
                    disclaimer={disclaimer}
                    coupon={coupon}
                    actionButton={
                        <SubmitButton
                            loading={isSubmitting}
                            label={t('hub-column-recap-continue')}
                            onClick={onSubmit}
                            disabled={!projectData}
                        />
                    }
                />
            }
        />
    ) : null;
};
