import { call, put, takeLatest } from 'redux-saga/effects';
import { projectCreationInfoActions } from './actions';
import { CreationInfoUseCase } from '../../core/entities/creation-info/CreationInfoUseCase';
import { CreationInfoForView } from './types';
import { CreationInfo } from '../../core/entities/creation-info/CreationInfo';

export function createProjectCreationInfoSaga(
    useCase: CreationInfoUseCase,
    viewAdapter: (info: CreationInfo) => CreationInfoForView,
) {
    return function* projectCreationInfoSaga() {
        yield takeLatest(projectCreationInfoActions.request, function* () {
            try {
                const response: CreationInfo = yield call([useCase, 'fetch']);
                const adapted: CreationInfoForView = viewAdapter(response);

                yield put(projectCreationInfoActions.success(adapted));
            } catch (e) {
                yield put(projectCreationInfoActions.failure(e.message));
            }
        });
    };
}
