import { ActionType, getType } from 'typesafe-actions';
import * as actions from './actions';
import {
    NotificationCategory,
    NotificationList,
    NotificationsByCategory,
    NotificationsState,
} from './types';

const initialState = {
    allIds: [],
    byCategory: {},
};
export const notificationsReducer = (
    state: NotificationsState = initialState,
    action: ActionType<typeof actions>,
) => {
    switch (action.type) {
        case getType(actions.setNotification.success): {
            const { category, id } = action.payload;
            if (state.allIds.includes(id)) {
                return state;
            }
            const previousNotifications = state.byCategory[category] ?? [];
            return {
                ...state,
                allIds: [...state.allIds, id],
                byCategory: {
                    ...state.byCategory,
                    [category]: [...previousNotifications, action.payload],
                },
            };
        }

        case getType(actions.removeNotification.success): {
            const { id, category } = action.payload;

            if (!(category in state.byCategory) || !state.allIds.includes(id)) {
                return state;
            }

            const updatedAllIds = removeFromAllIds(id, state.allIds);
            const updatedCategories = removeFromCategory(id, state.byCategory, category);

            return {
                ...state,
                allIds: updatedAllIds,
                byCategory: updatedCategories,
            };
        }

        case getType(actions.removeCategory): {
            const categoryToRemove = action.payload;
            if (!state.byCategory[categoryToRemove]) {
                return state;
            }
            const idsToRemove = extractIDsFromCategory(state.byCategory[categoryToRemove]);
            const newAllIds = state.allIds.filter((id) => !idsToRemove.includes(id));
            const { [categoryToRemove]: _, ...rest } = state.byCategory;
            return {
                ...state,
                allIds: newAllIds,
                byCategory: rest,
            };
        }

        default:
            return state;
    }
};

export function removeFromCategory(
    id: string,
    categories: NotificationsByCategory,
    category: NotificationCategory,
): NotificationsByCategory {
    if (!categories[category]) {
        return categories;
    }
    const hasOnlyOneNotification = categories[category].length === 1;
    if (hasOnlyOneNotification) {
        const { [category]: _, ...rest } = categories;
        return rest;
    }
    return { [category]: categories[category].filter((notification) => notification.id !== id) };
}

export function removeFromAllIds(id: string, allIds: string[]) {
    return [...allIds.filter((currID) => currID !== id)];
}

function extractIDsFromCategory(category: NotificationList) {
    return Object.values(category).map((n) => n.id);
}
