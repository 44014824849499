import { ProjectId } from 'core/entities/project/Project';
import { FetchInvoicesUseCase } from 'core/entities/invoice/FetchInvoicesUseCase';
import { InvoicesRepository } from 'core/repository/invoice/InvoicesRepository';
import { Invoices } from 'core/entities/invoice/Invoices';

export class HttpFetchInvoicesUseCase implements FetchInvoicesUseCase {
    constructor(private readonly invoices: InvoicesRepository) {}

    async fetch(projectId: ProjectId, lastInvoiceId?: string): Promise<Invoices> {
        try {
            return await this.invoices.getAllOf(projectId, lastInvoiceId);
        } catch (error) {
            throw new Error(`failed to fetch invoices for ${projectId}`);
        }
    }
}
