import Button from '@igenius_dev/crystalkit/lib/components/Button';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import Tile from '@igenius_dev/crystalkit/lib/components/Tile';
import theme from '@igenius_dev/crystalkit/lib/theme';
import styled from 'styled-components';

const tilePadding = 40;

export const RightContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 444px;
    width: 100%;
    position: relative;
`;

export const LeftContent = styled.div`
    width: 100%;
    max-width: 1400px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media (max-width: 1279px) {
        margin-top: 60px;
    }
`;

export const StyledTile = styled(Tile)`
    width: 100%;
    padding: ${tilePadding}px 24px;
    background-color: ${theme.colors.values.white};

    @media (max-width: 1279px) {
        margin-top: 16px;
    }
`;

const Title = styled(Text).attrs({ scale: 'Title' })``;

export const TitleContainer = styled.div`
    margin-bottom: 26px;

    @media (max-width: 1279px) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 36px;
        align-items: center;
    }

    @media (min-width: 1280px) {
        button {
            display: none;
        }
    }
`;

export const WelcomeTitle = styled(Title)``;

export const StyledForm = styled.form`
    display: flex;
    width: 100vw;
`;

export const Border = styled.div`
    height: 100%;
    background-color: ${theme.colors.values.purple700};
    width: 8px;
    position: absolute;
    left: 0;
`;

export const InfoBox = styled.div`
    position: relative;
    overflow: hidden;
    background-color: ${theme.colors.values.blue25};
    border-radius: 8px;
    height: 56px;
    padding: 8px 8px 8px 16px;
    display: grid;
    grid-template-columns: 40px auto 136px;
    align-items: center;

    @media (min-width: 1280px) {
        display: none;
    }
`;

export const InfoText = styled(Text).attrs({
    color: 'black500',
    scale: 'Small',
})`
    [data-lokalise] {
        color: ${theme.colors.values.purple700};
    }
`;

export const InfoIcon = styled(Icon).attrs({ icon: 'info' })`
    margin: 0 8px;

    svg {
        color: ${theme.colors.values.purple700};
    }
`;

export const LearnMore = styled(Button).attrs({
    size: 'small',
    inverted: true,
})`
    height: 24px;
    margin: 0 8px;
`;

export const SubmitButton = styled(Button).attrs({
    type: 'submit',
    size: 'small',
    color: 'purple',
})`
    height: 36px;
`;

export const FluidSubmitButton = styled(SubmitButton)`
    width: 100%;
    height: 56px;
`;
