import React, { useEffect } from 'react';
import HubModal from 'components/HubModal';

import { LargeText } from 'components/Typography/LargeText';
import { MediumText } from 'components/Typography/MediumText';
import { SeatsWithPrice } from 'components/Price/SeatsWithPrice';
import { expirationTimestampFromTimeInterval } from 'utils/expirationTimestampFromTimeInterval';
import { formatDateTo } from 'utils/formatDateTo';
import {
    ActionContainer,
    Background,
    CancelButton,
    CenteredLoaderContainer,
    ProjectName,
    ProjectRecap,
    SaveButton,
    SeatsPriceContainer,
} from './elements';
import { useDispatch, useSelector } from 'react-redux';
import { invoicePreviewSelectors } from 'modules/invoice/selectors';
import projectUpdateActions from 'modules/project/update/actions';
import { invoicePreviewActions } from 'modules/invoice/actions';
import { projectUpdateSelectors } from 'modules/project/update/selectors';
import { HubState } from 'types';
import { InvoicePreviewForView } from 'modules/invoice/types/views';
import { Coupon } from './Coupon';
import { CenteredLoader } from 'components/CenteredLoader';
import { PriceForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';
import { CouponRowWithPriceAndCode } from './CouponRowWithPriceAndCode';
import { TotalPriceWithNextBilling } from 'components/Price/TotalPriceWithNextBilling';

type Props = {
    projectName: string;
    price?: PriceForView;
    seats: number;
    projectId: string;
};

export const AddCouponModal = (props: Props) => {
    const { projectName, price, seats, projectId } = props;
    const priceId = price?.id;
    const dispatch = useDispatch();
    const t = useTranslator();
    const open = useSelector(projectUpdateSelectors.isCouponModalOpen);
    const invoice = useSelector<HubState, InvoicePreviewForView | undefined>(
        invoicePreviewSelectors.selectInvoice,
    );
    const isStatusError = useSelector(invoicePreviewSelectors.isStatusError);
    const isLoading = useSelector(invoicePreviewSelectors.isLoading);
    const onClose = () => dispatch(projectUpdateActions.coupon.hideModal());
    const nextBillingDate = price
        ? formatDateTo(expirationTimestampFromTimeInterval(Date.now(), price.timeInterval))
        : '';
    const couponCode = invoice?.coupon?.code;
    const couponDiscount = invoice?.coupon?.discount;
    const discount = t('hub-project-detail-dialogue-chip-coupon-discount-value', {
        payload: { DISCOUNT: couponDiscount },
    });
    const shouldSaveButtonBeDisabled = isStatusError || !couponCode;
    const isSubmitting = useSelector(projectUpdateSelectors.isRequestingChange);

    useEffect(() => {
        open &&
            dispatch(
                invoicePreviewActions.fetch.request({
                    data: {
                        projectId,
                        priceId,
                        seats,
                    },
                }),
            );
    }, [dispatch, projectId, seats, priceId, open]);

    return (
        <HubModal
            content={
                <>
                    <LargeText>
                        {t('hub-project-detail-dialogue-title-coupon-apply-a-coupon')}
                    </LargeText>
                    <Coupon
                        labels={{
                            applied: t('hub-project-detail-dialogue-label-coupon-applied'),
                            nextBilling: t(
                                'hub-project-detail-dialogue-coupon-subtitle-discount-applied-next-invoice',
                                {
                                    payload: {
                                        DATE: nextBillingDate,
                                    },
                                },
                            ),
                        }}
                        isStatusError={isStatusError}
                        coupon={{ code: couponCode, discount }}
                        onReset={() => {
                            dispatch(
                                invoicePreviewActions.fetch.request({
                                    data: {
                                        projectId,
                                        coupon: undefined,
                                    },
                                }),
                            );
                        }}
                        onApply={(coupon: string) => {
                            dispatch(
                                invoicePreviewActions.fetch.request({
                                    data: {
                                        projectId,
                                        coupon,
                                    },
                                }),
                            );
                        }}
                    />
                    <ProjectRecap>
                        <MediumText>
                            {t('hub-project-detail-dialogue-title-section-project-recap')}
                        </MediumText>
                        <Background>
                            <ProjectName>{projectName}</ProjectName>
                            {price && (
                                <SeatsPriceContainer>
                                    <SeatsWithPrice currentPrice={price} seats={seats} />
                                </SeatsPriceContainer>
                            )}
                        </Background>
                        {isLoading ? (
                            <CenteredLoaderContainer>
                                <CenteredLoader />
                            </CenteredLoaderContainer>
                        ) : (
                            <>
                                {couponCode && (
                                    <CouponRowWithPriceAndCode
                                        code={couponCode}
                                        discount={discount}
                                    />
                                )}
                                {price && (
                                    <TotalPriceWithNextBilling
                                        invoice={invoice}
                                        disclaimer={t(
                                            'hub-project-detail-dialogue-label-next-invoice',
                                            {
                                                payload: { DATE: nextBillingDate },
                                            },
                                        )}
                                    />
                                )}
                            </>
                        )}
                    </ProjectRecap>
                    <ActionContainer>
                        <CancelButton
                            onClick={onClose}
                            label={t('hub-project-detail-apply-coupon-dialogue-button-cancel')}
                        />
                        <SaveButton
                            loading={isSubmitting}
                            disabled={shouldSaveButtonBeDisabled}
                            onClick={() =>
                                dispatch(
                                    projectUpdateActions.request.request({
                                        projectData: {
                                            id: projectId,
                                            coupon: couponCode,
                                        },
                                        onSuccess: () =>
                                            dispatch(projectUpdateActions.coupon.hideModal()),
                                    }),
                                )
                            }
                            label={t('hub-project-detail-apply-coupon-dialogue-button-save')}
                        />
                    </ActionContainer>
                </>
            }
            open={open}
            onClose={onClose}
        />
    );
};
