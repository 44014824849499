import React from 'react';
import { createPortal } from 'react-dom';
import { useEffect, useState } from 'react';

type Props = {
    targetId: string;
};

export const Portal: React.FC<Props> = ({ children, targetId }) => {
    const [target, setTarget] = useState<HTMLElement | null>();

    useEffect(() => {
        const t = document.getElementById(targetId);
        setTarget(t);
    }, [targetId]);

    return target ? createPortal(children, target) : <>{children}</>;
};
