import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';

import somethingWentWrong from 'assets/29.png';
import { HubIllustrationDialog } from 'components/HubIllustrationDialog';
import React from 'react';
import useTranslator from '../../../../../hooks/useTranslator';

type DoYouReallyWantToUnsubscribeProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
};

const DoYouReallyWantToUnsubscribe: React.FC<DoYouReallyWantToUnsubscribeProps> = ({
    open = false,
    onClose,
    onSubmit,
}) => {
    const t = useTranslator();

    return (
        <HubIllustrationDialog
            open={open}
            onClose={onClose}
            actions={[
                {
                    label: t('hub-dialogue-unsubscribe-from-this-project-button-no'),
                    onClick: () => {
                        onClose();
                    },
                },
                {
                    label: t('hub-dialogue-unsubscribe-from-this-project-button-yes'),
                    onClick: () => {
                        onSubmit();
                    },
                    negative: true,
                },
            ]}
            illustration={{
                title: t('hub-dialogue-unsubscribe-from-this-project-title'),
                subtitle: t('hub-dialogue-unsubscribe-from-this-project-body'),
                imageSrc: somethingWentWrong,
                imageSize: Dialog.IllustrationContent.imageSize.small,
            }}
        />
    );
};

export default DoYouReallyWantToUnsubscribe;
