import { BillingInfo } from 'core/entities/billing-details/BillingInfo';
import { BillingInfoForView } from 'modules/billing-info/types/types';
import { VatTypes } from 'common/utils/VatTypes';
import { Countries } from 'common/utils/Countries';

const onlyDefinedValues = (value?: string) => !!value;
const withoutTrailingSpaces = (value: string) => value.trim();
const getCommaSeparatedValues = (...params: any[]): string =>
    params.filter(onlyDefinedValues).map(withoutTrailingSpaces).join(', ');

export const createBillingInfoForViewAdapter = (vatTypes: VatTypes, countries: Countries) => {
    return function toBillingInfoForView(params: BillingInfo): BillingInfoForView {
        const { tax, address, ...restOfInfo } = params;

        const adapted: BillingInfoForView = {
            ...restOfInfo,
            userType: !!tax ? 'company' : 'person',
            completeAddress: getCommaSeparatedValues(
                restOfInfo.name,
                address?.line1,
                [address?.postalCode, address?.city, address?.country]
                    .filter(onlyDefinedValues)
                    .join(' '),
                tax?.value,
            ),
        };

        if (tax?.value && tax.type && tax.country) {
            adapted.tax = {
                ...tax,
                label: vatTypes.findBy(tax.type, tax.country),
            };
        }

        if (address) {
            adapted.address = {
                ...address,
                country: countries.getBy(address.country),
            };
        }

        return adapted;
    };
};
