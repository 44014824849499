import React from 'react';

import { Action, Container, Illustration, Subtitle, Title, Wrapper } from './elements';

type Props = {
    image: React.ReactElement;
    action?: React.ReactElement;
    title: React.ReactElement | string;
    subtitle: React.ReactElement | string;
};

export const EmptyState = (props: Props) => {
    const { image, action, title, subtitle } = props;

    return (
        <Wrapper>
            <Container>
                <Illustration>{image}</Illustration>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
                {action && <Action>{action}</Action>}
            </Container>
        </Wrapper>
    );
};
