import { adaptToPaymentMethod } from 'common/adapters/adaptToPaymentMethod';
import {
    ExpiredCardNotification,
    ExpiringCardNotification,
    UserNotifications,
    UserNotificationType,
} from '../types';
import {
    ExpiredCardDTO,
    ExpiringCardDTO,
    UserNotificationDto,
    UserNotificationTypeDTO,
} from './types';

export function adaptToUserNotifications(r: UserNotificationDto): UserNotifications {
    return r.map((n) => adaptCard(n));
}

function adaptCard(
    n: ExpiringCardDTO | ExpiredCardDTO,
): ExpiringCardNotification | ExpiredCardNotification {
    const type = adaptType(n.type);
    const payload = adaptToPaymentMethod(n.payload);
    return {
        type,
        payload,
    };
}

function adaptType(t: UserNotificationTypeDTO): UserNotificationType {
    switch (t) {
        case UserNotificationTypeDTO.ExpiredCard:
            return UserNotificationType.ExpiredCard;
        case UserNotificationTypeDTO.ExpiringCard:
            return UserNotificationType.ExpiringCard;
    }
}
