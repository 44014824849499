import { fork, put, takeEvery, delay } from 'redux-saga/effects';
import * as toastsActions from '../actions';

const AUTO_DISMISS_DURATION = 5000;

function* autoDismissToast(toastId) {
    yield delay(AUTO_DISMISS_DURATION);
    yield put(toastsActions.removeToast(toastId));
}

function* handleAddToast(action) {
    const { id: toastId, isAutoDismissEnabled } = action.payload;

    if (isAutoDismissEnabled) {
        yield fork(autoDismissToast, toastId);
    }
}

export default function* toastsSaga() {
    yield takeEvery(toastsActions.addSuccessToast, handleAddToast);
}
