import { Countries } from 'common/utils/Countries';
import { VatTypes } from 'common/utils/VatTypes';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { UiModuleState } from 'modules/ui/types';

export const getUiModule = (
    countries: Countries,
    vatTypes: VatTypes,
): ISagaModule<UiModuleState> => {
    return {
        id: 'ui',
        reducerMap: {
            ui: () => ({
                countries: countries.list(),
                vatTypes: vatTypes.list(),
            }),
        },
    };
};
