import { ISagaModule } from 'redux-dynamic-modules-saga';
import { projectDetailReducer } from './reducer';
import { createProjectDetailSaga } from './saga';
import { ProjectDetailForView, ProjectDetailModuleState } from './types';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ProjectDetail } from 'core/entities/project/ProjectDetail';

export const getProjectDetailModule = (
    repository: ProjectRepository,
    adapterForView: (project: ProjectDetail) => ProjectDetailForView,
): ISagaModule<ProjectDetailModuleState> => ({
    id: 'projectDetail',
    reducerMap: { projectDetail: projectDetailReducer } as any,
    sagas: [createProjectDetailSaga(repository, adapterForView)],
});
