import { HubState } from 'types';
import { Project } from 'core/entities/project/Project';

const selectProjects = (state: HubState): Project[] => state.projectList?.projects || [];

const isModuleReady = (state: HubState): boolean => state.projectList?.status === 'READY' || false;

const projectListSelectors = {
    selectProjects,
    isModuleReady,
};

export default projectListSelectors;
