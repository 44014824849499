import React from 'react';

import { SidebarContent, SidebarItem } from '../EnrichedSidebar';
import {
    CollapsedIcon,
    ExpandedIcon,
    FooterItem,
    ItemListFooter,
} from 'components/Sidebar/Footer/elements';

interface FooterProps extends SidebarContent {}

export const Footer = ({ status, items }: FooterProps) => {
    return status === 'expanded' ? (
        <ItemListFooter
            items={items}
            renderItem={({ icon, label, onClick }: SidebarItem) => (
                <FooterItem
                    onClick={onClick}
                    left={<ExpandedIcon>{icon}</ExpandedIcon>}
                    center={label}
                    value={label}
                />
            )}
        />
    ) : (
        <ItemListFooter
            items={items}
            renderItem={({ icon, label, onClick }: SidebarItem) => (
                <FooterItem
                    center={<CollapsedIcon>{icon}</CollapsedIcon>}
                    value={label}
                    onClick={onClick}
                />
            )}
        />
    );
};
