import { LabelValuePair } from 'common/types';
import React from 'react';
import { NonInteractiveStatefulChip, Slider } from './elements';

type Value = string;

type Props = {
    chips: [LabelValuePair, LabelValuePair];
    currentSelection: Value;
    onChange: (v: 'person' | 'company') => void;
};
export const ChipSlider = ({ chips, currentSelection, onChange }: Props) => (
    <Slider>
        <>
            {chips.map((c) => (
                <NonInteractiveStatefulChip
                    isSelected={c.value === currentSelection}
                    label={c.label}
                    onClick={(_) => onChange(c.value as 'person' | 'company')}
                />
            ))}
        </>
    </Slider>
);
