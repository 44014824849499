import styled from 'styled-components';
import { Table } from '../../Project/Detail/Detail/Table/Table';

import Text from '@igenius_dev/crystalkit/lib/components/Text';
import Button from '@igenius_dev/crystalkit/lib/components/Button';
import theme from '@igenius_dev/crystalkit/lib/theme';

export const StyledTable = styled(Table)`
    background-color: ${theme.colors.values.blue15};
    border-radius: 8px;
    padding: 0 24px;
    margin-top: 24px;

    & > * {
        border-bottom-color: ${theme.colors.values.black50};
        padding: 22px 0;
    }
`;

export const Label = styled(Text)`
    color: ${theme.colors.values.purple700};
`;

export const Value = styled(Text).attrs({ scale: 'Small' })`
    color: ${theme.colors.values.black300};
    display: flex;
    word-break: break-all;
    align-items: center;
`;

export const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const ChangeButton = styled(Button).attrs({
    size: 'small',
    inverted: true,
    secondary: true,
})``;

export const Content = styled.div`
    display: flex;
    flex-direction: column;

    & > * {
        margin-bottom: 38px;
    }

    & > *:last-child {
        margin-bottom: 0;
    }
`;
