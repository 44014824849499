import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';

import somethingWentWrong from 'assets/29.png';
import { HubIllustrationDialog } from 'components/HubIllustrationDialog';
import React from 'react';
import { contactSupport } from 'utils/support';
import useTranslator from '../../../../hooks/useTranslator';

type ProjectCreationFailedProps = {
    open: boolean;
    onClose: () => void;
};

const ProjectCreationFailed: React.FC<ProjectCreationFailedProps> = ({ open = false, onClose }) => {
    const t = useTranslator();

    return (
        <HubIllustrationDialog
            open={open}
            onClose={onClose}
            actions={[
                {
                    label: t('hub-project-creation-button-help-center'),
                    onClick: contactSupport,
                    positive: true,
                },
            ]}
            illustration={{
                title: t('hub-project-error-message-title-project-creation'),
                subtitle: t('hub-project-error-message-body-project-creation'),
                imageSrc: somethingWentWrong,
                imageSize: Dialog.IllustrationContent.imageSize.small,
            }}
        />
    );
};

export default ProjectCreationFailed;
