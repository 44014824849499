import { HubState } from 'types';
import { projectCreationInfoSelectors } from '../../creation-info/selectors';
import { PlanForView, PriceForView } from '../../creation-info/types';
import { FormType } from 'views/PaymentWizard/Details/InvoiceDetails/InvoiceDetails';

const selectActiveSubscriptionId = (state: HubState) => state.projectUpdate?.data?.priceId;

const selectFormType = (state: HubState): FormType | undefined =>
    state.projectUpdate?.data?.formType;

const selectInvoice = (state: HubState) => state.projectUpdate?.data?.invoice;

const selectSeats = (state: HubState) => state.projectUpdate?.data?.seats;

const isWizardInitial = (state: HubState) => {
    const status = state.projectUpdate?.ui?.wizard?.status ?? 'INITIAL';

    return status === 'INITIAL';
};

const selectPlan = (state: HubState): PlanForView | undefined => {
    const planId = state.projectUpdate?.data?.planId;

    if (!planId) {
        return undefined;
    }

    return projectCreationInfoSelectors.selectPlanById(planId)(state);
};

const selectPrice = (state: HubState): PriceForView | undefined => {
    const priceId = state.projectUpdate?.data?.priceId;

    if (!priceId) {
        return undefined;
    }

    return selectPlan(state)?.prices.find((price) => price.id === priceId) ?? undefined;
};

const changingPlanStatus = (state: HubState) =>
    state.projectUpdate?.ui?.changePlan?.status ?? 'INITIAL';

const changingSeatsStatus = (state: HubState) =>
    state.projectUpdate?.ui?.changeSeats?.status ?? 'INITIAL';

const isChangingPlan = (state: HubState) =>
    ['OPEN', 'PENDING', 'SUCCESS'].includes(changingPlanStatus(state));

const isCouponModalOpen = (state: HubState): boolean =>
    state.projectUpdate?.ui?.coupon?.status === 'OPEN';

const isChangingSeats = (state: HubState) =>
    ['OPEN', 'PENDING', 'SUCCESS'].includes(changingSeatsStatus(state));

const isDowngradingPlan = (projectId: string) => (state: HubState) => {
    const nextPlanId = state.projectUpdate?.data?.planId;
    const currentDataSources =
        state.projectDetail[projectId].subscription.price.plan.metadata.dataSources ?? -1;
    const nextPlanDataSources =
        state.creationInfo?.info?.plans.find((p) => p.id === nextPlanId)?.features.dataSources ??
        -1;

    if (nextPlanDataSources === -1 && currentDataSources === -1) {
        return false;
    } else {
        return currentDataSources > nextPlanDataSources;
    }
};

const isDowngradingSeats = (projectId: string) => (state: HubState) => {
    const currentSeats = state.projectDetail[projectId]?.seats ?? -1;
    const nextSeats = state.projectUpdate?.data?.seats ?? -1;

    if (nextSeats === -1 && currentSeats === -1) {
        return false;
    } else {
        return currentSeats > nextSeats;
    }
};

const isRequestingChange = (state: HubState) => state.projectUpdate?.status === 'PENDING';

const selectCoupon = (state: HubState) => state.projectUpdate?.data?.coupon;

export const projectUpdateSelectors = {
    selectActiveSubscriptionId,
    selectFormType,
    selectInvoice,
    selectSeats,
    isWizardInitial,
    changingPlanStatus,
    isChangingPlan,
    isChangingSeats,
    isDowngradingPlan,
    isDowngradingSeats,
    isCouponModalOpen,
    selectPlan,
    selectPrice,
    isRequestingChange,
    selectCoupon,
};
