import React, { useState } from 'react';
import {
    Cell,
    ColumnTitle,
    Left,
    Right,
    StyledBackButton,
    StyledSearchField,
    StyledSheetHeader,
    Subtitle,
    Title,
    TitlesContainer,
} from './elements';
import useTranslator from 'hooks/useTranslator';

type Props = {
    onCloseSheet: () => void;
    projectName: string;
    onSearch: (q: string) => void;
};

export const Header = (props: Props) => {
    const t = useTranslator();
    const { onCloseSheet, projectName, onSearch } = props;
    const [value, setValue] = useState('');
    const titles = [
        t('hub-project-details-payment-history-label-date'),
        t('hub-project-details-payment-history-label-amount'),
        t('hub-project-details-payment-history-label-status'),
        t('hub-project-details-payment-history-label-download'),
    ];
    const handleChange = (q: string) => {
        setValue(q);

        window.setTimeout(() => onSearch(q), 300);
    };

    return (
        <StyledSheetHeader>
            <Left>
                <StyledBackButton onClick={onCloseSheet} label="" />
                <Title>{t('hub-project-details-payment-history-section-title')}</Title>
                <Subtitle>
                    {t('hub-project-details-payment-history-project-name', {
                        payload: { Projectname: projectName },
                    })}
                </Subtitle>
            </Left>
            <Right>
                <StyledSearchField
                    placeholder={t('hub-project-details-payment-history-searchbar-placeholder')}
                    onChange={handleChange}
                    value={value}
                    onClear={() => setValue('')}
                />
            </Right>

            <TitlesContainer>
                {titles.map((title, idx) => (
                    <Cell big={idx === 0}>
                        <ColumnTitle>{title}</ColumnTitle>
                    </Cell>
                ))}
            </TitlesContainer>
        </StyledSheetHeader>
    );
};
