import { CreditCardDTO } from '../dtos';
import { PaymentMethod } from '../../core/entities/payment-method/PaymentMethod';

export const adaptToPaymentMethod = (method: CreditCardDTO): PaymentMethod => {
    return {
        brand: method.brand,
        expiration: {
            month: method.exp_month,
            year: method.exp_year,
        },
        id: method.id,
        lastDigits: method.last4,
        type: method.type === 'card' ? 'card' : 'iban',
    };
};
