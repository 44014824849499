import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { projectUpgradeReducer } from './reducer';
import { createProjectUpgradeSaga } from './saga';
import { ProjectUpgradeModuleState } from './types';

export const getProjectUpgradeModule = (
    repository: ProjectRepository,
): ISagaModule<ProjectUpgradeModuleState> => ({
    id: 'projectUpgrade',
    sagas: [
        createProjectUpgradeSaga({
            repository: repository,
        }),
    ],
    reducerMap: {
        projectUpgrade: projectUpgradeReducer,
    },
});
