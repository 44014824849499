import { Plan } from '../../core/entities/plan/Plan';
import { Price } from '../../core/entities/Price';
import { CreationInfo } from '../../core/entities/creation-info/CreationInfo';

export interface ProjectCreationInfoModuleState {
    creationInfo?: ProjectCreationInfoState;
}

export type LanguageView = { label: string; value: string };

export type PriceForView = Omit<Price, 'amount'> & {
    amount: {
        withDigits: string;
        withoutDigits: string;
    };
};

export type PlanForView = Omit<Plan, 'prices'> & {
    prices: Array<PriceForView>;
};

export interface CreationInfoForView extends Pick<CreationInfo, 'regions' | 'baseDomain'> {
    languages: LanguageView[];
    plans: Array<PlanForView>;
}

export interface ProjectCreationInfoState {
    status: ProjectCreationInfoStatus;
    info?: CreationInfoForView;
}

export enum ProjectCreationInfoStatus {
    PENDING = 'PENDING',
    INITIAL = 'INITIAL',
    READY = 'READY',
    ERROR = 'ERROR',
}
