import styled from 'styled-components';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import theme from '@igenius_dev/crystalkit/lib/theme';
import Button from '@igenius_dev/crystalkit/lib/components/Button';
import { animated } from 'react-spring';

const colors = theme.colors.values;

export const InfoIcon = styled(Icon).attrs({ icon: 'info', size: 'base' })`
    margin-right: 10px;
`;

export const Label = styled(Text).attrs({ scale: 'Small' })``;

export const ActionButton = styled(Button).attrs({ color: 'white' })`
    min-height: 24px;
    min-width: 130px;
    height: 24px;
    margin-left: 24px;
`;

export const DismissIcon = styled(Icon).attrs({
    icon: 'remove',
    size: 'base',
    'data-cy': 'icon-dismiss-notification',
})`
    color: ${colors.yellow900};
    cursor: pointer;
    margin-left: auto;
`;

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    border: none;
    border-radius: 8px;
    padding: 16px 16px 19px;
`;

export const WarningNotification = styled(Wrapper)`
    border-left: 8px solid ${colors.yellow700};
    background-color: ${colors.yellow50};

    ${InfoIcon} {
        color: ${colors.yellow900};
    }

    ${ActionButton} {
        &:hover {
            border-color: ${colors.yellow700};
        }
        span {
            color: ${colors.yellow900};
        }
    }
`;

export const ErrorNotification = styled(Wrapper)`
    border-left: 8px solid ${colors.red700};
    background-color: ${colors.red25};
    ${InfoIcon} {
        color: ${colors.red700};
    }
    ${ActionButton} {
        &:hover {
            border-color: ${colors.red700};
        }
        span {
            color: ${colors.red700};
        }
    }
`;

export const InfoNotification = styled(Wrapper)`
    border-left: 8px solid ${colors.purple700};
    background-color: ${colors.blue25};

    ${InfoIcon} {
        color: ${colors.purple700};
    }

    ${ActionButton} {
        &:hover {
            border-color: ${colors.purple700};
        }
        span {
            color: ${colors.purple700};
        }
    }
`;

export const AnimatedAccordion = styled(animated.div)`
    width: 100%;
    position: relative;
    overflow: hidden;
`;

export const AnimatedBanner = styled(animated.div)<{ visible: boolean }>`
    position: absolute;
    width: 100%;
    transform: scale(0.9);
    left: 0;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
`;
