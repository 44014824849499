import React from 'react';
import { DiscountedPrice, PriceRecap, Subtitle, TotalPrice } from 'components/Price/elements';

interface PriceProps {
    total: string;
    hasCoupon: boolean;
    due: string | React.ReactElement;
    disclaimer?: string;
    className?: string;
}

export const Price = ({ total, hasCoupon, due, disclaimer, className }: PriceProps) => {
    return (
        <PriceRecap className={className}>
            <TotalPrice hasCoupon={hasCoupon}>
                {total}{' '}
                {hasCoupon && (
                    <DiscountedPrice data-cy="coupon-discounted-price" color="purple700">
                        {due}
                    </DiscountedPrice>
                )}
            </TotalPrice>

            {disclaimer && <Subtitle>{disclaimer}</Subtitle>}
        </PriceRecap>
    );
};
