import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormElement, FormRow, StyledSelectField, StyledTextField } from './elements';
import { LabelValuePair, TaxId } from 'common/types';
import useTranslator from '../../../../hooks/useTranslator';
import { useSelector } from 'react-redux';
import { HubState } from 'types';
import uiSelectors from 'modules/ui/selectors';

interface Props {
    details?: TaxId;
}

export const TaxIdForm = ({ details }: Props) => {
    const t = useTranslator();
    const vatIdTypes = useSelector<HubState, LabelValuePair[]>(uiSelectors.selectAllTaxTypes);
    const { control } = useFormContext<TaxId>();
    const defaultValues: TaxId = {
        vatNr: details?.vatNr ?? '',
        vatId: details?.vatId ?? ('' as any),
    };

    return (
        <FormRow>
            <FormElement>
                <Controller
                    rules={{ required: true }}
                    name={'vatId'}
                    defaultValue={defaultValues.vatId}
                    control={control}
                    render={({ onChange, value }) => (
                        <div data-cy="taxid-form-select-vat-id-type">
                            <StyledSelectField
                                alwaysEnableSearch
                                label={t(
                                    'hub-payment-details-invoice-details-section-tax-id-label',
                                )}
                                value={value}
                                onChange={onChange}
                                name={'vatId'}
                                placeholder={t(
                                    'hub-payment-details-invoice-details-section-tax-id-label-placeholder',
                                )}
                                items={vatIdTypes}
                            />
                        </div>
                    )}
                />
            </FormElement>
            <FormElement>
                <Controller
                    rules={{ required: true }}
                    name={'vatNr'}
                    defaultValue={defaultValues.vatNr}
                    control={control}
                    render={({ onChange, value }) => (
                        <StyledTextField
                            label={t(
                                'hub-payment-details-invoice-details-section-tax-id-type-label',
                            )}
                            value={value}
                            onChange={onChange}
                            name={'vatNr'}
                            placeholder={t(
                                'hub-payment-details-invoice-details-section-tax-id-type-placeholder',
                            )}
                        />
                    )}
                />
            </FormElement>
        </FormRow>
    );
};
