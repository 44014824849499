import styled from 'styled-components';

import Text from '@igenius_dev/crystalkit/lib/components/Text';

export const Instructions = styled(Text).attrs({
    scale: 'Small',
    color: 'black300',
})`
    margin-left: 1px;
    margin-top: 8px;
`;
