import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Button from '@igenius_dev/crystalkit/lib/components/Button';
import {
    ActionContainer,
    ContentCenter,
    CurrentPlanTitle,
    FeatureBox,
    FeatureContainer,
    FeaturePrice,
    FeatureSubtitle,
    FeatureTitle,
    ForwardIcon,
    LearnMore,
    NextPlanTitle,
    PlanDifferencesContainer,
    Row,
    SlightlyBiggerModal,
    Summary,
    Title,
    TitleSpacer,
} from './elements';
import tenantUsageActions from '../../modules/tenant-usage/actions';
import { TenantLimits } from '../../modules/tenant-usage/types/types';
import { ProjectDetailForView } from '../../modules/project/detail/types';
import useTranslator from '../../hooks/useTranslator';

type Features = {
    [K in keyof TenantLimits]?: {
        title: string;
        subtitle?: string;
        diff?: number;
        price?: string;
    };
};

export interface TenantUsageLimitPlan {
    title: string;
    features: Features;
}

export interface TenantUsageLimitProps {
    title: string;
    summary: string;
    project: ProjectDetailForView;
    open: boolean;
    currentPlan: TenantUsageLimitPlan;
    requestedPlan: TenantUsageLimitPlan;
}

export const TenantUsageLimitModal: React.FC<TenantUsageLimitProps> = (
    props: TenantUsageLimitProps,
) => {
    const { title, summary, project, open, currentPlan, requestedPlan } = props;
    const t = useTranslator();
    const dispatch = useDispatch();
    const closeModal = useCallback(() => dispatch(tenantUsageActions.hideModal()), [dispatch]);

    return (
        <SlightlyBiggerModal
            content={
                <div data-cy="tenant-usage-modal">
                    <Title>{title}</Title>
                    <Summary>{summary}</Summary>
                    <PlanDifferencesContainer>
                        <Row>
                            <CurrentPlanTitle>{currentPlan.title}</CurrentPlanTitle>
                            <TitleSpacer />
                            <NextPlanTitle>{requestedPlan.title}</NextPlanTitle>
                        </Row>
                        {Object.keys(currentPlan.features).map((key) => {
                            const feature = key as keyof Features;
                            const currentFeature = currentPlan.features[feature]!;
                            const nextFeature = requestedPlan.features[feature]!;
                            const showDifference = nextFeature.diff ? nextFeature.diff > 0 : false;

                            return (
                                <Row key={key}>
                                    {showDifference && (
                                        <FeatureContainer>
                                            <FeatureBox withPrice={!!nextFeature.price}>
                                                <FeatureTitle>{currentFeature.title}</FeatureTitle>
                                                {currentFeature.subtitle && (
                                                    <FeatureSubtitle>
                                                        {currentFeature.subtitle}
                                                    </FeatureSubtitle>
                                                )}
                                                {currentFeature.price && (
                                                    <FeaturePrice>
                                                        {currentFeature.price}
                                                    </FeaturePrice>
                                                )}
                                            </FeatureBox>
                                            <ForwardIcon />
                                            <FeatureBox withPrice={!!nextFeature.price}>
                                                <FeatureTitle color='purple700'>
                                                    {nextFeature.title}
                                                </FeatureTitle>
                                                {nextFeature.subtitle && (
                                                    <FeatureSubtitle color='purple700'>
                                                        {nextFeature.subtitle}
                                                    </FeatureSubtitle>
                                                )}
                                                {nextFeature.price && (
                                                    <FeaturePrice>{nextFeature.price}</FeaturePrice>
                                                )}
                                            </FeatureBox>
                                        </FeatureContainer>
                                    )}
                                </Row>
                            );
                        })}
                    </PlanDifferencesContainer>
                    <ActionContainer>
                        <Button
                            label={t('hub-limitation-dialogue-button-go-to-console')}
                            onClick={() => {
                                closeModal();

                                dispatch(
                                    tenantUsageActions.openConsole({
                                        domain: project.completeDomain,
                                    }),
                                );
                            }}
                        />
                    </ActionContainer>
                    <LearnMore>
                        {t('hub-limitation-dialogue-learn-more')}{' '}
                        <ContentCenter
                            onClick={() => {
                                closeModal();

                                dispatch(tenantUsageActions.openContentCenter());
                            }}
                        >
                            {t('hub-limitation-dialogue-text-link-go-to-contact-center')}
                        </ContentCenter>
                    </LearnMore>
                </div>
            }
            onClose={closeModal}
            dismissable={false}
            open={open}
        />
    );
};
