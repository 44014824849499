import { call, put, takeLatest } from 'redux-saga/effects';
import projectListActions from './actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { Project } from 'core/entities/project/Project';

function* fetchProjectList(repository: ProjectRepository) {
    try {
        const projectList: Project[] = yield call([repository, 'list']);

        yield put(projectListActions.projectListFetch.success(projectList));
    } catch (error) {
        yield put(projectListActions.projectListFetch.failure(error));
    }
}

function* init(onInit: () => void) {
    try {
        yield call(onInit);
        yield put(projectListActions.projectListInit.success());
    } catch (error) {
        yield put(projectListActions.projectListInit.failure(error));
    }
}

export function createProjectListSaga(repository: ProjectRepository) {
    return function* projectListSaga() {
        const projectList = () => fetchProjectList(repository);

        yield takeLatest(projectListActions.projectListFetch.request, projectList);
        yield takeLatest(projectListActions.projectListInit.request, () => init(projectList));
        yield put(projectListActions.projectListInit.request());
    };
}

export default createProjectListSaga;
