import React from 'react';
import styled from 'styled-components';

import { StyledTitle } from '../Details/elements';
import { ChangeButton, Label, StyledTable, Value } from './elements';
import useTranslator from '../../../hooks/useTranslator';

const TitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

interface Props {
    numberOfSeats?: number;
    onChange: () => void;
}

export const Seats = ({ numberOfSeats = -1, onChange }: Props) => {
    const t = useTranslator();

    return numberOfSeats > -1 ? (
        <div>
            <TitleContainer>
                <StyledTitle>{t('hub-confirm-and-subscribe-area-title-select-seats')}</StyledTitle>
                <ChangeButton label={t('Change')} onClick={onChange} />
            </TitleContainer>
            <StyledTable
                list={[
                    {
                        name: 'seatsNumber',
                        label: (
                            <Label>
                                {t(
                                    'hub-confirm-and-subscribe-area-select-seats-label-seats-number',
                                )}
                            </Label>
                        ),
                        value: (
                            <Value>
                                {t(
                                    'hub-confirm-and-subscribe-area-select-seats-seats-number-information',
                                    { payload: { NUMBER: numberOfSeats } },
                                )}
                            </Value>
                        ),
                    },
                ]}
            />
        </div>
    ) : null;
};
