import { format } from 'date-fns';
import { all, call, delay, fork, put, takeLatest } from 'redux-saga/effects';

import { NotificationCategories } from 'types';
import { contactSupport } from 'utils/support';
import { removeCategory, setNotification } from '../../notifications/actions';
import { addErrorToast } from '../../toasts/actions';
import { retrieveProjectDetail } from './actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import { ProjectStatusDto } from '../types/dto';
import { ProjectDetail } from 'core/entities/project/ProjectDetail';
import { ProjectDetailForView } from './types';
import { projectRestartActions } from 'modules/project/restart/actions';
import loggingActions from 'modules/logger/actions';

function* handleRequestForProjectDetail(
    action: ReturnType<typeof retrieveProjectDetail.request>,
    repository: ProjectRepository,
    adapter: (detail: ProjectDetail) => ProjectDetailForView,
) {
    try {
        const projectDetails: ProjectDetail = yield call([repository, 'getBy'], action.payload.id);
        const forView: ProjectDetailForView = adapter(projectDetails);
        const projectDetailsSucceeded = isProjectDetailSucceeded(projectDetails.status);

        yield all(clearProjectNotifications());

        if (!projectDetailsSucceeded) {
            yield all([getProjectNotification(projectDetails)]);
        }

        if (projectDetails.status === ProjectStatusDto.PendingActivation) {
            yield fork(pollProjectStatus, action.payload.id);
        }

        yield put(
            retrieveProjectDetail.success({
                id: action.payload.id,
                detail: forView,
            }),
        );
    } catch (e) {
        yield put(retrieveProjectDetail.failure(e));
    }
}

function isProjectDetailSucceeded(status: ProjectStatusDto) {
    return [ProjectStatusDto.PendingActivation, ProjectStatusDto.Active].includes(status);
}

function* pollProjectStatus(id: string) {
    yield delay(15000);
    yield put(retrieveProjectDetail.request({ id }));
}

function* handleRequestError({ payload }: ReturnType<typeof retrieveProjectDetail.failure>) {
    yield put(
        addErrorToast({
            id: 'detail-request-error',
            type: 'error',
            label: `An error occurred while loading your project`,
            isAutoDismissEnabled: false,
            actions: [
                {
                    label: 'Close',
                },
            ],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to retrieve project',
            error: payload,
        }),
    );
}

function getProjectNotification(proj: ProjectDetail) {
    switch (proj.status) {
        case ProjectStatusDto.Error:
            return put(
                setNotification.request({
                    id: proj.status,
                    category: NotificationCategories.ProjectError,
                    type: 'error',
                    message: 'hub-trial-detail-project-inline-notification-something-went-wrong',
                    actions: [
                        {
                            label: 'hub-trial-detail-project-inline-notification-button-contact us',
                            onClick: contactSupport,
                        },
                    ],
                }),
            );
        case ProjectStatusDto.PendingBlocking:
            return put(
                setNotification.request({
                    id: proj.status,
                    category: NotificationCategories.ProjectPendingBlocking,
                    type: 'warning',
                    message:
                        'hub-project-detail-unsubscription-request-inline-notification-project-still-available',
                    messagePayload: {
                        DATE: format(proj.subscription.endOfCurrentPeriod, 'dd/MM/yyyy'),
                    },
                    dismissable: true,
                    actions: [
                        {
                            label:
                                'hub-project-detail-unsubscription-request-inline-notification-button-reactivate',
                            onClickDispatch: projectRestartActions.openRestartModal(),
                        },
                    ],
                }),
            );
    }
}

function clearProjectNotifications() {
    const categoriesToRemove: NotificationCategories[] = [
        NotificationCategories.ProjectError,
        NotificationCategories.ProjectPendingBlocking,
    ];

    return categoriesToRemove.map((c) => put(removeCategory(c)));
}

export function createProjectDetailSaga(
    repository: ProjectRepository,
    adapter: (detail: ProjectDetail) => ProjectDetailForView,
) {
    return function* projectDetailSaga() {
        yield takeLatest(retrieveProjectDetail.request, (args) =>
            handleRequestForProjectDetail(args, repository, adapter),
        );
        yield takeLatest(retrieveProjectDetail.failure, handleRequestError);
    };
}
