import React, { SyntheticEvent } from 'react';
import Chip from '@igenius_dev/crystalkit/lib/components/Chip';
import useTranslator from '../../hooks/useTranslator';

type StatefulChipProps = {
    isSelected: boolean;
    label: string;
    onClick: (b: boolean) => void;
    isInteractive: boolean;
    className?: string;
};

export const StatefulChip = ({
    isSelected,
    label,
    onClick,
    isInteractive,
    className,
}: StatefulChipProps) => {
    const t = useTranslator();
    const avoidTriggeringFormSubmit = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <Chip
            className={className}
            isSelected={isSelected}
            isInteractive={isInteractive}
            onClick={(e: SyntheticEvent) => {
                avoidTriggeringFormSubmit(e);
                onClick(!isSelected);
            }}
        >
            {t(label)}
        </Chip>
    );
};
