import React, { SyntheticEvent } from 'react';
import styled from 'styled-components';
import { Instructions } from '../Instructions';
import Chip from '@igenius_dev/crystalkit/lib/components/Chip';
import { TitleField } from './TitleField';
// @ts-ignore
import { useTranslator } from '@igenius_dev/igenius-i18n/lib';

export type ChipItem = { label: string; value: string };

interface Props {
    label?: string;
    instructionText?: string;
    chips: ChipItem[];
    onSelect: (v: ChipItem) => void;
    onDeselect: (v: ChipItem) => void;
    onFavouriteSelect: (v: ChipItem) => void;
    onFavouriteDeselect: (v: ChipItem) => void;
    value: { list: ChipItem[]; favorite: string };
}

const margin = 4;

const ChipsContainer = styled.div`
    display: flex;
`;

const ChipWrapper = styled.div`
    margin-right: ${margin}px;

    & + & {
        margin-left: ${margin}px;
    }
`;

const Title = styled(TitleField).attrs({
    color: 'black500',
})`
    display: flex;
`;

export const ChipsField: React.FC<Props> = (props: Props) => {
    const t = useTranslator();
    const isSelected = (chip: ChipItem) => props.value.list.some((v) => v.value === chip.value);
    const isFavorite = (chip: ChipItem) => props.value.favorite === chip.value;
    const stopDefaultClickEvent = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
    };

    return (
        <>
            {props.label && <Title>{props.label}</Title>}

            <ChipsContainer>
                {props.chips.map((chip) => (
                    <ChipWrapper key={chip.value}>
                        <Chip
                            leadingIcon="star_deselect"
                            isInteractive
                            onIconClick={(e: SyntheticEvent) => {
                                stopDefaultClickEvent(e);
                                isFavorite(chip)
                                    ? props.onFavouriteDeselect(chip)
                                    : props.onFavouriteSelect(chip);
                            }}
                            onClick={(e: SyntheticEvent) => {
                                stopDefaultClickEvent(e);
                                isSelected(chip) ? props.onDeselect(chip) : props.onSelect(chip);
                            }}
                            isSelected={isSelected(chip)}
                            isFavorite={isFavorite(chip)}
                        >
                            {t(chip.label)}
                        </Chip>
                    </ChipWrapper>
                ))}
            </ChipsContainer>

            <Instructions>{props.instructionText}</Instructions>
        </>
    );
};
