import theme from '@igenius_dev/crystalkit/lib/theme';
import styled from 'styled-components';
import { StatefulChip } from '../Fields/StatefulChip';

export const Slider = styled.div`
    margin-top: 16px;
    border-radius: 16px;
    display: flex;
    width: fit-content;
    background-color: ${theme.colors.values.purple25};
`;

export const NonInteractiveStatefulChip = styled(StatefulChip).attrs({
    isInteractive: false,
})`
    & span {
        cursor: pointer;
    }
`;
