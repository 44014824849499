import { HubState } from 'types';

const selectAllCountries = (state: HubState) => state.ui.countries;
const selectAllTaxTypes = (state: HubState) => state.ui.vatTypes;

const uiSelectors = {
    selectAllCountries,
    selectAllTaxTypes,
};

export default uiSelectors;
