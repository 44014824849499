import React from 'react';
import { Control, Controller, DeepMap, FieldError } from 'react-hook-form';
import { StyledSelectField, StyledTextField } from './elements';
import { BillingDetailsFormData, BillingItemType, CompanyBillingDetailsFormData } from './types';
import useTranslator from '../../../../../hooks/useTranslator';
import { LabelValuePair, Translator } from 'common/types';

type FormErrors = DeepMap<CompanyBillingDetailsFormData, FieldError>;
type SelectFieldError = {
    value: {
        message: string;
    };
};
type TextFieldError = { message: string };

type Props = {
    items: BillingItemType[];
    control: Control<Omit<BillingDetailsFormData, 'formType'>>;
    errors: FormErrors;
};
export const FormContent = (props: Props) => {
    const t = useTranslator();

    const filterFn = (query: string, t: Translator) => (item: LabelValuePair) => {
        return t(item.label).toLowerCase().includes(query.toLowerCase());
    };

    const isValidItem = (item?: LabelValuePair) => {
        return item && item.value !== '';
    };

    return (
        <>
            {props.items.map((el) => (
                <div data-testid={`form-${el.name}`}>
                    <Controller
                        name={el.name}
                        rules={{ required: 'Required field' }}
                        control={props.control}
                        defaultValue={el.defaultValue}
                        render={({ onChange, value }) =>
                            el.formType === 'select' ? (
                                <StyledSelectField
                                    alwaysEnableSearch
                                    name={el.name}
                                    label={t(el.label)}
                                    value={isValidItem(value) ? value : undefined}
                                    defaultValue={
                                        isValidItem(el.defaultValue) ? el.defaultValue : undefined
                                    }
                                    filterFn={filterFn}
                                    items={el.items}
                                    placeholder={t(el.placeholder)}
                                    onChange={onChange}
                                    instructionText={
                                        (props.errors?.[el.name] as SelectFieldError)?.value
                                            ?.message
                                    }
                                />
                            ) : (
                                <StyledTextField
                                    name={el.name}
                                    label={t(el.label)}
                                    value={value}
                                    placeholder={el.defaultValue}
                                    onChange={onChange}
                                    instructionText={
                                        (props.errors?.[el.name] as TextFieldError)?.message
                                    }
                                    isErrored={
                                        !!(props.errors?.[el.name] as TextFieldError)?.message
                                    }
                                />
                            )
                        }
                    />
                </div>
            ))}
        </>
    );
};
