import React from 'react';
import { useDispatch } from 'react-redux';

import Button from '@igenius_dev/crystalkit/lib/components/Button';

import CircularLoader from '@igenius_dev/crystalkit/lib/components/CircularLoader';
// @ts-ignore
import Img from '@igenius_dev/crystalkit/lib/components/Img';
import letsparty from 'assets/13.png';
import somethingWentWrong from 'assets/29.png';
import emptyState from 'assets/emptyState.png';
import { contactSupport } from 'utils/support';
import { EmptyState } from '../State/EmptyState';
import { ProjectDetailForView } from 'modules/project/detail/types';
import useTranslator from 'hooks/useTranslator';
import { projectRestartActions } from 'modules/project/restart/actions';

type Props = {
    projectDetails: ProjectDetailForView;
};

const ProjectIllustration = ({ projectDetails }: Props) => {
    const t = useTranslator();
    const dispatch = useDispatch();

    switch (projectDetails.status) {
        case 'ACTIVE':
        case 'PENDING_BLOCKING':
            return (
                <EmptyState
                    image={<Img src={letsparty} alt="" height="153px" width="232px" />}
                    title={t('hub-trial-project-notification-area-message-title-project-setup')}
                    subtitle={t('hub-trial-project-notification-area-message-body-project-setup')}
                    action={
                        <Button
                            onClick={() => {
                                window.open(
                                    `https://${projectDetails.completeDomain}`,
                                    '_blank',
                                    'noreferrer',
                                );
                            }}
                            label={t('hub-project-notification-area-button-go-to-console')}
                        />
                    }
                />
            );
        case 'PENDING_ACTIVATION':
            return (
                <EmptyState
                    image={<CircularLoader size="super" />}
                    title={t('hub-trial-project-notification-area-message-title-project-creation')}
                    subtitle={t(
                        'hub-trial-project-notification-area-message-body-project-creation',
                    )}
                    action={
                        <Button
                            disabled
                            label={t('hub-project-notification-area-button-go-to-console')}
                        />
                    }
                />
            );
        case 'ERROR':
            return (
                <EmptyState
                    image={<Img src={somethingWentWrong} alt="" height="153px" width="232px" />}
                    title={t(
                        'hub-trial-project-notification-area-error-message-title-project-creation',
                    )}
                    subtitle={t(
                        'hub-trial-project-notification-area-error-message-body-project-creation',
                    )}
                    action={
                        <Button
                            onClick={contactSupport}
                            label={t('hub-project-creation-button-help-center')}
                        />
                    }
                />
            );
        case 'BLOCKED':
            return (
                <EmptyState
                    image={<Img src={emptyState} alt="not-found" height="153px" width="232px" />}
                    title={t(
                        'hub-project-detail-unsubscription-message-area-title-project-unsubscribed',
                    )}
                    subtitle={t(
                        'hub-project-detail-unsubscription-message-area-body-project-will-be-deleted',
                    )}
                    action={
                        <Button
                            label={t(
                                'hub-project-detail-unsubscription-message-area-button-project-unsubscribed',
                            )}
                            onClick={() => {
                                dispatch(projectRestartActions.openRestartModal());
                            }}
                        />
                    }
                />
            );
        default: {
            return null;
        }
    }
};

export default ProjectIllustration;
