import Text from '@igenius_dev/crystalkit/lib/components/Text';
import styled from 'styled-components';

export const Title = styled(Text).attrs({
    scale: 'Title',
})``;

export const LeftContent = styled.div`
    margin-top: 60px;
    width: 100%;
`;

export const RightContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;
