import { ProjectCreationState, ProjectCreationStatus } from './types';
import { projectCreationActions } from './actions';
import { ActionType, getType } from 'typesafe-actions';

export const projectCreationReducer = (
    state: ProjectCreationState = { status: ProjectCreationStatus.Initial },
    action: ActionType<typeof projectCreationActions>,
) => {
    switch (action.type) {
        case getType(projectCreationActions.update): {
            return {
                project: {
                    ...state.project,
                    ...action.payload,
                },
                status: ProjectCreationStatus.Completing,
            };
        }
        case getType(projectCreationActions.create.success):
            return { status: ProjectCreationStatus.Succeeded };
        case getType(projectCreationActions.create.failure):
            return { ...state, status: ProjectCreationStatus.Failed };
        case getType(projectCreationActions.create.request):
            return { ...state, status: ProjectCreationStatus.Pending };
        case getType(projectCreationActions.cancel):
            return { status: ProjectCreationStatus.Initial };
        default:
            return state;
    }
};
