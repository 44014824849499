import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import createLogger, { LogLevel } from '@igenius_dev/igenius-js-logger';
import { Application } from '@igenius_dev/igenius-js-logger/dist/Payload/LogPayload';

import App from './App';
import { AxiosHttpClient } from 'services/AxiosHttpClient';
import { createConfigLoader } from 'modules/config';
import createReduxStore from './config/store/reduxStore';
import { CenteredLoader } from 'components/CenteredLoader';
import { createModules } from 'config/modules/createModules';
import { domainValidator } from 'modules/project/create/api';
import { api, configUrl } from 'paths';
import { domainNormalizer } from 'modules/project/create/api/domain/domainNormalizer';
import { version } from '../package.json';

const name = 'hub-webapp';
const axiosInstance = axios.create({ timeout: 30000 });
const client = new AxiosHttpClient(axiosInstance);
const loadConfigFrom = createConfigLoader(client);
const loggerParams = {
    environment: process.env.NODE_ENV,
    application: 'crystal_hub' as Application,
    service: name,
    service_version: version,
    httpClient: client,
};

loadConfigFrom(configUrl)
    .then((config) => {
        const logger = createLogger({
            ...loggerParams,
            level: process.env.NODE_ENV === 'development' ? LogLevel.DEBUG : LogLevel.ERROR,
            application_version: config.crystal?.version ?? '',
        });
        const modules = createModules({
            config,
            client,
            axiosInstance,
            serviceName: name,
            logger,
        });
        const store = createReduxStore({
            logger,
            modules,
        });

        ReactDOM.render(
            <React.StrictMode>
                <App
                    store={store}
                    modules={modules}
                    features={config.features}
                    domainValidator={domainValidator(client, api.validateDomain)}
                    domainNormalizer={domainNormalizer}
                />
            </React.StrictMode>,
            document.getElementById('root'),
        );
    })
    .catch((e) => {
        createLogger({
            level: LogLevel.CRITICAL,
            application_version: '',
            ...loggerParams,
        }).critical('Unable to start application, cannot read configuration file.', {
            url: configUrl,
        });
    });

ReactDOM.render(
    <React.StrictMode>
        <CenteredLoader />
    </React.StrictMode>,
    document.getElementById('root'),
);
