import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProjectCreationData } from './types';

const create = createAsyncAction(
    '@create-project/PROJECT_CREATION_REQUEST',
    '@create-project/PROJECT_CREATION_SUCCESS',
    '@create-project/PROJECT_CREATION_FAILURE',
)<
    { projectData: ProjectCreationData; onSuccess: (newProjectId: string) => void },
    undefined,
    Error
>();

const update = createAction('@create-project/PROJECT_CREATION_UPDATE')<
    Partial<ProjectCreationData>
>();

const cancel = createAction('@create-project/PROJECT_CREATION_CANCEL')<{
    redirectPath: string;
}>();

export const projectCreationActions = {
    update,
    create,
    cancel,
};
