import { PlanDto, ProjectCreationInfoResponseDto } from '../../project/types/dto';
import { adaptToProjectLanguage } from '../adapter/adaptToProjectLanguage';
import { Price } from 'core/entities/Price';
import { HttpClient } from 'services/HttpClient';
import { CreationInfo } from 'core/entities/creation-info/CreationInfo';

type ProjectCreationInfoParams = {
    client: HttpClient;
    url: string;
};

export const createProjectCreationInfoRetriever = ({
    client,
    url,
}: ProjectCreationInfoParams) => async (): Promise<CreationInfo> => {
    const response: ProjectCreationInfoResponseDto = await client.get(url);

    return adapterToProjectCreationInformation(response);
};

function adapterToProjectCreationInformation(dto: ProjectCreationInfoResponseDto): CreationInfo {
    return {
        baseDomain: dto.base_domain,
        plans: dto.plans
            .map((plan: PlanDto) => ({
                id: plan.id,
                description: {
                    standard: plan.description,
                    notification: plan.metadata.inline_notification,
                },
                features: {
                    dataSources: plan.metadata.data_sources,
                    languages: plan.metadata.languages,
                    users: plan.metadata.customer_support,
                    topics: plan.metadata.topics,
                    features: adaptFeatures(plan.metadata.features),
                },
                name: plan.name,
                prices: plan.prices
                    .filter((p) => p.currency === 'usd')
                    .map(
                        (price) =>
                            ({
                                amount: price.unit_amount,
                                timeInterval:
                                    price.recurring_interval === 'month' ? 'month' : 'year',
                                id: price.id,
                                currency: price.currency,
                            } as Price),
                    )
                    .sort((a: Price, b: Price) => a.timeInterval.localeCompare(b.timeInterval)),
            }))
            .sort((a, b) => a.name.localeCompare(b.name)),
        regions: dto.regions,
        languages: adaptToProjectLanguage(dto.languages),
    };
}

function adaptFeatures(features: string = '[]') {
    try {
        return JSON.parse(features);
    } catch (e) {
        return [];
    }
}
