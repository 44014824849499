import { adaptToUserNotifications } from './adapters';
import { UserNotificationDto } from './types';
import { HttpClient } from '../../../services/HttpClient';
import { UserNotifications } from '../types';

export const createNotificationsFetcher = (
    client: HttpClient,
    url: string,
) => async (): Promise<UserNotifications> => {
    const response = await client.get<UserNotificationDto>(url);

    return adaptToUserNotifications(response);
};
