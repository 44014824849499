import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { FormProvider, useForm } from 'react-hook-form';

import { SelectPlan, SelectPlanFormData, SelectPlanProps } from './SelectPlan';
import { HubState } from 'types';
import { projectCreationInfoSelectors } from 'modules/creation-info/selectors';
import Button from '@igenius_dev/crystalkit/lib/components/Button';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import { projectCreationInfoActions } from 'modules/creation-info/actions';
import { SlightlyBiggerModal } from 'views/TenantUsageLimit/elements';
import { projectDetailSelectors } from 'modules/project/detail/selectors';
import { projectUpdateSelectors } from 'modules/project/update/selectors';
import { CenteredLoader } from 'components/CenteredLoader';
import { ProjectId } from 'core/entities/project/Project';
import { PlanForView } from 'modules/creation-info/types';
import useTranslator from 'hooks/useTranslator';

type Props = {
    projectId: ProjectId;
    onClose: () => void;
    onSubmit: (data: { priceId: string; planId: string }) => void;
} & Partial<SelectPlanProps>;

const ModalTitle = styled(Text)`
    margin-bottom: 24px;
`;

const OkButton = styled(Button)`
    margin-left: 4px;
`;

const CancelButton = styled(Button).attrs({
    color: 'white',
})`
    margin-right: 4px;
`;

function getPriceId(plans: PlanForView[], data: SelectPlanFormData) {
    return plans
        .find((plan) => plan.id === data.planId)!
        .prices.find((price) => price.timeInterval === data.cycle)!.id;
}

export const SelectPlanModal = ({ onClose, onSubmit, projectId }: Props) => {
    const plans = useSelector<HubState, PlanForView[]>(projectCreationInfoSelectors.selectPlans);
    const status = useSelector(projectUpdateSelectors.changingPlanStatus);
    const open = status === 'OPEN';
    const loading = status === 'PENDING';
    const t = useTranslator();
    const dispatch = useDispatch();
    const project = useSelector(projectDetailSelectors.selectProjectById(projectId));
    const currentPriceId = project?.subscription.price.id;
    const currentPlanId = project?.subscription.price.plan.id;
    const currentCycle = project?.subscription.price.timeInterval;
    const formProps = useForm<{
        planId: string;
        priceId: string;
        cycle: 'month' | 'year';
    }>({
        defaultValues: {
            priceId: currentPriceId,
            planId: currentPlanId,
            cycle: currentCycle,
        },
    });
    const submitDisabled = Object.keys(formProps.formState.dirtyFields).length === 0;

    useEffect(() => {
        dispatch(projectCreationInfoActions.request());
    }, [dispatch]);

    return plans.length > 0 ? (
        <SlightlyBiggerModal
            content={
                loading ? (
                    <CenteredLoader />
                ) : (
                    <div data-cy="select-plan-modal">
                        <FormProvider {...formProps} data-cy="select-plan-modal">
                            <ModalTitle scale="Large">
                                {t('hub-plan-selection-dialogue-title-change-plan')}
                            </ModalTitle>

                            <SelectPlan
                                selectedPlanId={currentPlanId}
                                showTrialDisclaimer={false}
                                plans={plans}
                                textScale="Base"
                                actions={[
                                    <CancelButton
                                        label={t('hub-plan-selection-dialogue-button-cancel')}
                                        key="hub-plan-selection-dialogue-button-cancel"
                                        onClick={onClose}
                                    />,
                                    <OkButton
                                        disabled={submitDisabled}
                                        label={t('hub-plan-selection-dialogue-button-continue')}
                                        key="hub-plan-selection-dialogue-button-continue"
                                        onClick={formProps.handleSubmit(
                                            (data: SelectPlanFormData) => {
                                                onSubmit({
                                                    planId: data.planId,
                                                    priceId: getPriceId(plans, data),
                                                });
                                            },
                                        )}
                                    />,
                                ]}
                            />
                        </FormProvider>
                    </div>
                )
            }
            onClose={onClose}
            open={open}
        />
    ) : null;
};
