import React from 'react';
import styled from 'styled-components';
import Tile from '@igenius_dev/crystalkit/lib/components/Tile';
import theme from '@igenius_dev/crystalkit/lib/theme';

const StyledTile = styled(Tile).attrs({
    fluid: true,
})`
    padding: 24px;
    margin-top: 40px;
    background-color: white;

    &:first-child {
        margin-top: 0;
    }
`;

interface Props {
    title?: string;
    subtitle?: string;
    className?: string;
}

export const Widget: React.FC<Props> = ({ title, subtitle, children, className }) => (
    <StyledTile className={className}>
        <Tile.Header>
            <Tile.Title>{title}</Tile.Title>
            {subtitle && (
                <Tile.Subtitle style={{ color: theme.colors.values.black300 }}>
                    {subtitle}
                </Tile.Subtitle>
            )}
        </Tile.Header>
        <Tile.Content>{children}</Tile.Content>
    </StyledTile>
);
