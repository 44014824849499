import { call, put, takeLatest } from 'redux-saga/effects';

import { terminateProject } from 'modules/project/terminate/actions';
import { addErrorToast, addSuccessToast } from 'modules/toasts/actions';
import { retrieveProjectDetail } from 'modules/project/detail/actions';
import { ProjectRepository } from 'core/repository/project/ProjectRepository';
import projectListActions from 'modules/project/list/actions';
import loggingActions from 'modules/logger/actions';

export function createProjectTerminationSaga(repository: ProjectRepository) {
    return function* () {
        yield takeLatest(terminateProject.request, (args) => handleDeleteProject(args, repository));
        yield takeLatest(terminateProject.failure, handleDeleteProjectError);
        yield takeLatest(terminateProject.success, handleDeleteProjectSuccess);
    };
}

function* handleDeleteProject(
    action: ReturnType<typeof terminateProject.request>,
    repository: ProjectRepository,
) {
    try {
        const projectId = action.payload.id;

        yield call([repository, 'terminate'], projectId);
        yield put(terminateProject.success({ id: projectId }));
        yield put(projectListActions.projectListFetch.request());
    } catch (e) {
        yield put(terminateProject.failure(e.message));
    }
}

function* handleDeleteProjectError({ payload }: ReturnType<typeof terminateProject.failure>) {
    yield put(
        addErrorToast({
            id: 'delete-project-error',
            type: 'error',
            label: 'hub-unsubscribe-toast-error',
            isAutoDismissEnabled: false,
            actions: [
                {
                    label: 'Close',
                },
            ],
        }),
    );

    yield put(
        loggingActions.error({
            msg: 'Unable to terminate project',
            error: payload,
        }),
    );
}

function* handleDeleteProjectSuccess(action: ReturnType<typeof terminateProject.success>) {
    yield put(retrieveProjectDetail.request({ id: action.payload.id }));
    yield put(
        addSuccessToast({
            type: 'success',
            id: 'terminate-project-success',
            label: 'hub-unsubscribe-toast-confirmed',
            isAutoDismissEnabled: true,
        }),
    );
}
