import React, { useCallback } from 'react';
import {
    AmericanExpress,
    CardBrand,
    CardBrandImage,
    CardBrandName,
    CardBrandNumber,
    MasterCard,
    Visa,
} from 'views/PaymentWizard/Details/PaymentMethod/elements';
import useTranslator from '../hooks/useTranslator';

interface Props {
    brand: string;
    lastDigits: string | number;
    expiration?: {
        month: string | number;
        year: string | number;
    };
    scale?: string;
    cardNameColor?: string;
    cardNumberColor?: string;
}

export const CreditCard = ({
    brand,
    scale,
    lastDigits,
    expiration,
    cardNameColor,
    cardNumberColor,
}: Props) => {
    const t = useTranslator();

    const getCardIcon = useCallback((brand: string) => {
        switch (brand) {
            case 'visa':
                return <Visa />;
            case 'mastercard':
                return <MasterCard />;
            case 'amex':
                return <AmericanExpress />;
            default:
                return null;
        }
    }, []);

    const cardIcon = getCardIcon(brand);

    return (
        <>
            <CardBrandImage>{cardIcon}</CardBrandImage>
            <CardBrand>
                <CardBrandName scale={scale} color={cardNameColor}>
                    {brand}
                </CardBrandName>
                <CardBrandNumber scale={scale} color={cardNumberColor}>
                    {t('hub-payment-details-payment-method-card-number', {
                        payload: {
                            CARDNUMBER: lastDigits,
                        },
                    })}
                    {expiration && (
                        <>
                            <br />
                            {t('hub-trial-project-detail-label-expiration-card-date', {
                                payload: {
                                    DATE: `${expiration.month}/${expiration.year}`,
                                },
                            })}
                        </>
                    )}
                </CardBrandNumber>
            </CardBrand>
        </>
    );
};
