export type ErrorMessage = string;

export interface PaymentWizardModuleState {
    paymentWizard?: PaymentWizardState;
}

export interface PaymentWizardState {
    status: PaymentWizardStatus;
}

export enum PaymentWizardStatus {
    'INITIAL' = 'INITIAL',
    'READY' = 'READY',
    'PENDING' = 'PENDING',
    'CANCELED' = 'CANCELED',
}
