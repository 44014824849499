import { HubState } from '../../types';
import { LanguageView, PlanForView, PriceForView } from './types';

const selectLanguages: (state: HubState) => LanguageView[] = (state: HubState) =>
    state.creationInfo?.info?.languages ?? [];

const selectPlanById = (planId?: string) => (state: HubState): PlanForView | undefined =>
    planId
        ? state.creationInfo?.info?.plans.find((plan: PlanForView) => plan.id === planId)
        : undefined;

const selectSubscriptionsByPlanId = (planId?: string) => (state: HubState): PriceForView[] =>
    planId ? selectPlanById(planId)(state)?.prices ?? [] : [];

const selectPlans = (state: HubState) => state.creationInfo?.info?.plans ?? [];

const selectDomain = (state: HubState) => state.creationInfo?.info?.baseDomain ?? '';

const isReady = (state: HubState) => state.creationInfo?.status === 'READY';

export const projectCreationInfoSelectors = {
    selectLanguages,
    selectSubscriptionsByPlanId,
    selectPlans,
    selectPlanById,
    selectDomain,
    isReady,
};
