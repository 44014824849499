import { ActionType, getType } from 'typesafe-actions';

import { projectRestartActions } from 'modules/project/restart/actions';
import { ProjectRestartState } from './types';

export const projectRestartReducer = (
    state: ProjectRestartState = { status: 'INITIAL', ui: 'CLOSED' },
    action: ActionType<typeof projectRestartActions>,
): ProjectRestartState => {
    switch (action.type) {
        case getType(projectRestartActions.restart.request): {
            return {
                ...state,
                status: 'PENDING',
            };
        }
        case getType(projectRestartActions.restart.success): {
            return {
                ...state,
                status: 'SUCCESS',
            };
        }
        case getType(projectRestartActions.restart.failure): {
            return {
                ...state,
                status: 'ERROR',
            };
        }
        case getType(projectRestartActions.openRestartModal): {
            return {
                ...state,
                ui: 'OPEN',
            };
        }
        case getType(projectRestartActions.closeRestartModal): {
            return {
                ...state,
                ui: 'CLOSED',
            };
        }
        default:
            return state;
    }
};
