import React from 'react';
import styled from 'styled-components';

import Button from '@igenius_dev/crystalkit/lib/components/Button';

import HubModal from '../../components/HubModal';
import { useFormContext } from 'react-hook-form';

interface PropertyChangeDialogProps {
    onClickOutside?: () => void;
    open: boolean;
    content: React.ReactElement;
    onSave: (data: any) => void;
    onClose: () => void;
    onCancel: () => void;
    actionText: {
        ok: string;
        cancel: string;
    };
}

const ActionContainer = styled.div`
    display: flex;
    justify-content: center;
    margin-top: 40px;
`;

const OkButton = styled(Button)`
    margin-left: 4px;
`;

const CancelButton = styled(Button).attrs({
    color: 'white',
})`
    margin-right: 4px;
`;

export const PropertyChangeDialog: React.FC<PropertyChangeDialogProps> = ({
    content,
    onClickOutside,
    onSave,
    onCancel,
    onClose,
    open,
    actionText,
}) => {
    const { formState, handleSubmit } = useFormContext();

    return (
        <HubModal
            onClose={onClose}
            open={open}
            onOutsideClick={onClickOutside}
            content={
                <>
                    {content}
                    <ActionContainer>
                        <CancelButton label={actionText.cancel} onClick={onCancel} />
                        <OkButton
                            label={actionText.ok}
                            onClick={handleSubmit(onSave)}
                            disabled={!formState.isValid}
                            loading={formState.isSubmitting}
                        />
                    </ActionContainer>
                </>
            }
        />
    );
};
