import { TimeInterval } from '../core/entities/Price';

export const formatRecurringInterval = (timeInterval: TimeInterval) => {
    if (timeInterval === 'month') {
        return 'hub-billing-cycle-label-monthly';
    }

    if (timeInterval === 'year') {
        return 'hub-billing-cycle-label-yearly';
    }

    return '';
};
