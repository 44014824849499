import { CenteredLoader } from 'components/CenteredLoader';
import { useFeatures } from 'components/FeatureFlags/hook';
import { PaymentMethod } from 'core/entities/payment-method/PaymentMethod';
import { ProjectId } from 'core/entities/project/Project';
import billingInfoSelectors from 'modules/billing-info/selectors';
import { BillingInfoForView } from 'modules/billing-info/types/types';
import { selectPaymentMethods } from 'modules/payment-methods/selectors';
import { projectDetailSelectors } from 'modules/project/detail/selectors';
import { ProjectDetailForView } from 'modules/project/detail/types';
import { ProjectStatusDto } from 'modules/project/types/dto';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as projectUpgradeActions from 'modules/project/upgrade/actions';
import styled from 'styled-components';
import { HubState } from 'types';
import { DeleteProject } from 'views/Project/Detail/Detail/Deletion/DeleteProject';
import { RestartProjectModal } from 'views/Project/Detail/Detail/Restart/RestartProjectModal';
import ProjectCreationFailed from './ProjectCreationFailed';
import { SheetInvoices } from './SheetInvoices';
import Unsubscribe from './Unsubscribe';
import { About } from './Widgets/About';
import { Payment } from './Widgets/Payment';
import { Plan } from './Widgets/Plan';

type DetailViewProps = {
    projectId: ProjectId;
    className?: string;
};

const LoaderContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

export const DetailView = ({ projectId, className }: DetailViewProps) => {
    const {
        deleteProject: { enabled: shouldShowDeleteProject },
    } = useFeatures();
    const dispatch = useDispatch();
    const paymentMethods = useSelector<HubState, PaymentMethod[]>(selectPaymentMethods);
    const projectDetail = useSelector<HubState, ProjectDetailForView | undefined>(
        projectDetailSelectors.selectProjectById(projectId),
    );
    const billingInfo = useSelector<HubState, BillingInfoForView | undefined>(
        billingInfoSelectors.billingInfo,
    );
    const [isErrorDialogVisible, setErrorDialogVisibility] = useState<boolean>(false);
    const shouldShowUnsubscribeBtn = (projectDetail: ProjectDetailForView) =>
        ![ProjectStatusDto.Blocked, ProjectStatusDto.BlockingError].includes(projectDetail.status);

    const previousDetailRef = useRef<ProjectDetailForView>();
    const previousDetail = previousDetailRef.current;

    const handleUpgradeProject = (projectVersion: string) => {
        if (projectDetail) {
            dispatch(
                projectUpgradeActions.upgradeProject.request({
                    projectId: projectDetail.id,
                    projectVersion,
                }),
            );
        }
    };

    useEffect(() => {
        previousDetailRef.current = projectDetail;
    }, [projectDetail]);

    useEffect(() => {
        if (
            previousDetail &&
            previousDetail.status !== 'ERROR' &&
            projectDetail &&
            projectDetail.status === 'ERROR'
        ) {
            setErrorDialogVisibility(true);
        }
    }, [previousDetail, projectDetail]);

    return projectDetail && billingInfo ? (
        <div className={className}>
            <About
                name={projectDetail.name}
                domain={projectDetail.completeDomain}
                projectVersion={projectDetail.projectVersion}
                status={projectDetail.status}
                onUpgradeProject={handleUpgradeProject}
            />
            <Plan
                editable={projectDetail.status === 'ACTIVE'}
                name={projectDetail.subscription.price.plan.name}
                languages={projectDetail.languages}
                seats={projectDetail.seats}
                expirationTimestamp={projectDetail.subscription.endOfTrial}
                projectId={projectId}
            />
            <Payment
                editable={projectDetail.status === 'ACTIVE'}
                projectId={projectId}
                subscription={projectDetail.subscription}
                billingInfo={billingInfo}
                methods={paymentMethods}
            />
            <ProjectCreationFailed
                open={isErrorDialogVisible}
                onClose={() => setErrorDialogVisibility(false)}
            />
            {shouldShowUnsubscribeBtn(projectDetail) && (
                <Unsubscribe
                    projectId={projectId}
                    editable={['ACTIVE', 'ERROR'].includes(projectDetail.status)}
                    currentPeriodEnd={projectDetail.subscription.endOfCurrentPeriod}
                />
            )}
            {shouldShowDeleteProject && <DeleteProject id={projectId} />}
            <SheetInvoices projectDetail={projectDetail} />
            <RestartProjectModal project={projectDetail} />
        </div>
    ) : (
        <LoaderContainer>
            <CenteredLoader size="super" />
        </LoaderContainer>
    );
};
