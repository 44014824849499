export const DOMAIN_REGEX = /^[a-z0-9][a-z0-9-]{3,13}[a-z0-9]$/;

const spaces = /[\s]/;
const charsNotAllowed = /[^a-z0-9-\s]/g;
const emptyString = '';

export function domainNormalizer(invalidString: string) {
    return replaceSpaces(removeNotAllowedChars(toLowerCase(invalidString)));
}

function toLowerCase(invalidString: string) {
    return invalidString.toLowerCase();
}

function removeNotAllowedChars(s: string) {
    return s.replace(charsNotAllowed, emptyString);
}

function replaceSpaces(s: string) {
    return s.replace(new RegExp(spaces, 'g'), '-');
}
