import React from 'react';

import { projectDetailSelectors } from 'modules/project/detail/selectors';
import { useSelector } from 'react-redux';
import { HubState } from 'types';
import { contactSupport } from 'utils/support';
import { SubmitButton } from '../../Create/elements';
import { ProjectDetailForView } from '../../../../modules/project/detail/types';
import useTranslator from '../../../../hooks/useTranslator';

const ProjectActionsButton = ({ projectId }: { projectId: string }) => {
    const projectDetails = useSelector<HubState, ProjectDetailForView | undefined>(
        projectDetailSelectors.selectProjectById(projectId),
    );
    const t = useTranslator();

    switch (projectDetails?.status) {
        case 'ACTIVE':
        case 'PENDING_BLOCKING':
            return (
                <SubmitButton
                    onClick={() => {
                        window.open(
                            `https://${projectDetails.completeDomain}`,
                            '_blank',
                            'noreferrer',
                        );
                    }}
                    label={t('hub-project-notification-area-button-go-to-console')}
                />
            );
        case 'PENDING_ACTIVATION':
            return (
                <SubmitButton
                    disabled
                    label={t('hub-project-notification-area-button-go-to-console')}
                />
            );
        case 'ERROR':
            return (
                <SubmitButton
                    onClick={contactSupport}
                    label={t('hub-project-creation-button-help-center')}
                />
            );
        default: {
            return null;
        }
    }
};

export default ProjectActionsButton;
