import { differenceInMonths } from 'date-fns';
import * as notificationsActions from 'modules/notifications/actions';
import { openPaymentMethodModal } from 'modules/project/detail/actions';
import { ProjectStatusDto } from 'modules/project/types/dto';
import { addErrorToast } from 'modules/toasts/actions';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NotificationCategories } from 'types';
import * as actions from './actions';
import { UserNotifications, UserNotificationType } from './types';

export function isProjectNearRenewal(todayTs: number, renewalTs: number, noticeInMonths: number) {
    const renewalDate = new Date(renewalTs);
    const todayDate = new Date(todayTs);
    const diffInMonths = differenceInMonths(renewalDate, todayDate);

    return diffInMonths <= noticeInMonths;
}

function hasAllTruthyVals(...args: any[]) {
    args.forEach((arg) => {
        if (!arg) {
            return false;
        }
    });
    return true;
}

function getNotificationActions(
    defaultPaymentMethodID: string,
    renewalTs: number,
    status: ProjectStatusDto,
    userNotifications: UserNotifications,
) {
    if (!defaultPaymentMethodID || !renewalTs) {
        return [];
    }

    const filteredNotifications = Object.values(userNotifications).filter(
        (n) => n.payload.id === defaultPaymentMethodID,
    );

    const isProjectPendingBlocking = status === ProjectStatusDto.PendingBlocking;

    const notificationsToAdd = filteredNotifications.map((n) => {
        switch (n.type) {
            case UserNotificationType.ExpiredCard:
                const todayTs = new Date().getTime();
                if (!isProjectNearRenewal(todayTs, renewalTs, 1) || isProjectPendingBlocking) {
                    return [];
                }
                return put(
                    notificationsActions.setNotification.request({
                        id: n.payload.id,
                        type: 'error',
                        message: 'hub-project-snackbar-credit-card-expired',
                        category: NotificationCategories.CreditCards,
                        actions: [
                            {
                                label: 'hub-project-snackbar-update-payment-button',
                                onClickDispatch: openPaymentMethodModal(),
                            },
                        ],
                    }),
                );
            case UserNotificationType.ExpiringCard:
            default:
                return put(
                    notificationsActions.setNotification.request({
                        id: n.payload.id,
                        type: 'warning',
                        message: 'hub-project-snackbar-credit-card-expiration',
                        category: NotificationCategories.CreditCards,
                        dismissable: true,
                        actions: [
                            {
                                label: 'hub-project-snackbar-update-payment-button',
                                onClickDispatch: openPaymentMethodModal(),
                            },
                        ],
                    }),
                );
        }
    });

    return notificationsToAdd;
}

function* handleFetchUserNotifications(
    action: ReturnType<typeof actions.fetchUserNotifications.request>,
    fetchNotifications: () => Promise<UserNotifications>,
) {
    yield put(notificationsActions.removeCategory(NotificationCategories.CreditCards));
    try {
        const { defaultPaymentMethodID, endOfCurrentPeriod, projectStatus } = action.payload;
        const userNotifications: UserNotifications = yield call(fetchNotifications);

        if (!hasAllTruthyVals(defaultPaymentMethodID, endOfCurrentPeriod, projectStatus)) {
            return;
        }

        if (userNotifications) {
            const notificationsToAdd = getNotificationActions(
                defaultPaymentMethodID!,
                endOfCurrentPeriod!,
                projectStatus!,
                userNotifications,
            );
            yield all([...notificationsToAdd]);
        }
    } catch (error) {
        yield put(
            addErrorToast({
                id: 'fetch-user-notifications',
                type: 'error',
                isAutoDismissEnabled: false,
                label: 'Failed to fetch user notifications',
                actions: [{ label: 'Close' }],
            }),
        );
    }
}

export function createUserNotificationSaga(fetchNotifications: () => Promise<UserNotifications>) {
    return function* userNotificationsSaga() {
        yield takeLatest(actions.fetchUserNotifications.request, (args) =>
            handleFetchUserNotifications(args, fetchNotifications),
        );
    };
}
