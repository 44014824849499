import React from 'react';
import { Features } from 'modules/config/types';

type Props = {
    features: Features;
};

export const FeatureFlagContext = React.createContext<Features>({
    editBillingDetails: { enabled: true },
    deleteProject: { enabled: false },
    upgradeProject: { enabled: false },
});

export const FeatureFlags: React.FC<Props> = ({ children, features }) => (
    <FeatureFlagContext.Provider value={features}>{children}</FeatureFlagContext.Provider>
);
