import React, { useEffect } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, useParams } from '@reach/router';

import { Notification } from 'modules/notifications/components';
import { NotificationCategory } from 'modules/notifications/types';
import { paymentMethodsActions } from 'modules/payment-methods/actions';
import { retrieveProjectDetail } from 'modules/project/detail/actions';
import * as userNotificationsActions from 'modules/userNotifications/actions';
import { HubState, NotificationCategories } from 'types';
import { CenteredLoader } from 'components/CenteredLoader';
import { projectDetailSelectors } from 'modules/project/detail/selectors';
import tenantUsageActions from 'modules/tenant-usage/actions';
import Layout from '../../Layout';
import { TitleContainer } from '../Create/elements';
import { DetailView } from './Detail/DetailView';
import ProjectActionsButton from './Detail/ProjectActionsButton';
import ProjectIllustration from './Detail/ProjectIllustration';
import { LeftContent, RightContent, Title } from './elements';
import { AddCouponModal } from '../../Coupon/AddCouponModal';
import { SelectPlanModal } from '../Create/Plan/SelectPlanModal';
import projectUpdateActions from 'modules/project/update/actions';
import { PlanLimitModal } from '../../Limitations/PlanLimitModal';
import { ProjectDetailForView } from 'modules/project/detail/types';
import { useModules } from 'hooks/useModules';
import useTranslator from 'hooks/useTranslator';

const StyledDetailView = styled(DetailView)`
    margin-top: 40px;
`;

const NotificationContainer = styled.div`
    margin: 16px 0;

    &:not(:empty) + ${StyledDetailView} {
        margin-top: 0;
    }
`;

const StyledTitleContainer = styled(TitleContainer)`
    margin-bottom: 0;
`;

export const ProjectDetail: React.FC<RouteComponentProps> = () => {
    const modules = useModules();
    const t = useTranslator();
    const { projectId } = useParams();
    const dispatch = useDispatch();
    const projectDetails = useSelector<HubState, ProjectDetailForView | undefined>(
        projectDetailSelectors.selectProjectById(projectId),
    );
    const defaultPaymentMethodID = projectDetails?.subscription.defaultPaymentMethod.id;
    const endOfCurrentPeriod = projectDetails?.subscription.endOfCurrentPeriod;
    const projectStatus = projectDetails?.status;
    const prioritizedNotificationCategories: Set<NotificationCategory> = new Set([
        NotificationCategories.ProjectError,
        NotificationCategories.ProjectPendingBlocking,
        NotificationCategories.CreditCards,
    ]);

    useEffect(() => {
        dispatch(retrieveProjectDetail.request({ id: projectId }));
        dispatch(paymentMethodsActions.retrieve.request({ type: 'card' }));
        dispatch(tenantUsageActions.fetch.request({ projectId }));
    }, [dispatch, projectId]);

    useEffect(() => {
        dispatch(
            userNotificationsActions.fetchUserNotifications.request({
                defaultPaymentMethodID,
                endOfCurrentPeriod,
                projectStatus,
            }),
        );
    }, [dispatch, projectId, projectStatus, defaultPaymentMethodID, endOfCurrentPeriod]);

    return (
        <DynamicModuleLoader
            modules={[
                modules.get('projectDetail'),
                modules.get('projectTermination'),
                modules.get('projectDeletion'),
                modules.get('projectUpdate'),
                modules.get('projectRestart'),
                modules.get('notifications'),
                modules.get('userNotifications'),
                modules.get('paymentMethods'),
                modules.get('invoices'),
                modules.get('tenantUsage'),
                modules.get('projectUpgrade'),
            ]}
        >
            {projectDetails ? (
                <>
                    <Layout
                        leftContent={
                            <LeftContent>
                                <StyledTitleContainer>
                                    <Title>
                                        {t('hub-trial-project-detail-title-project-detail')}
                                    </Title>
                                    <ProjectActionsButton projectId={projectId} />
                                </StyledTitleContainer>
                                <NotificationContainer>
                                    <Notification categories={prioritizedNotificationCategories} />
                                </NotificationContainer>

                                <StyledDetailView projectId={projectId} />

                                {projectDetails && (
                                    <AddCouponModal
                                        seats={projectDetails.seats}
                                        price={projectDetails.subscription.price}
                                        projectName={projectDetails.name}
                                        projectId={projectDetails.id}
                                    />
                                )}

                                <PlanLimitModal project={projectDetails} />
                            </LeftContent>
                        }
                        rightContent={
                            <RightContent>
                                <ProjectIllustration projectDetails={projectDetails} />
                            </RightContent>
                        }
                    />
                    <SelectPlanModal
                        projectId={projectId}
                        onSubmit={({ planId, priceId }) => {
                            dispatch(
                                projectUpdateActions.changePlan.submit.request({
                                    id: projectId,
                                    planId,
                                    priceId,
                                }),
                            );
                        }}
                        onClose={() => dispatch(projectUpdateActions.changePlan.cancel())}
                    />
                </>
            ) : (
                <CenteredLoader />
            )}
        </DynamicModuleLoader>
    );
};
