import { ISagaModule } from 'redux-dynamic-modules-saga';
import saga from './saga';
import { fetchDictionary } from './actions';
import reducer from './reducer';
import { I18nModuleState } from './types';

export const getModule = (): ISagaModule<I18nModuleState> => ({
    id: 'i18n',
    reducerMap: {
        i18n: reducer,
    },
    sagas: [saga],
    initialActions: [fetchDictionary.request()],
});
