import React, { useState } from 'react';
import { contactSupport } from '../../utils/support';
import { TrashIcon } from '../../views/PaymentWizard/Details/PaymentMethod/elements';
// @ts-ignore
import Tooltip from '@igenius_dev/crystalkit/lib/components/Tooltip';
import theme from '@igenius_dev/crystalkit/lib/theme';
import Text from '@igenius_dev/crystalkit/lib/components/Text';

import styled from 'styled-components';
import useTranslator from '../../hooks/useTranslator';

// FIXME: temporary css override. CKIT component
// should be changed or upgraded to v2
const StyledTooltip = styled(Tooltip)`
    button:nth-child(2) {
        color: ${theme.colors.values.black300};
    }
`;

interface Props {}

export const TrashWithTooltip = (_props: Props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const t = useTranslator();

    return (
        <StyledTooltip
            position={Tooltip.position.main.bottomCenter}
            isDismissable
            onDismiss={(e: any) => {
                e.stopPropagation();
                setShowTooltip(false);
            }}
            show={showTooltip}
            color="white"
            title={
                <Text weight={'bold'} size={'small'}>
                    {t('hub-payment-details-payment-method-tooltip-title-remove-card')}
                </Text>
            }
            label={t('hub-payment-details-payment-method-tooltip-body-remove-card')}
            actions={[
                {
                    label: t('hub-payment-details-payment-method-tooltip-button-contact-us'),
                    color: 'purple',
                    onClick: (e: any) => {
                        e.stopPropagation();
                        contactSupport();
                    },
                },
            ]}
        >
            <TrashIcon
                onClick={(e: any) => {
                    e.stopPropagation();
                    setShowTooltip(true);
                }}
            />
        </StyledTooltip>
    );
};
