import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import theme from '@igenius_dev/crystalkit/lib/theme';
import { format } from 'date-fns';
import profileSelectors from 'modules/profile/selectors';
import {
    closeBillingDetailsModal,
    closePaymentMethodModal,
    openBillingDetailsModal,
    openInvoicesSheet,
    openPaymentMethodModal,
} from 'modules/project/detail/actions';
import {
    isBillingDetailsModalOpen,
    isPaymentMethodModalOpen,
} from 'modules/project/detail/selectors';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { download } from 'utils/download';
import { FormEditBillingDetails } from 'views/Project/Detail/Detail/FormEditBillingDetails';
import {
    BillingDetailsFormData,
    BillingItemType,
} from 'views/Project/Detail/Detail/FormEditBillingDetails/types';
import {
    getAddrItems,
    getTaxItems,
} from 'views/Project/Detail/Detail/FormEditBillingDetails/utils';
import { CreditCard } from 'components/CreditCard';
import { NonInteractiveChip } from 'components/NonInteractiveChip';
import * as billingInfoActions from 'modules/billing-info/actions';
import billingInfoSelectors from 'modules/billing-info/selectors';
import projectUpdateActions from 'modules/project/update/actions';
import { formatDateTo } from 'utils/formatDateTo';
import { formatRecurringInterval } from 'utils/formatRecurringInterval';
import { LinkAction } from '../Actions';
import { DetailTable } from '../DetailTable';
import { ChangePaymentMethod, ChangePaymentMethodFormData } from '../Payment/ChangePaymentMethod';
import { Widget } from '../Widget';
import HubModal from 'components/HubModal';
import { SubscriptionForView } from 'modules/project/detail/types';
import { useFeatures } from 'components/FeatureFlags/hook';
import { contactSupport } from 'utils/support';
import useTranslator from 'hooks/useTranslator';
import { PaymentMethod } from 'core/entities/payment-method/PaymentMethod';
import { BillingInfoForView } from 'modules/billing-info/types/types';
import uiSelectors from 'modules/ui/selectors';

const Chip = styled(NonInteractiveChip)`
    margin-right: 12px;
`;

const StyledTextLink = styled(TextLink).attrs({
    scale: 'Small',
    color: 'black300',
})``;

interface Props {
    subscription: SubscriptionForView;
    billingInfo: BillingInfoForView;
    methods: PaymentMethod[];
    projectId: string;
    editable: boolean;
}

export const Payment = ({ subscription, billingInfo, methods, projectId, editable }: Props) => {
    const t = useTranslator();
    const dispatch = useDispatch();
    const userInfo = useSelector(profileSelectors.selectUser);
    const countries = useSelector(uiSelectors.selectAllCountries);
    const taxTypes = useSelector(uiSelectors.selectAllTaxTypes);
    const shouldShowPaymentModal = useSelector(isPaymentMethodModalOpen);
    const shouldShowBillingDetailsModal = useSelector(isBillingDetailsModalOpen);
    const closePaymentModal = useCallback(() => dispatch(closePaymentMethodModal()), [dispatch]);
    const { defaultPaymentMethod, price, endOfCurrentPeriod, latestInvoice } = subscription;
    const billingAddress = useSelector(billingInfoSelectors.selectCompleteAddress);
    const { editBillingDetails } = useFeatures();

    const addressItems: BillingItemType[] = getAddrItems({
        addr: billingInfo.address,
        fullName: billingInfo.name || userInfo.company_name || '',
        countries,
    });
    const taxItems: BillingItemType[] = getTaxItems({
        taxID: billingInfo.tax?.value || '',
        currTaxType: billingInfo.tax?.label ?? { label: '', value: '' },
        taxTypes,
    });

    return (
        <>
            {editable ? (
                <>
                    <ChangePaymentMethod
                        defaultPaymentMethodId={subscription.defaultPaymentMethod.id}
                        paymentMethods={methods}
                        onSave={(data: ChangePaymentMethodFormData) => {
                            dispatch(
                                projectUpdateActions.request.request({
                                    projectData: {
                                        paymentMethodId: data.paymentMethod,
                                        id: projectId,
                                    },
                                }),
                            );
                            closePaymentModal();
                        }}
                        onClose={closePaymentModal}
                        onCancel={closePaymentModal}
                        open={shouldShowPaymentModal}
                    />
                </>
            ) : null}

            <HubModal
                open={shouldShowBillingDetailsModal}
                onClose={() => dispatch(closeBillingDetailsModal())}
                content={
                    <FormEditBillingDetails
                        onCancel={() => dispatch(closeBillingDetailsModal())}
                        onSubmit={(data: BillingDetailsFormData) =>
                            dispatch(billingInfoActions.editBillingDetails.request(data))
                        }
                        addrItems={addressItems}
                        taxItems={taxItems}
                    />
                }
            />

            <Widget title={t('hub-trial-project-detail-section-title-payment-details')}>
                <DetailTable
                    list={[
                        {
                            name: 'rowBillingType',
                            label: t('hub-trial-project-detail-label-billing-type'),
                            value: (
                                <>
                                    <Chip>{t(formatRecurringInterval(price.timeInterval))}</Chip>

                                    {editable
                                        ? t('hub-label-payment-details-recurrence-due', {
                                              payload: {
                                                  date: formatDateTo(endOfCurrentPeriod),
                                              },
                                          })
                                        : ''}
                                </>
                            ),
                            action: (
                                <LinkAction
                                    label={t(
                                        'hub-trial-project-detail-billing-type-textlink-change',
                                    )}
                                    disabled={!editable}
                                    onClick={() =>
                                        dispatch(
                                            projectUpdateActions.changePlan.start({
                                                id: projectId,
                                            }),
                                        )
                                    }
                                />
                            ),
                        },
                        {
                            name: 'rowPaymentMethod',
                            label: t('hub-trial-project-detail-label-payment-method'),
                            value: (
                                <CreditCard
                                    brand={defaultPaymentMethod.brand}
                                    lastDigits={defaultPaymentMethod.lastDigits}
                                    scale="small"
                                    cardNameColor={theme.colors.values.black300}
                                    cardNumberColor={theme.colors.values.black300}
                                    expiration={defaultPaymentMethod.expiration}
                                />
                            ),
                            action: (
                                <LinkAction
                                    label={t(
                                        'hub-trial-project-detail-payment-method-textlink-change',
                                    )}
                                    disabled={!editable}
                                    onClick={() => {
                                        dispatch(openPaymentMethodModal());
                                    }}
                                />
                            ),
                        },
                        {
                            name: 'rowBillingDetails',
                            label: t('hub-trial-project-detail-label-billing-details'),
                            value: t('hub-trial-project-detail-label-billing-address', {
                                payload: {
                                    ADDRESS: billingAddress,
                                },
                            }),
                            action: (
                                <div data-testid="edit-billing-details">
                                    {editBillingDetails.enabled ? (
                                        <LinkAction
                                            label={t(
                                                'hub-trial-project-detail-textlink-billing-details-change',
                                            )}
                                            onClick={() => dispatch(openBillingDetailsModal())}
                                        />
                                    ) : (
                                        <LinkAction
                                            label="Contact support"
                                            onClick={contactSupport}
                                        />
                                    )}
                                </div>
                            ),
                        },
                        {
                            name: 'rowInvoices',
                            label: t('hub-trial-project-detail-label-invoices'),
                            value: latestInvoice ? (
                                <>
                                    <Chip>
                                        {t(
                                            latestInvoice.paid
                                                ? 'hub-trial-project-detail-chip-paid'
                                                : 'hub-trial-project-detail-chip-pending',
                                        )}
                                    </Chip>
                                    <StyledTextLink
                                        onClick={() => download(latestInvoice?.pdfLink)}
                                    >
                                        {t('hub-trial-project-detail-label-last-invoice', {
                                            payload: {
                                                DATE: format(
                                                    new Date(latestInvoice.createdAt),
                                                    'dd/MM/yyyy',
                                                ),
                                            },
                                        })}
                                    </StyledTextLink>
                                </>
                            ) : (
                                t('-')
                            ),
                            action: (
                                <LinkAction
                                    label={t('hub-trial-project-detail-textlink-invoices-see-all')}
                                    onClick={() => dispatch(openInvoicesSheet())}
                                />
                            ),
                        },
                        {
                            name: 'rowCoupon',
                            label: t('hub-trial-project-detail-label-coupon'),
                            value: subscription.coupon ? (
                                <NonInteractiveChip>{`${subscription.coupon.code} ${subscription.coupon.discount}`}</NonInteractiveChip>
                            ) : (
                                t('hub-trial-project-detail-message-no-coupon-applied')
                            ),
                            action: (
                                <LinkAction
                                    disabled={!editable || !!subscription.coupon}
                                    label={t('hub-trial-project-detail-textlink-apply-coupon')}
                                    onClick={() => {
                                        dispatch(projectUpdateActions.coupon.showModal());
                                    }}
                                />
                            ),
                        },
                    ]}
                />
            </Widget>
        </>
    );
};
