import { CouponDto } from '../../modules/project/types/dto';
import { Coupon, Discount, Duration } from '../../core/entities/Coupon';

export const adaptToCoupon = (coupon?: CouponDto): Coupon | undefined => {
    if (!coupon) {
        return undefined;
    }

    let adaptedCoupon: Coupon = {
        duration: adaptDuration(coupon.duration, coupon.duration_in_months),
        id: coupon.id,
        off: adaptOff(coupon.amount_off, coupon.percent_off),
    };

    if (coupon.currency) {
        adaptedCoupon.currency = coupon.currency;
    }

    return adaptedCoupon;
};

function adaptDuration(asString: string, asMonths?: number): Duration {
    let duration: Duration = {
        asString,
    };

    if (asMonths) {
        duration.asMonths = asMonths;
    }

    return duration;
}

function adaptOff(amount?: number, percent?: number): Discount {
    let off: Discount = {};

    if (amount) {
        off.amount = amount;
    }

    if (percent) {
        off.percent = percent;
    }

    return off;
}
