import { ISagaModule } from 'redux-dynamic-modules-saga';
import { projectListReducer } from './reducer';
import { ProjectListModuleState } from './types';
import { ProjectRepository } from '../../../core/repository/project/ProjectRepository';
import createProjectListSaga from './saga';

const getProjectListModule = (
    repository: ProjectRepository,
): ISagaModule<ProjectListModuleState> => {
    return {
        id: 'projectList',
        sagas: [createProjectListSaga(repository)],
        reducerMap: {
            projectList: projectListReducer,
        },
    };
};

export default getProjectListModule;
