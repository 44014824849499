import { createAction, createAsyncAction } from 'typesafe-actions';
import { ProjectId } from 'core/entities/project/Project';

const restart = createAsyncAction(
    '@project-update/RESTART_REQUEST',
    '@project-update/RESTART_SUCCESS',
    '@project-update/RESTART_FAILURE',
)<{ id: ProjectId }, undefined, undefined>();

const openRestartModal = createAction('@project-update/RESTART_OPEN')();
const closeRestartModal = createAction('@project-update/RESTART_CLOSE')();

export const projectRestartActions = {
    restart,
    openRestartModal,
    closeRestartModal,
};
