import styled, { css } from 'styled-components';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import theme from '@igenius_dev/crystalkit/lib/theme';

export const Label = styled(Text).attrs((props) => ({
    scale: props.scale ?? 'Base',
}))`
    font-weight: ${theme.typography.weights.values.medium};
`;

export const Price = styled(Text).attrs<{ withStrikethrough: boolean }>({
    scale: 'Title',
})`
    ${(props) =>
        props.withStrikethrough &&
        css`
            text-decoration: line-through;
        `};
`;

export const DuePrice = styled(Price).attrs({
    color: 'green500',
})`
    text-decoration: none;
`;

export const SubscriptionType = styled(Text).attrs({
    scale: 'Small',
})``;

export const Left = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Right = styled.div`
    ${Price} {
        margin-right: 8px;
    }
`;

export const PriceContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const VatIncluded = styled(Text).attrs({
    scale: 'Small',
    color: theme.colors.values.black200,
})``;
