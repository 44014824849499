import { ISagaModule } from 'redux-dynamic-modules-saga';

import { invoicesReducer } from './reducer';
import { createInvoicesSaga } from './saga';
import { FetchInvoicesUseCase } from 'core/entities/invoice/FetchInvoicesUseCase';
import { InvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';
import { Invoices } from 'core/entities/invoice/Invoices';
import { InvoicesModuleState } from 'modules/invoices/types';

export const getInvoicesModule = (
    useCase: FetchInvoicesUseCase,
    adaptToView: (invoices: Invoices) => InvoicesForView,
): ISagaModule<InvoicesModuleState> => ({
    id: 'invoices',
    reducerMap: { invoices: invoicesReducer as any },
    sagas: [createInvoicesSaga(useCase, adaptToView)],
});
