import styled from 'styled-components';

import theme from '@igenius_dev/crystalkit/lib/theme';

const leftColWidth = 30;
const rightColWidth = 20;
const centerColWidth = 100 - (leftColWidth + rightColWidth);

export const Row = styled.div`
    display: flex;
    padding: 22px 0;
    border-bottom: 1px solid ${theme.colors.values.blue50};
    align-items: center;
`;

export const StyledTable = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-top: 8px;

    ${Row}:last-child {
        border-bottom: none;
    }
    ${Row}:nth-child(3) {
        align-items: flex-start;
    }
`;

export const FieldLabel = styled.span`
    width: ${leftColWidth}%;
    display: flex;
`;

export const FieldValue = styled.div`
    width: ${centerColWidth}%;
    display: flex;
`;

export const FieldAction = styled.div`
    width: ${rightColWidth}%;
    justify-content: flex-end;
    display: flex;
    text-align: right;
`;
