import Button from '@igenius_dev/crystalkit/lib/components/Button';
import Icon from '@igenius_dev/crystalkit/lib/components/Icon';
// @ts-ignore
import Img from '@igenius_dev/crystalkit/lib/components/Img';
import Text from '@igenius_dev/crystalkit/lib/components/Text';
import TextLink from '@igenius_dev/crystalkit/lib/components/TextLink';
import theme from '@igenius_dev/crystalkit/lib/theme';
import styled from 'styled-components';
import americanExpress from '../../../../assets/credit-cards/american_express.png';
import masterCard from '../../../../assets/credit-cards/mastercard.png';
import visa from '../../../../assets/credit-cards/visa.png';
import { StyledSubtitle } from '../elements';
import Radio from '@igenius_dev/crystalkit/lib/components/Radio';

export const CardBrandImage = styled.div`
    border: 1px solid ${theme.colors.values.blue50};
    border-radius: 4px;
    padding: 0 6px;
    width: 40px;
    height: 27px;
    background-color: white;
`;

export const Visa = styled(Img).attrs({
    src: visa,
})``;

export const MasterCard = styled(Img).attrs({
    src: masterCard,
})``;

export const AmericanExpress = styled(Img).attrs({
    src: americanExpress,
})``;

export const CardBrand = styled('div')`
    margin-left: 16px;
    display: flex;
    align-items: center;
`;

export const CardBrandName = styled(Text).attrs((props) => ({
    scale: props.scale === 'small' ? 'Small' : 'Base',
    color: props.color ? props.color : theme.colors.values.black500,
}))`
    text-transform: capitalize;
`;

export const CardBrandNumber = styled(Text).attrs((props) => ({
    scale: props.scale === 'small' ? 'Small' : 'Base',
    color: props.color ? props.color : theme.colors.values.black500,
}))`
    margin-left: 7px;
`;

const Rectangle = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 70px;
    width: 100%;
    border: 1px solid
        ${({ selected }) => (selected ? theme.colors.values.purple100 : theme.colors.values.blue50)};
    background-color: ${({ selected }) => (selected ? theme.colors.values.purple15 : 'white')};
    cursor: pointer;
`;

export const StyledRectangle = styled(Rectangle)`
    justify-content: space-between;
`;

export const RectangleRowContainer = styled.div`
    display: flex;
    margin-top: 16px;
    flex-direction: column;

    ${StyledRectangle} {
        &:first-child {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }

        &:last-child {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
`;

export const RadioLabel = styled.div`
    display: flex;
    align-items: center;
    position: relative;
`;

export const TrashIcon = styled(Icon).attrs({
    icon: 'trash',
})`
    color: ${theme.colors.values.black400};

    :hover {
        color: ${theme.colors.values.red500};
    }
`;

export const AddPaymentMessage = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
`;

export const FirstPaymentMessage = styled(AddPaymentMessage)`
    margin-top: 24px;
`;

export const CreditCardBrands = styled.div`
    display: flex;
    align-items: center;

    & > * {
        margin-right: 8px;
    }

    & > *:last-child {
        margin-right: 0;
    }
`;
export const NewPaymentMethod = styled.div`
    color: ${theme.colors.values.purple700};
    display: inline-flex;
    align-items: center;
    cursor: pointer;
`;

export const AddPaymentMethod = styled(Text).attrs({
    scale: 'Small',
})`
    margin-left: 8px;
`;

export const AddIcon = styled(Icon).attrs({
    icon: 'add',
    size: 'small',
})`
    display: inline;
    color: ${theme.colors.values.purple700};
`;

export const ExpirationDate = styled(Text).attrs({ color: theme.colors.values.black500 })`
    margin-right: 16px;
`;

export const AddCreditCard = styled(Button).attrs({
    icon: 'add',
    size: 'small',
})`
    height: 36px;
    display: flex;
    align-items: center;
`;

export const SubtitleWithMargin = styled(StyledSubtitle)`
    margin-top: 8px;
`;

export const ContactUsLink = styled(TextLink).attrs({
    scale: 'Small',
    underline: true,
    color: 'black300',
})`
    color: ${theme.colors.values.black300};
    border-bottom-color: ${theme.colors.values.black300};
`;

export const PurpleRadioInput = styled(Radio).attrs({
    isControlled: false,
})`
    color: ${theme.colors.values.purple700};
    margin-right: 12px;
    min-width: 26px;
`;
