import { Vat, VatType, VatTypes } from 'common/utils/VatTypes';

type VatFixture = VatType;

export class FixtureVatTypes implements VatTypes {
    constructor(private readonly fixtures: VatFixture[]) {}

    findBy(value: string, country: string): VatType {
        const entry = this.fixtures.find((vat) => vat.value === value && vat.country === country);

        if (entry) {
            return entry;
        }

        throw new Error(`Cannot find an entry with value ${value} and country ${country}`);
    }

    list(): Vat[] {
        return this.fixtures.map(({ label, value }) => ({ label, value }));
    }
}
