import { InvoicesForView } from 'modules/invoices/adapter/toInvoicesForView';

export type InvoicesModuleState = { invoices: InvoicesState };
export type InvoicesState = {
    status: InvoicesStatus;
    invoices: InvoicesForView;
};

export enum InvoicesStatus {
    Loading = 'LOADING',
    Done = 'DONE',
    Error = 'ERROR',
}
