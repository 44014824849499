import { LabelValuePair } from 'common/types';
import { BillingItemType } from './types';
import { BillingAddressForView } from 'modules/billing-info/types/types';

type GetAddrItemsParams = {
    addr: BillingAddressForView | undefined;
    fullName: string;
    countries: LabelValuePair[];
};
export const getAddrItems = (params: GetAddrItemsParams): BillingItemType[] => {
    return [
        {
            name: 'country',
            formType: 'select',
            label: 'hub-billing-details-dialogue-label-country',
            placeholder: 'hub-billing-details-dialogue-label-country-placeholder',
            defaultValue: params.addr?.country ?? ('' as any),
            items: params.countries,
        },
        {
            name: 'fullName',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-name',
            placeholder: 'hub-billing-details-dialogue-label-name-company-placeholder',
            defaultValue: params.fullName,
        },
        {
            name: 'line1',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-address',
            placeholder: 'hub-billing-details-dialogue-label-address-placeholder',
            defaultValue: params.addr?.line1 || '',
        },
        {
            name: 'zipCode',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-zip-code',
            placeholder: 'hub-billing-details-dialogue-label-zip-code-placeholder',
            defaultValue: params.addr?.postalCode || '',
        },
        {
            name: 'city',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-city',
            placeholder: 'hub-billing-details-dialogue-label-city-placeholder',
            defaultValue: params.addr?.city || '',
        },
        {
            name: 'state',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-state',
            placeholder: 'hub-billing-details-dialogue-label-state-placeholder',
            defaultValue: params.addr?.state || '',
        },
    ];
};

type GetTaxItemParams = {
    taxID: string;
    taxTypes: LabelValuePair[];
    currTaxType: LabelValuePair;
};
export const getTaxItems = (params: GetTaxItemParams): BillingItemType[] => {
    return [
        {
            name: 'taxIDType',
            formType: 'select',
            label: 'hub-billing-details-dialogue-label-tax-type',
            placeholder: 'hub-billing-details-dialogue-label-tax-type-placeholder',
            defaultValue: params.currTaxType,
            items: params.taxTypes,
        },
        {
            name: 'taxIDValue',
            formType: 'textField',
            label: 'hub-billing-details-dialogue-label-tax-id',
            placeholder: 'hub-billing-details-dialogue-label-tax-id-placeholder',
            defaultValue: params.taxID,
        },
    ];
};
