import React from 'react';

import Dialog from '@igenius_dev/crystalkit/lib/components/Dialog';

// @ts-ignore
import somethingWentWrong from 'assets/29.png';
import { HubIllustrationDialog } from 'components/HubIllustrationDialog';
import useTranslator from 'hooks/useTranslator';

type DoYouReallyWantToDeleteProjectProps = {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
};

const DoYouReallyWantToDeleteProject: React.FC<DoYouReallyWantToDeleteProjectProps> = ({
    open = false,
    onClose,
    onSubmit,
}) => {
    const t = useTranslator();

    return (
        <HubIllustrationDialog
            open={open}
            onClose={onClose}
            actions={[
                {
                    label: t('hub-dialogue-unsubscribe-from-this-project-button-no'),
                    onClick: onClose,
                },
                {
                    label: 'Yes, delete it',
                    onClick: onSubmit,
                    negative: true,
                },
            ]}
            illustration={{
                title: 'Are you sure you want to delete this project?',
                imageSrc: somethingWentWrong,
                imageSize: Dialog.IllustrationContent.imageSize.small,
            }}
        />
    );
};

export default DoYouReallyWantToDeleteProject;
