import { useSelector } from 'react-redux';
import styled from 'styled-components';
import React from 'react';

import { RouteComponentProps } from '@reach/router';
import { FormProvider, useForm } from 'react-hook-form';
import { selectPaymentMethods } from '../../../modules/payment-methods/selectors';
import { AddingPaymentMethod } from '../../../modules/payment-methods/types/types';
import { HubState } from '../../../types';
import { PageTitle, PaymentWizardWidget, SubmitButton } from '../elements';
import { GenericStep } from '../GenericStep';
import { OrderSummary, SummaryProps } from '../Summary/OrderSummary';
import { FormType, InvoiceDetails } from './InvoiceDetails/InvoiceDetails';
import { PaymentMethods } from './PaymentMethod/PaymentMethods';
import { Address, TaxId } from 'common/types';
import useTranslator from '../../../hooks/useTranslator';
import { PaymentMethod } from '../../../core/entities/payment-method/PaymentMethod';

const SectionContainer = styled.div`
    margin-bottom: 40px;

    &:last-child {
        margin-bottom: 0;
    }
`;

type Props = {
    invoice: {
        address?: Address;
        taxId?: TaxId;
        type: FormType;
    };
    currentPaymentMethodId?: string;
    onSubmit: (data: PaymentDetails) => void;
    onPaymentTypeUpdate: (priceId: string) => void;
    onPaymentMethodSubmit: (p: AddingPaymentMethod) => void;
    summary: Omit<SummaryProps, 'actionButton'>;
};

export type PaymentDetails = {
    card: string;
    formType: FormType;
} & (Address & TaxId);

export const PaymentDetailsStep: React.FC<RouteComponentProps & Props> = (props) => {
    const {
        summary,
        onPaymentMethodSubmit,
        currentPaymentMethodId,
        invoice: { address, taxId, type },
    } = props;
    const t = useTranslator();
    const formProps = useForm<PaymentDetails>({
        mode: 'all',
    });
    const paymentMethods = useSelector<HubState, PaymentMethod[]>(selectPaymentMethods);
    const isSubmitButtonDisabled = !formProps.formState.isValid;

    return (
        <GenericStep
            left={
                <FormProvider {...formProps}>
                    <PageTitle>{t('hub-payment-details-page-title')}</PageTitle>
                    <PaymentWizardWidget>
                        <SectionContainer>
                            <PaymentMethods
                                paymentMethods={paymentMethods}
                                onSubmit={onPaymentMethodSubmit}
                                currentPaymentMethodId={currentPaymentMethodId}
                            />
                        </SectionContainer>
                        <SectionContainer>
                            <InvoiceDetails address={address} taxId={taxId} type={type} />
                        </SectionContainer>
                    </PaymentWizardWidget>
                </FormProvider>
            }
            right={
                <OrderSummary
                    disclaimer={summary.disclaimer}
                    seats={summary.seats}
                    subscription={summary.subscription}
                    plan={summary.plan}
                    coupon={summary.coupon}
                    actionButton={
                        <SubmitButton
                            disabled={isSubmitButtonDisabled}
                            label={t('hub-column-recap-continue')}
                            onClick={formProps.handleSubmit(props.onSubmit)}
                        />
                    }
                />
            }
        />
    );
};
